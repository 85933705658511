import { utils, writeFile } from "xlsx";

export const handleExportCsv = (fileName, data) => {
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);

    if (!fileName) throw new Error("fileName must be given")
    if (!data) throw new Error("data must be given")
    if (typeof fileName !== "string") throw new TypeError("fileName must be a string")
    if (!Array.isArray(data)) throw new TypeError("data must be an array")

    utils.sheet_add_json(ws, data, {
        origin: "A2",
        skipHeader: true,
    });
    utils.book_append_sheet(wb, ws, "Report");
    writeFile(wb, `${fileName}.xlsx`);
}