import React from "react"

const InfoItem = ({ label1, label2, data1, data2 }) => {
    return (
        <div className='row apercu--item border-bottom py-2'>
            <div className='col-sm-6 col-12'>
                <span>{label1} : {data1 ? <span className="fw-bold">{data1}</span> : <span className='required--info'>Information indispensable</span>}</span>
            </div>
            {
                label2 &&
                <div className='col-sm-6 col-12'>
                    <span>{label2} : {data2 ? <span className="fw-bold">{data2}</span> : <span className='required--info'>Information indispensable</span>}</span>
                </div>
            }
        </div>
    )
}

export default InfoItem