/* eslint-disable operator-linebreak */
import { rootStyles } from "assets/css/globalCss";
import FadeTransition from "components/shared/FadeTransition";
import Title3 from "components/shared/Title3";
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useSelector } from "react-redux";
import styled from "styled-components";

const ConduiteContainer = styled.table`
  border: 2px solid;
  border-collapse: collapse;
  margin-bottom: 30px;
`;
const ConduiteRow = styled.tr`
  border: 1px solid;
`;
const ConduiteColumn = styled.td`
  border: 1px solid;
  font-weight: 500;
`;
const Paragraphe = styled.p`
  font-size: ${rootStyles.fonts.fontSize};
`;

const BulletinResultat = () => {
  const { currentBulletin } = useSelector((state) => state.examens);
  const columns = useMemo(
    () => [
      {
        //   module
        dataField: "modules",
        text: "Modules",
        // sort: true
      },
      {
        dataField: "note_1",
        text: "Théorie",
      },
      {
        dataField: "note_2",
        text: "Pratique",
        // sort: true,
        //    formatter: (cell, row) => row.comment_of_coach ?? "",
      },
      {
        dataField: "moyenne_note",
        text: "Moyenne",
        // sort: true,
        //    formatter: (cell, row) => row.comment_of_coach ?? "",
      },
    ],
    []
  );
  return (
    <FadeTransition in={true}>
      <BootstrapTable
        keyField="id"
        data={currentBulletin.notes_bulletin}
        striped
        columns={columns}
        noDataIndication={() => "Pas encore de notes"}
        //   pagination={paginationFactory()}
        search
        rowClasses="custom-bulletin-row-class"
      />
      <ConduiteContainer>
        <ConduiteRow>
          <ConduiteColumn>Moyenne conduite</ConduiteColumn>
          <ConduiteColumn>
            {currentBulletin.conduite?.moyenne_conduite ?? "..."}
          </ConduiteColumn>
        </ConduiteRow>
        <ConduiteRow>
          <ConduiteColumn>Moyenne generale</ConduiteColumn>
          <ConduiteColumn>
            {currentBulletin.moyenne_globale ?? "..."}
          </ConduiteColumn>
        </ConduiteRow>
      </ConduiteContainer>

      <Title3>Remarque</Title3>
      <Paragraphe>
        {currentBulletin.comment_of_coach !== "NULL"
          ? currentBulletin.comment_of_coach
          : "..."}
      </Paragraphe>
    </FadeTransition>
  );
};

export default BulletinResultat;
