import axios from "axios";

export const API_IMAGE_URL = "https://via-api.bakeli.tech/uploads/rencontre_files/"

/**
 * BASE_API est defini dans .env.cmdrc
 */
export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API,
    // timeout: 10000,
    // timeoutErrorMessage: 'Request takes too longs',
    headers: {
        "Authorization": "Bearer "
    }
})

export const axiosSwrFetcher = (url) => axiosInstance.get(url).then((res) => res.data.data)
