import { rootStyles } from "assets/css/globalCss";
import { Badge } from "pages/bakelistes/Details/DetailsBakelistePage";
import React from "react"
import { BsPhone } from "react-icons/bs";
import { CiMail } from "react-icons/ci";
import { FiUserCheck } from "react-icons/fi";
import { FcAssistant } from "react-icons/fc";
import styled from "styled-components";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { tooltip_options } from "utils/tooltip_options";
import { FaChevronRight } from "react-icons/fa";

const ProspectCotainer = styled.div`
    background-color: ${rootStyles.color.primary};
    padding: ${rootStyles.padding.btnPadding};
    border-radius: ${rootStyles.radius.card};
    width:100%;
    cursor: pointer;
    box-shadow: ${rootStyles.shadow.card};
    position: relative
`
const Paragraphe = styled.div`
  font-size: ${rootStyles.fonts.fontSize};
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media only screen and (max-width: 500px) {
    font-size: 12px;
  }
`;
const Span = styled.span`
    font-weight: ${rootStyles.fonts.btnWeight}
`

const ProspectCardItem = ({ prospect, openConfirmation, convertProspectToBakeliste, navigateTo }) => {
    const { firstName, lastName, phone, canal_infos, study_choice, email, is_called } = prospect
    console.log({ prospect });
    return (
        <ProspectCotainer onClick={() => navigateTo(prospect)}>
            <div className='text-end mb-2'>
                <FaChevronRight
                    onClick={(e) => {
                        e.stopPropagation(); convertProspectToBakeliste(prospect)
                    }}
                    data-tip
                    data-tooltip-id='convertir-btn'
                    size={20}
                    style={{ cursor: "pointer" }}
                />
            </div>
            <Span>{firstName} {lastName}</Span>
            <p className='m-0 my-1'><Badge>{study_choice ?? ""}</Badge></p>
            <Paragraphe><CiMail />{email}</Paragraphe>
            <Paragraphe><BsPhone /> {phone}</Paragraphe>
            {
                !is_called ?
                    <div className='mt-2'>
                        <Badge
                            onClick={(e) => {
                                e.stopPropagation(); openConfirmation(prospect)
                            }}
                            bgColor={rootStyles.color.red}
                            color={rootStyles.color.primary}
                        >
                            <FcAssistant
                                size={25}
                                style={{ cursor: "pointer" }}
                                data-tip
                                data-tooltip-id='call-btn'
                            />
                        </Badge>
                    </div> :
                    <div className='mt-2'>
                        <Badge
                            disabled={true}
                            bgColor={rootStyles.color.secondary}
                            color={rootStyles.color.primary}
                        >
                            <FiUserCheck
                                data-tip
                                data-tooltip-id='called-btn'
                                color={rootStyles.color.primary}
                            />
                            <span style={{ fontSize: "10px", marginLeft: "5px" }}>Appelé</span>
                        </Badge>
                    </div>
            }
            <ReactTooltip style={tooltip_options} id='call-btn'>
                Déclarez comme appelé
            </ReactTooltip>
            <ReactTooltip style={tooltip_options} id='called-btn'>
                Prospect appelé
            </ReactTooltip>
            <ReactTooltip style={tooltip_options} id='convertir-btn'>
                Convertir en Bakeliste
            </ReactTooltip>
            <p className='d-flex justify-content-end mt-2'>
                <Badge bgColor={null}>{canal_infos}</Badge>
            </p>
        </ProspectCotainer>
    )
}

export default ProspectCardItem