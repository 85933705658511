import Modal from "components/Modal"
import BloqueBakelisteWithMotif from "features/bloqueBakelisteWithMotif/BloqueBakelisteWithMotif"
import { BloqueBakelisteWithMotifProvider } from "features/bloqueBakelisteWithMotif/Provider"
import React from "react"

const BloquerAvecMotif = ({ currentBakeliste, openMotifModal, setOpenMotifModal }) => {
    return (
        <BloqueBakelisteWithMotifProvider>
            <Modal
                opened={openMotifModal}
                onClosed={() => setOpenMotifModal(false)}
                title={"Veuillez ajouter le motif pour lequel vous bloquez ce bakeliste"}
                modalWidth={600}
            >
                <BloqueBakelisteWithMotif
                    onClosed={() => setOpenMotifModal(false)}
                    currentBakeliste={currentBakeliste} />
            </Modal>
        </BloqueBakelisteWithMotifProvider>
    )
}

export default BloquerAvecMotif