import { siAdminAPi } from "redux/rtkquery";
import { GET_USERS_HISTORY_ENDPOINT } from "routes/api/endpoints";

const historiquesApi = siAdminAPi.injectEndpoints({
  endpoints: (builder) => ({
    getUsersHistory: builder.query({
      query: () => GET_USERS_HISTORY_ENDPOINT,
      providesTags: ["historiques"],
    }),
  }),
});

export const { useGetUsersHistoryQuery } = historiquesApi;
