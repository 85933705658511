import React from "react"
import toast from "react-hot-toast"

import Modal from "components/Modal"
import { useUpdateStagiaireMutation } from "pages/bakelistes/api/mutations"
import StagiaireForm from "./StagiaireForm"

const EditStagiaireModal = ({ openEditStagiaire, onClosed, defaultValue }) => {
    const [updateStagiaire, { isLoading: isUpdating }] = useUpdateStagiaireMutation()
    const onSubmit = (data) => {
        updateStagiaire({ data, id: defaultValue.id })
            .unwrap()
            .then(() => {
                toast.success("Stagiaire modifié");
                onClosed();
            })
            .catch((err) => {
                console.log({ err });
                toast.error("Une erreur est survenue");
            });
    };
    return (
        <Modal
            title={"Modifier Stagiaire"}
            opened={openEditStagiaire}
            onClosed={onClosed}
        >
            <StagiaireForm defaultValue={defaultValue} onSubmit={onSubmit} isSubmiting={isUpdating} />
        </Modal>
    )
}

export default EditStagiaireModal