const { createSlice } = require("@reduxjs/toolkit");

const partenaireSlice = createSlice({
    name: "partenaires",
    initialState: {
        currentPartenaire: null,
        currentProgrammeApprenants: []
    },
    reducers: {
        setCurrentPartenaire: (state, { payload }) => {
            return {
                ...state,
                currentPartenaire: payload
            }
        },
        setCurrentProgrammeApprenants: (state, { payload }) => {
            return {
                ...state,
                currentProgrammeApprenants: payload
            }
        }
    }
})

export const { setCurrentPartenaire, setCurrentProgrammeApprenants } = partenaireSlice.actions
export default partenaireSlice.reducer