import React, { useState } from "react";
import { Title2 } from "components/shared/TableListeView";
import { usePartenaires } from "../api/hooks/usePartenaires";
import ButtonComp from "components/ButtonComp";
import { rootStyles } from "assets/css/globalCss";
import FlexBetween from "components/shared/FlexBetween";
import Loader from "components/shared/Loader";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentPartenaire } from "redux/features/partenaireSlice";
import EditPartenaireModal from "./components/partenaires/EditPartenaireModal";
import { IoMdAddCircle } from "react-icons/io";
import AddPartenaireModal from "./components/partenaires/AddPartenaireModal";
import PartenaireCardItem from "./components/partenaires/PartenaireCardItem";
import { Container } from "pages/bakelistes/BakelisteHomePageDashboard";
import ResourceDataMap from "components/ResourceDataMap";
import FadeTransition from "components/shared/FadeTransition";

const PartenairePage = () => {
  const [openPartenaireModal, setOpenPartenaireModal] = useState(false);
  const [openEditPartenaireModal, setOpenEditPartenaireModal] = useState(false);
  const { partenaires, isError, isLoading } = usePartenaires();
  const [selectedPartenaire, setSelectedPartenaire] = useState(null);
  // const [deletePartenaire] = useDeletePartenairesMutation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateTo = (partenaire) => {
    dispatch(setCurrentPartenaire(partenaire));

    return navigate(`${partenaire.name}`);
  };
  const editPartenaireFunc = (partenaire) => {
    setOpenEditPartenaireModal(true);
    setSelectedPartenaire(partenaire);
  };
  // const deletePartenaireFunc = (partenaire) => {
  //   toast.promise(deletePartenaire(partenaire.id), {
  //     loading: "Réintégration",
  //     success: partenaire.name + " supprimé",
  //     error: `La réintégration de ${partenaire.name} a esupprimé`,
  //   });
  // };

  const ClonedPartenaireCardItem = ({ partenaire }) =>
    React.cloneElement(<PartenaireCardItem partenaire={partenaire} />, {
      navigateTo,
      editPartenaireFunc,
    });

  return (
    <FadeTransition in={true}>
      {isError ? (
        <p>Une erreur est survenue lors du chargement des partenaires</p>
      ) : (
        <>
          {openPartenaireModal && (
            <AddPartenaireModal
              openAddPartenaire={openPartenaireModal}
              onClosed={() => setOpenPartenaireModal(false)}
            />
          )}
          {openEditPartenaireModal && (
            <EditPartenaireModal
              selectedPartenaire={selectedPartenaire}
              openEditPartenaire={openEditPartenaireModal}
              onClosed={() => setOpenEditPartenaireModal(false)}
            />
          )}
          <FlexBetween>
            <Title2>Liste des partenaires</Title2>
            <ButtonComp
              icon={{
                name: IoMdAddCircle,
              }}
              title="Nouveau"
              onClick={() => setOpenPartenaireModal(true)}
              bgColor={rootStyles.color.secondary}
              color={rootStyles.color.primary}
            />
          </FlexBetween>
          {isLoading ? (
            <Loader />
          ) : partenaires.data.length ? (
            // <GridList
            //   resourceData={partenaires.data}
            //   resourceName="partenaire"
            //   resourceItem={ClonedPartenaireCardItem}
            //   cardWidth={100}
            //     />
            <Container>
              <ResourceDataMap
                resourceData={partenaires.data}
                resourceName="partenaire"
                resourceItem={ClonedPartenaireCardItem}
              />
            </Container>
          ) : (
            <p>Pas encore de partenaires</p>
          )}
        </>
      )}
    </FadeTransition>
  );
};

export default PartenairePage;
