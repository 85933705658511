import SelectOnChange from "components/shared/SelectOnChange"
import { useAnnees } from "pages/paiements/api/hooks/useAnneesDePaiement";
import React from "react"

const FilterYearComp = ({ setFilterYear }) => {
    const { anneesDePaie, isAnneesLoading } = useAnnees();
    console.log({ year: new Date().getFullYear() });
    return (
        <SelectOnChange
            defaultValue={new Date().getFullYear()}
            label="Année"
            onChange={(value) => setFilterYear(value)}
        >
            {/* <option value="">Veuillez sélectionner le mois de paie</option> */}
            {!isAnneesLoading &&
                anneesDePaie.map((mois) => (
                    <option key={mois.annee} value={mois.annee}>
                        {mois.annee}
                    </option>
                ))}
        </SelectOnChange>
    )
}

export default FilterYearComp