import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { API_IMAGE_URL } from "redux/apiController/axiosInstance";
import styled from "styled-components";

import { FcOvertime } from "react-icons/fc";
import { BsWhatsapp } from "react-icons/bs";
import { BsLink } from "react-icons/bs";
import { BiTime } from "react-icons/bi";
// import { SiMicrosoftexcel } from "react-icons/si";

import { rootStyles } from "assets/css/globalCss";
import logoBakeli from "../../assets/img/bakeli-best-talent.jpg";

import Flex from "components/shared/Flex";
import { Badge } from "pages/bakelistes/Details/DetailsBakelistePage";
import Title3 from "components/shared/Title3";
// import ButtonComp from "components/ButtonComp";

import filterFactory from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import FadeTransition from "components/shared/FadeTransition";
import { groupByCanalInfosData } from "utils/groupByCanalInfos";
// import { handleExportCsv } from "utils/handleExportCsv";
import ExportExcel from "components/Export/ExportExcel";

const ContainerDetails = styled.div`
  min-height: 400px;
  background-color: ${rootStyles.color.primary};
  border-radius: ${rootStyles.radius.card};
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
`;
const ImageContainer = styled.div`
  height: 400px;
`;
const TimeSpan = styled.span`
  font-size: 12px;
  display: block;
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 2px;
`;
const CardBody = styled.div`
  padding: ${rootStyles.padding.btnPadding};
`;
const CardTitle = styled.h2`
  font-size: ${rootStyles.fonts.h2FontSize};
`;
const CardText = styled.p`
  font-size: ${rootStyles.fonts.fontSize};
`;
const CanalInfosContainer = styled.div`
  padding: 10px 0px;
`;

// const MyExportCSV = (props) => {
//   const handleClick = () => {
//     props.onExport();
//   };

//   return (
//     <div className="mt-3 mb-2">
//       <ButtonComp
//         bgColor={rootStyles.color.secondary}
//         color={rootStyles.color.primary}
//         title="Export CSV"
//         className=""
//         onClick={handleClick}
//       />
//     </div>
//   );
// };

const DetailsEvenementPage = () => {
  const { currentEvenement } = useSelector((state) => state.evenements);

  const groupByCanalInfos = groupByCanalInfosData(currentEvenement.participant)

  // const handleExport = () => {
  //   const wb = utils.book_new();
  //   const ws = utils.json_to_sheet([]);

  //   utils.sheet_add_json(ws, currentEvenement.participant, {
  //     origin: "A2",
  //     skipHeader: true,
  //   });
  //   utils.book_append_sheet(wb, ws, "Report");
  //   writeFile(wb, `${currentEvenement.rencontre_name}.xlsx`);
  // };

  const replaceImage = (error) => {
    // replacement of broken Image
    error.target.src = logoBakeli;
  };
  const columns = useMemo(
    () => [
      {
        dataField: "first_name",
        text: "Prénom",
      },
      {
        dataField: "last_name",
        text: "Nom",
      },
      {
        dataField: "address",
        text: "Adresse",
      },
      {
        dataField: "phone",
        text: "Téléphone",
      },
      {
        dataField: "email",
        text: "Email",
      },
      {
        dataField: "canal_infos",
        text: "Canal Info",
      },
    ],
    []
  );
  return (
    <FadeTransition in={true}>
      <ContainerDetails>
        <ImageContainer>
          <img
            style={{ height: "100%", objectFit: "cover", width: "100%" }}
            src={API_IMAGE_URL + currentEvenement.rencontre_file}
            alt={currentEvenement.rencontre_name}
            onError={replaceImage}
          />
        </ImageContainer>
        <CardBody>
          <CardTitle>{currentEvenement.rencontre_name}</CardTitle>
          <CardText>{currentEvenement.rencontre_description}</CardText>
          <div className="mt-4"></div>
          <TimeSpan>
            <FcOvertime size={20} />
            Date:{" "}
            {new Date(currentEvenement.rencontre_start_date).toLocaleString(
              "fr-Fr",
              {
                dateStyle: "short",
              }
            )}{" "}
            à {currentEvenement.rencontre_start_time}
          </TimeSpan>
          <TimeSpan>
            <BiTime size={20} />
            Créé le:{" "}
            {new Date(currentEvenement.created_at).toLocaleString("fr-Fr", {
              dateStyle: "short",
            })}
          </TimeSpan>
          <div className="mb-3">
            <Badge>
                <a
                  style={{ textDecoration: "none", color: "#33C7FF" }}
                  href={`https://network.bakeli.tech/inscription-rencontre/${currentEvenement?.id}`}
                  rel="noreferrer"
                  target="_blank"
                  className="fw-bold"
                >
                  <BsLink color={rootStyles.color.secondary} size={20} />{" "}
                  Lien d'inscription
                </a>
              </Badge>
          </div>
          <div className="mt-1">
            <Badge>
              <a
                style={{ textDecoration: "none", color: "#000" }}
                href={currentEvenement.lien_groupe_whatsapp}
                rel="noreferrer"
                target="_blank"
                className="fw-bold"
              >
                <BsWhatsapp color={rootStyles.color.secondary} size={20} />{" "}
                Rejoindre le groupe
              </a>
            </Badge>
          </div>
        </CardBody>
        <div className="p-3">
          <CanalInfosContainer>
            <Title3>Différents canaux</Title3>
            <Flex gap={20}>
              {Object.entries(groupByCanalInfos).map((canal) => {
                return (
                  <Badge
                    key={canal}
                    padding={10}
                    bgColor={rootStyles.color.redSecondary}
                    color={rootStyles.color.primary}
                  >
                    <span className="fw-bold">{`${canal[0]} : ${canal[1].length}`}</span>
                  </Badge>
                );
              })}
            </Flex>
          </CanalInfosContainer>

          <div className="mb-2 mt-4">
            <ExportExcel
              fileName={currentEvenement.rencontre_name}
              data={currentEvenement.participant}
            />
          </div>

          <ToolkitProvider
            keyField="id"
            data={currentEvenement?.participant}
            columns={columns}
            // exportCSV
          >
            {(props) => {
              return (
                <div>
                  {/* <MyExportCSV {...props.csvProps} /> */}

                  <BootstrapTable
                    pagination={paginationFactory()}
                    filter={filterFactory()}
                    noDataIndication={() => "Pas encore de Participants"}
                    {...props.baseProps}
                  />
                </div>
              );
            }}
          </ToolkitProvider>
        </div>
      </ContainerDetails>
    </FadeTransition>
  );
};

export default DetailsEvenementPage;
