export const HORAIRE_DE_FORMATION = [
    {
        label: "8h - 17h",
        value: "8h - 17h"
    },
    {
        label: "8h - 12h30",
        value: "8h - 12h30"
    },
    {
        label: "12h30 - 17h",
        value: "12h30 - 17h"
    },
]

export const JOUR_DE_FORMATION = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"]
/**
 * @readonly
 * @enum
 */
export const MOIS_ANNEE = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre"
];
