import { rootStyles } from "assets/css/globalCss";
import React, { useRef } from "react";
// import { MdClose } from "react-icons/md";
import styled from "styled-components";

/**
 *
 * @param {
 *  {
 *    type: String,
 *    name: String,
 *    value: String | Number,
 *    onChange: Function,
 *    label: String,
 *    errorMessage: String,
 *    icon: {
 *      name: String,
 *      color: String,
 *      size: Number
 *    },
 *    style: {},
 *    inputContainerStyle: {}
 *    height: Number,
 *    extraOptions: {}
 *  }
 * }
 * @returns
 */

const Label = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  backgroundcolor: #fff;
  position: relative;
//   margin: 0.5rem 0;
`;
const InputForm = styled.input`
    padding: ${rootStyles.padding.inputPadding};
    width: 100%;
    border: ${({ error }) =>
        !error ?
            `1px solid ${rootStyles.color.secondary} ` :
            `3px solid ${rootStyles.color.red} `};
    background-color: ${rootStyles.color.primaryWithRgba};
    font-size: ${rootStyles.fonts.fontSize};
    transition: all ease 0.1s;
    &:focus {
    border: ${({ error }) =>
    !error ?
        `2px solid ${rootStyles.color.secondary} ` :
        `2px solid ${rootStyles.color.red} `};
  }
`;
const IconContainer = styled.span`
  position: absolute;
  left: -1px;
`;
export const LabelText = styled.label`
  width: 100%;
  font-size: ${rootStyles.fonts.fontSize};
  color: ${({ error }) => (!error ? "normal" : rootStyles.color.red)};
  margin-bottom: -10px;
  font-weight: ${rootStyles.fonts.btnWeight};
`;

const ResetPane = styled.input`
  position: absolute;
  right: 10px;
  top: 10px;

  &:active{
    transform: scale(.95)
  }
`

function Input({
    type,
    value,
    onChange,
    label,
    errorMessage,
    icon,
    placeholder,
    extraOptions,
    name,
    autoFocus,
    reset,
    min,
    max
}) {
    const inputRef = useRef("")
    return (
        <div style={{ width: "100%" }}>
            {/* <LabelText>{label}</LabelText> */}
            <Label>
                <IconContainer>
                    {icon && (
                        <icon.name
                            color={icon?.color ?? "gray"}
                            style={{ marginLeft: 12 }}
                            size={icon?.size ?? 20}
                        />
                    )}
                </IconContainer>
                <InputForm
                    ref={inputRef}
                    autoFocus={autoFocus}
                    placeholder={placeholder}
                    type={type ?? "text"}
                    value={value ?? ""}
                    onChange={(e) => onChange(e.target.value, name)}
                    {...extraOptions}
                    min={min}
                    max={max}
                />
                {
                    reset && <ResetPane type="reset" value={"x"} onClick={reset} />
                }
                {errorMessage && (
                    <div style={{ marginTop: 10, fontSize: 13, color: "red" }}>
                        {errorMessage}
                    </div>
                )}
            </Label>
        </div>
    );
}

export default Input;
