import React, { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { useMemo } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import useWindowDimensions from "../hooks/useWindowsDimention";
import styled from "styled-components";
import { DrawerContext } from "context/useDrawerContext";
import Drawer from "components/drawer/Drawer";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
const NavbarPaner = styled.div`
  flex: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  z-index: 4;
`;
const BodyContainer = styled.div`
  display: flex;
`;
const SidebarPaner = styled.div`
  width: ${({ openedMenu }) => (openedMenu ? "190px" : "60px")};
  height: 100vh;
  position: fixed;
  top: 55px;
  box-sizing: border-box;
  left: 0%;
  transition: width 0.3s;
  z-index: 2;
  box-sizing: border-box;
  @media only screen and (max-width: 500px) {
    display: none;
    width: 0
  }
`;
const MenuController = styled.div`
  position: absolute;
  top: 5px;
  right: -10px;
  width: 25px;
  height: 25px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #008fa0;
  cursor: pointer;
  z-index: 3;
`;
const ContaintOutlet = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(241, 241, 241, 0.624);
  transition: padding 0.3s;
  overflow-x: hidden;
  z-index: 1;
  padding: ${({ openedMenu, minViewPort, padding }) => {
    // eslint-disable-next-line indent
    return !padding ? "0 10px" : openedMenu ?
        // eslint-disable-next-line indent
        `55px 10px 0 ${padding}px` : minViewPort ? "55px 10px 0 70px" : "55px 10px 0 70px"
    // eslint-disable-next-line indent
}
};
`;

const SpliTemplateScreen = ({ children }) => {
    const [openedMenu, setOpenedMenu] = useState(false);
    const [minViewPort, setMinViewPort] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(0)

    // const { isMenuOpen } = useContext(DrawerContext)
    const { isMenuOpen } = useContext(DrawerContext);

    const [navbar, sidebar, outlet] = children;

    const sidebarRef = useRef(null);

    const { width } = useWindowDimensions();
    const handleResize = () => {
        setOpenedMenu((v) => !v);
    };

    useEffect(() => {
        if (document.getElementById("sidebar").offsetWidth > 0) {
            setSidebarWidth(document.getElementById("sidebar").offsetWidth + 12)
        } else {
            // console.log(document.getElementById("sidebar").offsetWidth);
            setSidebarWidth(0)
        }
    }, [width])

    useMemo(() => {
        if (width <= 900) {
            setMinViewPort(true);
            setOpenedMenu(false);
        } else {
            setMinViewPort(false);
            setOpenedMenu(true);
        }
    }, [width]);

    return (
        <Container>
            {
                isMenuOpen && <Drawer />
            }
            {/* <Drawer isMenuOpen={isMenuOpen} /> */}
            <NavbarPaner>{navbar}</NavbarPaner>
            <BodyContainer>
                <SidebarPaner
                    id="sidebar"
                    openedMenu={openedMenu}
                    ref={sidebarRef}
                >
                    <MenuController onClick={handleResize}>
                        {!openedMenu ? (
                            <AiOutlineArrowRight className="menu-controller-icon" />
                        ) : (
                            <AiOutlineArrowLeft className="menu-controller-icon" />
                        )}
                    </MenuController>
                    {
                        React.cloneElement(sidebar, { openedMenu })
                    }
                </SidebarPaner>
                <ContaintOutlet
                    padding={sidebarWidth}
                    openedMenu={openedMenu}
                    minViewPort={minViewPort}
                >
                    {outlet}
                </ContaintOutlet>
            </BodyContainer>
        </Container>
    );
};

export default SpliTemplateScreen;
