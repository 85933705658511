import React from "react"
const { createContext, useState } = require("react");

export const DrawerContext = createContext()

export const DrawerContextProvider = ({ children }) => {
    const [openDrawer, setOpenDrawer] = useState(false)
    return <DrawerContext.Provider value={{
        isMenuOpen: openDrawer,
        openMenu: () => setOpenDrawer(true),
        closeMenu: () => setOpenDrawer(false)
    }}>
        {children}
    </DrawerContext.Provider>
}