import { createSlice } from "@reduxjs/toolkit/"

const initialState = {
    currentUser: null
}

const authSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setCurrentUser: (state, { payload }) => {
            state.currentUser = payload
        },
        clearCurrentUser: (state) => {
            state.currentUser = null
        }
    }
})


export const { setCurrentUser, clearCurrentUser } = authSlice.actions
export default authSlice.reducer
