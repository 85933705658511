import { siAdminAPi } from "redux/rtkquery";
import { ADD_NEW_EMPLOYE_ENDPOINT } from "routes/api/endpoints";

const employesMutationApi = siAdminAPi.injectEndpoints({
  endpoints: (builder) => ({
    addNewEmploye: builder.mutation({
      query: (data) => ({
        url: ADD_NEW_EMPLOYE_ENDPOINT,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Employees"],
    }),
  }),
});

export const { useAddNewEmployeMutation } = employesMutationApi;
