import React from "react";
import { useForm } from "react-hook-form";
import nombreDeJours from "./calculNombreJours";
import { motion } from "framer-motion";
// import { useSelector } from "react-redux";
import ButtonComp from "components/ButtonComp";
import { rootStyles } from "assets/css/globalCss";
import moment from "moment";
import useCoachs from "hooks/useCoachs";
import useCoachAssistants from "hooks/useCoachAssistants";
moment().format();


const AutresInfos = ({ page, setPage, bakelisteInfos, setBakelisteInfos, x, setX, setApercu }) => {
    // const { allCoachsList: coachs, allCoachsAssistantsList: coachAssistants } = useSelector((state) => state.coachs)
    const { data: coachs } = useCoachs()
    const { data: coachAssistants } = useCoachAssistants()
    // console.log({ coachs });
    const {
        setError,
        clearErrors,
        formState: { errors },
    } = useForm();

    const formatDate = (date) => {
        const d = new Date(date);
        let month = "" + (d.getMonth() + 1);
        let day = "" + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };

    const goToApercu = () => {
        const today = formatDate(new Date())
        const debutFormation = bakelisteInfos.debut_formation
        const finFormation = bakelisteInfos.fin_formation

        if (!debutFormation) {
            setError("debut_formation", { type: "custom", message: "Le début de formation est obligatoire" });
            setTimeout(() => {
                clearErrors("debut_formation")
            }, 1500);
            return
        }

        if (!finFormation) {
            setError("fin_formation", { type: "custom", message: "La fin de formation est obligatoire" });
            setTimeout(() => {
                clearErrors("fin_formation")
            }, 1500);
            return
        }

        if (debutFormation < today) {
            setError("debut_formation", { type: "custom", message: "Le début de formation est invalide, choisissez une date supérieur à celle aujourd'hui" });
            setTimeout(() => {
                clearErrors("debut_formation")
            }, 3000);
            return
        }

        if (finFormation < today) {
            setError("fin_formation", { type: "custom", message: "La fin de formation est invalide, choisissez une date supérieur à celle aujourd'hui" });
            setTimeout(() => {
                clearErrors("fin_formation")
            }, 3000);
            return
        }

        const totalJoursEntreDureeDeFormation = nombreDeJours(new Date(finFormation), new Date(debutFormation))

        if (totalJoursEntreDureeDeFormation >= 88) {
            setApercu(true)
        } else {
            setError("fin_formation", { type: "custom", message: "La durée de formation doit etre supérieure ou égale à 3 mois" });
            setTimeout(() => {
                clearErrors("fin_formation")
            }, 3000);

            return
        }
    }

    return (
        <motion.div initial={{ x: x }}
            transition={{ duration: 0.8 }}
            animate={{ x: 0 }} className="multi-form">
            <h6>Infos supplémentaires</h6>
            {/* select ******************** */}
            <div className='row-form'>
                <div className="">
                    <p className='date-description'>Date de début</p>
                    <input
                        defaultValue={bakelisteInfos.debut_formation}
                        type="date"
                        aria-describedby="dateDebut"
                        onChange={(e) => {
                            const selectedDate = new Date(e.target.value)
                            const endDateFormation = moment(selectedDate).add(Number(bakelisteInfos.training_duration), "months")
                            console.log({ selectedDate: formatDate(selectedDate), endDateFormation });
                            setBakelisteInfos((prev) => {
                                console.log({ prev });
                                return { ...prev, debut_formation: formatDate(selectedDate) }
                            })
                            setBakelisteInfos((prev) => ({ ...prev, fin_formation: formatDate(endDateFormation._d) }))
                        }}
                    />
                    {errors.debut_formation && <span role="alert">{errors.debut_formation.message}</span>}
                </div>
                <div className="">
                    <p className='date-description'>Date de fin</p>
                    <input
                        readOnly
                        defaultValue={bakelisteInfos.fin_formation}
                        type="date"
                        aria-describedby="finFormation"
                        // onChange={(e) => setBakelisteInfos({ ...bakelisteInfos, fin_formation: formatDate(new Date(e.target.value)) })}
                    />
                    {errors.fin_formation && <span role="alert">{errors.fin_formation.message}</span>}
                </div>
            </div>
            {/* --------------  */}
            <div className='row-form mt-3'>
                <div>
                    <textarea
                        defaultValue={bakelisteInfos.objectifs}
                        onChange={(e) => setBakelisteInfos({ ...bakelisteInfos, objectifs: e.target.value })}
                        placeholder='objectifs'
                        rows="" cols=""></textarea>
                    {errors.objectifs && <span role="alert">Objectifs obligatoire</span>}
                </div>
                <div>
                    <textarea
                        defaultValue={bakelisteInfos.commentaire}
                        onChange={(e) => setBakelisteInfos({ ...bakelisteInfos, commentaire: e.target.value })}
                        placeholder='Ajouter un commentaire'
                        rows="" cols={50}
                    ></textarea>
                    {errors.commentaire && <span role="alert">Commentaire obligatoire</span>}
                </div>
            </div>
            {/* --------------  */}
            <div className='row-form mt-3'>
                <div className="">
                    <select
                        defaultValue={bakelisteInfos.coach_id}
                        onChange={(e) => setBakelisteInfos({ ...bakelisteInfos, coach_id: Number(e.target.value) })}
                        className="p-2 animate__animated animate__fadeIn"
                    >
                        <option value=""> -- choisir coach --</option>
                        {
                            coachs.map((coach) => (
                                <option key={coach.id} value={coach.id}>{coach.first_name}</option>
                            ))
                        }
                    </select>
                    {errors.coach_id && (
                        <span role="alert">Coach obligatoire</span>
                    )}
                </div>
                <div className="">
                    <select
                        defaultValue={bakelisteInfos.coach_assistant_id}
                        onChange={(e) => setBakelisteInfos({ ...bakelisteInfos, coach_assistant_id: Number(e.target.value) })}
                        className="p-2 animate__animated animate__fadeIn"
                    >
                        <option value=""> -- choisir coach assistant --</option>
                        {
                            coachAssistants.map((coach) => (
                                <option key={coach.id} value={coach.id}>{coach.first_name}</option>
                            ))
                        }
                    </select>
                    {errors.coach_assistant_id && (
                        <span role="alert">Coach assistant obligatoire</span>
                    )}
                </div>
            </div>
            <div className='d-flex justify-content-between mt-4'>
                <ButtonComp
                    type="button"
                    title="prec"
                    onClick={() => {
                        setX(-10)
                        setPage(page - 1)
                    }}
                    bgColor={rootStyles.color.tercary}
                    color={rootStyles.color.primary}
                />
                <ButtonComp
                    type="button"
                    title="Aperçu"
                    onClick={goToApercu}
                    bgColor={rootStyles.color.secondary}
                    color={rootStyles.color.primary}
                />
                {/* <button className='btn-switch btn-prev' onClick={() => {
                    setX(-10)
                    setPage(page - 1)
                }}>
                    <img src={null} alt="Prev" className="mr-3" />    prec
                </button> */}

                {/* <button onClick={goToApercu} className='btn-switch btn-submit' type='button'>Aperçu</button> */}
            </div>
        </motion.div>
    )
}

export default AutresInfos