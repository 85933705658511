import { GET_ANNEES_PAIEMENTS_ENDPOINT } from "routes/api/endpoints";
import useSWR from "swr";
import { listeAnneesDePaieSwrFetcher } from "..";


export const useAnnees = () => {
    const { data: anneesDePaie = [], isLoading: isAnneesLoading } = useSWR(
        GET_ANNEES_PAIEMENTS_ENDPOINT,
        listeAnneesDePaieSwrFetcher
    );

    return { anneesDePaie, isAnneesLoading }
}