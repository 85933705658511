import { rootStyles } from "assets/css/globalCss"
import React from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"

// text-decoration: underline;
// text-underline-offset: 5px;
const H6Pane = styled.h6`
    font-weight: 600;
    margin-top: 40px;
`
const Paragraphe = styled.p`
    font-size: 14px;
`
const ParaSuccess = styled.p`
 font-size: 14px;
    color:${rootStyles.color.secondary}
`

const EpreuveTheorique = () => {
    const { epreuveTheorique } = useSelector((state) => state.examens)
    return (
        <>
            {
                epreuveTheorique.length ? epreuveTheorique.map((epreuve, index) => {
                    return <>
                        <H6Pane>{index + 1}- {epreuve.questions[0].libelle}</H6Pane>
                        <Paragraphe>
                            a) {epreuve.questions[0].opt1}
                        </Paragraphe>
                        <Paragraphe>
                            b) {epreuve.questions[0].opt2}
                        </Paragraphe>
                        <Paragraphe>
                            c) {epreuve.questions[0].opt3}
                        </Paragraphe>
                        <Paragraphe>
                            d) {epreuve.questions[0].opt4}
                        </Paragraphe>
                        <ParaSuccess>
                            Bonne reponse: {epreuve.questions[0].bonne_reponse}
                        </ParaSuccess>
                    </>
                }) : <p> Pas d'épreuves</p>
            }
        </>
    )
}

export default EpreuveTheorique