import { useGetProspectsByRegionQuery } from "../pages/prospects/api/queries"

export const useGetProspectsByRegionHook = (params) => {
    console.log({ params });
    const { data: prospects = {
        prospectDakar: 0,
        prospectThies: 0,
        prospectMbour: 0,
        allProspects: 0,
    }, isLoading: isProspectsLoading, refetch: refetchProspects, isFetching } = useGetProspectsByRegionQuery(params, {
        refetchOnReconnect: true,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })
    console.log({ prospects });
    return { prospects, isProspectsLoading, refetchProspects, isFetching }
}