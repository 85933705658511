import React, { cloneElement, useState } from "react"
import { GridList } from "react-flexible-list"
import AnneeCardItem from "../../../../components/examens/AnneeCardItem";
import { Title2 } from "components/shared/TableListeView";
import ButtonComp from "components/ButtonComp";
import { rootStyles } from "assets/css/globalCss";
import { BsPlusCircle } from "react-icons/bs";
import AjoutAnneeAcademiqueModal from "./AjoutAnneeAcademiqueModal";
import { useDispatch } from "react-redux";
import { setCurrentAnneeAcademique, setTrimestreByAnnee } from "redux/features/examenSlice";
import { useNavigate } from "react-router-dom";
import { EXAMENS_TRIMESTRE_PATH } from "routes/navigation/navigationPaths";
import Loader from "components/shared/Loader";
import useSWR from "swr";
import { GET_ALL_ANNEE_ACADEMIQUE } from "routes/api/endpoints";
import { anneesAcademiqueSwrFetcher } from "../api";
import FadeTransition from "components/shared/FadeTransition";

const ListeAnneePage = () => {
    const { data: anneeAcademiqueList, isLoading } = useSWR(GET_ALL_ANNEE_ACADEMIQUE, anneesAcademiqueSwrFetcher)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [openAddTrimestreModal, setOpenAddTrimestreModal] = useState(false)

    const goToAnneeAcademiqueTrimestre = (currentAcademique, anneeAcademiqueList) => {
        dispatch(setCurrentAnneeAcademique(currentAcademique))
        dispatch(setTrimestreByAnnee(anneeAcademiqueList))
        navigate(EXAMENS_TRIMESTRE_PATH)
    }

    const ClonedAnneeCardItem = ({ annee }) => cloneElement(<AnneeCardItem annee={annee} />, { goToAnneeAcademiqueTrimestre })

    return (
        <FadeTransition in={true} >
            <AjoutAnneeAcademiqueModal
                openAddTrimestreModal={openAddTrimestreModal}
                onClosed={() => setOpenAddTrimestreModal(false)}
            />
            <div className='d-flex justify-content-between align-items-center'>
                <Title2>Années académiques</Title2>
                <ButtonComp
                    type="button"
                    title="Année académique"
                    bgColor={rootStyles.color.secondary}
                    color={rootStyles.color.primary}
                    icon={{
                        name: BsPlusCircle
                    }}
                    onClick={() => setOpenAddTrimestreModal(true)}
                />
            </div>
            <div className='mt-3'>
                {
                    isLoading ?
                        <Loader /> :
                        <GridList
                            resourceName="annee"
                            resourceData={Object.entries(anneeAcademiqueList)}
                            resourceItem={ClonedAnneeCardItem}
                            cardWidth={120}
                        />
                }
            </div>
        </FadeTransition>
    )
}

export default ListeAnneePage