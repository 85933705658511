import { rootStyles } from "assets/css/globalCss";
import FlexBetween from "components/shared/FlexBetween";
import React from "react";
import { FaHandshake } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import styled from "styled-components";

const CardItemPane = styled.div`
  background-color: #ffffff;
  padding: 10px;
  border-radius: ${rootStyles.radius.card};
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 5px;
  cursor: pointer;
  transition: all 200ms ease;

  &:hover {
    background-color: rgba(20, 30, 40, 0.8);
  }

  @media only screen and (max-width: 500px) {
    height: 110px;
  }
`;
const SpanNamePane = styled.span`
  font-weight: ${rootStyles.fonts.btnWeight};
  color: ${rootStyles.color.secondary};

  @media only screen and (max-width: 500px) {
    font-size: 12px;
  }
`;
const SpanNumberPane = styled.span`
  font-weight: ${rootStyles.fonts.btnWeight};
  color: ${rootStyles.color.tercary};
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 5px;

  @media only screen and (max-width: 500px) {
    font-size: 12px;
  }
`;

const PartenaireCardItem = ({ partenaire, navigateTo, editPartenaireFunc }) => {
  const { name, programmes } = partenaire;
  return (
    <CardItemPane onClick={() => navigateTo(partenaire)}>
      <div className="d-flex justify-content-end gap-2">
        {/* <button
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="btn btn-outline-secondary border"
        >
          <BiTrash size={20} color={rootStyles.color.red} />
        </button> */}
        <button
          onClick={(e) => {
            e.stopPropagation();
            editPartenaireFunc(partenaire);
          }}
          className="btn btn-outline-secondary border"
        >
          <MdEdit size={20} color={rootStyles.color.tercary} />
        </button>
      </div>
      <FaHandshake size={25} color={rootStyles.color.secondary} />
      <FlexBetween>
        <SpanNamePane>{name}</SpanNamePane>
        <SpanNumberPane>
          {programmes.length}{" "}
          <span style={{ fontSize: "10px" }}>programmes</span>
        </SpanNumberPane>
      </FlexBetween>
    </CardItemPane>
  );
};

export default PartenaireCardItem;
