import { GET_JOURS_FORMATION } from "routes/api/endpoints";

const { siAdminAPi } = require("redux/rtkquery");

const joursApi = siAdminAPi.injectEndpoints({
    endpoints: (build) => ({
        get: build.query({
            query: () => GET_JOURS_FORMATION,
            providesTags: ["jours"],
        }),
    }),
    overrideExisting: false,
});

export const { useGetQuery } = joursApi