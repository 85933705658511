import React, { useContext, useState } from "react";
import bakeli from "../../assets/img/bakeli.png"
import styled from "styled-components";
import { rootStyles } from "assets/css/globalCss";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useAuthContext } from "context/userContext";
import { useNavigate } from "react-router-dom";
import { RxAvatar } from "react-icons/rx"
import DropDown from "components/dropDown/DropDown";
import { FiLogOut } from "react-icons/fi";
import { RiMenu2Line } from "react-icons/ri";
import { DrawerContext } from "context/useDrawerContext";
import Flex from "components/shared/Flex";
// import Ribbon from "components/Rinbbon";
// const Navbar = styled.div`
//   display: flex
// `

const HumbergerContainer = styled.button`
border: 1px solid rgba(20, 30, 40, .5);
background-color: transparent;
padding: 8px;
cursor: pointer;
border-radius: 5px;
&:focus {
    color: ${rootStyles.color.tercary};
    background-color: rgba(255, 200, 200, 0.4);
    // border-right: 5px solid ${rootStyles.color.tercary};
  }
    @media only screen and (min-width: 500px) {
  display: none;
  }
`

// const LogoContainer = styled.h1`
//   display: flex;
//   align-items:center;
//   gap:20px;
//   @media only screen and (max-width: 500px) {
//   display: none;
//   }
// `
const InmContainer = styled.div`
  height: 50px;
  width: 80px;
  display: flex; 
  align-items:center;
  border-radius:${rootStyles.radius.card};
  box-shadow:${rootStyles.shadow.card};
  padding:${rootStyles.padding.btnPadding};

`
const ProfilContainer = styled.div`
  border-radius:${rootStyles.radius.card};
  box-shadow:${rootStyles.shadow.card};
  padding:10px;
  display: flex; 
  align-items:center;
  gap:10px;
  background-color: rgba(40,50,60, .8);

  &:hover{
    opacity: 0.9;
    cursor: pointer
  };

    @media only screen and (max-width: 500px) {
    display: none;
  }
`
const Truncate = styled.div`
  width: 20px;
  height: 20px;
  border-radius:${rootStyles.radius.card};
  box-shadow:${rootStyles.shadow.card};
  padding:${rootStyles.padding.btnPadding};
  background-color: ${rootStyles.color.secondary};
  display: flex;
  justify-content: center;
  align-items: center
`
const NameSpan = styled.span`
  font-weight: 500;
  // color: #88888d;
  color: #FFFFFF;
  cursor: pointer;
  font-size: ${rootStyles.fonts.fontSize};
`
const LogoSpan = styled.span`
  font-weight: 500;
  color: #88888d;
  font-size: ${rootStyles.fonts.h2FontSize};
  position: relative;
`

const DropDownLine = styled.li`
  border-bottom: 1px solid rgba(20, 30, 40, .5);
  color: #000;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 0;
`
const DropDownLineLogout = styled.li`
  border-bottom: 1px solid ${rootStyles.color.red};
  color: #000;
  color: ${rootStyles.color.red};
  // background-color:rgba(255, 105, 115, .5);
  font-size: 14px;
  font-weight: 600;
  padding: 10px 0;
  margin-top: 5px;
`

function NavbarComponent({ bgColor, navbarContainerStyles }) {
  const navigate = useNavigate()
  const { logout } = useAuthContext()
  const { currentUser } = useCurrentUser()

  const { openMenu } = useContext(DrawerContext)

  // console.log({ openMenu });

  const [showDropdown, setShowDropdown] = useState(false);

  // / const currentTrimestreStr = `${currentTrimestre.trimestre.charAt(
  //   0
  // )} ${currentTrimestre.trimestre.substr(
  //   currentTrimestre.trimestre.length - 1
  // )} - ${new Date(currentTrimestre.annee_academique).getFullYear()}`;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 15px",
        height: 65,
        backgroundColor: bgColor ? bgColor : "#fff",
        borderBottom: "1.5px solid rgba(0,0,0, .2)",
        ...navbarContainerStyles,
      }}
    >
      {/* <LogoContainer> */}
        <InmContainer>
          <img
            src={bakeli}
            alt="Bakeli School of Technology"
          style={{ width: "50px" }}
          />
        </InmContainer>
      {/* </LogoContainer> */}
      {/* =================== Hamburger ================ */}
      <HumbergerContainer onClick={() => openMenu()}>
        <RiMenu2Line size={25} />
      </HumbergerContainer>

      <div className="d-flex align-items-center flex-column">
        <LogoSpan>
          <Flex align={"center"} gap={5}>
            <span style={{ color: rootStyles.color.tercary }}>SI</span>
            <span className="ms-2" style={{ color: rootStyles.color.secondary }}>ADMIN</span>
            <code>v{global.appVersion}</code>
            {/* <Ribbon /> */}
          </Flex>
        </LogoSpan>
        {/* <p style={{ fontSize: 10, fontWeight: 400 }}>
          ❣️ Bonne année, nous souhaitons à tous nos utilisateurs des moments de paix, de réflexion et de solidarité. Que cette année soit porteuse de sagesse et de bénédictions. ❣️
        </p> */}
      </div>
      <ProfilContainer title="Déconnexion" onClick={() => logout(navigate)}>
        <Truncate>
          <div>
            <RxAvatar size={30} color="#FFF" />
          </div>
        </Truncate>
        <NameSpan>
          {`${currentUser?.first_name ?? "..."} ${currentUser?.last_name ?? "..."}`}
        </NameSpan>
      </ProfilContainer>
      <DropDown
        showDropdown={showDropdown}
        setShowDropdown={() => setShowDropdown(!showDropdown)}
      >
        <DropDownLine>
          {`${currentUser?.first_name ?? "..."} ${currentUser?.last_name ?? "..."}`}
        </DropDownLine>
        <DropDownLineLogout onClick={() => logout(navigate)}>
          <FiLogOut />
          <span className="ms-2">Deconnexion</span>
        </DropDownLineLogout>
      </DropDown>
    </div>
  );
}

export default NavbarComponent;
