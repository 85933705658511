/* eslint-disable operator-linebreak */
import { rootStyles } from "assets/css/globalCss";
import ResourceDataMap from "components/ResourceDataMap";
import CardSkeleton from "components/shared/CardSkeleton";
import Div from "components/shared/Div";
import FadeTransition from "components/shared/FadeTransition";
import { Title2 } from "components/shared/TableListeView";
import TextOnEmpty from "components/shared/TextOnEmpty";
import TitleLine from "components/shared/TitleLine";
import { Container } from "pages/bakelistes/BakelisteHomePageDashboard";
import React, { cloneElement } from "react";
import { GridList } from "react-flexible-list";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setCoachCurrentYearTaskValidations,
  setCoachYearComments,
  setCurrentBakelistesSuiviCoach,
} from "redux/features/coachSlice";
import {
  COACH_ALL_BAKELISTS_PATH,
  COACH_BAKELISTES_ABANDONNES_PATH,
  COACH_BAKELISTES_EN_FORMATION_PATH,
  COACH_BAKELISTES_EN_LIGNE_PATH,
  COACH_BAKELISTES_EN_PAUSE_PATH,
  COACH_BAKELISTES_EN_PRESENTIEL_PATH,
  COACH_BAKELISTES_TERMINES_PATH,
  COACH_COMMENTS_PATH,
  COACH_CURRENT_BAKELISTES_STATUS_PATH,
  COACH_VALIDATIONS_PATH,
} from "routes/navigation/navigationPaths";
import { useGetCoachDetails } from "../../api/hooks/useGetCoachDetails";
import CommentairesByYearCardItem from "../components/CommentairesByYearCardItem";
import SuiviCoachingCardItem from "../components/SuiviCoachingCardItem";
import ValidationByYearCardItem from "../components/ValidationByYearCardItem";
import CoachInfo from "./CoachInfo";
import Loader from "components/shared/Loader";

const DetailsCoachSuiviPage = () => {
  const {
    suiviCurrentCoach,
    coachCommentaires,
    coachTaskValidations = [],
  } = useSelector((state) => state.coachs);
  const { coachingDetails, isDetailsLoading } = useGetCoachDetails(
    suiviCurrentCoach.id
  );

  console.log({ suiviCurrentCoach, coachingDetails });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const bakelistes = isDetailsLoading
    ? []
    : [
        {
          label: "Total",
          number: coachingDetails.kpi.allBakelistes,
          currentBakelistesSuivi: coachingDetails.data.allBakelistes,
          to: COACH_ALL_BAKELISTS_PATH,
        },
        {
          label: "Actuels",
          number: coachingDetails.kpi.bakelisteEnFormations,
          currentBakelistesSuivi: coachingDetails.data.bakelisteEnFormations,
          to: COACH_BAKELISTES_EN_FORMATION_PATH,
        },
        {
          label: "En Ligne",
          number: coachingDetails.kpi.bakelisteEnLigne,
          currentBakelistesSuivi: coachingDetails.data.bakelisteEnLigne,
          to: COACH_BAKELISTES_EN_LIGNE_PATH,
        },
        {
          label: "En Présentiel",
          number: coachingDetails.kpi.bakelisteEnPresentiel,
          currentBakelistesSuivi: coachingDetails.data.bakelisteEnPresentiel,
          to: COACH_BAKELISTES_EN_PRESENTIEL_PATH,
        },
        {
          label: "En Pause",
          number: coachingDetails.kpi.bakelisteEnPause,
          currentBakelistesSuivi: coachingDetails.data.bakelisteEnPause,
          to: COACH_BAKELISTES_EN_PAUSE_PATH,
        },
        {
          label: "Terminés",
          number: coachingDetails.kpi.bakelisteTermines,
          currentBakelistesSuivi: coachingDetails.data.bakelisteTermines,
          to: COACH_BAKELISTES_TERMINES_PATH,
        },
        {
          label: "Abandonnés",
          number: coachingDetails.kpi.bakelisteEnAbandon,
          currentBakelistesSuivi: coachingDetails.data.bakelisteEnAbandon,
          to: COACH_BAKELISTES_ABANDONNES_PATH,
        },
      ];

  const navigateToDetails = ({ label, currentBakelistesSuivi, to }) => {
    dispatch(setCurrentBakelistesSuiviCoach({ label, currentBakelistesSuivi }));
    return navigate(to);
  };

  const navigateToComments = (yearComments) => {
    dispatch(setCoachYearComments(yearComments));
    return navigate(COACH_COMMENTS_PATH);
  };
  const navigateToValidations = (yearValidations) => {
    dispatch(setCoachCurrentYearTaskValidations(yearValidations));
    return navigate(COACH_VALIDATIONS_PATH);
  };

  const ClonedSuiviCoachingCardItem = ({ suiviCoach }) =>
    cloneElement(<SuiviCoachingCardItem suiviCoach={suiviCoach} />, {
      navigateToDetails,
    });
  const ClonedCommentairesByYearCardItem = ({ commentaire }) =>
    cloneElement(<CommentairesByYearCardItem commentaire={commentaire} />, {
      navigateToComments,
    });
  const ClonedValidationByYearCardItem = ({ validation }) =>
    cloneElement(<ValidationByYearCardItem validation={validation} />, {
      navigateToValidations,
    });

  return (
    <FadeTransition in={true}>
      <Title2>
        Suivi coaching de{" "}
        {
          isDetailsLoading ? <Loader /> : <span style={{ color: rootStyles.color.tercary }}>
            {coachingDetails.data.coachAssistants[0].first_name} {coachingDetails.data.coachAssistants[0].last_name}
          </span>
        }
      </Title2>
      {
        isDetailsLoading ? <Loader /> : <CoachInfo
          navigateTo={() => {
            dispatch(setCurrentBakelistesSuiviCoach({ label: "Total", currentBakelistesSuivi: coachingDetails.data.data.allBakelistes }))
            return navigate(COACH_CURRENT_BAKELISTES_STATUS_PATH)
          }}
          suiviCurrentCoach={coachingDetails.data.coachAssistants[0]}
          bakelistes={bakelistes}
        />
      }
      <div className="mt-4">
        <TitleLine>Bakelistes</TitleLine>
        {isDetailsLoading ? (
          <>
            <GridList
              resourceData={[1, 2, 3, 4, 5]}
              resourceItem={CardSkeleton}
              cardWidth={300}
            />
          </>
        ) : (
          <Container padding={4}>
            <ResourceDataMap
              resourceData={bakelistes}
              resourceName="suiviCoach"
              resourceItem={ClonedSuiviCoachingCardItem}
            />
          </Container>
        )}

        <Div mt={50}>
          <TitleLine>Commentaires</TitleLine>
          {isDetailsLoading ? (
            <>
              <GridList
                resourceData={[1, 2, 3, 4]}
                resourceItem={CardSkeleton}
                cardWidth={300}
              />
            </>
          ) : (
            <>
              {Object.entries(coachCommentaires).length ? (
                // Object.entries(coachingDetails?.data?.commentaires).length ?
                <Container padding={4}>
                  <ResourceDataMap
                    // resourceData={Object.entries(coachingDetails?.data?.commentaires)}
                    resourceData={Object.entries(coachCommentaires)}
                    resourceName="commentaire"
                    resourceItem={ClonedCommentairesByYearCardItem}
                  />
                </Container>
              ) : (
                <TextOnEmpty textOnEmpty="Pas de commentaires" />
              )}
            </>
          )}
        </Div>

        <Div mt={50}>
          <TitleLine>Validations</TitleLine>
          {isDetailsLoading ? (
            <GridList
              resourceData={[1, 2, 3]}
              resourceItem={CardSkeleton}
              cardWidth={300}
            />
          ) : Object.entries(coachTaskValidations).length ? (
            <Container padding={4}>
              <ResourceDataMap
                resourceData={Object.entries(coachTaskValidations)}
                resourceName="validation"
                resourceItem={ClonedValidationByYearCardItem}
              />
            </Container>
          ) : (
            <TextOnEmpty textOnEmpty="Pas de livraisons" />
          )}
        </Div>
      </div>
    </FadeTransition>
  );
};

export default DetailsCoachSuiviPage;
