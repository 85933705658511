import React from "react";
import SidebarItem, { SubSidebarItem } from "../../components/SidebarItem";
// import ResourceDataMap from "../../components/ResourceDataMap";
import { links } from "routes/navigation/links";
import styled from "styled-components";
import { rootStyles } from "assets/css/globalCss";

const SidebarPane = styled.div`
    scrollbar-color: #6BAF8D #232E33;
    ::-webkit-scrollbar {
        width: 3px !important;
    }
    ::-webkit-scrollbar-track {
        background: #6BAF8D;
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: #232E33;
        border-radius: 10px;
    }
`

const DetailsPane = styled.details`
  position: relative;
  display: flex;
  padding-left: 0px;
  padding-right: 0;
  border-radius: ${rootStyles.radius.card};
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0 5px 10px 10px;
  overflow: hidden;
  text-decoration: none;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
  padding: 8px;
  color: ${rootStyles.color.primary};

  &:focus {
    color: ${rootStyles.color.tercary};
    background-color: ${rootStyles.color.tercary};
  }

  &:hover {
    color: ${rootStyles.color.primary};
    background-color: ${rootStyles.color.primary05};
    border-right: 2px solid ${rootStyles.color.tercary};
  }

  &.active {
    color: ${({ colorOnActive }) => colorOnActive};
    background-color: rgba(20, 30, 40, 0.5);
    border-right: 2px solid ${rootStyles.color.tercary};
    font-weight: ${rootStyles.fonts.btnWeight};
  }
  @media only screen and (max-width: 500px) {
    font-size: 16px;
  }
`
const SummaryPane = styled.summary`
    font-size: 14px;
`

const SidebarComponent = ({ bgColor, sideBarContainerStyles, openedMenu }) => {
    const ClonedSidebarItem = ({ linkItem }) => React.cloneElement(<SidebarItem linkItem={linkItem} />, { openedMenu })
    const ClonedSubSidebarItem = ({ linkItem }) => React.cloneElement(<SubSidebarItem linkItem={linkItem} />, { openedMenu })
    return (
        <SidebarPane
            style={{
                backgroundColor: bgColor ? bgColor : "red",
                height: "100%",
                padding: openedMenu ? "25px 0 100px 0px" : "25px 5px 100px 5px",
                // padding: openedMenu ? "25px 0 25px 0px" : "25px 0px 25px 0px",
                // marginLeft: '20px',
                borderRight: "1px solid rgba(0,0,0,0.1)",
                ...sideBarContainerStyles,
                overflow: "auto"
            }}
        >
            {links.map((link) => {
                if (link.details) {
                    return <DetailsPane key={link.path}>
                        <SummaryPane>{link.label}</SummaryPane>
                        {link.details.map((link) => {
                            return (
                                <ClonedSubSidebarItem linkItem={link} key={link.path} />
                            );
                        })}
                    </DetailsPane>
                }
                return (
                    <ClonedSidebarItem linkItem={link} key={link.path} />
                );
            })}
            {/* <ResourceDataMap
                resourceData={links}
                resourceItem={ClonedSidebarItem}
                resourceName="linkItem"
            /> */}
        </SidebarPane>
    );
};

export default SidebarComponent;
