import { rootStyles } from "assets/css/globalCss"
import ButtonComp from "components/ButtonComp"
import ResourceDataMap from "components/ResourceDataMap"
import TypeBakelisteCardItem from "components/bakelistes/TypeBakelisteCardItem"
import React, { useEffect, useMemo } from "react"
import { FaPlusCircle } from "react-icons/fa"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { resetCurrentProspect } from "redux/features/prospectSlice"

import {
    ADD_BAKELISTE_PATH, BAKELISTES_ACTIFS_PATH, BAKELISTES_ARCHIVES_PATH, BAKELISTES_INACTIFS_PATH, BAKELISTES_TERMINES_PATH,
    // BAKELISTES_ARCHIVES_PATH,
    // BAKELISTES_BLOQUES_PATH, BAKELISTES_EN_LIGNE_PATH,
    // BAKELISTES_EN_PAUSE_PATH, BAKELISTES_EN_PRESENTIEL_PATH,
    // BAKELISTES_EN_STAGE_PATH, BAKELISTES_TERMINES_PATH
} from "routes/navigation/navigationPaths"
import styled from "styled-components"

// import { useBakelistePresentiel } from "./api/hooks/useBakelistePresentiel"
// import { useBakelisteEnLigne } from "./api/hooks/useBakelisteEnLigne"
// import { useBakelisteAbandonnes } from "./api/hooks/useBakelisteAbandonnes"
// import { useBakelisteEnPause } from "./api/hooks/useBakelisteEnPause"
// import { useBakelisteBloques } from "./api/hooks/useBakelistesBloques"
// import { useBakelisteArchives } from "./api/hooks/useBakelisteArchives"
// import { useBakelisteEnStage } from "./api/hooks/useBakelisteEnStage"
// import { useBakelistesTermines } from "./api/hooks/useBakelistesTermines"
import FadeTransition from "components/shared/FadeTransition"
import { useBakelisteActif, useBakelisteInActif } from "actions/bakelistes"
import SearchBakeliste from "components/bakelistes/SearchBakelistes/SearchBakeliste"
import { useBakelistesTermines } from "./api/hooks/useBakelistesTermines"
import { useBakelisteArchives } from "./api/hooks/useBakelisteArchives"

export const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(250px,1fr));
    gap: 15px;
    overflow-y: auto;
    padding: ${({ padding }) => !padding ? "20px 5px" : `${padding}px`};

   @media only screen and (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr)
  }
`

const BakelisteHomePageDashboard = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { isActifError, isActifLoading, actifcount } = useBakelisteActif()
    const { isInactifError, isInactifLoading, InActifcount } = useBakelisteInActif()
    const { bakelisteTermines, isTerminesLoading } = useBakelistesTermines()
    const { bakelisteArchives, isArchivesLoading } = useBakelisteArchives()

    const typesBakelisteKpis = useMemo(() => [
        {
            title: "Actifs",
            isNotClickable: isActifLoading,
            number: actifcount,
            to: BAKELISTES_ACTIFS_PATH,
            description: "En presentiel, En ligne"
        },
        {
            title: "Non actifs",
            isNotClickable: isInactifLoading,
            number: InActifcount,
            to: BAKELISTES_INACTIFS_PATH,
            description: "Bloqués, En pause"
        },
        {
            title: "Archivés",
            isNotClickable: isArchivesLoading,
            number: bakelisteArchives?.countBakelistesArchived,
            to: BAKELISTES_ARCHIVES_PATH
        },

        {
            title: "Terminés",
            isNotClickable: isTerminesLoading,
            number: bakelisteTermines?.countBakelistesTermine,
            to: BAKELISTES_TERMINES_PATH
        },
    ], [isActifError, isActifLoading, isInactifError, isInactifLoading, actifcount, InActifcount, bakelisteArchives?.countBakelistesArchived, bakelisteTermines?.countBakelistesTermine])

    useEffect(() => {
        dispatch(resetCurrentProspect())
        // ApiController.bakelistes.getBakelistes()
    }, [])

    return (
        <FadeTransition in={true}>
            <SearchBakeliste />
            <div className='d-flex justify-content-end mb-3'>
                <ButtonComp
                    title="Nouveau Bakeliste"
                    onClick={() => navigate(ADD_BAKELISTE_PATH)}
                    icon={{
                        name: FaPlusCircle
                    }}
                    bgColor={rootStyles.color.secondary}
                    color={rootStyles.color.primary}
                />
            </div>
            <Container>
                <ResourceDataMap
                    resourceItem={TypeBakelisteCardItem}
                    resourceName="kpi"
                    resourceData={typesBakelisteKpis}
                />
            </Container>
        </FadeTransition>
    )
}

export default BakelisteHomePageDashboard