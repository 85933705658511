import { rootStyles } from "assets/css/globalCss";

export const useProspectAnaliticsInfo = (prospectsGroupByCanal) => {
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
                text: "Analyse Rapide des canaux",
            },
            datalabels: {
                display: true,
                color: "white",
                align: "start",
                padding: {
                    right: 0,
                    top: -15,
                },
                labels: {
                    padding: { top: 0 },
                    title: {
                        font: {
                            weight: "normal",
                        },
                    },
                    value: {
                        color: "white",
                    },
                },
                formatter: function (value) {
                    return value;
                },
            },
        },
    };
    const labels = Object.entries(prospectsGroupByCanal).map((canal) => canal[0].toLocaleUpperCase());
    const data = {
        labels,
        datasets: [
            {
                label: "Prospects par canal",
                data: Object.entries(prospectsGroupByCanal).map((canal) => canal[1].length),
                backgroundColor: rootStyles.color.secondary,
            }
        ],
    };

    return { data, options }
}