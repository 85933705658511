import Modal from "components/Modal";
import Comments from "features/CommentBox/Comments";
import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useSelector } from "react-redux";

const LivrablesModal = ({ opened, onClosed }) => {
  const { suiviCurrentBakelisteLivrables } = useSelector(
    (state) => state.bakelistes
  );

  const images = [];

  suiviCurrentBakelisteLivrables?.fichiers?.forEach((element) => {
    images.push({
      original: process.env.REACT_APP_BASE_APP + element.file_path,
      thumbnail: process.env.REACT_APP_BASE_APP + element.file_path,
    });
  });

  return (
    <Modal
      title={`Livrables de la tache: ${suiviCurrentBakelisteLivrables?.details_syllabus?.syllabus_task}`}
      opened={opened}
      onClosed={onClosed}
      modalWidth={1300}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7 order-md-1 mt-3 order-2 col-12">
            <ImageGallery
              items={images}
              showThumbnails={false}
              showIndex
              showPlayButton={false}
            />
          </div>
          <div className="col-md-5 order-md-2 order-1 col-12 mt-3 border-left">
            {suiviCurrentBakelisteLivrables?.comments?.length === 0 && (
              <div>Pas encore de commentaires pour cette livraison</div>
            )}
            <Comments comments={suiviCurrentBakelisteLivrables?.comments} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LivrablesModal;
