import TabsProvider from "components/Tabs/TabsProvider";
import Error from "components/error/Error";
import Div from "components/shared/Div"
import Flex from "components/shared/Flex";
import Loader from "components/shared/Loader";
import { Title2 } from "components/shared/TableListeView"
import SuiviBakelistesLivraisonsTable from "components/suivi-livraisons/SuiviBakelistesLivraisonsTable";
import React from "react"
import useSuiviLivraisons from "./hooks/useSuiviLivraisons";
import FiltrerEntreDeuxDate from "components/suivi-livraisons/Filtre/EntreDeuxDate/FiltrerEntreDeuxDate";
import { FaFilter } from "react-icons/fa";
import ButtonComp from "components/ButtonComp";
import { rootStyles } from "assets/css/globalCss";
import Input from "components/Input";

/* HTML: <div class="loader"></div> */

const SuiviLivraisonsPage = () => {
  const { data, isLoading, isError, isFetching, date, setDate, date_debut, setDateDebut, setSearchParams, searchParams, date_fin, setDateFin } = useSuiviLivraisons();
  // const [filterByDate, setFilterByDate] = useState(false);
  console.log({ date: searchParams.get("date"), isFetching })
  // if (isFetching) {
  //   return <FullPageLoader />
  // }
  return (
    <Div>
      <Flex justify={"space-between"} align={"center"} gap={20}>
        <Div>
          <Title2>Livraisons</Title2>
          <p>Contrôler les livrables des bakelistes pour une formation de qualité</p>
        </Div>
        <Flex gap={10}>
          <Div>
            {
              searchParams.has("entre") ? <FiltrerEntreDeuxDate
                date_debut={date_debut}
                setDateDebut={setDateDebut}
                date_fin={date_fin}
                setDateFin={setDateFin}
                setSearchParams={setSearchParams}
              /> : <Input
                type="date"
                value={date.value}
                onChange={(value) => {
                  // !ajouter le params sur l'url de la page
                  setSearchParams((searchParams) => {
                    searchParams.set("date", value)
                    return searchParams;
                  })
                  // !mise a jour du state
                  setDate({ value, errorMessage: "" });
                }}
                errorMessage={date.errorMessage}
              />
            }
            {/* <p onClick={() => setFilterByDate(!filterByDate)}>Filter par date</p> */}
          </Div>
          <ButtonComp
            onClick={() => {
              if (!searchParams.has("entre")) {
                setSearchParams({ entre: "=" })
              } else {
                setSearchParams()
              }
            }}
            icon={{
              color: rootStyles.color.secondary,
              name: FaFilter
            }}
            title={!searchParams.has("entre") ? "Personnaliser" : "Filtrer par date"}
            color={rootStyles.color.secondary}
            borderColor={rootStyles.color.secondary}
          />
        </Flex>
      </Flex>
      {
        (!isLoading && isFetching) && <Loader />
      }
      {
        isLoading ? <Loader /> : isError ? <Error /> : <Div mt={20}>
          <TabsProvider
            content={
              [
                {
                  title: "Présents",
                  content: () => <SuiviBakelistesLivraisonsTable data={data.liste_present} title={`Présents (${data.liste_present.length})`}/>,
                  number: data.nbr_present
                },
                {
                  title: "Livrés",
                  content: () => <SuiviBakelistesLivraisonsTable data={data.liste_bakelistes_livres} title={`Liste bakelistes livrés (${data.liste_bakelistes_livres.length})`}/>,
                  number: data.nbr_bakelistes_livres
                },
                {
                  title: "Non livrés",
                  content: () => <SuiviBakelistesLivraisonsTable data={data.liste_bakelistes_non_livres} title={`Liste bakelistes non livrés (${data.liste_bakelistes_non_livres.length})`} />,
                  number: data.nbr_bakelistes_non_livres
                },
              ]}
          />
        </Div>
      }
    </Div>
  )
}

export default SuiviLivraisonsPage