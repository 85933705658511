import React from "react";
import styled from "styled-components";

const LoaderPane = styled.div`
  width: 100%;
  height: 80px;
  margin: auto;
  display: block;
  position: relative;
  background: #fff;
  box-sizing: border-box;

  &:after {
    content: "";
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    top: 15px;
    left: 15px;
    position: absolute;
    background-image: linear-gradient(
        100deg,
        transparent,
        rgba(255, 255, 255, 0.5) 50%,
        transparent 80%
      ),
      linear-gradient(#ddd 56px, transparent 0),
      // linear-gradient(#ddd 24px, transparent 0),
      // linear-gradient(#ddd 18px, transparent 0),
      linear-gradient(#ddd 66px, transparent 0);
    background-repeat: no-repeat;
    background-size: 75px 100px, 100% 100px, 100% 30px, 260px 20px, 290px 56px;
    background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
    box-sizing: border-box;
    animation: animloader 1s linear infinite;
  }

  @keyframes animloader {
    0% {
      background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
    }
    100% {
      background-position: 150% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
    }
  }
`;

const CardSkeleton = () => {
    return <LoaderPane></LoaderPane>;
};

export default CardSkeleton;
