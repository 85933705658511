import React from "react";
import "./App.css";
import Roots from "./routes/Routes";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/css/styles.css"
import { Toaster } from "react-hot-toast";
import { persistor, store } from "redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "animate.css";
import { AuthProvider } from "context/userContext";
import { DrawerContextProvider } from "context/useDrawerContext";
import { useEffect, useState } from "react";
import ModalInstall from "components/installPwa/ModalInstal";
import CacheBuster from "CashBuster";
import Loader from "components/shared/Loader";

function App() {
    const [open, setOpened] = useState(false)
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const handleInstall = () => {
        setOpened(false);

        // Show the prompt
        if (deferredPrompt) {
            deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === "accepted") {
                    console.log("User accepted the A2HS prompt");
                } else {
                    console.log("User dismissed the A2HS prompt");
                }
                console.log({ choiceResult });
                setDeferredPrompt(null);
            });
        }
    };


    useEffect(() => {
    // btnInstall.current.style.display = "none";
    // console.log(btnInstall.current);
        async function actionInstall(e) {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            setDeferredPrompt(e);
            setOpened(true);
            // Update UI to notify the user they can add to home screen
            // btnInstall.current.style.display = "block";
        }

        window.addEventListener("beforeinstallprompt", actionInstall);
    // return window.removeEventListener("beforeinstallprompt", actionInstall);
    }, [])

    return (
        <>
            <CacheBuster>
                {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                    if (loading) return <Loader />;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        refreshCacheAndReload();
                    }

                    return (
                        <Provider store={store}>
                            <ModalInstall
                                opened={open}
                                onInstall={handleInstall}
                                onClosed={() => setOpened(false)}
                            />
                            <DrawerContextProvider>
                                <PersistGate
                                    loading={<Loader />}
                                    persistor={persistor}
                                >
                                    <AuthProvider>
                                        <Roots />
                                        <Toaster
                                            position="bottom-left"
                                            reverseOrder={false}
                                        />
                                    </AuthProvider>
                                </PersistGate>
                            </DrawerContextProvider>
                        </Provider>
                    );
                }}
            </CacheBuster>
        </>
    );
}

export default App;
