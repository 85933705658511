import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    currentDomaine: {},
    domainesList: [],
    allDomainesList: [],
}

/**
* !Some Domaineslice reducers are used in DomaineApi.js located in
redux/apiContoller/
*/

const domaineSlice = createSlice({
    name: "domaines",
    initialState,
    reducers: {
        setCurrentDomaineSlice: (state, { payload }) => {
            state.currentDomaine = payload
        },
        setDomainesListSlice: (state, { payload }) => {
            state.domainesList = payload
            state.allDomainesList = payload
        },
        addNewDomaineSlice: (state, { payload }) => {
            state.allDomainesList = [...state.allDomainesList, payload].reverse()
            state.domainesList = [...state.allDomainesList, payload].reverse()
        },
        updateDomaineSlice: (state, { payload }) => {
            let domaines = state.allDomainesList.filter((domaine) => domaine.id !==
                payload.id)
            domaines = [...domaines, payload].reverse()
            state.allDomainesList = domaines
            state.domainesList = domaines
        },
        archiveDomaineSlice: (state, { payload }) => {
            const domaines = state.allDomainesList.filter((domaine) => domaine.id !==
                payload.id)
            state.domainesList = domaines
            state.allDomainesList = domaines
        },
        filterDomaineList: (state, { payload }) => {
            /**
            * @param payload {string}
            */
            state.domainesList = state.allDomainesList.filter((item) => {
                const data = ["******** PUT RESOURCE PROPERTIES HERE *********"].join(" ").toLowerCase()
                return data.includes(payload.toLowerCase())
            })
        }
    }
}
)


export const { setDomainesListSlice, addNewDomaineSlice, setCurrentDomaineSlice, updateDomaineSlice, archiveDomaineSlice, filterDomaineList } = domaineSlice.actions

export default domaineSlice.reducer