import { useBakelisteActif } from "actions/bakelistes"
import { useMettreEnPresentielMutation } from "actions/bakelistes/mutatons"
import ConfirmAction from "components/shared/ConfirmAction"
import React from "react"
import toast from "react-hot-toast"

const MettreBakelisteEnPresentielFeature = ({ openConfirmModal, setOpenConfirmModal, selectedBakeliste }) => {
    const [placerBakelisteEnPresentiel, { isLoading }] = useMettreEnPresentielMutation()
    const { refetchBakelisteActif } = useBakelisteActif()
    const placerLeBakelisteEnPresentielFunc = () => {
        placerBakelisteEnPresentiel(selectedBakeliste.id).unwrap()
            .then(() => {
                refetchBakelisteActif()
                toast.success(`${selectedBakeliste.first_name} est mis en présentiel`)
                setOpenConfirmModal(false)
            })
            .catch((err) => {
                toast.error("Une erreur est survenue")
            })
    }
    return (
        <div>
            <ConfirmAction
                modalIsOpen={openConfirmModal}
                modalText="Mettre en présentiel"
                text={`Êtes-vous sûr de vouloir mettre ${selectedBakeliste?.first_name} ${selectedBakeliste?.last_name} en présentiel ?`}
                cancel={() => setOpenConfirmModal(false)}
                validate={placerLeBakelisteEnPresentielFunc}
                closeModal={() => setOpenConfirmModal(false)}
                loading={isLoading}
            />
        </div>
    )
}

export default MettreBakelisteEnPresentielFeature