import { SPACES_ENDPOINT } from "routes/api/endpoints";

const { siAdminAPi } = require("redux/rtkquery");

const typePresenceApi = siAdminAPi.injectEndpoints({
    endpoints: (build) => ({
        getSpaces: build.query({
            query: () => SPACES_ENDPOINT,
            providesTags: ["spaces"],
        }),
    }),
    overrideExisting: false,
});

export const { useGetSpacesQuery } = typePresenceApi