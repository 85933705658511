import { rootStyles } from "assets/css/globalCss";
import React from "react";
import styled from "styled-components";

const Title = styled.h3`
  color: ${({ color }) => color};
  font-size: ${rootStyles.fonts.h2FontSize};
  font-weight: ${rootStyles.fonts.h2FontWeight};
`;

export const Title3 = ({ children, color = "#000000" }) => {
  return <Title color={color}>{children}</Title>;
};

export default Title3;
