import React from "react"

const ErrorBakeliste = () => {
    return (
        <div className='text-center'>
            <p>Une erreur est survenu lors de la récupération des détails de ce bakeliste</p>
            <p className='text-danger fs-6'>Veuillez contacter l'équipe technique si l'erreur persiste</p>
        </div>
    )
}

export default ErrorBakeliste