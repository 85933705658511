import { rootStyles } from "assets/css/globalCss";
import FlexBetween from "components/shared/FlexBetween";
import React from "react";
import { FiUsers } from "react-icons/fi";
import { COACH_CURRENT_BAKELISTES_STATUS_PATH } from "routes/navigation/navigationPaths";
import styled from "styled-components";

const CardContainer = styled.div`
  padding: ${rootStyles.padding.btnPadding};
  background-color: white;
  box-shadow: ${rootStyles.shadow.card};
  border-radius: ${rootStyles.radius.card};
  border-right: 5px solid ${rootStyles.color.tercary};
  height: 100px;
  //   background-color: ${rootStyles.color.secondaryFonce};
  margin: 5px;
  transition: all 100ms ease;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  &:active {
    transform: scale(0.98, 0.98);
  }
`;
const NamePane = styled.span`
  font-weight: 600;
  @media only screen and (max-width: 500px) {
    font-size: 12px;
  }
  //   color: white;
`;
const NumberPane = styled.span`
  font-weight: 600;
  color: ${rootStyles.color.tercary};
  @media only screen and (max-width: 500px) {
    font-size: 12px;
  }
`;

const SuiviCoachingCardItem = ({ suiviCoach, navigateToDetails }) => {
  const { label, number, currentBakelistesSuivi } = suiviCoach;

  return (
    <CardContainer
      className="focus--click"
      onClick={() =>
        navigateToDetails({
          label,
          currentBakelistesSuivi,
          to: COACH_CURRENT_BAKELISTES_STATUS_PATH,
        })
      }
    >
      <FiUsers size={20} />
      <FlexBetween>
        <NamePane>{label}</NamePane>
        <NumberPane>{number}</NumberPane>
      </FlexBetween>
    </CardContainer>
  );
};

export default SuiviCoachingCardItem;
