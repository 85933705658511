import FadeTransition from "components/shared/FadeTransition";
import Loader from "components/shared/Loader";
import TableListeView, { Title2 } from "components/shared/TableListeView";
import React, { useMemo } from "react";
import {
  Comparator,
  dateFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import styled from "styled-components";
import { completeDateFormatter } from "utils/timeFormatters";
import { useGetUsersHistory } from "./api/hooks/useGetUsersHistory";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  gap: 13px;
  overflow-y: auto;
  padding: ${({ padding }) => (!padding ? "20px 5px" : `${padding}px`)};

  @media only screen and (max-width: 500px) {
    grid-template-columns: repeat(100%, 1fr);
  }
`;

const HistoriquesPage = () => {
  const columns = useMemo(
    () => [
      {
        dataField: "utilisateur_name",
        text: "Utilisateur",
        filter: textFilter({
          placeholder: "Recherche par nom complet",
        }),
      },
      {
        dataField: "auteur_name",
        text: "Auteur",
        filter: textFilter({
          placeholder: "Recherche par auteur",
        }),
      },
      {
        dataField: "type",
        text: "Action",
      },
      {
        dataField: "created_at",
        text: "Date",
        formatter: (cell, row) => completeDateFormatter(row?.created_at),
        filter: dateFilter({
          placeholder: "Recherche par date",
          withoutEmptyComparatorOption: true,
          comparators: [Comparator.EQ],
          comparatorClassName: "d-none",
          style: { display: "grid" },
        }),
      },
    ],
    []
  );

  const { historiques, loadingHistory } = useGetUsersHistory();

  return (
    <FadeTransition in={true}>
      <Title2>Historiques des utilisateurs</Title2>
      {loadingHistory ? (
        <Loader />
      ) : (
        <TableListeView
          data={historiques?.data}
          columns={columns}
          striped={false}
          wrapperClasses="bg-white"
          bordered={false}
        />
      )}
    </FadeTransition>
  );
};

export default HistoriquesPage;
