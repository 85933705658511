import { GET_BAKELISTES_ACTIF_ENPOINT, GET_BAKELISTES_INACTIF_ENPOINT, RESEARCH_BAKELISTE_ENDPOINT } from "routes/api/endpoints";

const { siAdminAPi } = require("redux/rtkquery");

const bakelisteApi = siAdminAPi.injectEndpoints({
    endpoints: (build) => ({
        research: build.query({
            query: (params) => {
                return {
                    url: RESEARCH_BAKELISTE_ENDPOINT,
                    params
                }
            },
            providesTags: ["Bakelistes"],
            keepUnusedDataFor: 5000
        }),
        getBakelisteActif: build.query({
            query: () => {
                return {
                    url: GET_BAKELISTES_ACTIF_ENPOINT
                }
            },
            providesTags: ["Bakelistes"],
            keepUnusedDataFor: 5000
        }),
        getBakelisteInActif: build.query({
            query: () => {
                return {
                    url: GET_BAKELISTES_INACTIF_ENPOINT
                }
            },
            providesTags: ["Bakelistes"],
            keepUnusedDataFor: 5000
        }),
    }),
    overrideExisting: false,
});

export const { useResearchQuery, useGetBakelisteActifQuery, useGetBakelisteInActifQuery } = bakelisteApi