import React from "react";
import { MdFolderShared } from "react-icons/md";
import styled from "styled-components";

const DriveContainer = styled.div`
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  border: 1px solid transparent;
  border-bottom-color: #c7c7c7;
  box-sizing: border-box;
  color: var(--dt-on-surface-variant, rgb(95, 99, 104));
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 48px;
  line-height: 48px;
  &:hover {
    background-color: rgba(199, 199, 199, 0.3);
    cursor: pointer;
  }

  @media only screen and (max-width: 500px) {
    font-size: 10px;
    font-weight: 500;
  }
`;

const DriveHeadContainer = styled.div`
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  border: 1px solid transparent;
  border-bottom-color: #c7c7c7;
  box-sizing: border-box;
  color: var(--dt-on-surface-variant, rgb(95, 99, 104));
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 48px;
  line-height: 48px;

  @media only screen and (max-width: 500px) {
    font-size: 10px;
    height: 40px;
  }
`;

const DriveIcon = styled.div`
  text-align: center;
  // flex-basis: 56px;
  // width: 56px;

  @media only screen and (max-width: 500px) {
    // flex-basis: 40px;
    // width: 40px;
  }
`;

const DriveContaint = styled.div`
  flex-basis: 120px;
  width: 120px;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  letter-spacing: 0.0178571429em;
  font-weight: 600;
  font-size: 14px;

  @media only screen and (max-width: 500px) {
    font-size: 14px;
    flex-basis: 140px;
    width: 140px;
  }
`;

const DriveFirstColumn = styled.div`
  flex-basis: 120px;
  width: 120px;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  letter-spacing: 0.0178571429em;
  font-weight: 600;
  font-size: 14px;

  @media only screen and (max-width: 500px) {
    font-size: 12.5px;
    flex-basis: 140px;
    width: 140px;
  }
`;

const DriveText = styled.div`
  font-weight: 500;
  font-size: 13px;

  @media only screen and (max-width: 500px) {
    flex-basis: 144px;
    width: 144px;
    // font-size: 1px;
    // font-weight: 400;
  }
`;

const DriveNumber = styled.div`
  font-weight: 500;
  flex-basis: 144px;
  width: 144px;

  @media only screen and (max-width: 500px) {
    flex-basis: 80px;
    width: 80px;
    font-size: 14px;
    font-weight: 500;
  }
`;

// const DriveHeadText = styled.div`
//   font-weight: 600;
//   font-size: 14px;

//   @media only screen and (max-width: 500px) {
//     font-size: 16px;
//   }
// `;

const DriveLineFirstPane = styled.div`
  flex-basis: 144px;
  width: 144px;
  display: flex;
  gap: 5px;
`;

const DriveLine = ({ label1, label2, label3, onClick }) => {
    return (
        <DriveContainer onClick={onClick}>
            <DriveLineFirstPane>
                <DriveIcon>
                    <MdFolderShared size={30} />
                </DriveIcon>
                <DriveFirstColumn>{label1}</DriveFirstColumn>
            </DriveLineFirstPane>
            <DriveText>{label2}</DriveText>
            <DriveNumber>{label3}</DriveNumber>
        </DriveContainer>
    );
};

export const DriveHead = ({ label1, label2, label3 }) => {
    return (
        <DriveHeadContainer>
            {/* <div className="d-flex"> */}
            <DriveContaint>{label1}</DriveContaint>
            {/* </div> */}
            {/* <DriveText> */}
            <DriveContaint>{label2}</DriveContaint>
            {/* </DriveText> */}
            <DriveNumber>
                <DriveContaint>{label3}</DriveContaint>
            </DriveNumber>
        </DriveHeadContainer>
    );
};

export default DriveLine;
