import { rootStyles } from "assets/css/globalCss"
import ButtonComp from "components/ButtonComp"
import InputForm from "components/shared/InputForm"
import React from "react"
import { useFormContext } from "react-hook-form"
import toast from "react-hot-toast"
import { useDeTerminerABakelisteMutation } from "pages/bakelistes/api/mutations"

const ReintegrerWithDateFin = ({ onClosed, currentBakeliste }) => {
    const { register, handleSubmit, reset, formState: { errors, isSubmitting } } = useFormContext()
    const [deTerminerABakeliste, { isLoading }] = useDeTerminerABakelisteMutation()
    const onSubmit = (data) => {
        const bakeliste_id = currentBakeliste.id
        const date = new Date(data.fin_formation)

        const annee = date.getFullYear()
        const mois = (date.getMonth() + 1).toString().padStart(2, "0")// Les mois sont 0-indexés
        const jour = date.getDate().toString().padStart(2, "0")

      const dateFormatee = `${annee}-${mois}-${jour}`
        toast.promise(deTerminerABakeliste({ bakeliste_id, fin_formation: dateFormatee })
            .unwrap().then(() => {
                onClosed()
                reset()
            }), {
                loading: "Reintégration en cours en cours...",
                success: currentBakeliste.first_name + " reintégré(e) avec succés",
                error: `La reintégration de ${currentBakeliste.first_name} a echouée`
        })
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ margin: "20px 0" }}>
            <InputForm
                label={"Date de fin de formation"}
                type={"date"}
                register={{ ...register("fin_formation") }}
                error={errors.fin_formation?.message}
            />
            <ButtonComp
                title={"Envoyez"}
                disabled={isLoading || isSubmitting}
                loading={isLoading || isSubmitting}
                bgColor={rootStyles.color.secondary}
                color={rootStyles.color.primary}
            />
        </form>
    )
}

export default ReintegrerWithDateFin