import { rootStyles } from "assets/css/globalCss";
import styled from "styled-components";

export const ListebakelistesPane = styled.div`
    position: absolute;
    background-color: ${rootStyles.color.primary};
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    width: 100%;
    border-radius: 20px;
    `
export const MainListePane = styled.div`
    margin: 10px;
    max-height: 300px;
    overflow: auto;
 scrollbar-color: #6BAF8D #232E33;
    ::-webkit-scrollbar {
        width: 3px;
    }
    ::-webkit-scrollbar-track {
        background: #6BAF8D;
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: #232E33;
        border-radius: 10px;
    }
`
export const ListItem = styled.li`
    cursor: pointer;
    text-decoration: none;
    color: #555;
    border-bottom: 1px solid #eee;
    display: block;
    margin-bottom: 5px;
    padding: 2px 15px;
    transition: all 200ms ease-in-out;

    &:hover{
        background-color: rgba(25, 35, 50, 0.1);
        border-left: 2px solid ${rootStyles.color.secondary};
        color: #333
    }
`
export const Name = styled.p`
    font-size: 14px;
    font-weight: 500;
`
export const Desc = styled.span`
    display: inline-block;
    font-size: 12px;
    margin-right: 10px;
`