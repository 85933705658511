import React from "react"

const MultiStepProgress = ({ page }) => {
  return (
    <div className="progress-form-container m-0">
        <div
          className="progress-form"
          style={{
            height: "3px",
            backgroundColor: "#00000033",
            width: "100%"
            // transition: 'all 0.8s ease-in'
          }}
        >
          <div
            style={{
              height: "3px",
              backgroundColor: "#009688",
            color: "white",
              width:
              page === 0 ?
                "2%" :
                page === 1 ?
                  "50%" :
                  page === 2 ?
                    "100%" :
                    "100%",
              transition: "all 0.5s ease-in"
            }}
          ></div>
        </div>
        <div className="form-step m-0">
        <div style={{ backgroundColor: "#03a192", color: "white" }}>1</div>
          <div
            style={{
              backgroundColor: page >= 1 ? "#03a192" : "white",
              color: page >= 1 ? "#fff" : "#03a192",
              transition: "all 1.1s ease-in"
            }}
          >
            2
          </div>
          <div
            style={{
              backgroundColor: page === 2 ? "#03a192" : "white",
              color: page === 2 ? "#fff" : "#03a192",
              transition: "all 1.1s ease-in"
            }}
          >
            3
          </div>
        </div>
      </div>
  )
}

export default MultiStepProgress