import { useGetAllRegionsPointageQuery, useGetHebdoPresencesAndAbsencesQuery } from "./queries"

export const useAllBakelistePointage = (params) => {
    const { data: pointages, isError, isLoading, refetch } = useGetAllRegionsPointageQuery(params)

    return { pointages, isError, isLoading, refetch }
}

export const useHebdoPresencesAbsences = (params) => {
    const { data, isError, isLoading, refetch } = useGetHebdoPresencesAndAbsencesQuery(params)

    return { data, isError, isLoading, refetch }
}