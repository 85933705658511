import React from "react"
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { axiosInstance } from "redux/apiController/axiosInstance";
import { setCurrentUser } from "redux/features/userSlice";
import { ACCEUIL_PATH, LOGIN_PATH } from "routes/navigation/navigationPaths";

const { createContext, useContext, useState } = require("react");

const AuthContext = createContext()

export const useAuthContext = () => {
    return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
    const dispatch = useDispatch()
    const [isSigning, setIsSigning] = useState(false)
    const handleLogin = (data, navigate) => {
        setIsSigning(true)
        axiosInstance.post("user/login", data).then((res) => {
            const currentUser = res.data.data
            console.log({ currentUser });
            if (currentUser.status !== "admin" && currentUser.entite === "Bakeli") {
                logout(navigate)
                toast.error("Vous n'êtes pas autorisé sur cette application")
                return navigate(LOGIN_PATH)
            }
            dispatch(setCurrentUser(currentUser))
            return navigate(ACCEUIL_PATH)
        }).catch((err) => {
            if (err.code === "ERR_NETWORK") {
                toast.error("Une erreur de connexion est survenu. Veuillez vérifier votre connexion internet")
            }
        }).finally(() => {
            setIsSigning(false)
        })
    }
    const logout = (navigate) => {
        dispatch({
            type: "USER_LOGOUT"
        })
        return navigate(LOGIN_PATH)
    }
    return <AuthContext.Provider
        value={{
            signIn: handleLogin,
            isSigning,
            logout
        }}>
        {children}
    </AuthContext.Provider>
}