import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    currentProspect: {},
    prospectsList: [],
    allProspectsList: [],
    currentTypeProspects: [],
    filterByAllProspects: true,
    filterByCalledProspects: false,
    filterByNotCalledProspects: false,
    currentRegion: "",
    listProspectDakar: [],
    listProspectMbour: [],
    listProspectThies: []
}

/**
* !Some Prospectslice reducers are used in ProspectApi.js located in
redux/apiContoller/
*/

const prospectSlice = createSlice({
    name: "prospects",
    initialState,
    reducers: {
        setCurrentProspectSlice: (state, { payload }) => {
            state.currentProspect = payload
        },
        setProspectsListSlice: (state, { payload }) => {
            state.prospectsList = payload
            state.allProspectsList = payload
            state.currentTypeProspects = payload
        },
        setCurrentRegion: (state, { payload }) => {
            if (typeof payload !== "string") {
                throw new TypeError("Veuillez fournir une chaine de caractere")
            }
            return {
                ...state,
                currentRegion: payload
            }
        },
        setListProspectDakar: (state, { payload }) => {
            return {
                ...state,
                listProspectDakar: payload
            }
        },
        setListProspectMbour: (state, { payload }) => {
            return {
                ...state,
                listProspectMbour: payload
            }
        },
        setListProspectThies: (state, { payload }) => {
            return {
                ...state,
                listProspectThies: payload
            }
        },
        addNewProspectSlice: (state, { payload }) => {
            state.allProspectsList = [...state.allProspectsList, payload].reverse()
            state.prospectsList = [...state.allProspectsList, payload].reverse()
            state.currentTypeProspects = [...state.allProspectsList, payload]
        },
        changeProspectStatus: (state, { payload }) => {
            state.currentTypeProspects = state.currentTypeProspects.map((prospect) => {
                if (prospect.id === payload.id) {
                    prospect = payload
                }
                return prospect
            })
            state.prospectsList = state.currentTypeProspects
            return {
                ...state
            }
        },
        updateProspectSlice: (state, { payload }) => {
            let prospects = state.allProspectsList.filter((prospect) => prospect.id !==
                payload.id)
            prospects = [...prospects, payload].reverse()
            state.allProspectsList = prospects
            state.prospectsList = prospects
            state.currentTypeProspects = prospects
        },
        archiveProspectSlice: (state, { payload }) => {
            // let prospects = state.allProspectsList.filter((prospect) => prospect.id !==
            //     payload.id)
            state.prospectsList = prospects
            state.allProspectsList = prospects
            state.currentTypeProspects = prospects
        },
        setFilterByAllProspects: (state, { payload }) => {
            // console.log({ payload });
            // state.prospectsList = s
            return {
                ...state,
                prospectsList: state.allProspectsList,
                currentTypeProspects: state.allProspectsList,
                filterByAllProspects: true,
                filterByCalledProspects: false,
                filterByNotCalledProspects: false,
            }
        },
        setFilterByCalledProspects: (state, { payload }) => {
            // console.log({ payload });
            // state.prospectsList = s
            return {
                ...state,
                prospectsList: state.allProspectsList.filter((prospect) => prospect.is_called),
                currentTypeProspects: state.allProspectsList.filter((prospect) => prospect.is_called),
                filterByCalledProspects: true,
                filterByNotCalledProspects: false,
                filterByAllProspects: false
            }
        },
        setFilterByNotCalledProspects: (state, { payload }) => {
            // console.log({ payload });
            // state.prospectsList = s
            return {
                ...state,
                prospectsList: state.allProspectsList.filter((prospect) => !prospect.is_called),
                currentTypeProspects: state.allProspectsList.filter((prospect) => !prospect.is_called),
                filterByNotCalledProspects: true,
                filterByCalledProspects: false,
                filterByAllProspects: false,
            }
        },
        filterProspectByDate: (state, { payload }) => {
            /**
             * Filtrer les prospects entre deux date
             */
            // console.log({ payload });
            let newProspects = state.prospectsList
            if (new Date(payload.date_debut.value) <= new Date(payload.date_fin.value)) {
                newProspects = state.allProspectsList.filter(
                    (prospect) => new Date(prospect.created_at) >= new Date(payload.date_debut.value) && new Date(prospect.created_at) <= new Date(payload.date_fin.value)
                );
            }
            // console.log({ prospects: state.prospectsList });
            return {
                ...state,
                prospectsList: newProspects
            }
        },
        filterProspectsList: (state, { payload }) => {
            /**
            * @param payload {string}
            */
            if (!payload) {
                state.prospectsList = state.currentTypeProspects
                return
            }
            state.prospectsList = state.currentTypeProspects.filter((item) => {
                const data = [item.type_formation].join(" ").toLowerCase()
                // item.domaine, item.firstName, item.lastName
                return data.includes(payload.toLowerCase())
            })
        },
        resetCurrentProspect: (state) => {
            state.currentProspect = null
        }
    }
}
)


export const {
    setProspectsListSlice,
    addNewProspectSlice,
    setCurrentProspectSlice,
    updateProspectSlice,
    archiveProspectSlice,
    filterProspectsList,
    setFilterByCalledProspects,
    setFilterByNotCalledProspects,
    setFilterByAllProspects,
    changeProspectStatus,
    resetCurrentProspect,
    setCurrentRegion,
    filterProspectByDate
} = prospectSlice.actions

export default prospectSlice.reducer