import React from "react"
import moment from "moment"
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import ProfilDetail from "../ProfilDetail";
import { rootStyles } from "assets/css/globalCss";
import { DETAILS_SUIVI_COACHING_PATH, SUIVI_COACHING_PATH } from "routes/navigation/navigationPaths";
import { setCurrentCoachSuivi } from "redux/features/coachSlice";

const ParaLink = styled.p`
  border-bottom: 1px solid;
  width: max-content;
  cursor: pointer;
  color: ${rootStyles.color.blue};
  box-sizing: border-box;
  padding: 5px;
  transition: all 500ms ease-in-out;
  outline: none;

  &:hover{
    outline: 1px solid;
  }
`

const SuiviFormation = ({ formation_days1, bakeliste, currentBakeliste }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    return (
        // console.log("dd", bakeliste, currentBakeliste),
        <div className='row'>
            <div className='col-sm-3'>
                <ProfilDetail title="Horaire" desc={() => bakeliste.type_presence?.libelle ?
                    `${bakeliste.type_presence?.libelle} (${bakeliste.type_presence?.heure_debut}-${bakeliste.type_presence?.heure_fin})` : "Pas d'horaire"} />
            </div>
            <div className='col-sm-3'>
                <ProfilDetail
                    title="Jours de formation"
                    desc={() => formation_days1.map((jour) => jour.name).join(", ")}
                />
            </div>
            <div className='col-sm-3'>
                <ProfilDetail
                    title="Type formation"
                    desc={() => bakeliste.type_formation ?? "...."}
                />
            </div>
            <div className='col-sm-3'>
                <ProfilDetail
                    title="Durée"
                    desc={() => `${moment(bakeliste.fin_formation).diff(moment(bakeliste.debut_formation), "month")} mois`}
                />
            </div>
            <div className='col-sm-3'>
                <ProfilDetail
                    title="Coach"
                    desc={() => currentBakeliste.coach_fullName}
                    style={{ cursor: "pointer" }}
                />
            </div>
            <div className='col-sm-3'>
                <ProfilDetail
                    title="Coach Assistant"
                    desc={() => <ParaLink
                        onClick={() => {
                            dispatch(setCurrentCoachSuivi({ id: currentBakeliste.coach_assistant_id }));
                            return navigate(SUIVI_COACHING_PATH + "/" + DETAILS_SUIVI_COACHING_PATH)
                        }}
                    >
                        {bakeliste?.coach_assistant}
                    </ParaLink>}
                />
                {/* <button
                                                     className = "btn-primary"
                                                    onClick={() => navigateToDetails(row)}
                                                >
                                                    {currentBakeliste.coach_assistant_fullName}
                                                </button> */}
            </div>
            <div className='col-sm-3'>
                <ProfilDetail
                    title="Date de début"
                    desc={() => new Date(bakeliste.debut_formation).toLocaleDateString("fr-Fr")}
                />
            </div>
            <div className='col-sm-3'>
                <ProfilDetail
                    title="Date de fin"
                    desc={() => new Date(bakeliste.fin_formation).toLocaleDateString("fr-Fr")}
                />
            </div>
        </div>
    )
}

export default SuiviFormation