import { rootStyles } from "assets/css/globalCss"
import ButtonComp from "components/ButtonComp"
import ResourceDataMap from "components/ResourceDataMap"
import FadeTransition from "components/shared/FadeTransition";
import InputForm, { Label } from "components/shared/InputForm"
import SelectForm from "components/shared/SelectForm"
import TextArea from "components/shared/TextArea"
import React, { useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-hot-toast"
import { MdEdit } from "react-icons/md"
import { useNavigate } from "react-router-dom"
import { ApiController } from "redux/apiController"
import styled from "styled-components"

const ContainerAjout = styled.div`
  background-color: ${rootStyles.color.primary};
  border-radius: ${rootStyles.radius.card};
  min-height: 400px;
  padding: ${rootStyles.padding.btnPadding};
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
`;
const SpanDesc = styled.span`
  font-size: 11px;
  display: block;
  margin-top: -15px;
  margin-bottom: 20px;
`;

const AjoutEvenementPage = () => {
  const navigate = useNavigate();
  const [file, setFile] = React.useState(null);
  const [uploadedFile, setUploadedFile] = React.useState("");
  const [creatingEvent, setCreatingEvent] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const typePresence = useMemo(
    () => [
      {
        label: "En Ligne",
        value: "en_ligne",
      },
      {
        label: "En presentiel",
        value: "en_présentiel",
      },
      {
        label: "En présentiel et en ligne",
        value: "en_ligne_presentiel",
      },
    ],
    []
  );
  const typeFormation = useMemo(
    () => [
      {
        label: "Workshop",
        value: "workshop",
      },
      {
        label: "JeemaCoder",
        value: "jeemaCoder",
      },
      {
        label: "Hackaton",
        value: "hackaton",
      },
    ],
    []
  );
  const fileValidation = (elementID) => {
    const fileInput = document.getElementById(elementID);

    const filePath = fileInput.value;

    // Allowing file type
    const allowedExtensions = /(\.png|\.jpg|\.jpeg|)$/i;

    if (!allowedExtensions.exec(filePath)) {
      fileInput.value = "";
      return false;
    } else {
      return true;
    }
  };
  const handlefileChange = (e) => {
    // console.log(e.target.files[0]);
    if (e.target.name === "rencontre_file") {
      if (fileValidation("rencontre_file")) {
        setUploadedFile(e.target.files[0]);
        setFile(URL.createObjectURL(e.target.files[0]));
      }
    }
  };
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("rencontre_file", uploadedFile);
    formData.append("lien_groupe_whatsapp", data.lien_groupe_whatsapp);
    formData.append("regime", data.regime);
    formData.append("rencontre_online", 0);
    formData.append("rencontre_description", data.rencontre_description);
    formData.append("rencontre_end_date", data.rencontre_end_date);
    formData.append("rencontre_end_time", data.rencontre_end_time);
    formData.append("rencontre_name", data.rencontre_name);
    formData.append("rencontre_place", data.rencontre_place);
    formData.append("rencontre_start_date", data.rencontre_start_date);
    formData.append("rencontre_start_time", data.rencontre_start_time);
    formData.append("rencontre_type", data.rencontre_type);
    formData.append("rencontre_visibility", data.rencontre_visibility);

    // console.log({ data, rencontre_file: uploadedFile });
    setCreatingEvent(true);
    ApiController.evenements
      .addNewEvenement(formData)
      .then(() => {
        toast.success("Evenement Créé");
        navigate(-1);
      })
      .catch(() => {
        toast.error("Une erreur est survenue");
      })
      .finally(() => {
        setCreatingEvent(false);
      });
  };
  return (
    <FadeTransition in={true}>
      <ContainerAjout>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={`event_bannerImg mb-3 ${
              file === null ? "event_bannerImgBorder" : ""
            }`}
          >
            {file ? (
              <>
                <img src={file} alt="" />
                <span
                  className="imgPreviewSection__Icon"
                  onClick={() => setFile(null)}
                >
                  <MdEdit size={20} />
                </span>
              </>
            ) : (
              <>
                <input
                  type="file"
                  onChange={(e) => handlefileChange(e)}
                  name="rencontre_file"
                  className="event__bannerFile"
                  id="rencontre_file"
                  accept=".png, .jpg, .jpeg"
                />
                <label htmlFor="rencontre_file">Choisir une image</label>
                {/* <button className="col-md-3 btn-theme"
                      type="file"
                      onClick={(e) => handlefileChange(e)}
                      name="rencontre_file"
                      id="rencontre_file"
                      accept=".png, .jpg, .jpeg">Choisir une image</button> */}
              </>
            )}
          </div>
          <InputForm
            htmlFor="theme"
            register={{ ...register("rencontre_name") }}
            label="Thème de l'événement"
            type="text"
            error={errors.rencontre_name}
          />
          <InputForm
            register={{ ...register("rencontre_place") }}
            htmlFor="lieu"
            label="Lieu de l'évenement"
            type="text"
            error={errors.rencontre_place}
          />
          <InputForm
            htmlFor="lien_wsp"
            register={{ ...register("lien_groupe_whatsapp") }}
            label="Lien du groupe whatsapp"
            type="text"
            error={errors.lien_groupe_whatsapp}
          />
          <SelectForm
            register={{ ...register("regime") }}
            label="L'évènement sera en ligne ou en présentiel?"
            error={errors.regime}
          >
            <option value="">--choisir lieu--</option>
            <ResourceDataMap
              resourceData={typePresence}
              resourceItem={({ option }) => (
                <option value={option.value}>{option.label}</option>
              )}
              resourceName="option"
            />
          </SelectForm>
          <div className="d-flex flex-wrap gap-2">
            <InputForm
              htmlFor="rencontre_start_date"
              register={{ ...register("rencontre_start_date") }}
              label="Date début"
              type="date"
              error={errors.rencontre_start_date}
            />
            <InputForm
              htmlFor="rencontre_start_time"
              register={{ ...register("rencontre_start_time") }}
              label="Heure début"
              type="time"
              error={errors.rencontre_start_time}
            />
            <InputForm
              htmlFor="rencontre_end_date"
              register={{ ...register("rencontre_end_date") }}
              label="Date fin"
              type="date"
              error={errors.rencontre_end_date}
            />
            <InputForm
              htmlFor="rencontre_end_time"
              register={{ ...register("rencontre_end_time") }}
              label="Heure fin"
              type="time"
              error={errors.rencontre_end_time}
            />
          </div>
          <TextArea
            htmlFor="rencontre_description"
            register={{ ...register("rencontre_description") }}
            label="Description"
            placeholder="Ajouter des détails tels que des sujets, programmes..."
            error={errors.rencontre_description}
          ></TextArea>
          <SelectForm
            htmlFor="rencontre_type"
            register={{ ...register("rencontre_type") }}
            label="Type d'événement"
            error={errors.rencontre_type}
          >
            <option value="">--choisir type--</option>
            <ResourceDataMap
              resourceData={typeFormation}
              resourceItem={({ option }) => (
                <option value={option.value}>{option.label}</option>
              )}
              resourceName="option"
            />
          </SelectForm>
          <Label>Visibilité de l’évènement</Label>
          <SpanDesc>
            La visibilité de l’évènement ne peut plus être modifiée une fois
            l’évènement crée. En savoir plus
          </SpanDesc>
          <div>
            <div>
              <Label>
                <input
                  {...register("rencontre_visibility")}
                  type="radio"
                  name="rencontre_visibility"
                  value="public"
                />
                <span className="ms-2">Événement public</span>
              </Label>
              <SpanDesc>
                Visible de tout le monde sur facebook. N’importe qui peut décidé
                de participer.
              </SpanDesc>
            </div>
            <div>
              <Label>
                <input
                  {...register("rencontre_visibility")}
                  type="radio"
                  name="rencontre_visibility"
                  value="privé"
                />
                <span className="ms-2">Événement privé</span>
              </Label>
              <SpanDesc>
                Visible des invités et de toute autre personne ayant le lien de
                l’événement. Les personnes qui ne sont pas invitées doivent
                obtenir votre approbation pour participer.
              </SpanDesc>
            </div>
          </div>
          <div className="d-flex mt-3 gap-2 justify-content-end">
            <ButtonComp
              loading={creatingEvent}
              bgColor={rootStyles.color.secondary}
              color={rootStyles.color.primary}
              title="Créer"
            />
            <ButtonComp
              type="button"
              onClick={() => navigate(-1)}
              bgColor={rootStyles.color.red}
              color={rootStyles.color.primary}
              title="Annuler"
            />
          </div>
        </form>
      </ContainerAjout>
    </FadeTransition>
  );
};

export default AjoutEvenementPage