import React, { useEffect, useState } from "react";
import CommentHeader from "./CommentHeader";
import ReplyContainer from "./ReplyContainer";
import "./Styles/Comment.scss";

const Comment = ({ commentData, livraison_id }) => {
  const [firstReply, setFirstReply] = useState();
  const [showAllReplies, setShowAllReplies] = useState(false);

  useEffect(() => {
    setFirstReply(commentData?.replies?.slice(0, 1));
  }, []);

  const handleShowReplies = () => {
    setShowAllReplies(true);
  };

  return (
    <div
      className={`comment-container ${
        commentData?.replies?.at(0) !== undefined ? "reply-container-gap" : ""
      }`}
    >
      <div className={"comment"}>
        <div className="comment--body">
          <CommentHeader commentData={commentData} />
          <div className="comment-content">{commentData?.comment_text}</div>
        </div>
        {/* <CommentFooter commentData={commentData} />{" "} */}
      </div>
      {commentData?.replies && (
        <ReplyContainer
          key={commentData?.replies?.id}
          commentData={commentData?.replies}
          livraison_id={livraison_id}
          parent_id={commentData?.id}
          secondParent_id={commentData?.replies?.id}
          firstReply={firstReply}
          showAllReplies={showAllReplies}
          handleShowReplies={handleShowReplies}
        />
      )}
    </div>
  );
};

export default Comment;
