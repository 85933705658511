import FadeTransition from "components/shared/FadeTransition";
import Loader from "components/shared/Loader";
import TableListeView from "components/shared/TableListeView";
import { useGetAllBakeliste } from "pages/bakelistes/api/hooks/useGetAllBakeliste";
import React, { useMemo } from "react";
import { textFilter } from "react-bootstrap-table2-filter";

// import { useDispatch } from "react-redux";

const HistoriquesBakelistesPage = () => {
  //   const dispatch = useDispatch();

  // Get all bakelistes
  const { allBakelistes, loadingAllBakelistes } = useGetAllBakeliste();

  // table columns
  const columns = useMemo(
    () => [
      {
        dataField: "first_name",
        text: "Nom complet",
        filter: textFilter({
          placeholder: "Recherche pas nom complet",
        }),
        formatter: (cell, row) => (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => navigateToDetails(row)}
          >
            {row?.first_name} {row?.last_name}
          </p>
        ),
      },
      {
        dataField: "email",
        text: "Email",
        formatter: (cell, row) => (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => navigateToDetails(row)}
          >
            {row?.email}
          </p>
        ),
        filter: textFilter({
          placeholder: "Recherche par mail",
        }),
      },
      {
        dataField: "phone",
        text: "Téléphone",
        formatter: (cell, row) => (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => navigateToDetails(row)}
          >
            {row?.phone}
          </p>
        ),
        filter: textFilter({
          placeholder: "Recherche par telephone",
        }),
      },
      {
        dataField: "domaine",
        formatter: (cell, row) => (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => navigateToDetails(row)}
          >
            {row?.domaine}
          </p>
        ),
        text: "Domaines",
      },
      //   {
      //     dataField: "Actions",
      //     text: "Actions",
      //     formatter: (cell, row) => {
      //       return (
      //         <div
      //           onClick={() => navigateToDetails(row)}
      //           className="d-flex justify-content-center"
      //         >
      //           <ClickPane className="focus--click">
      //             <BsEye size={20} color="#069a77" />
      //           </ClickPane>
      //         </div>
      //       );
      //     },
      //   },
    ],
    []
  );
  return (
    <FadeTransition in={true}>
      {console.log("all bakelistes", allBakelistes)}
      {loadingAllBakelistes ? (
        <Loader />
      ) : (
        <TableListeView data={allBakelistes?.data} columns={columns} />
      )}
    </FadeTransition>
  );
};

export default HistoriquesBakelistesPage;
