import { rootStyles } from "assets/css/globalCss";
import ButtonComp from "components/ButtonComp";
import Div from "components/shared/Div";
import FadeTransition from "components/shared/FadeTransition";
import Loader from "components/shared/Loader";
import TableListeView, { Title2 } from "components/shared/TableListeView";
import React, { useMemo, useState } from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import { AiOutlineUserAdd } from "react-icons/ai";
import { useGetEmployes } from "./api/hooks/useGetEmployes";
import AddNewEmployeModal from "./components/AddNewEmployeModal";

const EmployesPage = () => {
  // Initial states
  const [openAddEmploye, setOpenAdEmploye] = useState(false);
  // get employee
  const { employesList, loadingEmployes } = useGetEmployes();

  // Table columns
  const columns = useMemo(() => [
    {
      dataField: "",
      text: "Nom complet",
      filter: textFilter({
        placeholder: "Recherche par nom complet",
      }),
      formatter: (cell, row) => (
        <p>
          {row.first_name} {row.last_name}
        </p>
      ),
    },
    {
      dataField: "email",
      text: "Email",
      filter: textFilter({
        placeholder: "Recherche par email",
      }),
    },
    {
      dataField: "address",
      text: "Adresse",
      formatter: (cell, row) => cell ?? "Néant",
    },
    {
      dataField: "domaine",
      text: "Domaine",
      formatter: (cell, row) => cell?.name ?? "Néant",
    },
    {
      dataField: "entite",
      text: "Etité",
    },
  ]);
  return (
    <FadeTransition in={true}>
      {openAddEmploye && (
        <AddNewEmployeModal
          openAddNewEmploye={openAddEmploye}
          onClosed={() => setOpenAdEmploye(false)}
        />
      )}
      <Div className="d-flex align-items-center justify-content-between">
        <Title2>Liste des employés</Title2>
        <ButtonComp
          title="Nouveau"
          icon={{ name: AiOutlineUserAdd }}
          color={rootStyles.color.primary}
          bgColor={rootStyles.color.secondary}
          onClick={() => setOpenAdEmploye(true)}
        />
      </Div>
      {loadingEmployes ? (
        <Loader />
      ) : (
        <TableListeView
          data={employesList?.data}
          columns={columns}
          striped={true}
          bordered={false}
        />
      )}
    </FadeTransition>
  );
};

export default EmployesPage;
