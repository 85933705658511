import ProspectCardItem from "components/prospects/ProspectCardItem";
import ConfirmAction from "components/shared/ConfirmAction";
import FadeTransition from "components/shared/FadeTransition";
import React, { cloneElement, useState } from "react";
import { GridList } from "react-flexible-list";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ApiController } from "redux/apiController";
import { setCurrentProspectSlice } from "redux/features/prospectSlice";

import {
  ADD_BAKELISTE_PATH,
  BAKELISTES_PATH,
} from "routes/navigation/navigationPaths";

const ListeProspects = ({ currentItems }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [confirmAppel, setConfirmAppel] = useState(false);
  const [selectedProspect, setSelectedProspect] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // console.log({ currentItems });

  const openConfirmation = (prospect) => {
    setSelectedProspect(prospect);
    setConfirmAppel(true);
  };

  const confirmationDavoirAppelerLeProspectFunc = (prospect) => {
    setIsLoading(true);
    ApiController.prospects
      .confirmationDavoirAppelerLeProspect(prospect.id)
      .then(() => {
        toast.success(selectedProspect.firstName + " déclaré(e) comme appelé");
      })
      .catch(() => {
        toast.error("Une erreur est survenue, veuillez réessayer plustard");
      })
      .finally(() => {
        setIsLoading(false);
        setConfirmAppel(false);
      });
  };

  const convertProspectToBakeliste = (prospet) => {
    navigate(BAKELISTES_PATH + "/" + ADD_BAKELISTE_PATH);
    dispatch(setCurrentProspectSlice(prospet));
    return;
  };

  const navigateTo = (prospect) => {
    console.log("pros", prospect),
    dispatch(setCurrentProspectSlice(prospect));

    return navigate(`${prospect.lastName}`);
  };
  const ClonedProspectCardItem = ({ prospect }) =>
    cloneElement(<ProspectCardItem prospect={prospect} />, {
      openConfirmation,
      navigateTo,
      convertProspectToBakeliste,
    });

  return (
    <FadeTransition in={true}>
      <ConfirmAction
        modalIsOpen={confirmAppel}
        modalText="Confirmation appel"
        text={`Êtes-vous sûr de vouloir déclarer avoir appelé ${selectedProspect?.firstName} ?`}
        cancel={() => setConfirmAppel(false)}
        validate={() =>
          confirmationDavoirAppelerLeProspectFunc(selectedProspect)
        }
        closeModal={() => setConfirmAppel(false)}
        loading={isLoading}
      />
      {/* <Container>
          <ResourceDataMap
            resourceData={currentItems}
            resourceItem={ClonedProspectCardItem}
            resourceName="prospect"
          />
        </Container> */}
      <GridList
        resourceData={currentItems}
        resourceItem={ClonedProspectCardItem}
        resourceName="prospect"
        //   cardWidth={100}
      />
    </FadeTransition>
  );
};

export default ListeProspects;
