import { rootStyles } from "assets/css/globalCss";
import ButtonComp from "components/ButtonComp";
import InputForm from "components/shared/InputForm";
import withHandleForm from "hoc/withHandleForm";
import React from "react";
import { useForm } from "react-hook-form";
import image from "../../assets/img/bakeli-best-talent.jpg"
import { useAuthContext } from "context/userContext";
import { useNavigate } from "react-router-dom";
import bakeli from "../../assets/img/bakeli2.png"


const LoginPage = withHandleForm(() => {
    const { signIn, isSigning } = useAuthContext()
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors } } = useForm();
    const defaultMessage = "Ce champ est obligatoire"
    console.log(process.env.REACT_APP_BASE_API);
    const onSubmit = (data) => {
    // !---------------- connexion ici -------------------
        signIn(data, navigate)
    }
    return (
        <div
            style={{
                width: "100%",
                height: "100vh"
            }}
            // className="d-flex align-items-center justify-content-center"
            className="container-fluid"
        >
            <div className="row h-100">
                <div
                    style={{
                        background: `linear-gradient(45deg, rgb(6, 154, 119, 1), rgb(6, 154, 119, 0.7)), url(${image})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover"
                    }}
                    className="col-md-6 d-md-block d-none d-md-flex align-items-center justify-content-center"
                >
                    <div
                        style={{
                            marginTop: "-100px"
                        }}
                        className="animate__animated animate__fadeIn text-light"
                    >
                        <h1 className="fw-bold">BAKELI SI - ADMIN</h1>
                        <p className="ms-4">Training the best tech talent in Africa.</p>
                    </div>
                </div>
                <div className="col-md-6 d-flex align-items-center justify-content-center">
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        style={{
                            maxWidth: "500px",
                            margin: "aut",
                            marginTop: "-100px",
                            padding: "0.5rem"
                        }}
                        className="animate__animated animate__fadeIn"
                    >
                        <img
                            style={{ width: "100px", margin: "auto", display: "block" }}
                            src={bakeli}
                            alt="Bakeli School Of Technologie"
                        />
                        <h5
                            style={{
                                textAlign: "center",
                                fontSize: "1.1rem"
                            }}
                        >
                            <span className="d-block mb-1">Bonjour</span>  <span>Avec SI Admin, le suivi au rendez-vous!</span>
                        </h5>
                        <InputForm
                            label="Email"
                            type="email"
                            htmlFor="email"
                            register={{ ...register("email", { required: defaultMessage }) }}
                            error={errors?.email?.message}
                        />
                        <InputForm
                            label="Mot de passe"
                            type="password"
                            htmlFor="password"
                            register={{ ...register("password", { required: defaultMessage }) }}
                            error={errors?.password?.message}
                        />
                        <div className="mt-4">
                            <ButtonComp
                                loading={isSigning}
                                width={"100%"}
                                icon={{
                                    name: null
                                }}
                                title="Connexion"
                                onClick={null}
                                bgColor={rootStyles.color.secondary}
                                color={rootStyles.color.primary}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
})

export default LoginPage;
