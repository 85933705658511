import React from "react"
// import { AiOutlineEdit } from "react-icons/ai"
// import { FaEye } from "react-icons/fa"
// import { Tooltip as ReactTooltip } from "react-tooltip";
// import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";


import { useBakelisteActif } from "actions/bakelistes"
// import Flex from "components/shared/Flex";
// import ButtonComp from "components/ButtonComp";
import FadeTransition from "components/shared/FadeTransition";
// import MettreEnLigneFeature from "features/mettreEnLigne/MettreEnLigneFeature";
// import Loader from "components/shared/Loader";
// import TableListeView from "components/shared/TableListeView";
// import ErrorBakeliste from "components/error/ErrorBakeliste";
// import { BAKELISTES_EN_LIGNE_PATH, BAKELISTES_PATH, DETAILS_BAKELISTE_PATH, EDIT_BAKELISTE_PATH } from "routes/navigation/navigationPaths";
// import { HiUsers } from "react-icons/hi";
// import { textFilter } from "react-bootstrap-table2-filter";
// import { rootStyles } from "assets/css/globalCss";
// import { tooltip_options } from "utils/tooltip_options";
// import { setCurrentBakelisteSlice } from "redux/features/bakelisteSlice";
import TabsProvider from "components/Tabs/TabsProvider";
import ListeBakelistesPresentielPage from "../ListeBakelistesPresentielPage";
import ListeBakelistesEnLignePage from "../ListeBakelistesEnLignePage";
import Loader from "components/shared/Loader";
import ErrorBakeliste from "components/error/ErrorBakeliste";


const BakelistesActifPage = () => {
    const { bakelisteActifs, isActifError, isActifLoading } = useBakelisteActif()

    return (
        <FadeTransition in={true}>
            {
                isActifLoading ?
                    <Loader /> :
                    isActifError ?
                        <ErrorBakeliste /> :
                        <TabsProvider
                            content={
                                [
                                    {
                                        title: "Présentiel",
                                        number: bakelisteActifs.countBakelisteActifEnPresentiel,
                                        content: () => <ListeBakelistesPresentielPage
                                            bakelistePresentiel={bakelisteActifs.bakelisteEnPresentiel}
                                            isPresentielLoading={isActifLoading}
                                        />
                                    },
                                    {
                                        title: "En ligne",
                                        number: bakelisteActifs.countBakelisteActifEnLigne,
                                        content: () => <ListeBakelistesEnLignePage
                                            bakelisteEnLigne={bakelisteActifs.bakelisteEnLigne}
                                            isEnLigneLoading={isActifLoading}
                                        />
                                    },
                                ]}
                        />
            }
        </FadeTransition>
    )
}

export default BakelistesActifPage