import FadeTransition from "components/shared/FadeTransition";
import TableListeView from "components/shared/TableListeView";
import React, { useMemo } from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import { useSelector } from "react-redux";

const ListeParticipantsPages = () => {
  const { currentPartenaire, currentProgrammeApprenants } = useSelector(
    (state) => state.partenaires
  );

  const columns = useMemo(
    () => [
      {
        dataField: "first_name",
        text: "Nom complet",
        filter: textFilter({
          placeholder: "Recherche pas nom complet",
        }),
        formatter: (cell, row) => (
          <p>
            {row.first_name} {row.last_name}
          </p>
        ),
      },
      {
        dataField: "phone",
        text: "Téléphone",
        filter: textFilter({
          placeholder: "Recherche par téléphone",
        }),
      },
      {
        dataField: "email",
        text: "Email",
        filter: textFilter({
          placeholder: "Recherche par mail",
        }),
      },
      {
        dataField: "ecole",
        text: "Ecole",
        filter: textFilter({
          placeholder: "Recherche par école",
        }),
      },
      //  {
      //    dataField: "Actions",
      //    text: "Actions",
      //    formatter: actionsFormatter,
      //  },
      //  {
      //    dataField: "paiements",
      //    text: "Paiements",
      //    formatter: actionsPaiementFormatter,
      //  },
    ],
    []
  );
  return (
    <FadeTransition in={true}>
      <TableListeView
        data={currentProgrammeApprenants}
        columns={columns}
        title={`Apprenants de ${currentPartenaire.name} (${currentProgrammeApprenants.length})`}
        navigateToTitle="Bakelistes archivés"
      />
    </FadeTransition>
  );
};

export default ListeParticipantsPages;
