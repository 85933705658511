import React from "react"
import { useParams } from "react-router-dom"

import TabsProvider from "components/Tabs/TabsProvider";
import Div from "components/shared/Div";
import { Title2 } from "components/shared/TableListeView";
import { useHebdoPresencesAbsences } from "actions/suivi-pointage";
import Loader from "components/shared/Loader";
import ErrorBakeliste from "components/error/ErrorBakeliste";
import { rootStyles } from "assets/css/globalCss";
import { useCalendrierPresenceContext } from "context/usePointageCalendarContext";
import SuiviPointageBakelistesContainer from "components/suivi-pointage/Hebdo/SuiviPointageBakelistesContainer";

const SuiviPointageHebdoPage = () => {
    const { currentVille } = useCalendrierPresenceContext()
    const { week } = useParams()
    const {
        data = { data: { avenirs: [], presences: [], absences: [] } },
        isError,
        isLoading
    } = useHebdoPresencesAbsences({ startOfWeek: week, ville: currentVille })

    return (
        <div className="px-2">
            <div style={{ position: "sticky", top: 10, zIndex: 99, backgroundColor: rootStyles.color.primaryWithRgba, padding: "10px 0" }}>
                <Title2>
                    Rendez-vous Hebdo ({currentVille ? currentVille : "All Spaces"}), semaine du {new Date(week).toLocaleDateString("fr-Fr", { day: "2-digit", month: "long", year: "numeric" })}
                </Title2>
            </div>
            {
                isLoading ? <Div mt={20}><Loader /></Div> : isError ? <ErrorBakeliste /> : <Div mt={20}>
                    <TabsProvider
                        content={
                            [
                                {
                                    title: "A venir",
                                    content: () => <SuiviPointageBakelistesContainer bakelistesHebdoData={data.data.avenirs} frequence={"a_venir"} />
                                },
                                {
                                    title: "Présents",
                                    content: () => <SuiviPointageBakelistesContainer bakelistesHebdoData={data.data.presences} frequence={"presents"} />
                                },
                                {
                                    title: "En retard",
                                    content: () => <SuiviPointageBakelistesContainer bakelistesHebdoData={data.data.retard} frequence={"en_retards"} />
                                },
                                {
                                    title: "Absents",
                                    content: () => <SuiviPointageBakelistesContainer bakelistesHebdoData={data.data.absences} frequence={"absents"} />
                                },
                            ]}
                    />
                </Div>
            }
        </div>
    )
}

export default SuiviPointageHebdoPage