import React from "react";

const Div = ({ children, width, mt, mb, mr, ml, mh, className, refe }) => {
  return (
    <div
      style={{
        marginTop: `${mt}px`,
        marginBottom: `${mb}px`,
        marginRight: `${mr}px`,
        marginLeft: `${ml}px`,
        width: width,
        minHeight: `${mh}rem`,
      }}
      className={className}
      ref={refe}
    >
      {children}
    </div>
  );
};

export default Div;
