import React from "react"
import styled from "styled-components"

const FlexPane = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  gap: ${({ gap }) => `${gap}px`};
`;

const FlexBetween = ({ children, gap }) => {
    return <FlexPane gap={gap}>{children}</FlexPane>;
};

export default FlexBetween