import React from "react";

// import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import styled from "styled-components";
import { rootStyles } from "assets/css/globalCss";
// import { rootStyles } from "assets/css/globalCss";

import Chart from "react-apexcharts";

ChartJS.register(ArcElement, Tooltip, Legend);

const KpiCardPane = styled.div`
  border-right: 1px solid rgba(20, 30, 40, 0.5);
  padding: ${rootStyles.padding.btnPadding};
  flex: 1;
`;

const PaiementKpiCard = ({ paiementKpi }) => {
  const { text, labels, series } = paiementKpi;

  // console.log({ paiementKpi });

  return (
    <KpiCardPane>
      {/* <h5>{titre}</h5> */}
      {/* <Doughnut
        style={{
          transform: "translateY(0px)",
          height: "200px",
        }}
        options={{
          plugins: {
            legend: {
              display: true,
              position: "bottom",
              labels: {
                boxHeight: 20,
                boxWidth: 100,
                pointStyle: "triangle",
              },
            },
            datalabels: {
              borderWidth: 15,
              color: "#000",
              font: {
                size: 15,
              },
            },
          },
          spacing: 5,
        }}
        data={data}
      /> */}
      <Chart
        options={{
          labels: labels,
          title: {
            text: text,
          },
        }}
        type="donut"
        series={series}
        width={300}
      />
    </KpiCardPane>
  );
};

export default PaiementKpiCard;
