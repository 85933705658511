import { rootStyles } from "assets/css/globalCss"
import React from "react"
import { FcPlanner } from "react-icons/fc"

const TrimestreCardItem = ({ trimestreItem, goToExamensListe }) => {
    // console.log({ trimestreItem });
    const { trimestre } = trimestreItem
    return (
        <div
            onClick={() => {
                // const allExamens = [...trimestreItem.examen_theorique].concat(trimestreItem.examens_pratiques)
                goToExamensListe(trimestreItem)
            }}
            style={{
                backgroundColor: "white",
                padding: rootStyles.padding.btnPadding,
                borderRadius: rootStyles.radius.card,
                boxShadow: rootStyles.shadow.card,
                marginTop: 5,
                marginBottom: 5,
                cursor: "pointer"
            }}
            className='d-flex justify-content-between align-items-center'
        >
            <FcPlanner size={50} />
            <span className='fw-bold'>{trimestre}</span>
        </div>
    )
}

export default TrimestreCardItem