import { GET_COACHS_ASSISTANTS_ENDPOINT } from "routes/api/endpoints";

const { siAdminAPi } = require("redux/rtkquery");

const coachsAssistantsApi = siAdminAPi.injectEndpoints({
    endpoints: (build) => ({
        getCoachsAssistants: build.query({
            query: () => GET_COACHS_ASSISTANTS_ENDPOINT,
            providesTags: ["coach-assiatants"],
        }),
    }),
    overrideExisting: false,
});

export const { useGetCoachsAssistantsQuery } = coachsAssistantsApi