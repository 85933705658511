import { rootStyles } from "assets/css/globalCss";
import ButtonComp from "components/ButtonComp";
import ResourceDataMap from "components/ResourceDataMap";
import InputForm from "components/shared/InputForm"
import SelectForm from "components/shared/SelectForm";
import React, { useState } from "react"
import useSWR from "swr"
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ApiController } from "redux/apiController";
import { axiosSwrFetcher } from "redux/apiController/axiosInstance";
import { GET_COACHS_ENDPOINT } from "routes/api/endpoints";
import styled from "styled-components";
import { useCurrentUser } from "hooks/useCurrentUser"

export const BtnContainer = styled.div`
    max-width: 350px;
    margin-left:auto
`


const AjoutCoachAssistantPage = () => {
    const { allDomainesList } = useSelector((state) => state.domaines);
    // const { allCoachList } = useSelector((state) => state.coachs);
    const { data: coachsList } = useSWR(GET_COACHS_ENDPOINT, axiosSwrFetcher);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { currentUser } = useCurrentUser();
    const defaultMessage = "Ce champ est obligatoire"
    const navigate = useNavigate()
    const [isAddingCoachAssistant, setIsAddingCoachAssistant] = useState(false)

    const onSubmit = (data) => {
    data["created_by"] = currentUser.id
        setIsAddingCoachAssistant(true)
        ApiController.coachs.addNewCoachAssistant(data).then((res) => {
            if (res.status >= 200) {
                toast.success("Coach assistant ajouté")
                navigate(-1)
                return
            }
        })
            .catch((err) => {
                console.log({ err });
                toast.error("Une erreur est survenue lors de l'ajout du coach")
            })
            .finally(() => {
                isAddingCoachAssistant(false)
            })
    }

    return (
        <div className='container'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='row'>
                    <div className='col-sm-6'>
                        <InputForm
                            label="Prénom"
                            type="text"
                            htmlFor="first_name"
                            register={{ ...register("first_name", { required: defaultMessage }) }}
                            error={errors.first_name}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <InputForm
                            label="Nom"
                            type="text"
                            htmlFor="last_name"
                            register={{ ...register("last_name", { required: defaultMessage }) }}
                            error={errors.last_name}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <InputForm
                            label="Email"
                            type="email"
                            htmlFor="email"
                            register={{ ...register("email", { required: defaultMessage }) }}
                            error={errors.email}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <InputForm
                            label="Téléphone"
                            type="tel"
                            htmlFor="phone"
                            register={{ ...register("phone", { required: defaultMessage }) }}
                            error={errors.phone}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <SelectForm
                            label="Domaine"
                            register={{ ...register("coach_domaine_id", { required: defaultMessage }) }}
                        >
                            <option value="">--Selectionner un domaine--</option>
                            <ResourceDataMap
                                resourceData={allDomainesList}
                                resourceName="option"
                                resourceItem={({ option }) => <option value={option.id}>{option.name}</option>}
                            />
                        </SelectForm>
                    </div>
                    <div className='col-sm-6'>
                        <SelectForm
                            label="Coach"
                            register={{ ...register("coach_id", { required: defaultMessage }) }}
                        >
                            <option value="">--Selectionner un coach--</option>
                            <ResourceDataMap
                                resourceData={coachsList}
                                resourceName="option"
                                resourceItem={({ option }) => <option value={option.id}>{option.coachFullname}</option>}
                            />
                        </SelectForm>
                    </div>
                </div>
                <BtnContainer className='mt-4 d-flex justify-content-start'>
                    <ButtonComp
                        loading={isAddingCoachAssistant}
                        width={"100%"}
                        icon={{
                            name: null
                        }}
                        title="Ajouter"
                        onClick={null}
                        bgColor={rootStyles.color.secondary}
                        color={rootStyles.color.primary}
                    />
                </BtnContainer>
            </form>
        </div>
    )
}

export default AjoutCoachAssistantPage