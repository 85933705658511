import { rootStyles } from "assets/css/globalCss";
import React from "react";
import styled from "styled-components";

const TitleLinePane = styled.h3`
  color: ${({ color }) => color ?? "rgba(20, 30, 40, .6)"};
  font-size: ${rootStyles.fonts.h2FontSize};
  font-weight: ${rootStyles.fonts.h2FontWeight};
  display: flex;
  gap: 10px;
  align-items: center;
`;
const TextContentPane = styled.span`
  min-width: max-content;
`;
const HorizontalLinePane = styled.span`
  display: block;
  width: 100%;
  height: 1.6px;
  background-color: rgba(20, 30, 40, 0.2);
  border-radius: 8px;
`;

const TitleLine = ({ children, color }) => {
  return (
    <TitleLinePane color={color}>
      <TextContentPane>{children}</TextContentPane>
      <HorizontalLinePane></HorizontalLinePane>
    </TitleLinePane>
  );
};

export default TitleLine;
