import { siAdminAPi } from "redux/rtkquery";
import {
  ACTIVATE_PAUSED_BAKELISTE_ENDPOINT,
  ADD_NEW_STAGIAIRE_ENDPOINT,
  ARCHIVER_BAKELISTE,
  BLOQUE_BAKELISTE_COMPTE_ENDPOINT,
  DEBLOQUE_BAKELISTE_COMPTE_ENDPOINT,
  DESARCHIVER_BAKELISTE,
  DE_STAGE_EN_BAKELISTE,
  DE_TERMINE_A_BAKELISTE,
  PAUSE_BAKELISTE_ENDPOINT,
  PLACER_EN_STAGE,
  RECRUTABLE_ENDPOINT,
  TERMINER_BAKELISTE_FORMATION,
  UPDATE_BAKELISTE_ENDPOINT,
  UPDATE_STAGIAIRE_ENDPOINT,
} from "routes/api/endpoints";

const bakelistesApi = siAdminAPi.injectEndpoints({
  endpoints: (build) => ({
    pauseBakeliste: build.mutation({
      query: (data) => ({
        url: PAUSE_BAKELISTE_ENDPOINT,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Bakelistes"],
    }),
    activerBakeliste: build.mutation({
      query: (data) => (
        console.log(data),
        {
        url: ACTIVATE_PAUSED_BAKELISTE_ENDPOINT,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Bakelistes"],
    }),
    bloquerBakeliste: build.mutation({
      query: (data) => {
        // console.log({ data });
        return {
          url: BLOQUE_BAKELISTE_COMPTE_ENDPOINT,
          body: data,
          method: "POST",
        }
      },
      invalidatesTags: ["Bakelistes"],
    }),
    debloquerBakeliste: build.mutation({
      query: (data) => ({
        url: DEBLOQUE_BAKELISTE_COMPTE_ENDPOINT,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Bakelistes"],
    }),
    archiverBakeliste: build.mutation({
      query: (bakeliste_id) => ({
        url: ARCHIVER_BAKELISTE + bakeliste_id,
        method: "POST",
      }),
      invalidatesTags: ["Bakelistes"],
    }),
    desarchiverBakeliste: build.mutation({
      query: (bakeliste_id) => ({
        url: DESARCHIVER_BAKELISTE + bakeliste_id,
        method: "POST",
      }),
      invalidatesTags: ["Bakelistes"],
    }),
    terminerFormationBakeliste: build.mutation({
      query: (bakeliste_id) => ({
        url: TERMINER_BAKELISTE_FORMATION,
        method: "POST",
        body: { bakeliste_id },
      }),
      invalidatesTags: ["Bakelistes"],
    }),
    placerBakelisteEnStage: build.mutation({
      query: (data) => ({
        url: PLACER_EN_STAGE,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Bakelistes"],
    }),
    declarerBakelisteCommeRecrutable: build.mutation({
      query: (data) => ({
        url: RECRUTABLE_ENDPOINT,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Bakelistes"],
    }),
    deStageEnBakeliste: build.mutation({
      query: (data) => ({
        url: DE_STAGE_EN_BAKELISTE,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Bakelistes"],
    }),
    deTerminerABakeliste: build.mutation({
      query: (data) => (
      {
        url: DE_TERMINE_A_BAKELISTE,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Bakelistes"],
    }),
    addNewStagiaire: build.mutation({
      query: (data) => ({
        url: ADD_NEW_STAGIAIRE_ENDPOINT,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Bakelistes"],
    }),
    updateStagiaire: build.mutation({
      query: ({ data, id }) => ({
        url: UPDATE_STAGIAIRE_ENDPOINT + id,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Bakelistes"],
    }),
    updateBakeliste: build.mutation({
      query: (args) => {
        const { data, bakelisteId } = args;
        return {
          url: `${UPDATE_BAKELISTE_ENDPOINT}${bakelisteId}`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Bakelistes"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useActiverBakelisteMutation,
  useArchiverBakelisteMutation,
  useBloquerBakelisteMutation,
  useDeStageEnBakelisteMutation,
  useDebloquerBakelisteMutation,
  useDeclarerBakelisteCommeRecrutableMutation,
  useDesarchiverBakelisteMutation,
  usePauseBakelisteMutation,
  usePlacerBakelisteEnStageMutation,
  useTerminerFormationBakelisteMutation,
  useAddNewStagiaireMutation,
  useUpdateStagiaireMutation,
  useUpdateBakelisteMutation,
  useDeTerminerABakelisteMutation,
} = bakelistesApi;
