export const rootStyles = {
    color: {
        primary: "#ffffff",
        primaryWithRgba: "hsla(0,0%,100%,.6)",
        primary05: "rgba(224, 224, 224, .3)",
        secondary: "#069a77",
        secondaryFonce: "#08322d",
        blue: "#0075CB",
        tercary: "#ff9800",
        red: "#f44336",
        redSecondary: "#FF6973",
        black: "rgba(0,0,0,0.8)",
        green: "#069A77"
    },
    fonts: {
        fontSize: "14px",
        h2FontSize: "18px",
        h2FontWeight: 600,
        btnWeight: 500,
        spanErrFontSize: "12px"
    },
    padding: {
        btnPadding: "8px",
        inputPadding: "8px",
    },
    radius: {
        card: "10px",
    },
    shadow: {
        card: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
    }
}