import {
    setDomainesListSlice
} from "../features/domaineSlice"
import { store } from "../store"
import { GET_DOMAINES_ENDPOINTS } from "routes/api/endpoints.js"
import { axiosInstance } from "./axiosInstance"

const dispatch = store.dispatch
const domainesController = {
    getDomaineById(DomaineId) {
        return new Promise((resolve, reject) => {
            axiosInstance.get("******** PUT URL HERE ***********")
                .then((response) => {
                    if (response.status === 200) {
                        /**
                        * !setDomaineList is from reducer.Domaine.js, just for Domaine
                        * !Don't forget to check your response content data type
                        */
                        const data = response.data
                        resolve(data)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    getDomaines() {
        return new Promise((resolve, reject) => {
            axiosInstance.get(GET_DOMAINES_ENDPOINTS)
                .then((response) => {
                    if (response.status >= 200) {
                        /**
                * !setDomaineList is from reducer.Domaine.js, just for Domaine
                * !Don't forget to check your response content data type
                */
                        const data = response.data.data
                        dispatch(setDomainesListSlice(data))
                        resolve(data)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    addNewDomaine(data) {
        return new Promise((resolve, reject) => {
            axiosInstance.post("******** PUT URL HERE ***********", data)
                .then((response) => {
                    if (response.status === 201) {
                        /**
                        * !addNewDomaine is from reducer.Domaine.js, just for Domaine
                        * !Don't forget to check your response content data type
                        */
                        const Domaine = response.data
                        // dispatch(addNewDomaineSlice(Domaine))
                        resolve(Domaine)
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
    updateDomaine(data, DomaineId) {
        return new Promise((resolve, reject) => {
            axiosInstance.put("******** PUT URL HERE ***********", data)
                .then((response) => {
                    if (response.status === 200) {
                        /**
                        * !updateDomaine is from reducer.Domaine.js, just for Domaine
                        * !Don't forget to check your response content data type
                        */
                        const Domaine = response.data
                        // dispatch(updateDomaineSlice(Domaine))
                        resolve(Domaine)
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
    deleteDomaine(domaineId) {
        return new Promise((resolve, reject) => {
            axiosInstance.delete(`${"******** PUT URL HERE ***********" + domaineId}/`)
                .then((response) => {
                    if (response.status === 204) {
                        resolve(response)
                        // dispatch(archiveDomaineSlice(response))
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
}
export default domainesController