import React from "react"
import { rootStyles } from "assets/css/globalCss"
import ButtonComp from "components/ButtonComp"
import Modal from "components/Modal"
import { AiFillAlert } from "react-icons/ai"

const ConfirmAction = ({ modalText, text, cancel, validate, modalIsOpen, closeModal, loading }) => {
    return (
        <Modal
            opened={modalIsOpen}
            title={modalText}
            className="modal-container"
            onClosed={closeModal}
        >
            <div className='p-3'>
                <p className='text-center'>{text}</p>
                <p className='text-center text-danger'>
                    <AiFillAlert />
                </p>
                <div className='d-flex justify-content-center gap-4 mt-4'>
                    <ButtonComp
                        title="Non"
                        onClick={cancel}
                        color={rootStyles.color.primary}
                        bgColor={rootStyles.color.red}
                    />
                    <ButtonComp
                        loading={loading}
                        title="Oui"
                        onClick={validate}
                        color={rootStyles.color.primary}
                        bgColor={rootStyles.color.secondary}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default ConfirmAction