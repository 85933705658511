import { rootStyles } from "assets/css/globalCss"
import React from "react"
import { BiPhone, BiUser } from "react-icons/bi"
import { CiMail } from "react-icons/ci"
import { FaSchool } from "react-icons/fa"
import { MdEdit } from "react-icons/md"
import Flex from "components/shared/Flex"

const SpaceCardItem = ({ space, onClick }) => {
  return (
    <div className="col-sm-6 col-lg-4 col-12 p-2" >
      <div className="bg-white p-0 rounded p-3">
        <FaSchool />
        <div className="d-flex justify-content-between">
          <p className="fw-semibold">{space.name_space} - {space.region.name}</p>
          <MdEdit onClick={() => onClick(space)} spacing={10} style={{ cursor: "pointer" }} size={20} color={rootStyles.color.tercary} />
        </div>
        <span style={{ fontSize: 14 }} className="mt-3 fw-medium">Géré par</span>
        <div>
          <div className="d-flex align-items-center gap-2">
            <BiUser /> <p className="fs-6">{space.space_manager.first_name} {space.space_manager.last_name}</p>
          </div>
          <div className="d-flex align-items-center gap-2">
            <CiMail /> <p>{space.space_manager.email}</p>
          </div>
          <div className="d-flex align-items-center gap-2">
            <BiPhone /> <p>{space.space_manager.phone}</p>
          </div>
        </div>
        <Flex style={{ margin: 0 }} justify={"space-between"}>
      {/* <p spacing={10} style={{ cursor: "pointer" }} size={20} color={rootStyles.color.tercary} >  <BiUser /> {space.bakelistes_count}</p> */}
      <p className="fs-6 fw-semibold">Nbre bakelistes &nbsp;&nbsp;{space.bakelistes_count}</p>
      </Flex>
      </div>
    </div>
  )
}

export default SpaceCardItem