import React from "react";
import { axiosSwrFetcherForEpreuvesTheoriques } from "../../api";
import useSWR from "swr";
import { GET_EXAMENS_THEORIQUE_BY_TRIMESTRE_ENDPOINT } from "routes/api/endpoints";
import { useDispatch, useSelector } from "react-redux";
import ResourceDataMap from "components/ResourceDataMap";
import DriveLine, { DriveHead } from "components/examens/DriveLine";
import { useNavigate } from "react-router-dom";
import { EPREUVES_THEORIQUES_LISTE_PATH } from "routes/navigation/navigationPaths";
import {
  setCurrentEpreuveDomaine,
  setEpreuveTheoriqueList,
} from "redux/features/examenSlice";
import Loader from "components/shared/Loader";
import FadeTransition from "components/shared/FadeTransition";

const EpreuvesTheoriquesDomainesPage = () => {
  const { currentTrimestre } = useSelector((state) => state.examens);
  const dispatch = useDispatch();
  const { data = {}, isLoading } = useSWR(
    GET_EXAMENS_THEORIQUE_BY_TRIMESTRE_ENDPOINT + currentTrimestre.id,
    axiosSwrFetcherForEpreuvesTheoriques
  );
  const navigate = useNavigate();
  const goToEpreuvesTheoriquesListe = (epreuveDomaine, epreuves) => {
    dispatch(setCurrentEpreuveDomaine(epreuveDomaine));
    dispatch(setEpreuveTheoriqueList(epreuves));
    return navigate(EPREUVES_THEORIQUES_LISTE_PATH);
  };
  // console.log({ data: Object.entries(data) });
  return (
    <FadeTransition in={true}>
      <DriveHead
        label1="Domaines"
        // label2="Trimestres"
        label3="Nombre"
      />
      {isLoading ? (
        <Loader />
      ) : (
        <ResourceDataMap
          resourceName="domaine"
          resourceData={Object.entries(data)}
          resourceItem={({ domaine }) => {
            return (
              <DriveLine
                label1={domaine[0]}
                // label2={`${currentTrimestre.trimestre} - ${new Date(
                //   currentTrimestre.annee_academique
                // ).getFullYear()}`}
                label3={domaine[1].length}
                onClick={() =>
                  goToEpreuvesTheoriquesListe(domaine[0], domaine[1])
                }
              />
            );
          }}
        />
      )}
    </FadeTransition>
  );
};

export default EpreuvesTheoriquesDomainesPage;
