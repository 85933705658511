import { axiosInstance } from "redux/apiController/axiosInstance";

export const axiosSwrFetcherForEpreuves = (url) => axiosInstance.get(url).then((res) => {
    return [...res.data.examenTheorique].concat(res.data.examenPratique)
})
export const axiosSwrFetcherForEpreuvesTheoriques = (url) => axiosInstance.get(url).then((res) => {
    return res.data.examenTheorique
})
export const axiosSwrFetcherForEpreuvesPratiques = (url) => axiosInstance.get(url).then((res) => {
    return res.data.examenPratique
})