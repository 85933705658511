/* eslint-disable max-len */
import { rootStyles } from "assets/css/globalCss";
import Div from "components/shared/Div"
import { Badge } from "pages/bakelistes/Details/DetailsBakelistePage";
import React from "react"
// import { Link } from "react-router-dom";
import styled from "styled-components";
import { completeDateFormatter } from "utils/timeFormatters";

const ProfilContainerPane = styled.div`
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`

const ProfilImage = ({ src }) => {
    return <img
        src={src}
        alt="bakeliste_profil"
        className="img-fluid img-thumbnail rounded-circle"
        style={{
            width: "195px",
            height: "195px",
            objectFit: "cover",
            border: `6px solid ${rootStyles.color.tercary}`,
            boxShadow: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(6,154,119,1) 35%, rgba(0,212,255,1) 100%) 0px 0px 5px 0px, linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(6,154,119,1) 35%, rgba(0,212,255,1) 100%)",
        }}
    />
}
const CoachInfoItem = ({ label, description }) => {
    return <Div className="col-6 mb-3">
        <div className="">
            <p style={{ color: "#555" }} className="fw-semibold m-0 p-0 fs-6">
                {label}
            </p>
            {
                description()
            }
        </div>
    </Div>
}

const CoachInfo = ({ suiviCurrentCoach, bakelistes, navigateTo }) => {
    return (
        <ProfilContainerPane className="container-fluid bg-white rounded p-4">
            <Div className="row">
                <Div className="col-xl-3 col-lg-3 d-flex justify-content-center">
                    {suiviCurrentCoach.avatar_file !== null ? (
                        <ProfilImage
                            src={"https://via-api.bakeli.tech" + suiviCurrentCoach.avatar_file}
                        />
                    ) : (
                        <ProfilImage
                            src="https://images.pexels.com/photos/1499327/pexels-photo-1499327.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                        />
                    )
                    }
                </Div>
                <Div className="col-xl-9 col-lg-9 px-0">
                    <Div className="text-xl-start text-lg-start text-center">
                        <span
                            className="badge rounded-pill"
                            style={{
                                border: `1px solid ${rootStyles.color.secondary}`,
                                color: rootStyles.color.secondary,
                                fontWeight: rootStyles.fonts.btnWeight
                            }}
                        >
                            {suiviCurrentCoach?.domaine}
                        </span>
                    </Div>
                    <Div className={"mt-4 row"}>
                        <CoachInfoItem
                            label={"Total bakeliste"}
                            description={() =>
                                <Badge
                                    onClick={navigateTo}
                                    bgColor={rootStyles.color.tercary}
                                    color={rootStyles.color.primary}
                                >
                                    <span
                                        to={null}
                                        style={{
                                            color: "#fff",
                                            fontSize: 16,
                                            border: "1px solid #fff",
                                            textDecoration: "none",
                                            padding: "0px 20px",
                                            borderRadius: 50
                                        }}
                                    >
                                        {bakelistes[0]?.number ?? "Néant"}
                                    </span>
                                </Badge>}
                        />
                        <CoachInfoItem
                            label={"Début coaching"}
                            description={() => <span style={{ color: "#555", fontSize: 14 }}>{completeDateFormatter(
                                suiviCurrentCoach?.created_at
                            ) ?? "Néant"}</span>}
                        />
                        <CoachInfoItem
                            label={"Email"}
                            description={() => <span style={{ color: "#555", fontSize: 14 }}>{suiviCurrentCoach?.email}</span>}
                        />
                        <CoachInfoItem
                            label={"Téléphone"}
                            description={() => <span style={{ color: "#555", fontSize: 14 }}>{suiviCurrentCoach?.phone}</span>}
                        />
                        <CoachInfoItem
                            label={"Adresse"}
                            description={() => <span style={{ color: "#555", fontSize: 14 }}>{suiviCurrentCoach?.address ?? "..."}</span>}
                        />
                    </Div>
                </Div>
            </Div>
        </ProfilContainerPane>
    )
}

export default CoachInfo