// import {
//     setExamensListSlice
// } from "../reducers/examenSlice.js"
import { store } from "../store"
import { ADD_NEW_ANNEE_ACADEMIQUE, GET_ALL_ANNEE_ACADEMIQUE, GET_EXAMENS_BY_TRIMESTRE_ENDPOINT, GET_TRIMESTRE_BY_ANNEE } from "routes/api/endpoints.js"
import { axiosInstance } from "./axiosInstance"
import { addNewAnneeAcademiqueListSlice, setAnneeAcademiqueListSlice, setCurrentTrimestreBulletins, setExamensListSlice, setTrimestreByAnnee } from "redux/features/examenSlice"

const dispatch = store.dispatch
const examensController = {
    getExamensByTrimeste(trimestreId) {
        // console.log({ trimestreId });
        return new Promise((resolve, reject) => {
            axiosInstance
                .get(GET_EXAMENS_BY_TRIMESTRE_ENDPOINT + trimestreId)
                .then((response) => {
                    if (response.status === 200) {
                                    const allExamens = [...data.examenTheorique].concat(data.examenPratique)
                                    dispatch(setCurrentTrimestreBulletins(data.bulletins))
                                    dispatch(setExamensListSlice(allExamens))
                                    resolve(data)
                                } else {
                                    reject(response)
                                }
                            })
                .catch((res) => reject(res))
        })
    },
    getTrimestresByAnnee(ExamenId) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .get(GET_TRIMESTRE_BY_ANNEE)
                .then((response) => {
                    // console.log({ response });
                    if (response.status === 200) {
                        const trimestres = response.data.data
                        // console.log({ trimestres });
                        dispatch(setTrimestreByAnnee(trimestres))
                                    resolve(data)
                                } else {
                                    reject(response)
                                }
                            })
                .catch((res) => reject(res))
        })
    },
    getAllAnneeAcademique() {
        return new Promise((resolve, reject) => {
            axiosInstance
                .get(GET_ALL_ANNEE_ACADEMIQUE)
                .then((response) => {
                    // console.log({ response });
                    if (response.status === 200) {
                                    dispatch(setAnneeAcademiqueListSlice(response.data.data))
                                    resolve(data)
                                } else {
                                    reject(response)
                                }
                            })
                .catch((res) => reject(res))
        })
    },
    createAnneeAcademique(newAnnee) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(ADD_NEW_ANNEE_ACADEMIQUE, newAnnee)
                .then((response) => {
                    // console.log({ response });
                    if (response.status === 200) {
                        const { annee_academique } = newAnnee
                                    newAnneeAcademique[annee_academique] = data
                                    // console.log({ newAnneeAcademique });
                                    dispatch(addNewAnneeAcademiqueListSlice(newAnneeAcademique))
                                    resolve(data)
                                } else {
                                    reject(response)
                                }
                            })
                .catch((res) => reject(res))
        })
    },
}
export default examensController