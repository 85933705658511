
import {
    activateCoachArchiveSlice, addNewCoachSlice, archiveCoachSlice, addNewCoachAssistantSlice,
    setCoachsArchivesListSlice, setCoachsAssistantsListSlice,
    setCoachsListSlice, updateCoachSlice
} from "redux/features/coachSlice"
import { store } from "../store"
import { axiosInstance } from "./axiosInstance.js"
import {
    ACTIVATE_COACH_ENDPOINT, ADD_NEW_COACH_ENDPOINT, DELETE_COACH_ENDPOINT, ADD_NEW_COACH_ASSISTANT_ENDPOINT,
    GET_COACHS_ARCHIVES_ENDPOINT, GET_COACHS_ASSISTANTS_ARCHIVES_ENDPOINT, GET_COACHS_ASSISTANTS_ENDPOINT,
    GET_COACHS_ENDPOINT, UPDATE_COACH_ENDPOINT
} from "routes/api/endpoints.js"

const dispatch = store.dispatch
const coachsController = {
    getCoachById(CoachId) {
        return new Promise((resolve, reject) => {
            axiosInstance.get("******** PUT URL HERE ***********")
                .then((response) => {
                    if (response.status === 200) {
                        /**
                        * !setCoachList is from reducer.Coach.js, just for Coach
                        * !Don't forget to check your response content data type
                        */
                        const data = response.data
                        resolve(data)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    getCoachs() {
        return new Promise((resolve, reject) => {
            axiosInstance.get(GET_COACHS_ENDPOINT)
                .then((response) => {
                    if (response.status >= 200) {
                        /**
                        * !setCoachList is from reducer.Coach.js, just for Coach
                        * !Don't forget to check your response content data type
                        */
                        // console.log({ response });
                        const data = response.data.data
                        dispatch(setCoachsListSlice(data))
                        resolve(data)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    getCoachsAssistants() {
        return new Promise((resolve, reject) => {
            axiosInstance.get(GET_COACHS_ASSISTANTS_ENDPOINT)
                .then((response) => {
                    if (response.status >= 200) {
                        /**
                        * !setCoachList is from reducer.Coach.js, just for Coach
                        * !Don't forget to check your response content data type
                        */
                        // console.log({ response });
                        const data = response.data.data
                        dispatch(setCoachsAssistantsListSlice(data))
                        resolve(data)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    getCoachsArchives() {
        return new Promise((resolve, reject) => {
            axiosInstance.get(GET_COACHS_ARCHIVES_ENDPOINT)
                .then((response) => {
                    if (response.status >= 200) {
                        /**
                        * !setCoachList is from reducer.Coach.js, just for Coach
                        * !Don't forget to check your response content data type
                        */
                        const data = response.data.data
                        dispatch(setCoachsArchivesListSlice(data))
                        resolve(data)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    getCoachsAssistantsArchives() {
        return new Promise((resolve, reject) => {
            axiosInstance.get(GET_COACHS_ASSISTANTS_ARCHIVES_ENDPOINT)
                .then((response) => {
                    if (response.status >= 200) {
                        /**
                        * !setCoachList is from reducer.Coach.js, just for Coach
                        * !Don't forget to check your response content data type
                        */
                        const data = response.data.data
                        dispatch(setCoachsArchivesListSlice(data))
                        resolve(data)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    addNewCoach(data) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(ADD_NEW_COACH_ENDPOINT, data)
                .then((response) => {
                    if (response.status >= 200) {
                        const coach = response.data
                        this.getCoachs()
                        dispatch(addNewCoachSlice(coach))
                        resolve({ coach, status: response.status })
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
    addNewCoachAssistant(data) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(ADD_NEW_COACH_ASSISTANT_ENDPOINT, data)
                .then((response) => {
                    if (response.status >= 200) {
                        const coach = response.data
                        this.getCoachsAssistants()
                        dispatch(addNewCoachAssistantSlice(coach))
                        resolve({ coach, status: response.status })
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
    activateCoach(coach) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(ACTIVATE_COACH_ENDPOINT + coach.id)
                .then((response) => {
                    if (response.status >= 200) {
                        const coachRes = response.data.data
                        this.getCoachsArchives()
                        dispatch(activateCoachArchiveSlice(coach))
                        dispatch(addNewCoachSlice(coach))
                        resolve({ coachRes, status: response.status })
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
    activateCoachAssistant(coach) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(ACTIVATE_COACH_ENDPOINT + coach.id)
                .then((response) => {
                    if (response.status >= 200) {
                        const coachRes = response.data.data
                        this.getCoachsAssistantsArchives()
                        dispatch(activateCoachArchiveSlice(coach))
                        dispatch(addNewCoachSlice(coach))
                        resolve({ coachRes, status: response.status })
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
    updateCoach(data, coachId) {
        return new Promise((resolve, reject) => {
            axiosInstance.put(UPDATE_COACH_ENDPOINT + `${coachId}`, data)
                .then((response) => {
                    if (response.status >= 200) {
                        /**
                        * !updateCoach is from reducer.Coach.js, just for Coach
                        * !Don't forget to check your response content data type
                        */
                        const coach = response.data.data
                        console.log({ coach });
                        this.getCoachs()
                        dispatch(updateCoachSlice(coach))
                        resolve({ coach, status: response.status })
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
    deleteCoach(coachId) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(`${DELETE_COACH_ENDPOINT + coachId}`)
                .then((response) => {
                    if (response.status >= 200) {
                        this.getCoachs()
                        resolve(response)
                        dispatch(archiveCoachSlice(response.data.data))
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
    deleteCoachAssistant(coachId) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(`${DELETE_COACH_ENDPOINT + coachId}`)
                .then((response) => {
                    if (response.status >= 200) {
                        this.getCoachsAssistants()
                        resolve(response)
                        dispatch(archiveCoachSlice(response.data.data))
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
}
export default coachsController