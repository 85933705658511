import { rootStyles } from "assets/css/globalCss";
import styled from "styled-components";

export const Tabs = styled.div`
  // position: sticky;
  // top: 50px;
  // z-index: 2;
  overflow: hidden;
  background: ${rootStyles.color.primaryWithRgba};
//   font-family: Open Sans;
  height: 2.5em;
  box-shadow: ${rootStyles.shadow.card} ;
  border-radius: 5px;
//   border: 1px solid;
margin-bottom: 20px;
`;

export const Tab = styled.button`
  border: none;
  outline: none;
  padding: 2px 10px;
  cursor: pointer;
  width: fit-content;
  // min-width: 150px;
  position: relative;
  font-weight: ${(props) => (props.active ? rootStyles.fonts.btnWeight : "500")};

  margin-right: 0.1em;
  font-size: 14px;
  // border: ${(props) => (props.active ? "1px solid #ccc" : "")};
  border-bottom: ${(props) => (props.active ? "none" : "undefined")};
  background-color: ${(props) => (props.active ? rootStyles.color.primaryWithRgba : "inherit")};
  color: ${(props) => (props.active ? rootStyles.color.secondary : "inherit")};
  height: 100%;
  transition: background-color 0.5s ease-in-out;

  :hover {
    background-color: white;
  }
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: ${(props) => (props.active ? "100%" : "undefined")};
    height: 3px;
    background-color: ${rootStyles.color.secondaryFonce};
    animation:${(props) => (props.active ? "borderWidth 200ms ease-in" : "undefined")};
    border-radius: 100px;
  }
  @keyframes borderWidth{
    0%{
        width: 0%;
    }
    50%{
        width: 30%;
    }
    90%{
        width: 40%;
    }
    100%{
        width: 50%;
    }
  }
`;
export const Content = styled.div`
  ${(props) => (props.active ? "" : "display:none")}
`;
export const NumberTop = styled.p`
    position: absolute;
    top: 10px;
    right: 5px;
    padding: 5px;
  display: flex;
  justify-content: center;
  color: #fff;
  align-items: center;
  width: max-content;
  height: 20px;
  background-color: ${rootStyles.color.red};
  border-radius: 50px;
  font-size: 10px
`


// const Tabs = () => {
//     return (
//         <div>Tabs</div>
//     )
// }

// export default Tabs