// import { GET_CURRENT_MONTH_PAIEMENTS_ENDPOINT } from "routes/api/endpoints";
// import { getCurrentMonthPaiementSwrFetcher } from "..";
// import useSWR from 'swr';
import { useDispatch } from "react-redux";
import { useGetCurrentMonthPaiementQuery } from "../queries";
import { setDakarBakelistesCurrentMonthPaiement, setMbourBakelistesCurrentMonthPaiement, setThiesBakelistesCurrentMonthPaiement } from "redux/features/paiementSlice";
import { useEffect } from "react";

export const useCurrentMonthPaiement = () => {
    const { data: currentMonth = { paiement: { "Grand Yoff 1": [], "Grand Yoff 2": [], "Thiés": [], "Mbour": [] } }, isLoading: isCurrentMonthLoading } = useGetCurrentMonthPaiementQuery()

    console.log({ currentMonth });

    const dispatch = useDispatch()

    /**
     * On recupere les paiements du mois en cours
     */
    useEffect(() => {
        dispatch(setDakarBakelistesCurrentMonthPaiement([...currentMonth.paiement["Grand Yoff 1"], ...currentMonth.paiement["Grand Yoff 2"]]))
        dispatch(setThiesBakelistesCurrentMonthPaiement(currentMonth.paiement["Thiés"]))
        dispatch(setMbourBakelistesCurrentMonthPaiement(currentMonth.paiement["Mbour"]))
    }, [isCurrentMonthLoading])

    return { currentMonth, isCurrentMonthLoading }
}