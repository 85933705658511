import Modal from "components/Modal"
import DebloquerBakelisteWithMotif from "features/debloquerBakelisteWithMotif/DebloquerBakelisteWithMotif"
import { DebloquerBakelisteWithMotifProvider } from "features/debloquerBakelisteWithMotif/Provider"
import React from "react"

const DebloquerAvecMotif = ({ currentBakeliste, openMotifDeblocageModal, setOpenMotifDeblocageModal }) => {
    return (
        <DebloquerBakelisteWithMotifProvider>
            <Modal
                opened={openMotifDeblocageModal}
                onClosed={() => setOpenMotifDeblocageModal(false)}
                title={"Veuillez ajouter le motif pour lequel vous débloquez ce bakeliste"}
                modalWidth={600}
            >
                <DebloquerBakelisteWithMotif
                    onClosed={() => setOpenMotifDeblocageModal(false)}
                    currentBakeliste={currentBakeliste}
                />
            </Modal>
        </DebloquerBakelisteWithMotifProvider>
    )
}

export default DebloquerAvecMotif