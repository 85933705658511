import Modal from "components/Modal"
import React from "react"
import SpaceForm from "../AddSpace/SpaceForm"
import { useSpace } from "../AddSpace/useSpace"
import { SpaceProvider } from "../AddSpace/AddSpaceProvider"

const SpaceContainer = ({ isEditModal, closeEditModal }) => {
  const { handleEditSpace, isEditingSpace } = useSpace({ closeSpaceModal: closeEditModal })
  return (
    <div>
      <Modal
        opened={isEditModal}
        onClosed={closeEditModal}
        title={"Nouveau space"}
      >
        <SpaceForm isFormSubmitting={isEditingSpace} onSubmit={handleEditSpace} />
      </Modal>
    </div>
  )
}

const EditSpaceModal = ({ openModal, closeEditModal, initialValues }) => {
  return (
    <SpaceProvider initialValues={initialValues} >
      <SpaceContainer isEditModal={openModal} closeEditModal={closeEditModal} />
    </SpaceProvider >
  )
}

export default EditSpaceModal