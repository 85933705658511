export const LOGIN_PATH = "/login";
export const REGISTER_PATH = "/register";
export const FORGETPASSWORD = "/auth/forgetPassword";
export const RESETPASSWORD = "/aut/resetPassword";

export const NOTFOUND = "404";

/** ------------------------------------------------------------
 *! ----------------- HOME PATHS ------------------------------
 */
export const ACCEUIL_PATH = "/";

/** ------------------------------------------------------------
 *! ----------------- SUIVI POINTAGE PATHS ------------------------------
 */
export const SUIVI_POINTAGE_PATH = "/suivi-pointage";
export const SUIVI_POINTAGE_BAKELISTES_PRESENTS_PATH = "bakelistes-presents";
export const SUIVI_POINTAGE_BAKELISTES_ABSENTS_PATH = "bakelistes-absents";
export const SUIVI_POINTAGE_HEBDO_PATH = ":week";

// export const COMPTERENDU = "/compteRendu";
/** ------------------------------------------------------------
 *! ----------------- COACH PATHS ------------------------------
 */
export const COACHS_PATH = "/coachs";
export const ADD_COACH_PATH = "ajout-coach";
export const UPDATE_COACH_PATH = "update-coach";
export const COACHS_ARCHIVES_PATH = "coachs-archives";
/** -------------------------------------------------------------
 *! ----------------- COACH ASSISTANT PATHS ---------------------
 */
export const COACHS_ASSISTANTS_PATH = "/coachs-assistants";
export const UPDATE_COACH_ASSISTANT_PATH = "update-coach-assistant";
export const COACHS_ASSISTANT_ARCHIVES_PATH = "coachs-assistants-archives";
export const ADD_COACH_ASSISTANT_PATH = "ajout-coach-assistant";

/** -------------------------------------------------------------
 *! ----------------- SUIVI COACHING PATHS ---------------------
 */
export const SUIVI_COACHING_PATH = "/suivi-coaching";
export const DETAILS_SUIVI_COACHING_PATH = "details-suivi-coach";
export const COACH_CURRENT_BAKELISTES_STATUS_PATH = "status";
export const COACH_COMMENTS_PATH = "commentaires";
export const COACH_VALIDATIONS_PATH = "validations";
export const COACH_ALL_BAKELISTS_PATH = "all-bakelistes";
export const COACH_BAKELISTES_EN_FORMATION_PATH = "bakelistes-en-formation";
export const COACH_BAKELISTES_EN_LIGNE_PATH = "bakelistes-en-ligne";
export const COACH_BAKELISTES_EN_PRESENTIEL_PATH = "bakelistes-en-presentiel";
export const COACH_BAKELISTES_EN_PAUSE_PATH = "bakelistes-en-pause";
export const COACH_BAKELISTES_TERMINES_PATH = "bakelistes-termines";
export const COACH_BAKELISTES_ABANDONNES_PATH = "bakelistes-abandonnes";
/** -------------------------------------------------------------
 *! ----------------- SUIVI BAKELISTE PATHS ---------------------------
 */
export const SUIVI_BAKELISTES_PATH = "/suivi-bakelistes";
export const DETAILS_SUIVI_BAKELISTES_PATH = "details-suivi-bakelistes";
export const DETAILS_SUIVI_BAKELISTES_SYLLABUS_PATH = "syllabus/";
export const DETAILS_SUIVI_BAKELISTES_SYLLABUS_DETAILS_PATH = "syllabus";
export const DETAILS_SUIVI_BAKELISTES_TRIMESTRE_DETAILS_PATH = "details";

/** -------------------------------------------------------------
 *! ----------------- BAKELISTE PATHS ---------------------------
 */
export const BAKELISTES_PATH = "/bakelistes";
export const BAKELISTES_ACTIFS_PATH = "actifs";
export const BAKELISTES_INACTIFS_PATH = "non-actifs";
export const DETAILS_BAKELISTE_PATH = "details";
export const ADD_BAKELISTE_PATH = "ajout";
export const EDIT_BAKELISTE_PATH = "modifier";
export const LISTE_BAKELISTES_PATH = "liste";
export const TOUS_LES_BAKELISTES__PATH = "tous";
export const BAKELISTES_EN_PRESENTIEL_PATH = "presentiel";
export const BAKELISTES_EN_LIGNE_PATH = "en-ligne";
export const BAKELISTES_EN_PAUSE_PATH = "en-pause";
export const BAKELISTES_BLOQUES_PATH = "bloques";
export const BAKELISTES_ARCHIVES_PATH = "bakelistes-archives";
export const BAKELISTES_EN_STAGE_PATH = "en-stage";
export const BAKELISTES_TERMINES_PATH = "termines";
export const BAKELISTES_RECRUTABLES_PATH = "recrutables";
export const BAKELISTES_LIVRAISONS_PATH = "livraisons";
/** -------------------------------------------------------------
 *! ----------------- STAGIAIRES PATHS ----------------------------
 */
export const STAGIAIRES_ARCHIVES_PATH = "/stagiaires-archives";
/** -------------------------------------------------------------
 *! ----------------- PARTENAIRES PATHS ----------------------------
 */
export const PARTENAIRES_PATH = "/partenaires";
export const PARTICIPANTS_PATH = "participants";

/** -------------------------------------------------------------
 *! ----------------- SYLLABUS PATHS ----------------------------
 */
export const SYLLABUS_PATH = "/syllabus";
/** -------------------------------------------------------------
 *! ----------------- EMPLOYES PATHS ----------------------------
 */

export const EMPLOYES_PATH = "/employes";

/** -------------------------------------------------------------
 *! ----------------- PROSPECT PATHS ----------------------------
 */
export const PROSPECTS_PATH = "/prospects";
export const PROSPECTS_PAR_REGION_PATH = "par-region";
/** -------------------------------------------------------------
 *! ----------------- PAIEMENTS PATHS ----------------------------
 */
export const PAIEMENTS_PATH = "/paiements";
export const BAKELISTE_PRESENTIEL_PAIEMENTS_PATH = "";
export const BAKELISTE_ENLIGNE_PAIEMENTS_PATH = "bakeliste-en-ligne";
export const LISTE_PAIEMENTS_PATH = "liste";
export const DETAILS_PAIEMENTS_BAKELISTES_PATH = "details-paiements/";
/** -------------------------------------------------------------
 *! ----------------- EVENEMENTS PATHS ----------------------------
 */
export const EVENEMENTS_PATH = "/evenements";
export const AJOUT_EVENEMENT_PATH = "ajout";
export const DETAILS_EVENEMENT_PATH = "details";
/** -------------------------------------------------------------
 *! ----------------- EXAMENS PATHS ----------------------------
 */
export const EXAMENS_PATH = "/examens";
export const EXAMENS_TRIMESTRE_PATH = "trimestres/";
export const TRIMESTRE_DOSSIERS_PATH = "dossiers/";
export const TYPES_EPREUVES_PATH = "type-epreuves/";
export const BULLETINS_DOMAINES_PATH = "bulletins-domaines/";
export const EPREUVES_THEORIQUES_PATH = "theoriques/";
export const EPREUVES_THEORIQUES_LISTE_PATH = "liste/";
export const EPREUVES_PRATIQUES_LISTE_PATH = "liste/";
export const EPREUVES_PRATIQUES_PATH = "pratiques/";
export const LISTE_EPREUVES_PATH = "liste";
export const LISTE_BULLETINS_PATH = "bulletins";

/** -------------------------------------------------------------
 *! ----------------- HISTORIQUES PATHS ----------------------------
 */
export const HISTORY_PATH = "/historiques";
export const BAKELISTES_HISTORY_PATH = "bakelistes";
export const COACH_ASSISTANT_HISTORY_PATH = "coachs-assistants";

/** -------------------------------------------------------------
 *! ----------------- PARAMTRES PATHS ----------------------------
 */
export const PARAMETRES_PATH = "/parametres";