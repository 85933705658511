import React from "react";
import { useState } from "react";
import ApercuInformationsPersonnelles from "./ApercuInformationsPersonnelles";
import ApercuFormation from "./ApercuFormation";
import ApercuInfosSupplementaire from "./ApercuInfosSupplementaire";
import ButtonComp from "components/ButtonComp";
import { rootStyles } from "assets/css/globalCss";
import { ApiController } from "redux/apiController";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { BAKELISTES_PATH } from "routes/navigation/navigationPaths";
import { useDispatch } from "react-redux";
import { resetCurrentProspect } from "redux/features/prospectSlice";
import { useBakelisteActif } from "actions/bakelistes";


const BakelisteInfosApercu = ({
  setPage,
  setApercu,
  bakelisteInfos,
  // domaines,
  // route,
  // requestMethod,
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { refetchBakelisteActif } = useBakelisteActif()

  // console.log({ bakelisteInfos });

  // const onSubmit = async (data) => {
  // setLoading(true);
  // for (var key in bakelisteInfos) {
  //   formData.append(key, bakelisteInfos[key]);
  // }
  // try {
  //   await axios({
  //     method: requestMethod,
  //     url: API + route,
  //     data: formData,
  //   });
  //   successMsg("Bakeliste ajoute avec succes");
  //   history.push("/all-bakelistes-presentiel");
  // } catch (err) {
  //   console.log(err);
  // } finally {
  //   setLoading(false);
  // }
  // };

  // const onSubmit = async (data) => {
  //   setLoading(true);
  //   for (var key in bakelisteInfos) {
  //     formData.append(key, bakelisteInfos[key]);
  //   }
  //   try {
  //     const res = await axios({
  //       method: requestMethod,
  //       url: API + route,
  //       data: formData,
  //     });
  //     if (res.status === 201) {
  //       successMsg("Bakeliste ajoute avec succes");
  //       history.push("/all-bakelistes-presentiel");
  //     }
  //     if (res.status === 200) {
  //       existMsg("Cette email exite deja dans le système");
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     errorMsg("Erreur!! Veuillez reessayer");

  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const submit = (data) => {
    // const formData = new FormData();
    // eslint-disable-next-line guard-for-in
    // for (const key in bakelisteInfos) {
    //   formData.append(key, bakelisteInfos[key]);
    // }
    setLoading(true)
    ApiController.bakelistes.addNewBakeliste({ ...bakelisteInfos, type_presence_id: Number(bakelisteInfos.type_presence_id) }).then((res) => {
      toast.success("Bakeliste ajouté")
      dispatch(resetCurrentProspect())
      refetchBakelisteActif()
      // console.log(BAKELISTES_PATH + BAKELISTES_EN_PRESENTIEL_PATH)
      return navigate(BAKELISTES_PATH)
    }).catch((err) => {
      console.log({ err });
      toast.error("Une erreur est survenue");
    })
      .finally(() => {
        setLoading(false)
      })
  }

  console.log({ bakelisteInfos });
  return (
    <section
      style={{
        borderRadius: rootStyles.radius.card,
        backgroundColor: "white",
        padding: rootStyles.padding.btnPadding,
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      }}
    >
      <div className="d-flex justify-content-between mt-4">
        <ButtonComp
          title="prec"
          onClick={() => {
            setApercu(false);
            setPage(2);
          }}
          bgColor={rootStyles.color.tercary}
          color={rootStyles.color.primary}
        />
        <ButtonComp
          loading={loading}
          title="Ajouter"
          onClick={submit}
          bgColor={rootStyles.color.secondary}
          color={rootStyles.color.primary}
        />
        {/* <button
          className="btn-switch btn-prev"
          onClick={() => {
            setApercu(false);
            setPage(2);
          }}
        >
          <img src={null} alt="Prev" className="mr-3" /> Retour
        </button> */}
        {/* <button
          onClick={null}
          className="btn-switch btn-submit"
          type="button"
        >
          {loading ? "Ajout en cours..." : "Ajouter"}
        </button> */}
      </div>

      <h1
        style={{ fontSize: "2rem", fontWeight: "bold" }}
        className="text-center apercu--title"
      >
        Résumé Bakeliste informations
      </h1>
      <main
        className="container--infos-supplementaire"
        style={{ border: "1px solid #bbbbbb", padding: "1rem 1rem 2rem 1rem", borderRadius: rootStyles.radius.card, }}
      >
        <ApercuInformationsPersonnelles bakelisteInfos={bakelisteInfos} />
        <ApercuFormation bakelisteInfos={bakelisteInfos} />
        <ApercuInfosSupplementaire bakelisteInfos={bakelisteInfos} />
      </main>
    </section>
  );
};

export default BakelisteInfosApercu;
