import { GET_MONTHS_PAIEMENTS_ENDPOINT } from "routes/api/endpoints";
import { listeMoisDePaieSwrFetcher } from "..";
import useSWR from "swr";

export const useMoisPaiement = () => {
    const { data: moisDePaie = [], isLoading: isMoisDePaieLoading } = useSWR(
        GET_MONTHS_PAIEMENTS_ENDPOINT,
        listeMoisDePaieSwrFetcher
    );

    return { moisDePaie, isMoisDePaieLoading }
}