import React, { memo } from "react"
import { useNavigate } from "react-router-dom"
import { useDebounce } from "use-debounce";
import { useDispatch, useSelector } from "react-redux"

import { useBakelisteResearch } from "actions/bakelistes"
import Div from "components/shared/Div"
import { setCurrentBakelisteSlice } from "redux/features/bakelisteSlice"
import { BAKELISTES_PATH, DETAILS_BAKELISTE_PATH } from "routes/navigation/navigationPaths"
import Search from "./Search";
import ListeBakelistes from "./ListeBakelistes";
import { redirectToFormationEtatDetails } from "utils/redirectToFormationEtatDetails"


const SearchBakeliste = () => {
    const { research } = useSelector((state) => state.bakelistes)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [value] = useDebounce(research, 500);
    const { data: bakelistes = [], isError, isLoading } = useBakelisteResearch({ bakeliste: value })

    const navigateToBakelisteDetails = (bakeliste) => {
        dispatch(setCurrentBakelisteSlice(bakeliste))
        return navigate(BAKELISTES_PATH + "/" + redirectToFormationEtatDetails(bakeliste.etat) + "/" + DETAILS_BAKELISTE_PATH)
    }

    return (
        <Div className={"position-relative w-50"}>
            <Search />
            <ListeBakelistes
                bakelistes={bakelistes}
                isError={isError}
                isLoading={isLoading}
                navigateToBakelisteDetails={navigateToBakelisteDetails}
            />
        </Div >
    )
}

export default memo(SearchBakeliste)