import ButtonComp from "components/ButtonComp"
// import ErrorBakeliste from "components/error/ErrorBakeliste"
import Loader from "components/shared/Loader"
import { Title2 } from "components/shared/TableListeView"
import React from "react"
import { MdAdd } from "react-icons/md"
import SpaceCardItem from "../components/SpaceCardItem"
import AddSpaceModal from "./AddSpace/AddSpaceModal"
import { useSpaces } from "./useSpaces"
import EditSpaceModal from "./EditSpace/EditSpaceModal"

const Spaces = () => {
  const { spaces, isLoading, isAddSpaceModal, addAddSpaceModal, closeAddSpaceModal, isEditSpaceModal, openEditSpaceModal, closeEditSpaceModal, initialValues } = useSpaces()

  if (isLoading) {
    return <Loader />
  }
  // if (isError) {
  //   return <ErrorBakeliste />
  // }

  return (
    <div className="container m-0 mt-4">
      <div className="d-flex align-items-center gap-2">
        <Title2>Spaces</Title2> <ButtonComp icon={{ name: MdAdd }} onClick={addAddSpaceModal} />
      </div>
      {/* d-flex gap-2 flex-wrap */}
      <div className="row">
        {
          !spaces.spaces?.length ? <p>Pas encore de spaces</p> :
          spaces.spaces.map((space, index) => {
            return <SpaceCardItem onClick={openEditSpaceModal} key={index} space={space} />
          })
        }
      </div>
      <AddSpaceModal isAddModal={isAddSpaceModal} closeAddModal={closeAddSpaceModal} />
      {
        openEditSpaceModal &&
        <EditSpaceModal
          initialValues={initialValues}
          openModal={isEditSpaceModal}
          closeEditModal={closeEditSpaceModal}
        />
      }
    </div>
  )
}

export default Spaces