import { TYPE_PRESENCE_ENDPOINT } from "routes/api/endpoints";

const { siAdminAPi } = require("redux/rtkquery");

const typesPresenceApi = siAdminAPi.injectEndpoints({
    endpoints: (builder) => ({
        update: builder.mutation({
            query: ({ data, id }) => {
                return {
                    url: TYPE_PRESENCE_ENDPOINT + "/" + id,
                    method: "POST",
                    body: data
                }
            },
            invalidatesTags: ["typesPresence"],
        })
    }),
});

export const { useUpdateMutation } = typesPresenceApi