const { siAdminAPi } = require("redux/rtkquery");
const { GET_SYLLABUS } = require("routes/api/endpoints");

const syllabusApi = siAdminAPi.injectEndpoints({
    endpoints: (build) => ({
        getSyllabusById: build.query({
            query: (syllabus_id) => GET_SYLLABUS + syllabus_id,
            providesTags: ["Syllabus"]
        }),
        getSyllabus: build.query({
            query: () => GET_SYLLABUS,
            providesTags: ["Syllabus"]
        }),
    }),
    overrideExisting: false,
})

export const { useGetSyllabusByIdQuery, useGetSyllabusQuery } = syllabusApi