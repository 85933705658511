// import React from "react";
import { useSelector } from "react-redux";
import Div from "components/shared/Div"
import { Badge } from "pages/bakelistes/Details/DetailsBakelistePage";
import { completeDateFormatter } from "utils/timeFormatters";
import styled from "styled-components";
import { rootStyles } from "assets/css/globalCss";
import FlexBetween from "components/shared/FlexBetween";
import { Title2 } from "components/shared/TableListeView";
import { FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { setCurrentProspectSlice } from "redux/features/prospectSlice";
import ConfirmAction from "components/shared/ConfirmAction";
import { FiUserCheck } from "react-icons/fi";
import { FcAssistant } from "react-icons/fc";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { tooltip_options } from "utils/tooltip_options";

import React, { useState } from "react";
import {
    ADD_BAKELISTE_PATH,
    BAKELISTES_PATH,
  } from "routes/navigation/navigationPaths";
const ProfilContainerPane = styled.div`
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`
const ProfilImage = ({ src }) => {
    return <img
        src={src}
        alt="bakeliste_profil"
        className="img-fluid img-thumbnail rounded-circle"
        style={{
            width: "195px",
            height: "195px",
            objectFit: "cover",
            border: `6px solid ${rootStyles.color.tercary}`,
            boxShadow: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(6,154,119,1) 35%, rgba(0,212,255,1) 100%) 0px 0px 5px 0px, linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(6,154,119,1) 35%, rgba(0,212,255,1) 100%)",
        }}
    />
}
const ProspectInfoItem = ({ label, description }) => {
    return <Div className="col-6 mb-3">
        <div className="">
            <p style={{ color: "#555" }} className="fw-semibold m-0 p-0 fs-6">
                {label}
            </p>
            {
                description()
            }
        </div>
    </Div>
}

const DetailsDunProspect = () => {
    const openConfirmation = (prospect) => {
        setSelectedProspect(prospect);
        setConfirmAppel(true);
      };
      const [confirmAppel, setConfirmAppel] = useState(false);
      const [selectedProspect, setSelectedProspect] = useState(null);
  const [isLoading,] = useState(false);

  const navigate = useNavigate();
  const convertProspectToBakeliste = (prospet) => {
    navigate(BAKELISTES_PATH + "/" + ADD_BAKELISTE_PATH);
    dispatch(setCurrentProspectSlice(prospet));
    return;
  };
const { currentProspect } = useSelector((state) => state.prospects);
return (
  <>
    <ConfirmAction
        modalIsOpen={confirmAppel}
        modalText="Confirmation appel"
        text={`Êtes-vous sûr de vouloir déclarer avoir appelé ${selectedProspect?.firstName} ?`}
        cancel={() => setConfirmAppel(false)}
        validate={() =>
          confirmationDavoirAppelerLeProspectFunc(selectedProspect)
        }
        closeModal={() => setConfirmAppel(false)}
        loading={isLoading}
      />
     <FlexBetween>
            <Title2>
              Détails du prospect{" "}
              <span
                style={{ fontSize: "20px", color: rootStyles.color.tercary }}
              >
                {currentProspect?.firstName + currentProspect?.lastName}
              </span>
            </Title2>
          </FlexBetween>
  <ProfilContainerPane className="container-fluid bg-white rounded p-4">
  <div className='text-end mb-2'>
                <FaChevronRight
                    onClick={() => convertProspectToBakeliste(currentProspect)}
                    data-tip
                    data-tooltip-id='convertir-btn'
                    size={20}
                    style={{ cursor: "pointer" }}
                />
            </div>
  <Div className="row">
      <Div className="col-xl-3 col-lg-3 d-flex justify-content-center">
            <ProfilImage
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiNmZmYiIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij48cGF0aCBmaWxsPSIjRjZGNkY5IiBkPSJNMCAwaDEyOHYxMjhIMHoiLz48cGF0aCBmaWxsPSIjQkRCREMwIiBkPSJNMTYgMTE2YzAtMTYuNTY5IDEzLjQzMS0zMCAzMC0zMGgzNmMxNi41NjkgMCAzMCAxMy40MzEgMzAgMzB2MjQuODM3YzAgMTYuNTY5LTEzLjQzMSAzMC0zMCAzMEg0NmMtMTYuNTY5IDAtMzAtMTMuNDMxLTMwLTMwVjExNlptNzYtNjRjMCAxNS40NjQtMTIuNTM2IDI4LTI4IDI4UzM2IDY3LjQ2NCAzNiA1MnMxMi41MzYtMjggMjgtMjggMjggMTIuNTM2IDI4IDI4WiIvPjwvc3ZnPgo="
            />
      </Div>
      <Div className="col-xl-9 col-lg-9 px-0">
          <Div className={"mt-4 row"}>
              <ProspectInfoItem
                  label={"Domaine choisit"}
                  description={() =>
                      <Badge
                          bgColor={rootStyles.color.tercary}
                          color={rootStyles.color.primary}
                      >
                              {currentProspect?.study_choice?? "Néant"}
                      </Badge>}
              />
              <ProspectInfoItem
                  label={"Date d'inscription"}
                  description={() => <span style={{ color: "#555", fontSize: 14 }}>{completeDateFormatter(
                    currentProspect?.created_at
                  ) ?? "Néant"}</span>}
              />
              <ProspectInfoItem
                  label={"Email"}
                  description={() => <span style={{ color: "#555", fontSize: 14 }}>{currentProspect?.email}</span>}
              />
              <ProspectInfoItem
                  label={"Téléphone"}
                  description={() => <span style={{ color: "#555", fontSize: 14 }}>{currentProspect?.phone}</span>}
              />
              <ProspectInfoItem
                  label={"Ville résidence"}
                  description={() => <span style={{ color: "#555", fontSize: 14 }}>{currentProspect?.ville_residence ?? "..."}</span>}
              />
               <ProspectInfoItem
                  label={"Espace de formation choisit"}
                  description={() => <span style={{ color: "#555", fontSize: 14 }}>{currentProspect?.session ?? "..."}</span>}
              />
               <ProspectInfoItem
                  label={"Canal"}
                  description={() => <span style={{ color: "#555", fontSize: 14 }}>{currentProspect?.canal_infos ?? "..."}</span>}
              />
               <ProspectInfoItem
                  label={"Type de formation"}
                  description={() => <span style={{ color: "#555", fontSize: 14 }}>{currentProspect?.type_formation ?? "..."}</span>}
              />
               <ProspectInfoItem
                  label={"Motivation"}
                  description={() => <span style={{ color: "#555", fontSize: 14 }}>{currentProspect?.motivations ?? "..."}</span>}
              />
          </Div>
          {
                !currentProspect?.is_called ?
                    <div className='mt-2'>
                        <Badge
                            onClick={(e) => {
                                e.stopPropagation(); openConfirmation(currentProspect)
                            }}
                            bgColor={rootStyles.color.red}
                            color={rootStyles.color.primary}
                        >
                            <FcAssistant
                                size={25}
                                style={{ cursor: "pointer" }}
                                data-tip
                                data-tooltip-id='call-btn'
                            />
                        </Badge>
                    </div> :
                    <div className='mt-2'>
                        <Badge
                            disabled={true}
                            bgColor={rootStyles.color.secondary}
                            color={rootStyles.color.primary}
                        >
                            <FiUserCheck
                                data-tip
                                data-tooltip-id='called-btn'
                                color={rootStyles.color.primary}
                            />
                            <span style={{ fontSize: "10px", marginLeft: "5px" }}>Appelé</span>
                        </Badge>
                    </div>
            }
             <ReactTooltip style={tooltip_options} id='call-btn'>
                Déclarez comme appelé
            </ReactTooltip>
            <ReactTooltip style={tooltip_options} id='called-btn'>
                Prospect appelé
            </ReactTooltip>
            <ReactTooltip style={tooltip_options} id='convertir-btn'>
                Convertir en Bakeliste
            </ReactTooltip>
      </Div>
  </Div>
</ProfilContainerPane>
</>

  );
};

export default DetailsDunProspect;
