import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    currentEvenement: {},
    evenementsRecentsList: [],
    allEvenementsRecentsList: [],
    evenementsAVenirList: [],
    allEvenementsAVenirList: [],
    evenementsAAfficher: [],
    isEvenementsAVenir: false,
    isEvenementsRecents: true
}

const evenementSlice = createSlice({
    name: "evenements",
    initialState,
    reducers: {
        setCurrentEvenementSlice: (state, { payload }) => {
            state.currentEvenement = payload
        },
        setEvenementsRecentsListSlice: (state, { payload }) => {
            state.evenementsRecentsList = payload
            state.allEvenementsRecentsList = payload
            state.evenementsAAfficher = payload
        },
        setEvenementsAVenirListSlice: (state, { payload }) => {
            state.evenementsAVenirList = payload
            state.allEvenementsAVenirList = payload
        },
        addNewEvenementSlice: (state, { payload }) => {
            console.log({ payload });
            return {
                ...state,
                allEvenementsAVenirList: [...state.allEvenementsAVenirList, payload].reverse(),
                evenementsAVenirList: state.allEvenementsAVenirList,
                evenementsAAfficher: state.evenementsAVenirList
            }
        },
        updateEvenementSlice: (state, { payload }) => {
            let evenements = state.allEvenementsRecentsList.filter((evenement) => evenement.id !==
                payload.id)
            evenements = [...evenements, payload].reverse()
            state.allEvenementsRecentsList = evenements
            state.evenementsRecentsList = evenements
        },
        displaysRecentsEvents: (state, { payload }) => {
            return {
                ...state,
                evenementsAAfficher: state.allEvenementsRecentsList,
                isEvenementsRecents: payload,
                isEvenementsAVenir: !payload,
            }
        },
        displaysInComingEvents: (state, { payload }) => {
            return {
                ...state,
                evenementsAAfficher: state.allEvenementsAVenirList,
                isEvenementsAVenir: payload,
                isEvenementsRecents: !payload,
            }
        },
        archiveEvenementSlice: (state, { payload }) => {
            const evenements = state.allEvenementsRecentsList.filter((evenement) => evenement.id !==
                payload.id)
            state.evenementsRecentsList = evenements
            state.allEvenementsRecentsList = evenements
        },
        filterEvenementsRecentsList: (state, { payload }) => {
            /**
            * @param payload {string}
            */
            state.evenementsRecentsList = state.allEvenementsRecentsList.filter((item) => {
                const data = ["******** PUT RESOURCE PROPERTIES HERE *********"].join(" ").toLowerCase()
                return data.includes(payload.toLowerCase())
            })
        },
        restartFilter: (state) => {
            return {
                ...state,
                isEvenementsAVenir: false,
                isEvenementsRecents: true
            }
        }
    }
}
)


export const {
    setEvenementsRecentsListSlice,
    setEvenementsAVenirListSlice,
    addNewEvenementSlice,
    setCurrentEvenementSlice,
    updateEvenementSlice,
    archiveEvenementSlice,
    filterEvenementsRecentsList,
    displaysRecentsEvents,
    displaysInComingEvents,
    restartFilter
} = evenementSlice.actions

export default evenementSlice.reducer