import { rootStyles } from "assets/css/globalCss";
import ButtonComp from "components/ButtonComp";
import InputForm from "components/shared/InputForm";
import SelectForm from "components/shared/SelectForm";
import useCoachAssistants from "hooks/useCoachAssistants";
import { useRegions } from "hooks/useRegions";
import React from "react"
import { useFormContext } from "react-hook-form";
// import { useSpace } from "./useSpace";

const SpaceForm = ({ onSubmit, isFormSubmitting }) => {
  const { register, handleSubmit, formState: { errors, isSubmitting } } = useFormContext()
  const { data, isLoading } = useCoachAssistants()
  const { regions, isLoading: isRegionsLoading } = useRegions()
  // const { isAddingSpace } = useSpace()

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="py-3">
      <InputForm
        label={"Nom"}
        register={{ ...register("name_space") }}
        error={errors.name_space?.message}
      />
      <SelectForm error={errors?.space_manager_id?.message} register={{ ...register("space_manager_id") }} label={"Space manager"}>
        {
          isLoading ? <option disabled>En cours de chargement...</option> : <>
            <option disabled>Sélectionner un coach</option>
            {
              !data?.length ? <option>Pas encore de coachs</option> : data.map((option, index) => {
                return <option value={option.id} key={index}>{option.coachFullname}</option>
              })
            }
          </>
        }
      </SelectForm>
      <SelectForm register={{ ...register("region_id") }} label={"Région"}>
        {
          isRegionsLoading ? <option disabled>En cours de chargement...</option> : <>
            <option disabled>Sélectionner un régions</option>
            {
              !regions?.length ? <option>Pas encore de régions</option> : regions.map((region, index) => {
                // console.log({ region });
                return <option value={region.id} key={index}>{region.name}</option>
              })
            }
          </>
        }
      </SelectForm>
      <ButtonComp
        type={"submit"}
        title={"Envoyez"}
        disabled={isFormSubmitting || isSubmitting}
        loading={isFormSubmitting || isSubmitting}
        bgColor={rootStyles.color.secondary}
        color={rootStyles.color.primary}
      />
    </form>
  )
}

export default SpaceForm