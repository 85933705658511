import TableListeView from "components/shared/TableListeView"
import React, { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { ApiController } from "redux/apiController"
import { COACHS_ASSISTANTS_PATH } from "routes/navigation/navigationPaths"
import { Tooltip as ReactTooltip } from "react-tooltip";
import { tooltip_options } from "utils/tooltip_options"
import { FaArrowCircleUp } from "react-icons/fa"
import { rootStyles } from "assets/css/globalCss"
import ConfirmAction from "components/shared/ConfirmAction"
import { toast } from "react-hot-toast"
import FadeTransition from "components/shared/FadeTransition"

const ListeCoachsAssistantsArchivesPage = () => {
    const navigate = useNavigate()
    const { coachsArchivesList } = useSelector((state) => state.coachs)
    const [isActivatingCoach, setIsActivatingCoach] = useState(false)
    const [confirmActivate, setConfirmActivate] = useState(false)
    const [selectedCoach, setSelectedCoach] = useState(null)

    const activateCoach = (coach) => {
        setIsActivatingCoach(true)
        ApiController.coachs.activateCoachAssistant(coach).then(() => {
            toast.success("Coach assistant activé")
            setConfirmActivate(false)
        }).catch(() => {
            toast.error("Une erreur est survenue")
        })
            .finally(() => {
                setIsActivatingCoach(false)
            })
    }

    const actionsFormatter = (cell, row) => {
        return <div className='d-flex gap-4 justify-content-center'>
            <FaArrowCircleUp
                onClick={() => {
                    setSelectedCoach(row)
                    setConfirmActivate(true)
                }}
                color={rootStyles.color.secondary}
                data-tip data-tooltip-id='activate-btn'
                className='cursor-pointer'
                size={20}
            />

            <ReactTooltip style={tooltip_options} id='activate-btn'>
                Activer
            </ReactTooltip>
        </div>
    }
    const columns = useMemo(() => [{
        dataField: "coachFullname",
        text: "Nom complet",
    },
    {
        dataField: "domaine",
        text: "Domaine",
    },
    {
        dataField: "email",
        text: "Email",
    },
    {
        dataField: "phone",
        text: "Numéro",
    },
    // {
    //     dataField: 'price',
    //     text: 'Actions',
    // },
        {
            dataField: "actions",
            text: "Actions",
            formatter: actionsFormatter
        }
    ], [])

    useEffect(() => {
        ApiController.coachs.getCoachsAssistantsArchives()
    }, [])

    return (
        <FadeTransition in={true}>
            <ConfirmAction
                loading={isActivatingCoach}
                modalIsOpen={confirmActivate}
                modalText="Activez coach assistant"
                text={`Voulez-vous vraiment activer ${selectedCoach?.first_name} ?`}
                cancel={() => setConfirmActivate(false)}
                validate={() => activateCoach(selectedCoach)}
                closeModal={() => setConfirmActivate(false)}
            />
            <TableListeView
                data={coachsArchivesList}
                columns={columns}
                title="Listes des coachs assistants archivés"
                navigateTo={() => navigate(COACHS_ASSISTANTS_PATH)}
                navigateToTitle="Liste des coachs assistants"
            />
        </FadeTransition>
    )
}

export default ListeCoachsAssistantsArchivesPage