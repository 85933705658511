import { rootStyles } from "assets/css/globalCss"
import { useCurrentUser } from "hooks/useCurrentUser"
import {
    useActiverBakelisteMutation,
    useArchiverBakelisteMutation, useDeStageEnBakelisteMutation,
    useDeclarerBakelisteCommeRecrutableMutation,
    useDesarchiverBakelisteMutation,
    usePlacerBakelisteEnStageMutation, useTerminerFormationBakelisteMutation
} from "pages/bakelistes/api/mutations"
import React from "react"
import toast from "react-hot-toast"
import styled from "styled-components"
import { TbPlayerPause } from "react-icons/tb";
import { MdBlock } from "react-icons/md";
import { BiArchiveIn } from "react-icons/bi";
import { GrLocationPin } from "react-icons/gr";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { MdClose } from "react-icons/md";


const BtnBadge = styled.button`
    padding: 5px 15px;
    display:flex;
    gap: 3px;
    justify-content:center;
    align-items:center;
    font-weight: ${rootStyles.fonts.btnWeight};
    border: none;
    font-size: 12px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px;
    background-color: ${({ bgColor }) => bgColor};
    cursor: ${({ disabled }) => disabled ? "not-allowed" : "pointer"};
    opacity: ${({ disabled }) => disabled ? "0.5" : "1"};
    color: ${({ color }) => color};
    border-radius: 50px;
    transition: all 0.2s ease-in-out;
    &:hover{
    opacity: ${({ disabled }) => disabled ? "0.4" : "0.8"};
    transform: ${({ disabled }) => disabled ? "scale(1)" : "scale(1.01)"};
    };
    &:active{
    box-shadow: 0 3px 0 rgba(0,0,0,.5);
    margin-bottom: 1px;
    }

      @media only screen and (max-width: 500px) {
    font-size: 12px;
    font-weight: 500;
    padding: ${({ padding }) => padding ? `${padding}px` : "7px"};
  }
`

export const Badge = ({ children, bgColor, color, disabled, onClick }) => {
    return <BtnBadge onClick={onClick} disabled={disabled} bgColor={bgColor} color={color}>{children}</BtnBadge>
}

const ActionsRapides = ({
    bakeliste,
    setOpenPauseModal,
    setOpenMotifModal,
    setOpenMotifDeblocageModal,
}) => {
    const [activatePauseBakelisteFormation] = useActiverBakelisteMutation()
    const [archiverBakelisteMutation] = useArchiverBakelisteMutation()
    const [desarchiverBakelisteMutation] = useDesarchiverBakelisteMutation()
    const [terminerBakelisteFormation] = useTerminerFormationBakelisteMutation()
    const [deStagiaireEnBakeliste] = useDeStageEnBakelisteMutation()
    const [placerBakelisteEnStage] = usePlacerBakelisteEnStageMutation()
    const [declarerBakelisteCommeRecrutable] = useDeclarerBakelisteCommeRecrutableMutation()
    const { currentUser } = useCurrentUser()

    const reIntegrerBakeliste = () => {
        const bakeliste_id = bakeliste.id
        toast.promise(activatePauseBakelisteFormation({ bakeliste_id, paused_by: currentUser.id }), {
            loading: "Réintégration",
            success: bakeliste.first_name + " est réintegré",
            error: `La réintégration de ${bakeliste.first_name} a echouée`
        })
    }
    const archiverBakeliste = () => {
        const bakeliste_id = bakeliste.id
        toast.promise(archiverBakelisteMutation(bakeliste_id), {
            loading: "Archivage en cours",
            success: bakeliste.first_name + " archivée",
            error: `L'archivage ${bakeliste.first_name} a echouée`
        })
    }
    const desarchiverBakeliste = () => {
        const bakeliste_id = bakeliste.id
        toast.promise(desarchiverBakelisteMutation(bakeliste_id), {
            loading: "Désarchivage en cours",
            success: bakeliste.first_name + " désarchivé(e)",
            error: `L'archivage ${bakeliste.first_name} a echouée`
        })
    }
    const terminerBakelisteFormationFunc = () => {
        const bakeliste_id = bakeliste.id
        toast.promise(terminerBakelisteFormation(bakeliste_id), {
            loading: "Términer en cours",
            success: bakeliste.first_name + " Terminé(e)",
            error: `La términaison de la formation de ${bakeliste.first_name} a echouée`
        })
    }
    const placerEnStageFunc = () => {
        toast.promise(placerBakelisteEnStage({ bakeliste_id: bakeliste.id, recruteur_raison: "susi", recruteur_domaine: undefined, change_status_by: currentUser.id }), {
            loading: "En cours",
            success: bakeliste.first_name + " est maintenant en stage",
            error: `La recrutement de ${bakeliste.first_name} a echoué`
        })
    }
    const reIntegrerStagiaireEnBakeliste = (bakeliste) => {
        const bakeliste_id = bakeliste.id
        toast.promise(deStagiaireEnBakeliste({ bakeliste_id, change_status_by: currentUser.id }), {
            loading: "Réintégration",
            success: bakeliste.first_name + " est réintegré en tant que bakeliste",
            error: `La réintégration de ${bakeliste.first_name} a echouée`
        })
    }

    const recrutableFunc = () => {
        toast.promise(declarerBakelisteCommeRecrutable({ bakeliste_nickname: "expert", bakeliste_id: bakeliste.id, label: "recruitable" }), {
            loading: "En cours",
            success: bakeliste.first_name + " est maintenant recrutable",
            error: `La recrutement de ${bakeliste.first_name} a echoué`
        })
    }

    return (
        <div className='d-flex flex-wrap gap-2'>
            {
                !bakeliste.is_training_paused ? <Badge
                    // onClick={pauseBakeliste}
                    onClick={() => setOpenPauseModal(true)}
                    disabled={bakeliste.is_archived || bakeliste.is_completed}
                    bgColor={rootStyles.color.blue}
                    color={rootStyles.color.primary}
                >
                    <TbPlayerPause /> Pause
                </Badge> : <Badge
                    onClick={reIntegrerBakeliste}
                    disabled={bakeliste.is_archived || bakeliste.is_completed}
                    bgColor={rootStyles.color.blue}
                    color={rootStyles.color.primary}
                >
                    Réintegrer
                </Badge>
            }

            {
                !bakeliste.is_account_blocked ? <Badge
                    onClick={() => setOpenMotifModal(true)}
                    disabled={bakeliste.is_archived || bakeliste.is_completed}
                    bgColor={rootStyles.color.redSecondary}
                    color={rootStyles.color.primary}
                >
                    <MdBlock /> Bloquer
                </Badge> : <Badge
                    onClick={() => setOpenMotifDeblocageModal(true)}
                    disabled={bakeliste.is_archived || bakeliste.is_completed}
                    bgColor={rootStyles.color.redSecondary}
                    color={rootStyles.color.primary}
                >
                    Débloquer
                </Badge>
            }

            {
                !bakeliste.is_archived ? <Badge
                    onClick={archiverBakeliste}
                    disabled={bakeliste.is_completed}
                    bgColor={rootStyles.color.tercary}
                    color={rootStyles.color.primary}
                >
                    <BiArchiveIn /> Archiver
                </Badge> : <Badge
                    onClick={desarchiverBakeliste}
                    disabled={bakeliste.is_completed}
                    bgColor={rootStyles.color.tercary}
                    color={rootStyles.color.primary}
                >
                    DésArchiver
                </Badge>
            }
            {
                bakeliste.status === "bakeliste" ? <Badge
                    onClick={placerEnStageFunc}
                    disabled={bakeliste.is_archived || bakeliste.is_completed}
                    bgColor={rootStyles.color.secondary}
                    color={rootStyles.color.primary}
                >
                    <GrLocationPin color="#" /> Placer en stage
                </Badge> : <Badge
                    onClick={reIntegrerStagiaireEnBakeliste}
                    disabled={bakeliste.is_archived || bakeliste.is_completed}
                    bgColor={rootStyles.color.secondary}
                    color={rootStyles.color.primary}
                >
                    Réintegrer en tant que Bakeliste
                </Badge>
            }

            {
                !bakeliste.is_recruitable ? <Badge
                    onClick={recrutableFunc}
                    disabled={bakeliste.is_archived || bakeliste.is_completed}
                    bgColor={rootStyles.color.secondary}
                    color={rootStyles.color.primary}
                >
                    <HiOutlineSpeakerphone /> Déclarer recrutable
                </Badge> : <Badge
                    onClick={null}
                    disabled={true}
                    bgColor={"gray"}
                    color={rootStyles.color.primary}
                >
                    Déjà recrutable
                </Badge>
            }

            {
                bakeliste.is_completed ? <Badge
                    onClick={null}
                    disabled={bakeliste.is_archived || bakeliste.is_completed}
                    bgColor={rootStyles.color.red}
                    color={rootStyles.color.primary}
                >
                    Formation Bakeliste Términée
                </Badge> : <Badge
                    onClick={terminerBakelisteFormationFunc}
                    disabled={bakeliste.is_archived}
                    bgColor={rootStyles.color.red}
                    color={rootStyles.color.primary}
                >
                        <MdClose /> Terminer
                </Badge>
            }
        </div>
    )
}

export default ActionsRapides