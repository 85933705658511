import useSWR from "swr";
import { evenementsAVenirSwrFetcher } from "..";
import { EVENEMENTS_A_VENIR_ENDPOINT } from "routes/api/endpoints";

export const useEvenementsAVenir = () => {
    const { data: evenementsAVenir = [], isLoading: isEvenementsAvenirLoading } = useSWR(
        EVENEMENTS_A_VENIR_ENDPOINT,
        evenementsAVenirSwrFetcher
    );

    return { evenementsAVenir, isEvenementsAvenirLoading }
}