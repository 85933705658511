const { createSlice } = require("@reduxjs/toolkit");

const paiementSlice = createSlice({
    name: "paiements",
    initialState: {
        currentBakelistePaiement: null,
        dakarBakelistesPaiement: null,
        dakarBakelistesCurrentMonthPaiement: null,
        thiesBakelistesPaiement: null,
        thiesBakelistesCurrentMonthPaiement: null,
        mbourBakelistesPaiement: null,
        mbourBakelistesCurrentMonthPaiement: null,
        isFilteringPaiement: false,
        currentMoisFilter: null,
        currentAnneeFilter: null,
    },
    reducers: {
        setCurrentBakelistePaiement: (state, { payload }) => {
            state.currentBakelistePaiement = payload
        },
        setDakarBakelistesPaiement: (state, { payload }) => {
            console.log({ payload });
            return {
                ...state,
                dakarBakelistesPaiement: payload
            }
        },
        setDakarBakelistesCurrentMonthPaiement: (state, { payload }) => {
            return {
                ...state,
                dakarBakelistesCurrentMonthPaiement: payload
            }
        },
        setThiesBakelistesPaiement: (state, { payload }) => {
            return {
                ...state,
                thiesBakelistesPaiement: payload
            }
        },
        setThiesBakelistesCurrentMonthPaiement: (state, { payload }) => {
            return {
                ...state,
                thiesBakelistesCurrentMonthPaiement: payload
            }
        },
        setMbourBakelistesPaiement: (state, { payload }) => {
            return {
                ...state,
                mbourBakelistesPaiement: payload
            }
        },
        setMbourBakelistesCurrentMonthPaiement: (state, { payload }) => {
            return {
                ...state,
                mbourBakelistesCurrentMonthPaiement: payload
            }
        },
        setIsFilteringPaiement: (state, { payload }) => {
            return {
                ...state,
                isFilteringPaiement: payload
            }
        },
        setCurrentAnneeFilter: (state, { payload }) => {
            return {
                ...state,
                currentAnneeFilter: payload
            }
        },
        setCurrentMoisFilter: (state, { payload }) => {
            return {
                ...state,
                currentMoisFilter: payload
            }
        }
    }
})

export const {
    setCurrentBakelistePaiement,
    setDakarBakelistesPaiement,
    setDakarBakelistesCurrentMonthPaiement,
    setThiesBakelistesPaiement,
    setThiesBakelistesCurrentMonthPaiement,
    setMbourBakelistesPaiement,
    setMbourBakelistesCurrentMonthPaiement,
    setIsFilteringPaiement,
    setCurrentAnneeFilter,
    setCurrentMoisFilter
} = paiementSlice.actions
export default paiementSlice.reducer