import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePartenaireProgrammes } from "../api/hooks/usePartenairesProgrammes";
import { Title2 } from "components/shared/TableListeView";
// import { GridList } from "react-flexible-list";
import ProgrammeCardItem from "./components/programmes/ProgrammeCardItem";
import Loader from "components/shared/Loader";
import { useDispatch, useSelector } from "react-redux";
import { rootStyles } from "assets/css/globalCss";
import FlexBetween from "components/shared/FlexBetween";
import ButtonComp from "components/ButtonComp";
import { IoMdAddCircle } from "react-icons/io";
import AddProgrammeModal from "./components/programmes/AddProgrammeModal";
import EditProgrammeModal from "./components/programmes/EditProgrammeModal";
import { setCurrentProgrammeApprenants } from "redux/features/partenaireSlice";
import { PARTICIPANTS_PATH } from "routes/navigation/navigationPaths";
import { Container } from "pages/bakelistes/BakelisteHomePageDashboard";
import ResourceDataMap from "components/ResourceDataMap";
import FadeTransition from "components/shared/FadeTransition";

const ProgrammesDunPartenaire = () => {
  const { currentPartenaire } = useSelector((state) => state.partenaires);
  const [openAddProgramme, setOpenAddProgramme] = useState(false);
  const [openEditProgramme, setOpenEditProgramme] = useState(false);
  const [selectedProgramme, setSelectedProgramme] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { name } = useParams();
  const { programmes, isError, isLoading } = usePartenaireProgrammes(name);

  console.log({ programmes });

  const editProgrammeFunc = (programme) => {
    setOpenEditProgramme(true);
    console.log({ programme });
    setSelectedProgramme(programme);
  };

  const navigateToApprenantsPage = (participants) => {
    dispatch(setCurrentProgrammeApprenants(participants));
    navigate(PARTICIPANTS_PATH);
  };

  const ClonedProgrammeCardItem = ({ programme }) =>
    React.cloneElement(<ProgrammeCardItem programme={programme} />, {
      editProgrammeFunc,
      navigateToApprenantsPage,
    });

  return (
    <FadeTransition in={true}>
      {openAddProgramme && (
        <AddProgrammeModal
          openAddProgramme={openAddProgramme}
          onClosed={() => setOpenAddProgramme(false)}
        />
      )}
      {openEditProgramme && (
        <EditProgrammeModal
          selectedProgramme={selectedProgramme}
          openEditProgramme={openEditProgramme}
          onClosed={() => setOpenEditProgramme(false)}
        />
      )}
      {isError ? (
        <p>Une erreur est survenue lors de la récuperation du partenaire</p>
      ) : (
        <>
          <FlexBetween>
            <Title2>
              Liste de programmes de{" "}
              <span
                style={{ fontSize: "20px", color: rootStyles.color.tercary }}
              >
                {currentPartenaire.name}
              </span>
            </Title2>
            <ButtonComp
              icon={{
                name: IoMdAddCircle,
              }}
              title="Nouveau"
              onClick={() => setOpenAddProgramme(true)}
              bgColor={rootStyles.color.secondary}
              color={rootStyles.color.primary}
            />
          </FlexBetween>
          {isLoading ? (
            <Loader />
          ) : programmes.data.partenaire.programmes.length ? (
            <>
              <Container>
                <ResourceDataMap
                  resourceData={programmes.data.partenaire.programmes}
                  resourceName="programme"
                  resourceItem={ClonedProgrammeCardItem}
                />
              </Container>
              {/* <GridList
                resourceData={programmes.data.partenaire.programmes}
                resourceName="programme"
                resourceItem={ClonedProgrammeCardItem}
              /> */}
            </>
          ) : (
            <p>Ce partenaire n'a pas encore de programmes</p>
          )}
        </>
      )}
    </FadeTransition>
  );
};

export default ProgrammesDunPartenaire;
