import { rootStyles } from "assets/css/globalCss";
import FlexBetween from "components/shared/FlexBetween";
import React from "react";
import { CiLocationOn } from "react-icons/ci";
import styled from "styled-components";

// outline: 1px solid white;
// outline-offset: -10px;
const CardContainer = styled.div`
  padding: ${rootStyles.padding.btnPadding};
  background-color: white;
  box-shadow: ${rootStyles.shadow.card};
  border-radius: ${rootStyles.radius.card};
  border-right: 5px solid ${rootStyles.color.tercary};
  height: 100px;
  //   background-color: ${rootStyles.color.secondaryFonce};
  margin: 5px;
  &:hover {
    cursor: pointer;
  }
  @media only screen and (max-width: 500px) {
    height: 80px;
  }
`;
const NamePane = styled.span`
  font-weight: 600;
  //   color: white;
  @media only screen and (max-width: 500px) {
    font-size: 12px;
  }
`;
const NumberPane = styled.span`
  font-weight: 600;
  color: ${rootStyles.color.tercary};
  @media only screen and (max-width: 500px) {
    font-size: 12px;
  }
`;

const LoaderPointPane = styled.div`
  width: 20px;
  padding: 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #25b09b;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
@keyframes l3 {to{transform: rotate(1turn)}}
}
`

const CardRegionItem = ({ location, goToProspectsParRegion }) => {
    const { region, number, prospectsList, isLoading } = location;
    return (
        <CardContainer
            style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
            onClick={() =>
        isLoading ? null : goToProspectsParRegion(region, prospectsList)
            }
        >
            <CiLocationOn size={20} color={rootStyles.color.tercary} />
            <FlexBetween>
                <NamePane>{region}</NamePane>
          <NumberPane>{!isLoading ? number : <LoaderPointPane className="loader" />}</NumberPane>
            </FlexBetween>
        </CardContainer>
    );
};

export default CardRegionItem;
