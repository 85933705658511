import { rootStyles } from "assets/css/globalCss"
import ButtonComp from "components/ButtonComp"
import EvenementCardItem from "components/evenements/EvenementCardItem"
import Checkbox from "components/shared/Checkbox"
import GridListe from "components/shared/GridListe"
import PaginationItems from "components/shared/PaginationItems"
import React, { cloneElement, useEffect } from "react";
import { HiVideoCamera } from "react-icons/hi"
import { FcOvertime } from "react-icons/fc"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";
import { displaysInComingEvents, displaysRecentsEvents, restartFilter, setCurrentEvenementSlice } from "redux/features/evenementSlice"
import { AJOUT_EVENEMENT_PATH, DETAILS_EVENEMENT_PATH } from "routes/navigation/navigationPaths"
import styled from "styled-components"
import { useEvenementsAVenir } from "./api/hooks/useEvenementsAVenir";
import { useEvenementsRecents } from "./api/hooks/useEvenementsRecents";
import FadeTransition from "components/shared/FadeTransition";

const EventCard = styled.div`
  background-color: ${rootStyles.color.primary};
  padding: ${rootStyles.padding.btnPadding};
  border-radius: ${rootStyles.radius.card};
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
`;
const CardText = styled.p`
  font-size: ${rootStyles.fonts.fontSize};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-weight: 600;
  margin-bottom: 10px;
`;
const CardItem = styled.div`
  background-color: ${rootStyles.color.primary};
  box-shadow: ${rootStyles.shadow.card};
  padding: 10px;
  border-radius: ${rootStyles.radius.card};
  max-width: 100px;
  width: 100px;
  text-align: center;
`;
const SpanText = styled.span`
  font-size: ${rootStyles.fonts.fontSize};
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const SpanNumber = styled.span`
  font-size: 25px;
  color: ${rootStyles.color.secondary};
  font-weight: ${rootStyles.fonts.h2FontWeight};
  display: block;
`;

// !liste des evenements
const RenderEvents = ({ currentItems, isLoadingEvenements }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateToDetails = (evenement) => {
    dispatch(setCurrentEvenementSlice(evenement));
    return navigate(DETAILS_EVENEMENT_PATH);
  };

  const ClonedEvenementCardItem = ({ evenement }) =>
    cloneElement(<EvenementCardItem evenement={evenement} />, {
      navigateToDetails,
    });

  return (
    <GridListe
      isLoading={isLoadingEvenements}
      resourceData={currentItems}
      resourceName="evenement"
      resourceItem={ClonedEvenementCardItem}
      emptyMessage="Pas encore d'événements"
    />
  );
};

const ListeEvenementsPage = () => {
  const { evenementsAVenir, isEvenementsAvenirLoading } = useEvenementsAVenir();
  const { evenementsRecent, isEvenementsRecentLoading } =
    useEvenementsRecents();

  const { isEvenementsAVenir, isEvenementsRecents } = useSelector(
    (state) => state.evenements
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(restartFilter());
  }, [dispatch]);

  return (
    <FadeTransition in={true}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-9 order-lg-1 order-2 p-0">
            <div className="d-flex gap-3 mt-3">
              <Checkbox
                checked={isEvenementsAVenir}
                onChange={(checked) =>
                  dispatch(displaysInComingEvents(checked))
                }
                label="A venir"
              />
              <Checkbox
                checked={isEvenementsRecents}
                onChange={(value) => dispatch(displaysRecentsEvents(value))}
                label="Récents"
              />
            </div>
            <div className="mt-3">
              <PaginationItems
                itemsPerPage={10}
                ressourceData={
                  isEvenementsAVenir ? evenementsAVenir : evenementsRecent
                }
              >
                <RenderEvents
                  isLoadingEvenements={
                    isEvenementsAvenirLoading || isEvenementsRecentLoading
                  }
                />
              </PaginationItems>
            </div>
          </div>
          <div className="col-lg-3 order-lg-2 order-1 p-0">
            <EventCard>
              <CardText>
                <FcOvertime size={25} />
                Événements
              </CardText>
              <div className="d-flex justify-content-center align-items-center gap-2">
                <CardItem>
                  <SpanNumber>{evenementsAVenir?.length}</SpanNumber>
                  <SpanText>A venir</SpanText>
                </CardItem>
                <CardItem>
                  <SpanNumber>{evenementsRecent?.length}</SpanNumber>
                  <SpanText>Passé</SpanText>
                </CardItem>
              </div>
            </EventCard>
            <EventCard>
              <CardText>
                <FcOvertime size={25} /> Prochain événement
              </CardText>
              <div className="d-flex justify-content-center">
                <ButtonComp
                  onClick={() => navigate(AJOUT_EVENEMENT_PATH)}
                  width={"200px"}
                  bgColor={rootStyles.color.primary}
                  borderColor={rootStyles.color.secondary}
                  title="Créer"
                  icon={{
                    name: HiVideoCamera,
                    color: "#2d8cff",
                    size: 25,
                  }}
                />
              </div>
            </EventCard>
          </div>
        </div>
      </div>
    </FadeTransition>
  );
};

export default ListeEvenementsPage