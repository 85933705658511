import { store } from "../store"
import { ADD_EVENEMENT_ENDPOINT, EVENEMENTS_A_VENIR_ENDPOINT, EVENEMENTS_DETAILS_ENDPOINT, EVENEMENTS_RECENTS_ENDPOINT } from "routes/api/endpoints.js"
import { addNewEvenementSlice, setCurrentEvenementSlice, setEvenementsAVenirListSlice, setEvenementsRecentsListSlice } from "redux/features/evenementSlice.js"
import { axiosInstance } from "./axiosInstance"

const dispatch = store.dispatch
const evenementsController = {
    getEvenementById(evenement_id) {
        return new Promise((resolve, reject) => {
            axiosInstance.get(EVENEMENTS_DETAILS_ENDPOINT + evenement_id)
                .then((response) => {
                    if (response.status >= 200) {
                        const data = response.data.data
                        console.log({ data });
                        dispatch(setCurrentEvenementSlice(data))
                        resolve(data)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    getEvenementsRecents() {
        return new Promise((resolve, reject) => {
            axiosInstance.get(EVENEMENTS_RECENTS_ENDPOINT)
                .then((response) => {
                    if (response.status >= 200) {
                        const data = response.data.data
                        // console.log('events recents: ', data);
                        dispatch(setEvenementsRecentsListSlice(data))
                        resolve(data)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    getEvenementsAVenir() {
        return new Promise((resolve, reject) => {
            axiosInstance.get(EVENEMENTS_A_VENIR_ENDPOINT)
                .then((response) => {
                    if (response.status >= 200) {
                        const data = response.data.data
                        // console.log('events a venir: ', data);
                        dispatch(setEvenementsAVenirListSlice(data))
                        resolve(data)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    addNewEvenement(data) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(ADD_EVENEMENT_ENDPOINT, data)
                .then((response) => {
                    if (response.status >= 200) {
                        /**
                        * !addNewEvenement is from reducer.Evenement.js, just for Evenement
                        * !Don't forget to check your response content data type
                        */
                        const evenement = response.data.data
                        console.log({ evenement });
                        dispatch(addNewEvenementSlice(evenement))
                        resolve(evenement)
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
    updateEvenement(data, EvenementId) {
        return new Promise((resolve, reject) => {
            axiosInstance.put("******** PUT URL HERE ***********", data)
                .then((response) => {
                    if (response.status === 200) {
                        /**
                        * !updateEvenement is from reducer.Evenement.js, just for Evenement
                        * !Don't forget to check your response content data type
                        */
                        const Evenement = response.data
                        // dispatch(updateEvenementSlice(Evenement))
                        resolve(Evenement)
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
    deleteEvenement(evenementId) {
        return new Promise((resolve, reject) => {
            axiosInstance.delete(`${"******** PUT URL HERE ***********" + evenementId}/`)
                .then((response) => {
                    if (response.status === 204) {
                        resolve(response)
                        // dispatch(archiveEvenementSlice(response))
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
}
export default evenementsController