import React from "react";

const withHandleForm = (Component) => {
    // eslint-disable-next-line react/display-name
    return (props) => {
        const [form, setForm] = React.useState({});
        const handleChange = (value, name) => {
            setForm({ ...form, [name]: value });
        };
        return <Component form={form} handleChange={handleChange} {...props} />
    }
}
export default withHandleForm