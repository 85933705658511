import { rootStyles } from "assets/css/globalCss";
import TrimestreCardItem from "components/examens/TrimestreCardItem";
import FadeTransition from "components/shared/FadeTransition";
import { Title2 } from "components/shared/TableListeView";
import { Badge } from "pages/bakelistes/Details/DetailsBakelistePage";
import React from "react";
import { GridList } from "react-flexible-list";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentTrimestre } from "redux/features/examenSlice";
import { TRIMESTRE_DOSSIERS_PATH } from "routes/navigation/navigationPaths";
// import { ApiController } from 'redux/apiController'

const ListeTrimestrePage = () => {
  const { currentAnneeAcademique, trimestreByAnneeList } = useSelector(
    (state) => state.examens
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToExamensListe = (currentTrimestre) => {
    // dispatch(setExamensListSlice(allExamens))
    dispatch(setCurrentTrimestre(currentTrimestre));
    // return navigate(LISTE_EXAMENS_PATH)
    return navigate(TRIMESTRE_DOSSIERS_PATH);
  };
  const ClonedTrimestreCardItem = ({ trimestreItem }) =>
    React.cloneElement(<TrimestreCardItem trimestreItem={trimestreItem} />, {
      goToExamensListe,
    });

  return (
    <FadeTransition in={true}>
      <div className="d-flex gap-1 align-items-center mb-2">
        <Title2>Trimestres </Title2>
        <Badge
          bgColor={rootStyles.color.tercary}
          color={rootStyles.color.primary}
        >
          {currentAnneeAcademique}
        </Badge>
      </div>
      <GridList
        resourceName="trimestreItem"
        resourceData={trimestreByAnneeList}
        resourceItem={ClonedTrimestreCardItem}
        cardWidth={170}
      />
    </FadeTransition>
  );
};

export default ListeTrimestrePage;
