/* eslint-disable react/react-in-jsx-scope */
// import { rootStyles } from "assets/css/globalCss";
// import ButtonComp from "components/ButtonComp";
import FadeTransition from "components/shared/FadeTransition";
// import InputForm from "components/shared/InputForm";
// import Loader from "components/shared/Loader";
import TableListeView from "components/shared/TableListeView";
// import { useGetBakelistePointages } from "pages/suivi-bakelistes/api/hooks/useGetBakelistePointages";
import { useMemo } from "react";
// import { useForm } from "react-hook-form";
// import { BiSearch } from "react-icons/bi";
// import { useSelector } from "react-redux";
import { completeDateFormatter } from "utils/timeFormatters";

const ListePointages = ({ pointages = [] }) => {
  // Extra parameters for the query
  // const [extraData, setExtraData] = useState({
  //   start_date: "",
  //   end_date: "",
  // });

  // const { suiviCurrentBakeliste } = useSelector((state) => state.bakelistes);

  // const {
  //   currentBakelistePointages,
  //   loadingCurrentBakelistePointages,
  //   refetchBakelistePointages,
  //   currentPointagesData,
  // } = useGetBakelistePointages(
  //   bakelisteId,
  //   extraData?.start_date,
  //   extraData?.end_date
  // );

  // const { register, handleSubmit } = useForm();

  // console.log({ pointages });


  const columns = useMemo(() => [
    {
      dataField: "date_pointage",
      text: "Date",
      formatter: (cell, row) => (
        <p className="fw-bold text-center">
          {completeDateFormatter(row?.date_pointage)}
        </p>
      ),
    },
    {
      dataField: "arriving_time",
      text: "Heure d'arrivée",
      formatter: (cell, row) => (
        <p className="fw-bold text-center">{row?.arriving_time}</p>
      ),
    },
    // {
    //   dataField: "departure_time",
    //   text: "Heure départ",
    // },
  ]);

  return (
    <FadeTransition in={true}>
      {/* {loadingCurrentBakelistePointages ||
      (currentBakelistePointages && !currentPointagesData) ? (
        <Loader />
      ) : (
        ""
      )} */}

      {/* <form
        className="d-flex align-items-center justify-content-end flex-sm-no-wrap gap-2 px-2 mt-4"
        onSubmit={handleSubmit((data) => {
          setExtraData({
            start_date: data?.start_date,
            end_date: data?.end_date,
          });
          refetchBakelistePointages(extraData);
        })}
      >
        <div>
          <InputForm
            type="date"
            label="Date début"
            register={{
              ...register("start_date"),
            }}
          />
        </div>
        <div>
          <InputForm
            type="date"
            label="Date fin"
            register={{
              ...register("end_date"),
            }}
          />
        </div>
        <div className="mt-2">
          <ButtonComp
            bgColor={rootStyles.color.secondary}
            color={rootStyles.color.primary}
            icon={{
              name: BiSearch,
            }}
          />
        </div>
      </form> */}
      <TableListeView
        columns={columns}
        data={[...pointages].reverse()}
      />
    </FadeTransition>
  );
};

export default ListePointages;
