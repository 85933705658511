import LoginPage from "pages/auth/LoginPage";
import RegisterPage from "pages/auth/RegisterPage";
import BakelisteHomePage from "pages/bakelistes/BakelisteHomePage";
import BakelisteHomePageDashboard from "pages/bakelistes/BakelisteHomePageDashboard";
import DetailsBakelistePage from "pages/bakelistes/Details/DetailsBakelistePage";
import ListeBakelisteEnPausePage from "pages/bakelistes/ListeBakelisteEnPausePage";
import ListeBakelisteRecrutable from "pages/bakelistes/ListeBakelisteRecrutable";
import ListeBakelistesArchivesPage from "pages/bakelistes/ListeBakelistesArchivesPage";
import ListeBakelistesBloques from "pages/bakelistes/ListeBakelistesBloques";
import ListeBakelistesEnLignePage from "pages/bakelistes/ListeBakelistesEnLignePage";
import ListeBakelistesEnStage from "pages/bakelistes/ListeBakelistesEnStage";
import ListeBakelistesPage from "pages/bakelistes/ListeBakelistesPage";
import ListeBakelistesPresentielPage from "pages/bakelistes/ListeBakelistesPresentielPage";
import ListeBakelistesTerminesPage from "pages/bakelistes/ListeBakelistesTerminesPage";
import UpdateBakelistePage from "pages/bakelistes/UpdateBakelistePage";
import AjouterBakelistePage from "pages/bakelistes/ajouterBakeliste/AjouterBakelistePage";
import AjoutCoachAssistantPage from "pages/coachs-assitants/AjoutCoachAssistantPage";
import CoachsAssistantHomePage from "pages/coachs-assitants/CoachsAssistantHomePage";
import ListeCoachAssistantsPage from "pages/coachs-assitants/ListeCoachAssistantsPage";
import ListeCoachsAssistantsArchivesPage from "pages/coachs-assitants/ListeCoachsAssistantsArchivesPage";
import UpdateCoachAssistantPage from "pages/coachs-assitants/UpdateCoachAssistantPage";
import AjoutCoachPage from "pages/coachs/AjoutCoachPage";
import CoachHomePage from "pages/coachs/CoachHomePage";
import ListeCoachPage from "pages/coachs/ListeCoachPage";
import ListeCoachsArchivesPage from "pages/coachs/ListeCoachsArchivesPage";
import UpdateCoachPage from "pages/coachs/UpdateCoachPage";
import AjoutEvenementPage from "pages/evenements/AjoutEvenementPage";
import DetailsEvenementPage from "pages/evenements/DetailsEvenementPage";
import EvenementsHomePage from "pages/evenements/EvenementsHomePage";
import ListeEvenementsPage from "pages/evenements/ListeEvenementsPage";
import ListeAnneePage from "pages/examens/anneesAcademiques/ui/ListeAnneePage";
import BulletinsDomainesPage from "pages/examens/bulletins/ui/BulletinsDomainesPage";
import ListeBulletins from "pages/examens/bulletins/ui/ListeBulletinsPage";
import ListeEpreuvesHomePage from "pages/examens/epreuves/ui/ListeEpreuvesHomePage";
import ListeEpreuvesPage from "pages/examens/epreuves/ui/ListeEpreuvesPage";
import TypeEpreuvesHomePage from "pages/examens/epreuves/ui/TypeEpreuvesHomePage";
import TypeEpreuvesPage from "pages/examens/epreuves/ui/TypeEpreuvesPage";
import EpreuvesPratiquesDomainesPage from "pages/examens/epreuves/ui/pratiques/EpreuvesPratiquesDomainesPage";
import EpreuvesPratiquesHomePage from "pages/examens/epreuves/ui/pratiques/EpreuvesPratiquesHomePage";
import ListeEpreuvesPratiques from "pages/examens/epreuves/ui/pratiques/ListeEpreuvesPratiques";
import EpreuvesTheoriquesDomainesPage from "pages/examens/epreuves/ui/theoriques/EpreuvesTheoriquesDomainesPage";
import EpreuvesTheoriquesHomePage from "pages/examens/epreuves/ui/theoriques/EpreuvesTheoriquesHomePage";
import ListeEpreuvesTheoriques from "pages/examens/epreuves/ui/theoriques/ListeEpreuvesTheoriques";
import DossierTrimestreHomePage from "pages/examens/trimestres/ui/DossierTrimestreHomePage";
import DossiersTrimestre from "pages/examens/trimestres/ui/DossiersTrimestre";
import ListeTrimestrePage from "pages/examens/trimestres/ui/ListeTrimestrePage";
import DetailsPaiementsPage from "pages/paiements/ui/DetailsPaiementsPage";
import ListePaiementPage from "pages/paiements/ui/ListePaiementPage";
import PaiementsPage from "pages/paiements/ui/PaiementsPage";
import ListeParticipantsPages from "pages/partenaires/ui/ListeParticipantsPages";
import PartenairePage from "pages/partenaires/ui/PartenairePage";
import ProgrammesDunPartenaire from "pages/partenaires/ui/ProgrammesDunPartenaire";
import SyllabusPage from "pages/syllabus/ui/SyllabusPage";
import DetailsDunSyllabus from "pages/syllabus/ui/DetailsDunSyllabus";
import ProspectsParRegionDashboardPage from "pages/prospects/ui/ProspectsParRegionDashboardPage";
import ProspectsParRegionPage from "pages/prospects/ui/ProspectsParRegionPage";
import DetailsDunProspect from "pages/prospects/ui/DetailsDunProspect";

import StagiaireArchivesPage from "pages/stagiaires/StagiaireArchivesPage";
import DetailsSuiviBakeliste from "pages/suivi-bakelistes/ui/details-bakeliste/DetailsSuiviBakeliste";
import DetailsSyllabusPage from "pages/suivi-bakelistes/ui/details-bakeliste/syllabus/DetailsSyllabusPage";
// import ListeSyllabusPage from "pages/suivi-bakelistes/ui/details-bakeliste/syllabus/ListeSyllabusPage";
import EmployesPage from "pages/employes/EmployesPage";
import HistoriquesPage from "pages/historiques/HistoriquesPage";
import HistoriquesBakelistesPage from "pages/historiques/bakelistes/HistoriquesBakelistesPage";
import HistoriquesCoachsAssitantsPage from "pages/historiques/coachs-assistants/HistoriquesCoachsAssitantsPage";
import BulletinDetailsPage from "pages/suivi-bakelistes/ui/details-bakeliste/bulletins/BulletinDetailsPage";
import SuiviListeTrimestrePage from "pages/suivi-bakelistes/ui/details-bakeliste/bulletins/SuiviListeTrimestrePage";
import ListeLivraisons from "pages/suivi-bakelistes/ui/details-bakeliste/livraisons/ListeLivraisons";
import ListePointages from "pages/suivi-bakelistes/ui/details-bakeliste/pointages/ListePointages";
import ListeSyllabusPage from "pages/suivi-bakelistes/ui/details-bakeliste/syllabus/ListeSyllabusPage";
import SuiviBakelistePage from "pages/suivi-bakelistes/ui/home/SuiviBakelistePage";
import CoachBakelistesCurrentStatus from "pages/suivi-coaching/ui/bakelistes/CoachBakelistesCurrentStatus";
import CoachCommentsPage from "pages/suivi-coaching/ui/commentaires-coach/CoachCommentsPage";
import DetailsCoachSuiviPage from "pages/suivi-coaching/ui/details-coach/DetailsCoachSuiviPage";
import SuiviCoachingpage from "pages/suivi-coaching/ui/home/SuiviCoachingpage";
import CoachValidationsPage from "pages/suivi-coaching/ui/validations-coach/CoachValidationsPage";
import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import ListeBakelisteEnStagePage from "../../pages/bakelistes/ListeBakelisteEnStagePage";
import {
  ACCEUIL_PATH,
  ADD_BAKELISTE_PATH,
  ADD_COACH_ASSISTANT_PATH,
  ADD_COACH_PATH,
  AJOUT_EVENEMENT_PATH,
  BAKELISTES_ACTIFS_PATH,
  BAKELISTES_ARCHIVES_PATH,
  BAKELISTES_BLOQUES_PATH,
  BAKELISTES_EN_LIGNE_PATH,
  BAKELISTES_EN_PAUSE_PATH,
  BAKELISTES_EN_PRESENTIEL_PATH,
  BAKELISTES_EN_STAGE_PATH,
  BAKELISTES_HISTORY_PATH,
  BAKELISTES_INACTIFS_PATH,
  BAKELISTES_LIVRAISONS_PATH,
  BAKELISTES_PATH,
  BAKELISTES_RECRUTABLES_PATH,
  BAKELISTES_TERMINES_PATH,
  BULLETINS_DOMAINES_PATH,
  COACHS_ARCHIVES_PATH,
  COACHS_ASSISTANTS_PATH,
  COACHS_ASSISTANT_ARCHIVES_PATH,
  COACHS_PATH,
  COACH_ASSISTANT_HISTORY_PATH,
  COACH_COMMENTS_PATH,
  COACH_CURRENT_BAKELISTES_STATUS_PATH,
  COACH_VALIDATIONS_PATH,
  DETAILS_BAKELISTE_PATH,
  DETAILS_EVENEMENT_PATH,
  DETAILS_SUIVI_BAKELISTES_PATH,
  DETAILS_SUIVI_BAKELISTES_SYLLABUS_DETAILS_PATH,
  DETAILS_SUIVI_BAKELISTES_TRIMESTRE_DETAILS_PATH,
  //   DETAILS_SUIVI_BAKELISTES_SYLLABUS_PATH,
  DETAILS_SUIVI_COACHING_PATH,
  EDIT_BAKELISTE_PATH,
  EMPLOYES_PATH,
  EPREUVES_PRATIQUES_LISTE_PATH,
  EPREUVES_PRATIQUES_PATH,
  EPREUVES_THEORIQUES_LISTE_PATH,
  EPREUVES_THEORIQUES_PATH,
  EVENEMENTS_PATH,
  EXAMENS_PATH,
  EXAMENS_TRIMESTRE_PATH,
  HISTORY_PATH,
  LISTE_BAKELISTES_PATH,
  LISTE_BULLETINS_PATH,
  LISTE_EPREUVES_PATH,
  LISTE_PAIEMENTS_PATH,
  LOGIN_PATH,
  PAIEMENTS_PATH,
  PARAMETRES_PATH,
  PARTENAIRES_PATH,
  SYLLABUS_PATH,
  PARTICIPANTS_PATH,
  PROSPECTS_PAR_REGION_PATH,
  PROSPECTS_PATH,
  REGISTER_PATH,
  STAGIAIRES_ARCHIVES_PATH,
  SUIVI_BAKELISTES_PATH,
  SUIVI_COACHING_PATH,
  SUIVI_POINTAGE_HEBDO_PATH,
  SUIVI_POINTAGE_PATH,
  TRIMESTRE_DOSSIERS_PATH,
  TYPES_EPREUVES_PATH,
  UPDATE_COACH_ASSISTANT_PATH,
  UPDATE_COACH_PATH,
} from "./navigationPaths";
import BakelistesActifPage from "pages/bakelistes/Actifs/BakelistesActifPage";
import BakelistesNonActifPage from "pages/bakelistes/NonActif/BakelistesNonActifPage";
import SuiviPointagePage from "pages/suivi-pointage/SuiviPointagePage";
import SuiviPointageHebdoPage from "pages/suivi-pointage/Bakelistes/Hebdo/SuiviPointageHebdoPage";
import CalendrierPresenceProvider from "context/usePointageCalendarContext";
import ParametresPage from "pages/parametres/ParametresPage";
import SuiviLivraisonsPage from "pages/suivi-livraison/SuiviLivraisonsPage";

// ! lazy loading ========================================================
// const SuiviCoachingpage = lazy(() => import("pages/suivi-coaching/ui/SuiviCoachingpage"))
const HomePage = lazy(() => import("../../pages/home/ui/HomePage"));
// const LoginPage = lazy(() => import("pages/auth/LoginPage"))
// const EvenementsHomePage = lazy(() => import("pages/evenements/EvenementsHomePage"))

const DETAILS_SUIVI_BAKELISTES_OUTLETS = {
  path: DETAILS_SUIVI_BAKELISTES_PATH,
  breadcrumb: "Détails suivi bakelistes",
  element: <Outlet />,
  children: [
    {
      path: "",
      element: <DetailsSuiviBakeliste />,
      children: [
        {
          path: "",
          element: <Outlet />,
          children: [
            {
              path: "",
              element: <ListeSyllabusPage />,
            },
            {
              path: DETAILS_SUIVI_BAKELISTES_SYLLABUS_DETAILS_PATH,
              breadcrumb: "Détails syllabus suivi bakeliste",
              element: <DetailsSyllabusPage />,
            },
          ],
        },
        {
          path: "bulletins",
          breadcrumb: " ",
          element: <Outlet />,
          children: [
            {
              path: "",
              element: <SuiviListeTrimestrePage />,
            },
            {
              path: DETAILS_SUIVI_BAKELISTES_TRIMESTRE_DETAILS_PATH,
              breadcrumb: "Détails trimestre",
              element: <BulletinDetailsPage />,
            },
          ],
        },
        {
          path: "pointages",
          breadcrumb: " ",
          element: <ListePointages />,
        },
        {
          path: "livraisons",
          breadcrumb: " ",
          element: <ListeLivraisons />,
        },
      ],
    },
    {
      path: DETAILS_SUIVI_BAKELISTES_SYLLABUS_DETAILS_PATH,
      breadcrumb: "Détails syllabus suivi bakeliste",
      element: <DetailsSyllabusPage />,
    },
  ],
};

export const CustomPropsBreadcrumb = ({ match }) => {
  // console.log({ match });
  return <span>{"someProp"}</span>;
};

export const publicRoutes = [
  // {
  //   path: "*",
  //   element: <Navigate to={LOGIN} />,
  // },
  {
    path: LOGIN_PATH,
    element: <LoginPage />,
  },
  {
    path: REGISTER_PATH,
    element: <RegisterPage />,
  },
];

export const privateRoutes = [
  {
    path: ACCEUIL_PATH,
    element: <HomePage />,
    breadcrumb: "Accueil",
  },
  {
    path: COACHS_PATH,
    element: <CoachHomePage />,
    children: [
      {
        path: "",
        element: <ListeCoachPage />,
      },
      {
        path: ADD_COACH_PATH,
        element: <AjoutCoachPage />,
      },
      {
        path: UPDATE_COACH_PATH,
        element: <UpdateCoachPage />,
      },
      {
        path: COACHS_ARCHIVES_PATH,
        element: <ListeCoachsArchivesPage />,
      },
    ],
  },
  /** ****************************************
   * !SUIVI POINTAGE PATHS
   * *****************************************
   */
  {
    path: SUIVI_POINTAGE_PATH,
    element: <CalendrierPresenceProvider>
      <Outlet />
    </CalendrierPresenceProvider>,
    children: [
      {
        path: "",
        element: <SuiviPointagePage />
      },
      {
        path: SUIVI_POINTAGE_HEBDO_PATH,
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <SuiviPointageHebdoPage />
          },
          {
            path: DETAILS_BAKELISTE_PATH,
            element: <Outlet />,
            children: [
              {
                breadcrumb: "Détails",
                path: "",
                element: <DetailsBakelistePage />
              },
              {
                path: EDIT_BAKELISTE_PATH,
                element: <UpdateBakelistePage />,
              },
              { ...DETAILS_SUIVI_BAKELISTES_OUTLETS }
            ]
          },
        ]
      },
    ]
  },
  {
    path: BAKELISTES_LIVRAISONS_PATH,
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <SuiviLivraisonsPage />
      },
      {
        path: DETAILS_BAKELISTE_PATH,
        element: <Outlet />,
        children: [
          {
            breadcrumb: "Détails",
            path: "",
            element: <DetailsBakelistePage />,
          },
          {
            path: EDIT_BAKELISTE_PATH,
            element: <UpdateBakelistePage />,
          },
          { ...DETAILS_SUIVI_BAKELISTES_OUTLETS }
        ]
      },
    ]
  },
  /** ****************************************
   * !COACHS PATHS
   * *****************************************
   */
  {
    path: COACHS_ASSISTANTS_PATH,
    element: <CoachsAssistantHomePage />,
    children: [
      {
        path: "",
        element: <ListeCoachAssistantsPage />,
      },
      {
        path: UPDATE_COACH_ASSISTANT_PATH,
        element: <UpdateCoachAssistantPage />,
      },
      {
        path: COACHS_ASSISTANT_ARCHIVES_PATH,
        element: <ListeCoachsAssistantsArchivesPage />,
      },
      {
        path: ADD_COACH_ASSISTANT_PATH,
        element: <AjoutCoachAssistantPage />,
      },
    ],
  },
  /** ****************************************
   * !SUIVI COACHING PATHS
   * *****************************************
   */
  {
    path: SUIVI_COACHING_PATH,
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <SuiviCoachingpage />,
      },
      {
        path: DETAILS_SUIVI_COACHING_PATH,
        element: <Outlet />,
        children: [
          {
            path: "",
            breadcrumb: "Détails suivi coach",
            element: <DetailsCoachSuiviPage />,
          },
          {
            path: COACH_CURRENT_BAKELISTES_STATUS_PATH,
            element: <CoachBakelistesCurrentStatus />,
          },
          {
            path: COACH_COMMENTS_PATH,
            element: <CoachCommentsPage />,
          },
          {
            path: COACH_VALIDATIONS_PATH,
            element: <CoachValidationsPage />,
          },
        ],
      },
    ],
  },
  /** ****************************************
   * !SUIVI BAKELISTES PATHS
   * *****************************************
   */
  {
    path: SUIVI_BAKELISTES_PATH,
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <SuiviBakelistePage />,
      },
      {
        path: DETAILS_SUIVI_BAKELISTES_PATH,
        breadcrumb: "Détails suivi bakelistes",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <DetailsSuiviBakeliste />,
            children: [
              {
                path: "",
                element: <Outlet />,
                children: [
                  {
                    path: "",
                    element: <ListeSyllabusPage />,
                  },
                  {
                    path: DETAILS_SUIVI_BAKELISTES_SYLLABUS_DETAILS_PATH,
                    breadcrumb: "Détails syllabus suivi bakeliste",
                    element: <DetailsSyllabusPage />,
                  },
                ],
              },
              {
                path: "bulletins",
                breadcrumb: " ",
                element: <Outlet />,
                children: [
                  {
                    path: "",
                    element: <SuiviListeTrimestrePage />,
                  },
                  {
                    path: DETAILS_SUIVI_BAKELISTES_TRIMESTRE_DETAILS_PATH,
                    breadcrumb: "Détails trimestre",
                    element: <BulletinDetailsPage />,
                  },
                ],
              },
              {
                path: "pointages",
                breadcrumb: " ",
                element: <ListePointages />,
              },
              {
                path: "livraisons",
                breadcrumb: " ",
                element: <ListeLivraisons />,
              },
            ],
          },
          {
            path: DETAILS_SUIVI_BAKELISTES_SYLLABUS_DETAILS_PATH,
            breadcrumb: "Détails syllabus suivi bakeliste",
            element: <DetailsSyllabusPage />,
            // children: [
            //   {
            //     path: "",
            //     element: <ListeSyllabusPage />,
            //   },
            //   {
            //     path: "bulletins",
            //     element: <div>tett</div>,
            //   },
            //   {
            //     path: "horraires",
            //     element: <div>horraires</div>,
            //   },
            // ],
          },
          //   {
          //     path: DETAILS_SUIVI_BAKELISTES_SYLLABUS_PATH,
          //     breadcrumb: "Syllabus du bakeliste",
          //     element: <Outlet />,
          //     children: [
          //       {
          //         path: "",
          //         element: <ListeSyllabusPage />,
          //       },
          //     ],
          //   },
        ],
      },
    ],
  },
  /** ****************************************
   * !BAKELISTES PATHS
   * *****************************************
   */
  {
    path: BAKELISTES_PATH,
    element: <BakelisteHomePage />,
    children: [
      {
        path: "",
        element: <BakelisteHomePageDashboard />,
      },
      {
        path: ADD_BAKELISTE_PATH,
        element: <AjouterBakelistePage />,
      },
      {
        path: LISTE_BAKELISTES_PATH,
        element: <ListeBakelistesPage />,
      },
      {
        path: BAKELISTES_ACTIFS_PATH,
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <BakelistesActifPage />
          },
          {
            path: DETAILS_BAKELISTE_PATH,
            element: <Outlet />,
            children: [
              {
                breadcrumb: "Détails",
                path: "",
                element: <DetailsBakelistePage />,
                // children: [
                //   {
                //     path: "",
                //     element: <Outlet />,
                //     children: [
                //       {
                //         path: "",
                //         element: <ListeSyllabusPage />,
                //       },
                //       {
                //         path: DETAILS_SUIVI_BAKELISTES_SYLLABUS_DETAILS_PATH,
                //         breadcrumb: "Détails syllabus suivi bakeliste",
                //         element: <DetailsSyllabusPage />,
                //       },
                //     ],
                //   },
                //   {
                //     path: "bulletins",
                //     breadcrumb: " ",
                //     element: <Outlet />,
                //     children: [
                //       {
                //         path: "",
                //         element: <SuiviListeTrimestrePage />,
                //       },
                //       {
                //         path: DETAILS_SUIVI_BAKELISTES_TRIMESTRE_DETAILS_PATH,
                //         breadcrumb: "Détails trimestre",
                //         element: <BulletinDetailsPage />,
                //       },
                //     ],
                //   },
                //   {
                //     path: "pointages",
                //     breadcrumb: " ",
                //     element: <ListePointages />,
                //   },
                //   {
                //     path: "livraisons",
                //     breadcrumb: " ",
                //     element: <ListeLivraisons />,
                //   },
                // ],
              },
              {
                path: DETAILS_SUIVI_BAKELISTES_SYLLABUS_DETAILS_PATH,
                breadcrumb: "Détails syllabus suivi bakeliste",
                element: <DetailsSyllabusPage />,
              },
              {
                path: EDIT_BAKELISTE_PATH,
                element: <UpdateBakelistePage />,
              },
              // { ...DETAILS_SUIVI_BAKELISTES_OUTLETS }
            ]
          },
          {
            path: EDIT_BAKELISTE_PATH,
            element: <UpdateBakelistePage />,
          },
        ]
      },
      {
        path: BAKELISTES_INACTIFS_PATH,
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <BakelistesNonActifPage />
          },
          {
            path: DETAILS_BAKELISTE_PATH,
            element: <Outlet />,
            children: [
              {
                breadcrumb: "Détails",
                path: "",
                element: <DetailsBakelistePage />,
              },
              {
                path: EDIT_BAKELISTE_PATH,
                element: <UpdateBakelistePage />,
              },
              { ...DETAILS_SUIVI_BAKELISTES_OUTLETS }
            ]
          },
          {
            path: EDIT_BAKELISTE_PATH,
            element: <UpdateBakelistePage />,
          },
        ]
      },
      {
        path: BAKELISTES_EN_PRESENTIEL_PATH,
        element: <Outlet />,
        children: [
          {
            breadcrumb: "Présentiel",
            path: "",
            element: <ListeBakelistesPresentielPage />
          },
          {
            path: DETAILS_BAKELISTE_PATH,
            element: <Outlet />,
            children: [
              {
                breadcrumb: "Détails",
                path: "",
                element: <DetailsBakelistePage />,
              },
              {
                path: EDIT_BAKELISTE_PATH,
                element: <UpdateBakelistePage />,
              },
            ]
          },
          {
            path: EDIT_BAKELISTE_PATH,
            element: <UpdateBakelistePage />,
          },
        ]
      },
      {
        path: BAKELISTES_EN_LIGNE_PATH,
        element: <Outlet />,
        children: [
          {
            breadcrumb: "En ligne",
            path: "",
            element: <ListeBakelistesEnLignePage />
          },
          {
            path: DETAILS_BAKELISTE_PATH,
            element: <Outlet />,
            children: [
              {
                breadcrumb: "Détails",
                path: "",
                element: <DetailsBakelistePage />,
              },
              {
                path: EDIT_BAKELISTE_PATH,
                element: <UpdateBakelistePage />,
              },
            ]
          },
          {
            path: EDIT_BAKELISTE_PATH,
            element: <UpdateBakelistePage />,
          },
        ]
      },
      {
        path: BAKELISTES_EN_PAUSE_PATH,
        element: <ListeBakelisteEnPausePage />,
      },
      {
        path: BAKELISTES_BLOQUES_PATH,
        element: <ListeBakelistesBloques />,
      },
      {
        path: BAKELISTES_ARCHIVES_PATH,
        element: <Outlet />,
        children: [
          {
            breadcrumb: "Bakelistes archivés",
            path: "",
            element: <ListeBakelistesArchivesPage />
          },
          {
            path: DETAILS_BAKELISTE_PATH,
            element: <Outlet />,
            children: [
              {
                breadcrumb: "Détails",
                path: "",
                element: <DetailsBakelistePage />,
              },
              {
                path: EDIT_BAKELISTE_PATH,
                element: <UpdateBakelistePage />,
              },
              { ...DETAILS_SUIVI_BAKELISTES_OUTLETS }
            ]
          },
        ]
      },
      {
        path: BAKELISTES_EN_STAGE_PATH,
        element: <ListeBakelistesEnStage />,
      },
      {
        path: BAKELISTES_TERMINES_PATH,
        element: <Outlet />,
        children: [
          {
            breadcrumb: "Terminés",
            path: "",
            element: <ListeBakelistesTerminesPage />
          },
          {
            path: DETAILS_BAKELISTE_PATH,
            element: <Outlet />,
            children: [
              {
                breadcrumb: "Détails",
                path: "",
                element: <DetailsBakelistePage />,
              },
              {
                path: EDIT_BAKELISTE_PATH,
                element: <UpdateBakelistePage />,
              },
              { ...DETAILS_SUIVI_BAKELISTES_OUTLETS }
            ]
          },
        ]
      },
      {
        breadcrumb: "Détails",
        path: DETAILS_BAKELISTE_PATH,
        element: <DetailsBakelistePage />,
      },
      {
        path: "",
        element: <SuiviBakelistePage />,
      },
      { ...DETAILS_SUIVI_BAKELISTES_OUTLETS },
      {
        path: DETAILS_SUIVI_COACHING_PATH,
        element: <Outlet />,
        children: [
          {
            path: "",
            breadcrumb: "Détails suivi coach",
            element: <DetailsCoachSuiviPage />,
          },
          {
            path: COACH_CURRENT_BAKELISTES_STATUS_PATH,
            element: <CoachBakelistesCurrentStatus />,
          },
          {
            path: COACH_COMMENTS_PATH,
            element: <CoachCommentsPage />,
          },
          {
            path: COACH_VALIDATIONS_PATH,
            element: <CoachValidationsPage />,
          },
        ],
      },
    ],
  },
  {
    path: BAKELISTES_EN_STAGE_PATH,
    element: <ListeBakelisteEnStagePage />,
  },
  {
    path: STAGIAIRES_ARCHIVES_PATH,
    element: <StagiaireArchivesPage />,
  },
  {
    path: BAKELISTES_RECRUTABLES_PATH,
    element: <ListeBakelisteRecrutable />,
  },
  /** ****************************************
   * !PARTENAIRES PATHS
   * *****************************************
   */
  {
    path: PARTENAIRES_PATH,
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <PartenairePage />,
      },
      {
        path: ":name",
        breadcrumb: "Listes des programmes",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <ProgrammesDunPartenaire />,
          },
          {
            path: PARTICIPANTS_PATH,
            element: <ListeParticipantsPages />,
          },
        ],
      },
    ],
  },
  /** ****************************************
   * !SYLLABUS PATHS
   * *****************************************
   */
  {
    path: SYLLABUS_PATH,
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <SyllabusPage />,
      },
      {
        path: ":name",
        breadcrumb: "Détails du syllabus",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <DetailsDunSyllabus />,
          },
          // {
          //   path: PARTICIPANTS_PATH,
          //   element: <ListeParticipantsPages />,
          // },
        ],
      },
    ],
  },
  /** ****************************************
   * !PROSPECTS PATHS
   * *****************************************
   */
  {
    path: PROSPECTS_PATH,
    element: <Outlet />,
    children: [
      {
        path: "",
        breadcrumb: "Prospects par région",
        element: <ProspectsParRegionDashboardPage />,
      },
      {
        path: PROSPECTS_PAR_REGION_PATH,
        element: <Outlet />,
        children: [
          {
          path: "",
          element: <ProspectsParRegionPage />,
          },
          {
          path: ":lastName",
          breadcrumb: "Détails d'un prospect",
          element: <DetailsDunProspect />,
          },
        ],
      },
    ],
  },
  /** ****************************************
   * !PAIEMENTS PATHS
   * *****************************************
   */
  {
    path: PAIEMENTS_PATH,
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <PaiementsPage />,
      },
      {
        path: LISTE_PAIEMENTS_PATH,
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <ListePaiementPage />,
          },
          {
            path: ":bakelisteId",
            breadcrumb: "Détails paiements",
            element: <DetailsPaiementsPage />,
          },
        ],
      },
    ],
    // PAIEMENT_PATH
  },
  /** ****************************************
   * !EVENEMENTS PATHS
   * *****************************************
   */
  {
    path: EVENEMENTS_PATH,
    element: <EvenementsHomePage />,
    children: [
      {
        path: "",
        breadcrumb: "Evénements",
        element: <ListeEvenementsPage />,
      },
      {
        path: AJOUT_EVENEMENT_PATH,
        element: <AjoutEvenementPage />,
      },
      {
        path: DETAILS_EVENEMENT_PATH,
        element: <DetailsEvenementPage />,
      },
    ],
  },
  /** ****************************************
   * !EXAMENS PATHS
   * *****************************************
   */
  {
    path: EXAMENS_PATH,
    element: <Outlet />,
    children: [
      {
        path: "",
        breadcrumb: "Années académiques",
        element: <ListeAnneePage />,
      },
      {
        path: EXAMENS_TRIMESTRE_PATH,
        breadcrumb: "Liste des trimestres",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <ListeTrimestrePage />,
          },
          {
            path: TRIMESTRE_DOSSIERS_PATH,
            element: <DossierTrimestreHomePage />,
            children: [
              {
                path: "",
                element: <DossiersTrimestre />,
              },
              /** ****************************************
               * !EPREUVES PATHS
               * *****************************************
               */
              {
                path: TYPES_EPREUVES_PATH,
                breadcrumb: "Type d'épreuves",
                element: <TypeEpreuvesHomePage />,
                children: [
                  {
                    path: "",
                    element: <TypeEpreuvesPage />,
                  },
                  {
                    path: EPREUVES_THEORIQUES_PATH,
                    element: <EpreuvesTheoriquesHomePage />,
                    children: [
                      {
                        path: "",
                        breadcrumb: "Théoriques",
                        element: <EpreuvesTheoriquesDomainesPage />,
                      },
                      {
                        path: EPREUVES_THEORIQUES_LISTE_PATH,
                        breadcrumb: "Epreuves théoriques",
                        element: <ListeEpreuvesTheoriques />,
                      },
                    ],
                  },
                  {
                    path: EPREUVES_PRATIQUES_PATH,
                    element: <EpreuvesPratiquesHomePage />,
                    children: [
                      {
                        path: "",
                        breadcrumb: "Pratiques",
                        element: <EpreuvesPratiquesDomainesPage />,
                      },
                      {
                        path: EPREUVES_PRATIQUES_LISTE_PATH,
                        breadcrumb: "Epreuves pratiques",
                        element: <ListeEpreuvesPratiques />,
                      },
                    ],
                  },

                  // ! ***********************
                  {
                    path: LISTE_EPREUVES_PATH,
                    // breadcrumb: "Liste des examens",
                    element: <ListeEpreuvesHomePage />,
                    children: [
                      // {
                      //   path: '',
                      //   breadcrumb: "Liste des épreuves",
                      //   element: <ListeEpreuvesPage />,
                      // },
                      {
                        path: EPREUVES_THEORIQUES_PATH,
                        breadcrumb: "Epreuves théoriques",
                      },
                      {
                        path: LISTE_BULLETINS_PATH,
                        breadcrumb: "Liste des bulletins",
                        element: <ListeBulletins />,
                      },
                    ],
                  },
                ],
              },
              /** ****************************************
               * BULLETINS PATHS
               * ******************************************
               */
              {
                path: BULLETINS_DOMAINES_PATH,
                element: <Outlet />,
                children: [
                  {
                    path: "",
                    element: <BulletinsDomainesPage />,
                  },
                  {
                    path: LISTE_BULLETINS_PATH,
                    element: <ListeBulletins />,
                  },
                ],
              },
            ],
          },
          {
            path: LISTE_EPREUVES_PATH,
            // breadcrumb: "Liste des examens",
            element: <ListeEpreuvesHomePage />,
            children: [
              {
                path: "",
                breadcrumb: "Liste des épreuves",
                element: <ListeEpreuvesPage />,
              },
              {
                path: LISTE_BULLETINS_PATH,
                breadcrumb: "Liste des bulletins",
                element: <ListeBulletins />,
              },
            ],
          },
          // {
          //   path: LISTE_BULLETINS_PATH,
          //   breadcrumb: "Liste des bulletins",
          //   element: <ListeBulletins />,
          // },
        ],
      },
    ],
  },

  /** ****************************************
   * !HISTORIQUES PATHS
   * *****************************************
   */
  {
    path: HISTORY_PATH,
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <HistoriquesPage />,
      },
      {
        path: BAKELISTES_HISTORY_PATH,
        element: <HistoriquesBakelistesPage />,
      },
      {
        path: COACH_ASSISTANT_HISTORY_PATH,
        element: <HistoriquesCoachsAssitantsPage />,
      },
    ],
  },

  /** ****************************************
   * EMPLOYES PATHS
   * *****************************************
   */
  {
    path: EMPLOYES_PATH,
    breadcrumb: "Employés",
    element: <EmployesPage />,
  },

  /** ****************************************
 * !EMPLOYES PATHS
 * *****************************************
 */
  {
    path: PARAMETRES_PATH,
    breadcrumb: "Paramètres",
    element: <ParametresPage />,
  },

  // ParametresPage
];
