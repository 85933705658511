import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { ApiController } from "redux/apiController";
import MultiStepForm from "./multiStep/MultiStepForm";
import BakelisteInfosApercu from "./bakelisteInfosApercu/BakelisteInfosApercu";
import "./ajouterBakeliste.css"
import { useSelector } from "react-redux";
import { Title2 } from "components/shared/TableListeView";
import { useCurrentUser } from "hooks/useCurrentUser"
// import './ajouterBakelisteminified.css'

const AjouterBakelistePage = () => {
  const { currentProspect } = useSelector((state) => state.prospects)
  const days = ["lundi", "mardi", "mercredi", "jeudi", "vendredi"];
  const [page, setPage] = useState(0);
  const [apercu, setApercu] = useState(false);
  const { currentUser } = useCurrentUser();
  const [bakelisteInfos, setBakelisteInfos] = useState({
    civility: "",
    marital_status: "",
    first_name: currentProspect?.firstName,
    last_name: currentProspect?.lastName,
    email: currentProspect?.email,
    phone: currentProspect?.phone,
    telephone: currentProspect?.phone,
    date_naissance: "",
    address: "",
    ecole: "",
    lieu_naissance: "",
    // !informations sur la formation
    type_presence_id: null,
    jours_presence: [],
    type_formation: "",
    is_pro: 0,
    is_presentiel: 0,
    is_online: 0,
    is_etudiant: 0,
    is_etudiant_a_tester: 0,
    is_week: 0,
    is_weekend: 0,
    nbre_jours: 0,
    // horaire: null,
    // jour_1: "",
    // jour_2: "",
    // jour_3: "",
    // jour_4: "",
    // jour_5: "",
    // jour_6: "",
    // jour_fd: "",
    domaines_id: null,
    is_formation_payante: null,
    paiement_inscription: null,
    is_stagede_formation: null,
    ville: "",
    lieu_formation: "",
    bakeliste_level: "",
    training_duration: "",
    partenariat: 0,
    programme_id: "",
    // !autres infos
    debut_formation: "",
    fin_formation: "",
    objectifs: "",
    commentaire: "",
    demi_boursier: 0,
    is_isa_contract: 0,
    coach_id: null,
    coach_assistant_id: null,
    created_by: currentUser.id,
  });

  // onFID(console.log);

  useEffect(() => {
    ApiController.domaines.getDomaines()
    ApiController.coachs.getCoachs()
    ApiController.coachs.getCoachsAssistants()
  }, []);
  return (
    <section
      className="mx-auto"
      style={{
        maxWidth: "1300px",
        minHeight: "70vh",
        padding: "2rem 0 0 0",
        margin: "0rem 0 10rem 0",
      }}
    >
      <Title2>Nouveau bakeliste</Title2>

      {!apercu ? (
        <MultiStepForm
          apercu={apercu}
          setApercu={setApercu}
          days={days}
          page={page}
          setPage={setPage}
          bakelisteInfos={bakelisteInfos}
          setBakelisteInfos={setBakelisteInfos}
        />
      ) : (
        <BakelisteInfosApercu
          route="add-bakeliste"
          requestMethod="post"
          bakelisteInfos={bakelisteInfos}
          setApercu={setApercu}
          setPage={setPage}
        />
      )}
    </section>
  );
};

export default AjouterBakelistePage;
