import { rootStyles } from "assets/css/globalCss"
import ButtonComp from "components/ButtonComp"
import InputForm from "components/shared/InputForm"
import SelectForm from "components/shared/SelectForm"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import { ApiController } from "redux/apiController"

const StagiaireForm = ({ defaultValue, onSubmit, isSubmiting }) => {
    const { allDomainesList } = useSelector((state) => state.domaines);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        values: {
            ...defaultValue,
            domaine_id: defaultValue?.bakeliste_domaine_id,
            debut_stage: defaultValue?.debut_formation,
            fin_stage: defaultValue?.fin_formation
        },
        defaultValues: {
            ...defaultValue,
            domaine_id: defaultValue?.bakeliste_domaine_id,
            debut_stage: defaultValue?.debut_formation,
            fin_stage: defaultValue?.fin_formation
        }
    });

    useEffect(() => {
        ApiController.domaines.getDomaines();
    }, []);

    console.log({ defaultValue });
    return (
        <div>
            <form className="py-2" onSubmit={handleSubmit(onSubmit)}>
                <div className="d-md-flex gap-2">
                    <InputForm
                        min={3}
                        max={50}
                        label="Prénom"
                        type="text"
                        htmlFor="first_name"
                        register={{
                            ...register("first_name", {
                                required: "Prenom obligatoire",
                            }),
                        }}
                        error={errors.first_name?.message}
                    />
                    <InputForm
                        min={3}
                        max={50}
                        label="Nom"
                        type="text"
                        htmlFor="last_name"
                        register={{
                            ...register("last_name", {
                                required: "Nom obligatoire",
                            }),
                        }}
                        error={errors.last_name?.message}
                    />
                </div>
                <div className="d-md-flex gap-2">
                    <InputForm
                        min={3}
                        max={50}
                        label="Téléphone"
                        type="tel"
                        htmlFor="phone"
                        register={{
                            ...register("phone", {
                                required: "Nom obligatoire",
                            }),
                        }}
                        error={errors.phone?.message}
                    />
                    <InputForm
                        min={3}
                        max={50}
                        label="Email"
                        type="mail"
                        htmlFor="email"
                        register={{
                            ...register("email", {
                                required: "Nom obligatoire",
                            }),
                        }}
                        error={errors.email?.message}
                    />
                </div>
                <InputForm
                    min={3}
                    max={50}
                    label="Adresse"
                    type="text"
                    htmlFor="address"
                    register={{
                        ...register("address", {
                            required: "Nom obligatoire",
                        }),
                    }}
                    error={errors.address?.message}
                />
                <SelectForm
                    label="Domaine"
                    register={{
                        ...register("domaine_id", {
                            required: "Domaine obligatoire",
                        }),
                    }}
                    error={errors.domaine_id?.message}
                >
                    <option value="">Sélectionner</option>
                    {allDomainesList &&
                        allDomainesList.map((domaine) => (
                            <option key={domaine.id} value={domaine.id}>{domaine.name}</option>
                        ))}
                </SelectForm>
                <div className="d-md-flex gap-2">
                    <InputForm
                        label="Début stage"
                        type="date"
                        htmlFor="debut_stage"
                        register={{
                            ...register("debut_stage", {
                                required: "Debut stage obligatoire",
                            }),
                        }}
                        error={errors.debut_stage?.message}
                    />
                    <InputForm
                        label="Fin stage"
                        type="date"
                        htmlFor="fin_stage"
                        register={{
                            ...register("fin_stage", {
                                required: "Fin stage obligatoire",
                            }),
                        }}
                        error={errors.fin_stage?.message}
                    />
                </div>
                <div className="d-md-flex gap-2">
                    <SelectForm
                        label="Civilité"
                        register={{
                            ...register("civility", {
                                required: "Civilité obligatoire",
                            }),
                        }}
                        error={errors.civility?.message}
                    >
                        <option value="">Sélectionner</option>
                        <option value="madame">Madame</option>
                        <option value="monsieur">Monsieur</option>
                    </SelectForm>
                    <SelectForm
                        label="Entité"
                        register={{
                            ...register("entite", {
                                required: "Entité obligatoire",
                            }),
                        }}
                        error={errors.entite?.message}
                    >
                        <option value="">Sélectionner</option>
                        <option value="RED Team">RED Team</option>
                        <option value="Bakeli">Bakeli</option>
                        <option value="Volkeno">Volkeno</option>
                    </SelectForm>
                </div>
                <div className="mt-0">
                    <ButtonComp
                        loading={isSubmiting}
                        title="Enregistrer"
                        bgColor={rootStyles.color.secondary}
                        color={rootStyles.color.primary}
                        width={"100%"}
                    />
                </div>
            </form>
        </div>
    )
}

export default StagiaireForm