import Modal from "components/Modal"
import React from "react"
import SpaceForm from "./SpaceForm"
import { SpaceProvider } from "./AddSpaceProvider"
import { useSpace } from "./useSpace"

const SpaceContainer = ({ isAddModal, closeAddModal }) => {
  const { handleAddSpace, isAddingSpace } = useSpace({ closeSpaceModal: closeAddModal })
  return (
    <div>
      <Modal
        opened={isAddModal}
        onClosed={closeAddModal}
        title={"Nouveau space"}
      >
        {/* <SpaceProvider initialValues={initialValues}> */}
        <SpaceForm isFormSubmitting={isAddingSpace} onSubmit={handleAddSpace} />
        {/* </SpaceProvider> */}
      </Modal>
    </div>
  )
}

const AddSpaceModal = ({ isAddModal, closeAddModal, initialValues }) => {
  return <SpaceProvider initialValues={initialValues}>
    <SpaceContainer isAddModal={isAddModal} closeAddModal={closeAddModal} />
  </SpaceProvider>
}

export default AddSpaceModal