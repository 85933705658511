/* eslint-disable operator-linebreak */
import { rootStyles } from "assets/css/globalCss";
import ButtonComp from "components/ButtonComp";
import FadeTransition from "components/shared/FadeTransition";
import InputForm from "components/shared/InputForm";
import SelectForm from "components/shared/SelectForm";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ApiController } from "redux/apiController";
import styled from "styled-components";
import { useUpdateBakelisteMutation } from "./api/mutations";
import { useGetBakelisteById } from "./api/hooks/useGetBakelisteById";
import Loader from "components/shared/Loader";
import ErrorBakeliste from "components/error/ErrorBakeliste";
import Flex from "components/shared/Flex";
import Checkbox from "components/shared/Checkbox";
import { useJoursFormations } from "actions/jours/queries";
import { useTypePresence } from "actions/typePresence";
import useCoachAssistants from "hooks/useCoachAssistants";
import useCoachs from "hooks/useCoachs";
import { VILLE_FORMATION } from "constants";
import { CAMPUS_VILLES } from "constants";
import { useProgrammes } from "pages/partenaires/api/hooks/useProgrammes";
import { Title2 } from "components/shared/TableListeView";
import { useSpaces } from "pages/parametres/Spaces/useSpaces";

const Container = styled.div`
  background-color: ${rootStyles.color.primary};
  border-radius: ${rootStyles.radius.card};
  min-height: 400px;
  padding: ${rootStyles.padding.btnPadding};
`;

const lieux_formations = [
  {
    value: "Grand Yoff 1",
    label: "Grand Yoff 1",
  },
  {
    value: "Grand Yoff 2",
    label: "Grand Yoff 2",
  },
  {
    value: "Thies",
    label: VILLE_FORMATION.thies,
  },
  {
    value: "Mbour",
    label: VILLE_FORMATION.mbour,
  },
  {
    value: "Labé",
    label: "Labé (Guinée)",
  },
  {
    value: "Conakry",
    label: "Conakry(Guinée)",
  },
];
// const villes = [
//   {
//     value: "Dakar",
//     label: "Dakar",
//   },
//   {
//     value: "Thiès",
//     label: "Thiès",
//   },
//   {
//     value: "Mbour",
//     label: "Mbour",
//   },
//   {
//     value: "Labé",
//     label: "Labé",
//   },
// ];
const UpdateBakelistePage = () => {
  const { allProgrammes } = useProgrammes();
  const { spaces, isLoading: isSpacesLoading } = useSpaces();
  const { data: coachsAssistantsList = [] } = useCoachAssistants();
  const { data: coachsList = [] } = useCoachs();
  const { allDomainesList = [] } = useSelector((state) => state.domaines);
  const { currentBakeliste } = useSelector((state) => state.bakelistes);
  const {
    data: bakeliste = { data: [], formation_days1: [] },
    isLoading,
    isError,
  } = useGetBakelisteById(currentBakeliste.id);
  const bakelisteJoursFormation = bakeliste.formation_days1.length
    ? bakeliste.formation_days1.map((day) => day.id)
    : [5];
  // console.log({ bakeliste });
  const [jours_presence, setJours_presence] = useState([]);
  const {
    joursFormation = [],
    // isError, isLoading
  } = useJoursFormations();
  const {
    typePresence,
    // isError: isTypePresenceError, isLoading: isTypePresenceLoading
  } = useTypePresence();
  // const formationDays = data.formations_days
  const {
    register,
    handleSubmit,
    formState: { errors },
    // getValues
  } = useForm({
    defaultValues: {
      ...currentBakeliste,
      domaines_id: currentBakeliste?.domaine?.id,
      type_presence_id: bakeliste.data[0]?.type_presence?.id,
      coach_id: bakeliste.data[0]?.coach_id,
      coach_assistant_id: bakeliste.data[0]?.coach_assistant_id,
      space_id: bakeliste.data[0]?.space_id,
    },
    values: {
      ...currentBakeliste,
      domaines_id: currentBakeliste?.domaine?.id,
      type_presence_id: bakeliste.data[0]?.type_presence?.id,
      coach_id: bakeliste.data[0]?.coach_id,
      coach_assistant_id: bakeliste.data[0]?.coach_assistant_id,
      space_id: bakeliste.data[0]?.space_id,
    },
  });
  const [updating, setUpdating] = useState(false);
  const navigate = useNavigate();

  /* Update mutation */
  // console.log({ bakeliste });

  const [updateBakeliste] = useUpdateBakelisteMutation();

  useEffect(() => {
    setJours_presence(bakelisteJoursFormation);
  }, [bakelisteJoursFormation.length]);

  useEffect(() => {
    ApiController.domaines.getDomaines();
  }, []);

  console.log({ bakeliste });

  const onSubmit = (data) => {
    setUpdating(true);
    const dataToUpdate = {
      type_presence_id: Number(data.type_presence_id),
      jours_presence,
      first_name: data.first_name,
      last_name: data.last_name,
      bakeliste_domaine_id: data.domaines_id,
      ecole: data.ecole,
      phone: data.phone,
      space_id: Number(data.space_id),
      date_naissance: data.date_naissance,
      lieu_naissance: data.lieu_naissance,
      address: data.address,
      debut_formation: data.debut_formation,
      fin_formation: data.fin_formation,
      lieu_formation: data.lieu_formation,
      ville: data.ville,
      email: data.email,
      paiement_inscription: data.paiement_inscription,
      is_stagede_formation: data.is_stagede_formation,
      is_isa_contract: data.is_isa_contract,
      is_formation_payante: data.is_formation_payante,
      demi_boursier: data.demi_boursier,
      boursier: data.boursier,
      coach_assistant_id: Number(data.coach_assistant_id),
      coach_id: Number(data.coach_id),
      partenariat: !!data.programme_id,
      programme_id: data.programme_id,
    };
    updateBakeliste({ data: dataToUpdate, bakelisteId: currentBakeliste?.id })
      .unwrap()
      .then(() => {
        toast.success(currentBakeliste.first_name + " a été modifié");
        navigate(-1);
      })
      .catch(() => {
        toast.error("Une erreur est survenue");
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  // console.log({ currentBakeliste, bakeliste, joursFormation, jours_presence });
  // console.log({ typePresence });

  return (
    <FadeTransition in={true}>
      <Container>
        {isError ? (
          <ErrorBakeliste />
        ) : isLoading ? (
          <Loader />
        ) : (
          <div className="container p-0">
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Informations personnelles */}
              <div className="p-0">
                <Title2>Informations personnelles</Title2>
                <div className="row p-0">
                  <div className="col-sm-6">
                    <InputForm
                      label="Prénom"
                      type="text"
                      htmlFor="prenom"
                      register={{
                        ...register("first_name", {
                          required: "Prénom obligatoire",
                        }),
                      }}
                      error={errors.first_name?.message}
                    />
                  </div>
                  <div className="col-sm-6">
                    <InputForm
                      label="Nom"
                      type="text"
                      htmlFor="last_name"
                      register={{
                        ...register("last_name", {
                          required: "Nom obligatoire",
                        }),
                      }}
                      error={errors.last_name?.message}
                    />
                  </div>
                  <div className="col-sm-6">
                    <InputForm
                      label="Date de naissance"
                      type="date"
                      htmlFor="date_naissance"
                      register={{
                        ...register("date_naissance", {
                          required: "Date de naissance obligatoire",
                        }),
                      }}
                      error={errors.date_naissance?.message}
                    />
                  </div>
                  <div className="col-sm-6">
                    <InputForm
                      label="Lieu de naissance"
                      type="text"
                      htmlFor="lieu_naissance"
                      register={{ ...register("lieu_naissance") }}
                      error={errors.lieu_naissance?.message}
                    />
                  </div>
                  <div className="col-sm-6">
                    <InputForm
                      label="Email"
                      type="email"
                      htmlFor="email"
                      register={{
                        ...register("email", { required: "Email obligatoire" }),
                      }}
                      error={errors.email?.message}
                    />
                  </div>
                  <div className="col-sm-6">
                    <InputForm
                      label="Téléphone"
                      type="tel"
                      htmlFor="phone"
                      register={{
                        ...register("phone", {
                          required: "Téléphone obligatoire",
                        }),
                      }}
                      error={errors.phone}
                    />
                  </div>
                  <div className="col-sm-6">
                    <InputForm
                      label="Adresse"
                      type="text"
                      htmlFor="address"
                      register={{
                        ...register("address", {
                          required: "Adresse obligatoire",
                        }),
                      }}
                      error={errors.address?.message}
                    />
                  </div>
                  <div className="col-sm-6">
                    <InputForm
                      label="Ecole"
                      type="text"
                      htmlFor="ecole"
                      register={{
                        ...register("ecole", { required: "Ecole obligatoire" }),
                      }}
                      error={errors.ecole?.message}
                    />
                  </div>
                </div>
              </div>
              {/* Formation */}
              <div className="row p-0 mt-5">
                <Title2>Formation</Title2>
                <div className="col-sm-6">
                  <SelectForm
                    // label={"Horaire"}
                    register={{
                      ...register("type_presence_id", {
                        required: "Horaire obligatoire",
                      }),
                    }}
                    error={errors.type_presence_id?.message}
                    className="p-2 animate__animated animate__fadeIn"
                  >
                    {/* <option value=""> -- Horaire --</option> */}
                    {[...typePresence, { id: "", libelle: "--Horaire--" }]
                      .reverse()
                      .map((presence) => {
                        // console.log({ presence });
                        return (
                          <option value={presence.id} key={presence.id}>
                            {presence.libelle}
                            {presence.heure_debut} - {presence.heure_fin}
                          </option>
                        );
                      })}
                  </SelectForm>
                </div>
                <div className="col-sm-6 d-flex align-end">
                  <Flex align={"center"} gap={20}>
                    {joursFormation
                      .filter((jour) => jour.id !== 6 && jour.id !== 7)
                      .map((jour) => {
                        return (
                          <Checkbox
                            key={jour.id}
                            disabled={jour.id === 5}
                            checked={jours_presence.includes(jour.id)}
                            onChange={(checked) => {
                              if (!checked) {
                                return setJours_presence((prev) => {
                                  return prev.filter((day) => day !== jour.id);
                                });
                              }
                              setJours_presence((prev) => [...prev, jour.id]);
                            }}
                            label={jour.name}
                          />
                        );
                      })}
                  </Flex>
                </div>
                <div className="col-sm-6">
                  <InputForm
                    label="Date début"
                    type="date"
                    htmlFor="debut_formation"
                    register={{
                      ...register("debut_formation", {
                        required: "Date début obligatoire",
                      }),
                    }}
                    error={errors.ecole?.message}
                  />
                </div>
                <div className="col-sm-6">
                  <InputForm
                    label="Date fin"
                    type="date"
                    htmlFor="fin_formation"
                    register={{
                      ...register("fin_formation", {
                        required: "Date fin obligatoire",
                      }),
                    }}
                    error={errors.ecole?.message}
                  />
                </div>
                <div className="col-6">
                  <SelectForm
                    register={{ ...register("programme_id") }}
                    label={"Partenanire"}
                  >
                    {[...allProgrammes.data, { id: "", name: "--Partenaire--" }]
                      ?.reverse()
                      ?.map((programme) => (
                        <option key={programme.id} value={programme.id}>
                          {programme.name}
                        </option>
                      ))}
                  </SelectForm>
                </div>
                <div className="col-sm-6">
                  <SelectForm
                    label="Domaine de formation"
                    register={{
                      ...register("domaines_id", {
                        required: "Domaine de formation obligatoire",
                      }),
                    }}
                    error={errors.domaines_id?.message}
                  >
                    {[
                      ...allDomainesList,
                      { id: "", name: "--Veuillez choisir le domaine--" },
                    ]
                      .reverse()
                      .map((domaine) => {
                        return (
                          <option key={domaine.id} value={domaine.id}>
                            {domaine.name}
                          </option>
                        );
                      })}
                  </SelectForm>
                </div>
                <div className="col-sm-6">
                  <SelectForm
                    label="Space de formation"
                    register={{
                      ...register("space_id", {
                        required: "Space de formation obligatoire",
                      }),
                    }}
                    error={errors.space_id?.message}
                  >
                    {isSpacesLoading ? (
                      <option>En cours de chargement...</option>
                    ) : (
                      [
                        ...spaces?.spaces,
                        { id: "", name_space: "--Veuillez choisir le space--" },
                      ]
                        ?.reverse()
                        ?.map((space) => {
                          return (
                            <option key={space.id} value={space.id}>
                              {space.name_space}
                            </option>
                          );
                        })
                    )}
                  </SelectForm>
                </div>
              </div>
              {/* coaching */}
              <div className="row p-0 mt-5">
                <Title2>Coaching</Title2>
                <div className="col-6">
                  <SelectForm
                    register={{
                      ...register("coach_id", {
                        required: "Veuillez choir son coach",
                      }),
                    }}
                    error={errors.coach_id?.message}
                    label={"Coach"}
                  >
                    {[
                      ...coachsList,
                      { id: "", first_name: "--Veuillez choisir le coach--" },
                    ]
                      ?.reverse()
                      ?.map((coach) => (
                        <option
                          selected={coach.id === bakeliste.data[0].coach_id}
                          key={coach.id}
                          value={coach.id}
                        >
                          {coach.first_name}
                        </option>
                      ))}
                  </SelectForm>
                </div>
                <div className="col-6">
                  <SelectForm
                    label={"Coach Assistant"}
                    register={{
                      ...register("coach_assistant_id", {
                        required: "Veuillez choir son coach",
                      }),
                    }}
                    error={errors.coach_assistant_id?.message}
                  >
                    {[
                      ...coachsAssistantsList,
                      {
                        id: "",
                        first_name: "--Veuillez choisir le coach assistant--",
                      },
                    ]
                      ?.reverse()
                      .map((coach) => (
                        <option
                          selected={
                            coach.id === bakeliste.data[0].coach_assistant_id
                          }
                          key={coach.id}
                          value={coach.id}
                        >
                          {coach.first_name}
                        </option>
                      ))}
                  </SelectForm>
                </div>
                <div className="col-sm-6">
                  <SelectForm
                    label="Ville de formation"
                    register={{
                      ...register("ville", {
                        required: "Ville de formation obligatoire",
                      }),
                    }}
                    error={errors.ville?.message}
                  >
                    {[...CAMPUS_VILLES, { value: "", label: "--Ville--" }]
                      ?.reverse()
                      .map((ville) => (
                        <option
                          selected={
                            ville.id === bakeliste.data[0].lieu_formation
                          }
                          key={ville.value}
                          value={ville.value}
                        >
                          {ville.label}
                        </option>
                      ))}
                    {/* <ResourceDataMap
                        resourceData={CAMPUS_VILLES}
                        resourceItem={({ ville }) => (
                          <option value={ville.id}>
                            {ville.label}
                          </option>
                        )}
                        resourceName="ville"
                      /> */}
                  </SelectForm>
                </div>
                <div className="col-sm-6">
                  <SelectForm
                    label="Lieu de formation"
                    register={{
                      ...register("lieu_formation", {
                        required: "Lieu de formation obligatoire",
                      }),
                    }}
                    error={errors.lieu_formation?.message}
                  >
                    {/* <ResourceDataMap
                        resourceData={lieux_formations}
                        resourceItem={({ lieu_formation }) => (
                          <option value={lieu_formation.value}>
                            {lieu_formation.label}
                          </option>
                        )}
                        resourceName="lieu_formation"
                      /> */}
                    {[...lieux_formations, { value: "", label: "--Lieu--" }]
                      ?.reverse()
                      .map((lieu) => (
                        <option
                          selected={
                            lieu.value === bakeliste.data[0].lieu_formation
                          }
                          key={lieu.value}
                          value={lieu.value}
                        >
                          {lieu.label}
                        </option>
                      ))}
                  </SelectForm>
                </div>
              </div>
              <div className="d-flex gap-3 mt-3 justify-content-start">
                <ButtonComp
                  type={"button"}
                  onClick={() => navigate(-1)}
                  width={"150px"}
                  loading={null}
                  title="Annuler"
                  bgColor={rootStyles.color.red}
                  color={rootStyles.color.primary}
                />
                <ButtonComp
                  width={"150px"}
                  loading={updating}
                  title="Modifier"
                  bgColor={rootStyles.color.tercary}
                  color={rootStyles.color.primary}
                />
              </div>
            </form>
          </div>
        )}
      </Container>
    </FadeTransition>
  );
};

export default UpdateBakelistePage;
