import TrimestreCardItem from "components/examens/TrimestreCardItem";
import FadeTransition from "components/shared/FadeTransition";
import GridListe from "components/shared/GridListe";
import { useGetBakelisteBulletins } from "pages/suivi-bakelistes/api/hooks/useGetBakelisteBulletins";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentTrimestreBulletin } from "redux/features/bakelisteSlice";
import { DETAILS_SUIVI_BAKELISTES_TRIMESTRE_DETAILS_PATH } from "routes/navigation/navigationPaths";

const SuiviListeTrimestrePage = () => {
  const { suiviCurrentBakeliste } = useSelector((state) => state.bakelistes);

  const { currentBakelisteBulletins } = useGetBakelisteBulletins(
    suiviCurrentBakeliste.id
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToExamensListe = (currentTrimestre) => {
    dispatch(setCurrentTrimestreBulletin(currentTrimestre));

    return navigate(DETAILS_SUIVI_BAKELISTES_TRIMESTRE_DETAILS_PATH);
  };

  const ClonedTrimestreCardItem = ({ trimestreItem }) =>
    React.cloneElement(<TrimestreCardItem trimestreItem={trimestreItem} />, {
      goToExamensListe,
    });

  return (
    <FadeTransition in={true}>
      <GridListe
        resourceName="trimestreItem"
        resourceData={currentBakelisteBulletins?.data}
        resourceItem={ClonedTrimestreCardItem}
        cardWidth={170}
        emptyMessage="Pas encore de bulletins pour ce bakeliste."
      />
    </FadeTransition>
  );
};

export default SuiviListeTrimestrePage;
