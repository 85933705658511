import React from "react"
import { NavLink } from "react-router-dom";
import { privateRoutes } from "routes/navigation/navigationRoutes";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";


const BreadCrumps = () => {
    const breadcrumbs = useReactRouterBreadcrumbs(privateRoutes);

    // console.log({ breadcrumbs });

    return (
        <div
            // style={{

            // }}
            className='breadcrumb py-0'
        >
            {breadcrumbs.map(({ match, breadcrumb }) => (
                <NavLink
                    className="breadcrump-navlink"
                    key={match.pathname}
                    to={match.pathname}
                >
                    {breadcrumb}
                </NavLink>
            ))}
        </div>
    );
}

export default BreadCrumps