import SelectOnChange from "components/shared/SelectOnChange"
import React, { useEffect, useState } from "react"
import SuiviPointageBakelistesTable from "../Table/SuiviPointageBakelistesTable";
import { rootStyles } from "assets/css/globalCss";
import { regrouperParDomaine } from "utils/groupeByDomaine";
import Flex from "components/shared/Flex";
import Badge from "components/Badge/Badge";
import FadeTransition from "components/shared/FadeTransition";
import { useSearchParams } from "react-router-dom";


const SuiviPointageBakelistesContainer = ({ bakelistesHebdoData, frequence }) => {
  // selectionner le premier jour comme de la semaine: Lundi
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedDay, setSelectedDay] = useState(searchParams.get(`${frequence}`) ?? Object.keys(bakelistesHebdoData)[0]);

  useEffect(() => {
    // On veut garder le filtre meme si on change de tab
    searchParams.set(`${frequence}`, selectedDay)
    setSearchParams(searchParams)
  }, [selectedDay])

  return (
    <FadeTransition>
      <div style={{ position: "sticky", top: 110, backgroundColor: rootStyles.color.primaryWithRgba }}>
        <Flex align={"center"} justify={"space-between"} >
          <div style={{ width: "150px" }}>
            <SelectOnChange onChange={(value) => setSelectedDay(value)}>
              {
                Object.keys(bakelistesHebdoData).map((day, index) => <option value={day} key={index}>{day.toUpperCase()}</option>)
              }
            </SelectOnChange>
          </div>
          <Flex gap={10}>
            {
              Object.entries(regrouperParDomaine(bakelistesHebdoData[selectedDay])).map((domaine, index) => (
                <Badge key={index}>{domaine[0]} ({domaine[1].length})</Badge>
              )
              )
            }
          </Flex>
        </Flex>
      </div>
      <SuiviPointageBakelistesTable title={`Total (${bakelistesHebdoData[selectedDay].length})`} data={bakelistesHebdoData[selectedDay]} />
    </FadeTransition>
  )
}

export default SuiviPointageBakelistesContainer