import { CALENDRIER_PRESENCE_ENDPOINT, PRESENCES_ABSENCES_HEBDO_ENDPOINT } from "routes/api/endpoints";


const { siAdminAPi } = require("redux/rtkquery");

const pointagesApi = siAdminAPi.injectEndpoints({
    endpoints: (build) => ({
        getAllRegionsPointage: build.query({
            query: (params) => {
                return {
                    url: CALENDRIER_PRESENCE_ENDPOINT,
                    params
                }
            },
            providesTags: ["Pointages"],
            keepUnusedDataFor: 10
        }),
        getHebdoPresencesAndAbsences: build.query({
            query: (params) => {
                return {
                    url: PRESENCES_ABSENCES_HEBDO_ENDPOINT,
                    params
                }
            },
            providesTags: ["Pointages"],
            keepUnusedDataFor: 10
        }),
    }),
    overrideExisting: false,
});

export const { useGetAllRegionsPointageQuery, useGetHebdoPresencesAndAbsencesQuery } = pointagesApi