import { siAdminAPi } from "redux/rtkquery"
import { STATISTIQUES_PROSPECTS_PAR_REGION_ENDPOINT } from "routes/api/endpoints"

const prospectsApi = siAdminAPi.injectEndpoints({
    endpoints: (build) => ({
        getProspectsByRegion: build.query({
            query: (params) => {
                console.log({ params })
                return {
                    url: STATISTIQUES_PROSPECTS_PAR_REGION_ENDPOINT,
                    params
                }
            },
            providesTags: ["Prospects"],
            keepUnusedDataFor: 5000
        }),
    })
})

export const { useGetProspectsByRegionQuery } = prospectsApi