import { rootStyles } from "assets/css/globalCss";
import ButtonComp from "components/ButtonComp";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaPlusCircle } from "react-icons/fa";
import ExportExcel from "components/Export/ExportExcel";
import styled from "styled-components";

// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
// import FlexBetween from './FlexBetween'

// const { SearchBar, ClearSearchButton } = Search;

export const Title2 = styled.h2`
  color: ${rootStyles.color.black};
  font-size: ${rootStyles.fonts.h2FontSize};
  font-weight: ${rootStyles.fonts.h2FontWeight};

  @media only screen and (max-width: 500px) {
    font-size: 14px;
  }
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
// const InputSearcheContainer = styled.div`
//     max-width: 250px;
//     margin-left:auto
// `

const TableListeView = ({
  title,
  data = [],
  columns,
  navigateTo,
  navigateToTitle,
  nagigateToAddNewEntity,
  tableLength,
  striped = true,
  wrapperClasses = "",
  bordered = true,
  icon
}) => {
  return (
    <div>
      <HeaderContainer>
        {title && (
          <Title2>
            {title} {tableLength}
          </Title2>
        )}
        {/* {navigateTo && (
          <ButtonComp
            icon={icon ?? {
              name: FaArchive,
            }}
            title={navigateToTitle}
            onClick={navigateTo}
            // bgColor={rootStyles.color.tercary}
            borderColor={rootStyles.color.tercary}
            color={rootStyles.color.tercary}
          />
        )} */}
         <ExportExcel fileName={title} data={data} />
      </HeaderContainer>
      <div className="mt-4">
        {nagigateToAddNewEntity && (
          <div className="mb-3 d-flex justify-content-end">
            <ButtonComp
              icon={{
                name: FaPlusCircle,
              }}
              title="Ajouter"
              onClick={nagigateToAddNewEntity}
              bgColor={rootStyles.color.secondary}
              color={rootStyles.color.primary}
            />
          </div>
        )}
        {/* <InputSearcheContainer>
                    <Input
                        placeholder="Recherche"
                        value={inputValue}
                        onChange={(value) => {
                            setInputValue(value)
                            onChangeInputSearch(value)
                        }}
                    />
                </InputSearcheContainer> */}
        <BootstrapTable
          keyField="id"
          data={data}
          striped={striped}
          columns={columns}
          filter={filterFactory()}
          noDataIndication={() => "Pas encore de données"}
          pagination={paginationFactory()}
          search
          wrapperClasses={wrapperClasses}
          bordered={bordered}
        />
        {/* <ToolkitProvider
                    keyField="id"
                    data={data}
                    columns={columns}
                    search
                >
                    {
                        props => {

                            console.log({ props });

                            return <div>
                                <FlexBetween>
                                    <SearchBar {...props.searchProps} />
                                </FlexBetween>
                                <BootstrapTable
                                    {...props.baseProps}
                                    data={[]}
                                />
                            </div>
                        }
                    }
                </ToolkitProvider> */}
      </div>
    </div>
  );
};

export default TableListeView;
