import { useDispatch } from "react-redux"
import { useGetCoachingDetailsQuery } from "../queries"
import { setCoachCommentaires, setCoachTaskValidations } from "redux/features/coachSlice"
import { useEffect } from "react"

export const useGetCoachDetails = (coach_id) => {
    const { data: coachingDetails, isLoading: isDetailsLoading } = useGetCoachingDetailsQuery(coach_id)
    const dispatch = useDispatch()

    useEffect(() => {
        if (coachingDetails?.data) {
            dispatch(setCoachCommentaires(coachingDetails?.data?.commentaires))
            dispatch(setCoachTaskValidations(coachingDetails?.data?.tachesValides))
        }
    }, [isDetailsLoading])

    return { coachingDetails, isDetailsLoading }
}