const { siAdminAPi } = require("redux/rtkquery");
const {
  SUIVI_COACHING_ENDPOINT,
  SUIVI_COACHING_DETAILS_ENDPOINT,
  FILTER_COACHS_AND_ASSISTANT_COACHS_COMMENTS_AND_VALIDATIONS,
} = require("routes/api/endpoints");

const coachingApi = siAdminAPi.injectEndpoints({
  endpoints: (build) => ({
    getSuiviCoaching: build.query({
      query: () => SUIVI_COACHING_ENDPOINT,
      providesTags: ["Suivi"],
    }),
    getCoachingDetails: build.query({
      query: (coach_id) => SUIVI_COACHING_DETAILS_ENDPOINT + coach_id,
      providesTags: ["Suivi"],
    }),
    getCoachsAssistantCommentsAndValidations: build.mutation({
      query: (args) => {
        const { commentTime, start_date, end_date } = args;
        return {
          url: FILTER_COACHS_AND_ASSISTANT_COACHS_COMMENTS_AND_VALIDATIONS,
          method: "POST",
          params: { commentTime, start_date, end_date },
        };
      },
    }),
  }),
});

export const {
  useGetSuiviCoachingQuery,
  useGetCoachingDetailsQuery,
  useGetCoachsAssistantCommentsAndValidationsMutation,
} = coachingApi;
