import SyllabusCardItem from "components/bakelistes/SyllabusCardItem";
import FadeTransition from "components/shared/FadeTransition";
import GridListe from "components/shared/GridListe";
import { useGetBakelisteFormationInfos } from "pages/suivi-bakelistes/api/hooks/useGetBakelisteFormationInfos";
import React, { cloneElement } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentBakelisteSuiviSyllabus } from "redux/features/bakelisteSlice";
import { DETAILS_SUIVI_BAKELISTES_SYLLABUS_DETAILS_PATH } from "routes/navigation/navigationPaths";

// eslint-disable-next-line no-unused-vars
const RenderSyllabus = ({ currentItems }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateToDetails = (syllabus) => {
    dispatch(setCurrentBakelisteSuiviSyllabus(syllabus));
    return navigate(DETAILS_SUIVI_BAKELISTES_SYLLABUS_DETAILS_PATH);
  };

  const ClonedSyllabusCardItem = ({ syllabus }) =>
    cloneElement(<SyllabusCardItem syllabus={syllabus} />, {
      navigateToDetails,
    });
  return (
    <GridListe
      resourceData={currentItems}
      resourceName="syllabus"
      resourceItem={ClonedSyllabusCardItem}
      emptyMessage="Ce bakeliste n'est dans auccun syllabus"
    />
  );
};

const ListeSyllabusPage = ({ bakelisteId }) => {
// const { suiviCurrentBakeliste } = useSelector((state) => state.bakelistes);

  const { bakelisteFormationInfos } = useGetBakelisteFormationInfos(bakelisteId);

  console.log({ bakelisteFormationInfos });

  return (
    <FadeTransition in={true}>
      <RenderSyllabus currentItems={bakelisteFormationInfos?.data} />
    </FadeTransition>
  );
};

export default ListeSyllabusPage;
