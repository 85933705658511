import { siAdminAPi } from "redux/rtkquery";
import { ADD_PAIEMENT_ENDPOINT, FILTER_PAIEMENT_BY_MONTH_ANNEE_ENDPOINT } from "routes/api/endpoints";

export const paiementApi = siAdminAPi.injectEndpoints({
    endpoints: (build) => ({
        makePaiement: build.mutation({
            query: (data) => ({
                method: "POST",
                url: ADD_PAIEMENT_ENDPOINT,
                body: data
            }),
            invalidatesTags: ["Paiements"]
        }),
        filterPaiementsByMoisAndAnnee: build.mutation({
            query: ({ mois, annee }) => ({
                method: "POST",
                url: FILTER_PAIEMENT_BY_MONTH_ANNEE_ENDPOINT + mois + "/" + annee
            }),
            transformResponse: (response) => {
                if (Array.isArray(response.paiement)) {
                    response.paiement = {}
                    if (!response.paiement.hasOwnProperty("Grand Yoff 1")) {
                        response.paiement["Grand Yoff 1"] = []
                    }
                    if (!response.paiement.hasOwnProperty("Grand Yoff 2")) {
                        response.paiement["Grand Yoff 2"] = []
                    }
                    if (!response.paiement.hasOwnProperty("Thiés")) {
                        response.paiement["Thiés"] = []
                    }
                    if (!response.paiement.hasOwnProperty("Mbour")) {
                        response.paiement["Mbour"] = []
                    }
                }

                return response
            },
            providesTags: ["Paiements"]
        }),
    })
})

export const { useMakePaiementMutation, useFilterPaiementsByMoisAndAnneeMutation } = paiementApi