import { rootStyles } from "assets/css/globalCss";
import FadeTransition from "components/shared/FadeTransition";
import Flex from "components/shared/Flex";
import { Title2 } from "components/shared/TableListeView";
import { Badge } from "pages/bakelistes/Details/DetailsBakelistePage";
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useSelector } from "react-redux";

const CoachBakelistesCurrentStatus = () => {
  const { currentBakelisteSuiviCoach, suiviCurrentCoach } = useSelector(
    (state) => state.coachs
  );

  console.log({
    bakelistes: currentBakelisteSuiviCoach.currentBakelistesSuivi,
  });

  const columns = useMemo(
    () => [
      {
        dataField: "first_name",
        filter: textFilter({
          placeholder: "Recherche pas nom complet",
        }),
        text: "Nom complet",
        formatter: (cell, row) => (
          <p>
            {row.first_name} {row.last_name}
          </p>
        ),
      },
      {
        dataField: "email",
        filter: textFilter({
          placeholder: "Recherche pas mail",
        }),
        text: "Email",
      },
      {
        dataField: "phone",
        filter: textFilter({
          placeholder: "Recherche",
        }),
        text: "Téléphone",
      },
      {
        dataField: "debut_formation",
        text: "Début formation",
        formatter: (cell, row) => {
          return new Date(row.debut_formation).toLocaleString("fr-Fr", {
            dateStyle: "short",
          });
        },
      },
      {
        dataField: "fin_formation",
        text: "Début formation",
        formatter: (cell, row) => {
          return new Date(row.fin_formation).toLocaleString("fr-Fr", {
            dateStyle: "short",
          });
        },
      },
      {
        dataField: "type_formation",
        filter: textFilter({
          placeholder: "Recherche",
        }),
        text: "Type formation",
        formatter: (cell, row) => (
          <Badge
            bgColor={rootStyles.color.tercary}
            color={rootStyles.color.primary}
          >
            {row.type_formation}
          </Badge>
        ),
      },
    ],
    []
  );
  return (
    <FadeTransition in={true}>
      <Flex gap={10} align={"center"}>
        <Title2>
          Bakelistes{" "}
          <span style={{ color: rootStyles.color.red }}>
            {currentBakelisteSuiviCoach.label}{" "}
          </span>
          de {""}
          <span style={{ color: rootStyles.color.tercary }}>
            {suiviCurrentCoach.first_name} {suiviCurrentCoach.last_name}
          </span>
        </Title2>
        <Badge>
          <span className="fs-5 px-3">{currentBakelisteSuiviCoach?.currentBakelistesSuivi?.length}</span>
        </Badge>
      </Flex>
      <div className="mt-3">
        <BootstrapTable
          keyField="id"
          data={currentBakelisteSuiviCoach.currentBakelistesSuivi}
          striped
          columns={columns}
          filter={filterFactory()}
          noDataIndication={() => "Pas encore de données"}
          pagination={paginationFactory()}
          search
        />
      </div>
    </FadeTransition>
  );
};

export default CoachBakelistesCurrentStatus;
