import React from "react";
import { useDispatch } from "react-redux";
import { setCurrentBakelisteLivrables } from "redux/features/bakelisteSlice";
import styled from "styled-components";

const Card = styled.div.attrs(() => ({
  className: "card",
}))`
  background: #fff !important;
`;

const CardHeader = styled.div.attrs(() => ({
  className: "card-header",
}))`
  background: #fff !important;
`;

const CardBody = styled.div.attrs(() => ({
  className: "card-body",
}))`
  background: #fff !important;
  height: 200px;
  padding: 5px;
`;

const CardFooter = styled.div.attrs(() => ({
  className: "card-footer",
}))`
  background: #fff !important;
`;

const Div = styled.div`
  p {
    display: -webkit-box;
    font-weight: 600;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const Row = styled.div.attrs(() => ({
  className: "row",
}))``;

const FooterDiv = styled.div.attrs(() => ({
  className: "col",
}))``;

// const CommentBtn = styled.button`
//   background: #fff !important;
//   border-radius: 5px;
//   font-family: "Inter", sans-serif;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 9px;
//   line-height: 13px;
//   letter-spacing: 0.1em;
//   color: #f39831 !important;
//   transition: 0.2s ease-out;
//   border: 1px solid #f39831 !important;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 30px;
//   width: 100%;
// `;

const LivrableBtn = styled.button`
  border: 1px solid #069a77;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 0.1em;
  /* text-transform: uppercase; */
  color: #fff;
  width: 100%;
  /* padding: 0.7rem 0; */
  background: #069a77;
  transition: 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
`;

const LivraisonCardItem = ({ livraisonItem, showLivrable, showComment }) => {
  const { details_syllabus, first_image } = livraisonItem;

  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader>
        <Div>
          <p>{details_syllabus?.syllabus_task}</p>
        </Div>
      </CardHeader>

      <CardBody>
        <img
          src={`${process.env.REACT_APP_BASE_APP}${first_image}`}
          alt={details_syllabus?.syllabus_task}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "5px",
          }}
        />
      </CardBody>

      <CardFooter>
        <Row>
          <FooterDiv>
            <LivrableBtn
              onClick={() => {
                showLivrable();
                dispatch(setCurrentBakelisteLivrables(livraisonItem));
              }}
            >
              Commentaires & Livraisons
            </LivrableBtn>
          </FooterDiv>
        </Row>
      </CardFooter>
    </Card>
  );
};

export default LivraisonCardItem;
