import DOMPurify from "dompurify";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const H6Pane = styled.h6`
  font-weight: 600;
  margin-top: 40px;
`;

const EpreuvePratique = () => {
    const { epreuvePratique } = useSelector((state) => state.examens);
    return (
        <>
            {epreuvePratique.length ? (
        epreuvePratique.map((epreuve, index) => {
            return (
                <div key={epreuve.id}>
                    <H6Pane>Exercice {index + 1}: </H6Pane>
                    <div
                        className="dangerously_set_inner_html"
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(epreuve.exercices[0].contenu),
                        }}
                    ></div>
                </div>
            );
        })
      ) : (
        <p> Pas d'épreuves</p>
      )}
        </>
    );
};

export default EpreuvePratique;
