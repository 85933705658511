import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";

const bloquerSchema = Yup.object().shape({
    motif_blocage: Yup.string().required("Veuillez fournir le motif de blocage")
})

export const BloqueBakelisteWithMotifProvider = ({ children }) => {
    const methods = useForm({
        resolver: yupResolver(bloquerSchema),
        defaultValues: {
            motif_blocage: ""
        }
    })
    return <FormProvider {...methods}>{children}</FormProvider>
}