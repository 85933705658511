import React, { memo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { BiPhone, BiUser } from "react-icons/bi"
import { FixedSizeList as List } from "react-window";
import memoize from "memoize-one";

import Div from "components/shared/Div"
import Input from "components/Input"
import Loader from "components/shared/Loader"
import Flex from "components/shared/Flex"
import * as Styled from "./styles"
import { filterAllBakeliste } from "redux/features/bakelisteSlice";
import Badge from "components/Badge/Badge";
import { MdEmail } from "react-icons/md";
import { FORMATION_STATE_COLORS } from "constants";

// Cette fonction mémorise les props,
// Pour éviter de provoquer un nouveau rendu inutile du composant BakelistRow.
// Ceci n'est nécessaire que puisque nous transmettons plusieurs props avec un objet wrapper.
// Si nous ne transmettions qu'une seule valeur stable (par exemple des éléments),
// Nous pourrions simplement transmettre la valeur directement.
const createItemData = memoize((items) => items);

const ListeBakelistes = ({ isLoading, isError, bakelistes, navigateToBakelisteDetails }) => {
    const { research } = useSelector((state) => state.bakelistes)
    const dispatch = useDispatch()

    // eslint-disable-next-line react/display-name
    const BakelistRow = memo(({ data, index, style }) => {
        // Les données transmises à List en tant que "itemData" sont disponibles en tant que props.data ou {data}
        return (
            <Styled.ListItem
                onClick={() => navigateToBakelisteDetails(data[index])}
                key={data[index].id}
                style={{
                    ...style,
                    display: "flex",
                    alignItems: "flex-start",
                    padding: "10px 5px"
                }}
            >
                <Flex Flex style={{ margin: 0 }} align={"flex-start"} gap={10}>
                    <BiUser />
                    <Div>
                        <Flex gap={7} style={{ margin: 0 }}>
                            <Styled.Name>
                                {data[index].first_name.substring(0, 15)} {data[index].last_name}
                            </Styled.Name>
                            {data[index].etat && <Badge
                                style={{
                                    padding: "0px 10px",
                                    fontSize: 10,
                                    // backgroundColor: rootStyles.color.tercary,
                                    backgroundColor: FORMATION_STATE_COLORS[data[index].etat],
                                    borderColor: FORMATION_STATE_COLORS[data[index].etat],
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#fff",
                                    height: 16
                                }}>
                                {data[index].etat}
                            </Badge>}
                        </Flex>
                        <Div>
                            <MdEmail /> <Styled.Desc>{data[index].email ?? "..."}</Styled.Desc>
                        </Div>
                        <Div mt={3} mb={2}>
                            <BiPhone /> <Styled.Desc>{data[index].phone ?? "..."}</Styled.Desc>
                        </Div>
                    </Div>
                </Flex>
            </Styled.ListItem>
        );
    });

    const itemData = createItemData(bakelistes);

    return (
        <div>
            {
                research && <Styled.ListebakelistesPane>
                    <Input
                        value={research}
                        autoFocus
                        onChange={(value) => {
                            // setInputValue(value)
                            dispatch(filterAllBakeliste(value))
                        }}
                        placeholder={"Rechercher bakelistes"}
                        reset={() => dispatch(filterAllBakeliste(""))}
                    />
                    <Styled.MainListePane>
                        {
                            isLoading ? <Loader /> :
                                isError ? <p className="fs-6 text-danger">Une erreur est survenue</p> :
                                    bakelistes.length ?
                                        <List
                                            style={{ minHeight: 300 }}
                                            height={300}
                                            itemCount={bakelistes.length}
                                            itemData={itemData}
                                            itemSize={90}
                                            width={"100%"}
                                        >
                                            {BakelistRow}
                                        </List> :
                                        <p className="fs-6 text-gray"><span className="fst-italic">"{research}"</span> introuvable</p>
                        }
                    </Styled.MainListePane>
                </Styled.ListebakelistesPane>
            }
        </div>
    )
}

export default ListeBakelistes