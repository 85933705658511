import DriveLine, { DriveHead } from "components/examens/DriveLine";
import FadeTransition from "components/shared/FadeTransition";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  EPREUVES_PRATIQUES_PATH,
  EPREUVES_THEORIQUES_PATH,
  // TYPES_EPREUVES_PATH,
} from "routes/navigation/navigationPaths";

const TypeEpreuvesPage = () => {
  const { currentTrimestre } = useSelector((state) => state.examens);
  const navigate = useNavigate();
  const goToEpreuvesTheorique = () => {
    return navigate(EPREUVES_THEORIQUES_PATH);
  };
  const goToEpreuvesPratiques = () => {
    return navigate(EPREUVES_PRATIQUES_PATH);
  };
  // EPREUVES_PRATIQUES_PATH
  return (
    <FadeTransition in={true}>
      <DriveHead
        label1="Nom"
        // label2="Trimestres"
        label3="Nombre"
      />
      <DriveLine
        label1="THEORIQUES"
        // label2={`${currentTrimestre.trimestre} - ${new Date(
        //   currentTrimestre.annee_academique
        // ).getFullYear()}`}
        label3={currentTrimestre.examen_theorique_count}
        onClick={goToEpreuvesTheorique}
      />
      <DriveLine
        label1="PRATIQUES"
        // label2={`${currentTrimestre.trimestre} - ${new Date(
        //   currentTrimestre.annee_academique
        // ).getFullYear()}`}
        label3={currentTrimestre.examens_pratiques_count}
        onClick={goToEpreuvesPratiques}
      />
    </FadeTransition>
  );
};

export default TypeEpreuvesPage;
