/* eslint-disable operator-linebreak */
import { rootStyles } from "assets/css/globalCss";
import TaskCardItem from "components/bakelistes/TaskCardItem";
// import TaskCardItem from "components/bakelistes/TaskCardItem";
import FadeTransition from "components/shared/FadeTransition";
import FlexBetween from "components/shared/FlexBetween";
// import GridListe from "components/shared/GridListe";
import Title3 from "components/shared/Title3";
import { useGetBakelisteSyllabusDetails } from "pages/suivi-bakelistes/api/hooks/useGetSyllabusDetails";
import React from "react";

// import React, { cloneElement } from "react";
import { FcOvertime } from "react-icons/fc";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { createMarkup } from "utils/createMarkup";
import { dateFormatter } from "utils/timeFormatters";

const ContainerDetails = styled.div`
  min-height: 400px;
  background-color: ${rootStyles.color.primary};
  border-radius: ${rootStyles.radius.card};
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
`;
const ImageContainer = styled.div`
  height: 400px;
`;
const TimeSpan = styled.span`
  font-size: 14px;
  margin-top: 8px;
`;

const TaskSpan = styled.span`
  font-size: 14px;
  margin-top: 8px;
`;
const CardBody = styled.div`
  padding: ${rootStyles.padding.btnPadding};
`;
const CardTitle = styled.h1`
  font-size: 14;
`;

const Section = styled.section`
  padding: ${rootStyles.padding.btnPadding};
  margin-top: 20px;
`;

// const RenderTasks = ({ currentItems }) => {
//   const ClonedTaskCardItem = ({ task }) =>
//     cloneElement(<TaskCardItem task={task} />, {});

//   return (
//     <GridListe
//       resourceData={currentItems}
//       resourceItem={ClonedTaskCardItem}
//       resourceName="task"
//       emptyMessage="Pas de tache pour ce syllabus"
//     />
//   );
// };

const DetailsSyllabusPage = () => {
  const { suiviCurrentBakeliste, suiviCurrentBakelisteSyllabus } = useSelector(
    (state) => state.bakelistes
  );

  const { currentBakelistesSyllabusDetails } = useGetBakelisteSyllabusDetails(
    suiviCurrentBakelisteSyllabus?.id,
    suiviCurrentBakeliste.id
  );

  return (
    <FadeTransition>
      <ContainerDetails>
        <ImageContainer>
          <img
            style={{ height: "100%", objectFit: "cover", width: "100%" }}
            src={
              currentBakelistesSyllabusDetails?.data?.syllabus_image
                ? `${process.env.REACT_APP_API_IMAGE_URL}syllabusImages/${currentBakelistesSyllabusDetails?.data?.syllabus_image}`
                : "https://www.ekreative.com/wp-content/uploads/2021/03/pankaj-patel-_SgRNwAVNKw-unsplash-1024x683.jpg"
            }
            alt={currentBakelistesSyllabusDetails?.data?.syllabus_name}
            // onError={replaceImage}
          />
        </ImageContainer>
        <CardBody>
          <CardTitle>
            {currentBakelistesSyllabusDetails?.data?.syllabus_name}
          </CardTitle>
          <TaskSpan
            dangerouslySetInnerHTML={createMarkup(
              currentBakelistesSyllabusDetails?.data?.syllabus_description
            )}
          ></TaskSpan>
          <FlexBetween>
            {/* TODO <TaskSpan>TaskSpan</TaskSpan> */}
            <TimeSpan>
              <FcOvertime size={20} className="me-2" />
              Date de création:{" "}
              {dateFormatter(
                currentBakelistesSyllabusDetails?.data?.created_at
              )}
            </TimeSpan>
          </FlexBetween>
          <Section>
            <Title3>Liste des tâches</Title3>
            {/* <RenderTasks
              currentItems={currentBakelistesSyllabusDetails?.data?.details}
            /> */}
            <div className="row">
              {currentBakelistesSyllabusDetails?.data?.details.map(
                (item, index) => (
                  <div className="col-xl-12 my-2" key={index}>
                    <TaskCardItem task={item} />
                  </div>
                )
              )}
            </div>
          </Section>
        </CardBody>
      </ContainerDetails>
    </FadeTransition>
  );
};

export default DetailsSyllabusPage;
