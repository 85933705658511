import { rootStyles } from "assets/css/globalCss";
import FadeTransition from "components/shared/FadeTransition";
import Loader from "components/shared/Loader";
import TableListeView from "components/shared/TableListeView";
import { useStagiaireArchives } from "pages/bakelistes/api/hooks/useStagiaireArchives";
import React, { useMemo, useState } from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import { toast } from "react-hot-toast";
import { FiArchive } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { ApiController } from "redux/apiController";
import { BAKELISTES_EN_STAGE_PATH } from "routes/navigation/navigationPaths";
import { tooltip_options } from "utils/tooltip_options";

const StagiaireArchivesPage = () => {
  const [refreshInterval, setRefreshInterval] = useState(0);
  const { stagiairesArchives, isStagiairesArchivesLoading } =
    useStagiaireArchives(refreshInterval);
  const navigate = useNavigate();

  const navigateTo = () => {
    return navigate("/" + BAKELISTES_EN_STAGE_PATH);
  };

  const desarchiverBakelistefuc = (bakeliste) => {
    const bakeliste_id = bakeliste.id;
    toast.promise(ApiController.bakelistes.desarchiverBakeliste(bakeliste_id), {
      loading: "Désarchivage en cours",
      success: bakeliste.first_name + " désarchivé(e)",
      error: `L'archivage ${bakeliste.first_name} a echouée`,
    });
    setRefreshInterval(500);
  };

  const actionsFormatter = (cell, row) => {
    return (
      <div className="d-flex gap-4 justify-content-center">
        <FiArchive
          onClick={() => desarchiverBakelistefuc(row)}
          data-tip
          data-tooltip-id="archiver-btn"
          className="cursor-pointer"
          size={20}
          color={rootStyles.color.secondary}
        />

        <ReactTooltip style={tooltip_options} id="archiver-btn">
          Desarchivez
        </ReactTooltip>
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        dataField: "first_name",
        text: "Nom complet",
        filter: textFilter({
          placeholder: "Recherche pas nom complet",
        }),
        formatter: (cell, row) => (
          <p>
            {row.first_name} {row.last_name}
          </p>
        ),
      },
      {
        dataField: "email",
        filter: textFilter({
          placeholder: "Recherche pas mail",
        }),
        text: "Email",
      },
      {
        dataField: "domaine.name",
        text: "Domaines",
      },
      {
        dataField: "Actions",
        text: "Actions",
        formatter: actionsFormatter,
      },
    ],
    []
  );
  return (
    <FadeTransition in={true}>
      {isStagiairesArchivesLoading ? (
        <Loader />
      ) : (
        <TableListeView
          navigateTo={navigateTo}
          navigateToTitle="Stagiaires"
          data={stagiairesArchives.data}
          columns={columns}
          title="Stagiaires archivés"
        />
      )}
    </FadeTransition>
  );
};

export default StagiaireArchivesPage;
