import { TYPE_PRESENCE_ENDPOINT } from "routes/api/endpoints";

const { siAdminAPi } = require("redux/rtkquery");

const typePresenceApi = siAdminAPi.injectEndpoints({
    endpoints: (build) => ({
        getTypePresence: build.query({
            query: () => TYPE_PRESENCE_ENDPOINT,
            providesTags: ["typesPresence"],
        }),
    }),
    overrideExisting: false,
});

export const { useGetTypePresenceQuery } = typePresenceApi