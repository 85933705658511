import { rootStyles } from "assets/css/globalCss";
import AvatarBakeliste from "components/Avatar/AvatarBakeliste"
import TableListeView from "components/shared/TableListeView"
import React, { useMemo } from "react"
import { textFilter } from "react-bootstrap-table2-filter"
import { FaEye } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { setCurrentBakelisteSlice } from "redux/features/bakelisteSlice";
import { DETAILS_BAKELISTE_PATH } from "routes/navigation/navigationPaths";
import { tooltip_options } from "utils/tooltip_options";

const SuiviBakelistesLivraisonsTable = ({ data, title }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigateToBakelisteDetails = (bakeliste) => {
    dispatch(setCurrentBakelisteSlice(bakeliste))
    navigate(DETAILS_BAKELISTE_PATH)
    return
  }
  const actionsFormatter = (cell, row) => {
    return <div className='d-flex gap-4 justify-content-center'>
      <FaEye
        onClick={() => navigateToBakelisteDetails(row)}
        data-tip data-tooltip-id='details-btn'
        className='cursor-pointer'
        size={20}
        color={rootStyles.color.secondary}
      />

      <ReactTooltip style={tooltip_options} id='details-btn'>
        Détails
      </ReactTooltip>
    </div>
  }
  const columns = useMemo(() => [{
    dataField: "avatar",
    text: "Profil",
    formatter: (cell, row) =>
      <div onClick={navigateToBakelisteDetails} className="d-flex justify-content-center cursor">
        <AvatarBakeliste avatar_file={row.avatar_file} />
      </div>
  },
  {
    dataField: "first_name",
    text: "Nom complet",
    filter: textFilter({
      placeholder: "Recherche par nom",
    }),
    formatter: (cell, row) => {
      return <span>{row.first_name} {row.last_name}</span>
    }
  },
  // {
  //   dataField: "heure_pointage",
  //   text: "Heure pointage",
  //   filter: textFilter({
  //     placeholder: "Recherche par heure",
  //   })
  // },
  {
    dataField: "phone",
    text: "Téléphone",
    filter: textFilter({
      placeholder: "Recherche par telephone",
    })
  },
  {
    dataField: "ville",
    text: "Ville",
    filter: textFilter({
      placeholder: "Recherche par ville",
    })
  },
  // {
  //   dataField: "domaine",
  //   text: "Domaines",
  //   filter: textFilter({
  //     placeholder: "Recherche par domaine",
  //   }),
  // },
  {
    dataField: "Actions",
    text: "Détails",
    formatter: actionsFormatter
  }
  ], [])
  return (
    <div>
      {
        <TableListeView
          data={data}
          columns={columns}
          title={title}
        // icon={{ name: HiUsers }}
        />
      }
    </div>
  )
}

export default SuiviBakelistesLivraisonsTable;