import { useGetCoachsQuery } from "actions/coachs/queries"
// import { axiosSwrFetcher } from "redux/apiController/axiosInstance"
// import { GET_COACHS_ENDPOINT } from "routes/api/endpoints"
// import useSWR from "swr"

const useCoachs = () => {
    const { data: coachsList = { data: [] }, isLoading } = useGetCoachsQuery()
    console.log({ coachsList })
    return { data: coachsList.data, isLoading }
}

export default useCoachs