import { rootStyles } from "assets/css/globalCss";
import React from "react";
import styled, { keyframes } from "styled-components";

const BtnBtn = styled.button`
  width: ${({ width }) => width};
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ borderColor }) =>
    borderColor ? `1px solid ${borderColor}` : "none"};
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px;
  background-color: ${({ bgColor }) => bgColor};
  font-weight: ${rootStyles.fonts.btnWeight};
  font-size: ${({ fontSize }) => fontSize};
  padding: ${rootStyles.padding.btnPadding};
  cursor: pointer;
  color: ${({ color }) => color};
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    box-shadow: 0 3px 0 rgba(0, 0, 0, 0.5);
    // margin-bottom: 1px;
  }

  @media only screen and (max-width: 500px) {
    font-size: 12px;
  }
`;
const BtnBody = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const spinnerAnimation = keyframes`
  to {
    transform: rotate(360deg);
    }
`;

const Spinner = styled.div`
  height: 20px;
  width: 20px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.4);
  border-top-color: #fff;
  border-bottom-color: #fff;
  animation-name: ${spinnerAnimation};
  animation-duration: 1s;
  animation-iteration-count: infinite;
`;

function ButtonComp({
  width,
  title,
  onClick,
  buttonLabelStyle,
  icon,
  color = "#000000",
  loading,
  bgColor = "#fff",
  borderColor,
  type,
  className,
  disabled,
}) {
  return (
    <BtnBtn
      type={type ?? "submit"}
      borderColor={borderColor}
      onClick={onClick}
      disabled={loading || disabled}
      width={width}
      bgColor={bgColor}
      color={color}
      id={className}
    >
      {loading ? (
        <Spinner></Spinner>
      ) : (
        <BtnBody>
          {icon?.name && icon.possition !== "left" && (
            <icon.name
              color={icon?.color ?? color ?? "gray"}
              style={{}}
              size={icon?.size ?? 15}
            />
          )}
          {title && (
              <span
              style={{
                  fontSize: 15,
                marginLeft: 7,
                marginRight: 7,
                ...buttonLabelStyle,
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {title}
              </span>
          )}
        </BtnBody>
      )}
    </BtnBtn>
  );
}

export default ButtonComp;
