import React from "react";

const CommentHeader = ({ commentData }) => {
  return (
    <div className="comment--header">
      {/* <div className={`profile-pic ${commentData.username}`}></div> */}
      <div className="username">{`${commentData?.user?.first_name} ${commentData?.user?.last_name}`}</div>

      {commentData?.user?.status === "coach-assistant" && (
        <div className="coach-tag">Coach</div>
      )}
      <div className="comment-posted-time">
        {/* {moment(commentData.created_at).fromNow()} */}
      </div>
    </div>
  );
};

export default CommentHeader;
