/* eslint-disable operator-linebreak */
import { rootStyles } from "assets/css/globalCss";
import FadeTransition from "components/shared/FadeTransition";
import FlexBetween from "components/shared/FlexBetween";
import Title3 from "components/shared/Title3";
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import bakeliLogo from "../../../../../assets/img/bakeli2.png";
import senegalLogo from "../../../../../assets/img/senegal.png";

const ConduiteContainer = styled.table`
  border: 2px solid;
  border-collapse: collapse;
  margin-bottom: 30px;
`;
const ConduiteRow = styled.tr`
  border: 1px solid;
`;
const ConduiteColumn = styled.td`
  border: 1px solid;
  font-weight: 500;
`;
const Paragraphe = styled.p`
  font-size: ${rootStyles.fonts.fontSize};
`;

const LeftPane = styled.div`
  display: flex;
`;
const RightPane = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
const LogoPane = styled.div`
  width: 70px;
  margin-bottom: 10px;
`;
const InfoPane = styled.div`
  margin-left: 10px;
`;
const PersonalInfoPane = styled.div`
  margin-bottom: 15px;
`;
// const Paragraphe = styled.p`
//   font-size: ${rootStyles.fonts.fontSize};
//   margin-bottom: 5px;
// `;
const SpanWeight = styled.span`
  font-weight: 600;
`;
const Image = styled.img`
  width: 100%;
`;

const BulletinDetailsPage = () => {
  const { suiviCurrentBakeliste } = useSelector((state) => state.bakelistes);
  const { currentTrimestreBulletin } = useSelector((state) => state.bakelistes);

  const columns = useMemo(
    () => [
      {
        //   module
        dataField: "modules",
        text: "Modules",
        // sort: true
      },
      {
        dataField: "note_1",
        text: "Théorie",
      },
      {
        dataField: "note_2",
        text: "Pratique",
        // sort: true,
        //    formatter: (cell, row) => row.comment_of_coach ?? "",
      },
      {
        dataField: "moyenne_note",
        text: "Moyenne",
        // sort: true,
        //    formatter: (cell, row) => row.comment_of_coach ?? "",
      },
    ],
    []
  );
  return (
    <FadeTransition in={true}>
      <FlexBetween>
        <LeftPane>
          <LogoPane>
            <Image src={bakeliLogo} alt="Bakeli School Of Technology" />
          </LogoPane>
          <InfoPane>
            <p style={{ marginBottom: 2 }}>BAKELI School of Technology</p>
            <Paragraphe>Hlm grand yoff, villa n°241</Paragraphe>
            <Paragraphe>Téléphone : +221 33 867 75 06</Paragraphe>
          </InfoPane>
        </LeftPane>
        <RightPane>
          <LogoPane>
            <Image src={senegalLogo} alt="Drapeau Senegal" />
          </LogoPane>
          <div>
            <Paragraphe>République du SENEGAL</Paragraphe>
            <Paragraphe>Ministère de la formation professionnelle</Paragraphe>
          </div>
        </RightPane>
      </FlexBetween>

      <PersonalInfoPane>
        <Paragraphe>
          <SpanWeight>Prénom</SpanWeight>: {suiviCurrentBakeliste.first_name}
        </Paragraphe>
        <Paragraphe>
          <SpanWeight>Nom</SpanWeight>: {suiviCurrentBakeliste.last_name}
        </Paragraphe>
        <Paragraphe>
          <SpanWeight>Adresse</SpanWeight>: {suiviCurrentBakeliste.address}
        </Paragraphe>
        <Paragraphe>
          <SpanWeight>Téléphone</SpanWeight>: {suiviCurrentBakeliste.phone}
        </Paragraphe>
      </PersonalInfoPane>
      <BootstrapTable
        keyField="id"
        data={currentTrimestreBulletin.notes_bulletin}
        striped
        columns={columns}
        noDataIndication={() => "Pas encore de notes"}
        //   pagination={paginationFactory()}
        search
        rowClasses="custom-bulletin-row-class"
      />
      <div className="table-responsive">
        <ConduiteContainer>
          <ConduiteRow>
            <ConduiteColumn>Moyenne conduite</ConduiteColumn>
            <ConduiteColumn>
              {currentTrimestreBulletin.conduite?.moyenne_conduite ?? "..."}
            </ConduiteColumn>
          </ConduiteRow>
          <ConduiteRow>
            <ConduiteColumn>Moyenne generale</ConduiteColumn>
            <ConduiteColumn>
              {currentTrimestreBulletin.moyenne_globale ?? "..."}
            </ConduiteColumn>
          </ConduiteRow>
        </ConduiteContainer>
      </div>

      <Title3>Remarque</Title3>
      <Paragraphe>
        {currentTrimestreBulletin.comment_of_coach !== "NULL"
          ? currentTrimestreBulletin.comment_of_coach
          : "..."}
      </Paragraphe>
    </FadeTransition>
  );
};

export default BulletinDetailsPage;
