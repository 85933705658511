import Modal from "components/Modal";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const HeaderPane = styled.header`
  border: 2px solid;
  padding: 30px 0;
  text-transform: uppercase;
  text-align: center;
  margin: 20px 0;
  font-weight: 600;
`;

const EpreuveModal = ({ opened, onClosed, children }) => {
    const { currentEpreuveType, currentEpreuveModule } = useSelector(
        (state) => state.examens
    );

    useEffect(() => {
        const modalChildren = document.querySelector(".modal-children");
        const modalTitle = document.getElementById("modal-title");

        modalChildren.addEventListener("scroll", (e) => {
            if (modalChildren.scrollTop < 70) {
                modalTitle.classList.remove("show");
                modalTitle.classList.add("hide");
            } else {
                modalTitle.classList.remove("hide");
                modalTitle.classList.add("show");
            }
        });
    }, []);

    useEffect(() => {
        const modalTitle = document.getElementById("modal-title");
        modalTitle.classList.add("hide");
    }, []);

    return (
        <Modal
            title={`Epreuve ${currentEpreuveType} ${currentEpreuveModule}`}
            opened={opened}
            onClosed={onClosed}
            modalWidth={800}
        >
            <HeaderPane id="header-pane">
        Epreuve {currentEpreuveType} {currentEpreuveModule}
            </HeaderPane>
            {children}
        </Modal>
    );
};

export default EpreuveModal;
