import { rootStyles } from "assets/css/globalCss"
import ButtonComp from "components/ButtonComp"
import InputForm from "components/shared/InputForm"
import { useCurrentUser } from "hooks/useCurrentUser"
import { useDebloquerBakelisteMutation } from "pages/bakelistes/api/mutations"
import React from "react"
import { useFormContext } from "react-hook-form"
import toast from "react-hot-toast"

const DebloquerBakelisteWithMotif = ({ onClosed, currentBakeliste }) => {
    const { register, handleSubmit, reset, formState: { errors, isSubmitting } } = useFormContext()
    const [debloqueBakelisteCompte, { isLoading }] = useDebloquerBakelisteMutation()
    const { currentUser } = useCurrentUser()

    const onSubmit = (data) => {
        const bakeliste_id = currentBakeliste.id
        toast.promise(debloqueBakelisteCompte({ bakeliste_id, unblocked_by: currentUser.id, motif_deblocage: data.motif_deblocage })
            .unwrap().then(() => {
                onClosed()
                reset()
            }), {
            loading: "Déblocage en cours...",
            success: currentBakeliste.first_name + " bloqué(e)",
            error: `Le déblocage de ${currentBakeliste.first_name} a echoué`
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ margin: "20px 0" }}>
            <InputForm
                label={"Motif déblocage"}
                register={{ ...register("motif_deblocage") }}
                error={errors.motif_deblocage?.message}
            />
            <ButtonComp
                title={"Envoyez"}
                disabled={isLoading || isSubmitting}
                loading={isLoading || isSubmitting}
                bgColor={rootStyles.color.secondary}
                color={rootStyles.color.primary}
            />
        </form>
    )
}

export default DebloquerBakelisteWithMotif