import React from "react";
import styled from "styled-components";

const FlexPane = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 10px 0;
  gap: ${({ gap }) => `${gap}px`};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  margin: 0
`;

const Flex = ({ children, gap, justify, align, style }) => {
  return <FlexPane style={style} justify={justify} align={align} gap={gap}>{children}</FlexPane>;
};

export default Flex;
