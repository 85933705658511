import React from "react"
import TypesPresences from "./TypesPresences/TypesPresences"
import { Title2 } from "components/shared/TableListeView"
import Spaces from "./Spaces/Spaces"

const ParametresPage = () => {
  return (
    <div>
      <Title2>Types de présences</Title2>
      <TypesPresences />
      <Spaces />
    </div>
  )
}

export default ParametresPage