import LivraisonCardItem from "components/bakelistes/LivraisonCardItem";
import FadeTransition from "components/shared/FadeTransition";
import GridListe from "components/shared/GridListe";
import Loader from "components/shared/Loader";
import { useGetBakelisteLivraisons } from "pages/suivi-bakelistes/api/hooks/useGetBakelisteLivraisons";
import React, { useState } from "react";
// import { useSelector } from "react-redux";
import LivrablesModal from "./LivrablesModal";

const ListeLivraisons = ({ bakelisteId }) => {
  const [showLivrableModal, setShowLivrableModal] = useState(false);

  // const { suiviCurrentBakeliste } = useSelector((state) => state.bakelistes);

  const { currentBakelisteLivraisons, loadingCurrentBakelisteLivraisons } =
    useGetBakelisteLivraisons(bakelisteId);

  const showLivrable = () => {
    setShowLivrableModal(true);
  };

  const ClonedLivraisonCardItem = ({ livraisonItem }) =>
    React.cloneElement(<LivraisonCardItem livraisonItem={livraisonItem} />, {
      showLivrable,
    });

  return (
    <FadeTransition in={true}>
      {loadingCurrentBakelisteLivraisons ? (
        <Loader />
      ) : (
        <GridListe
          resourceName="livraisonItem"
          resourceData={currentBakelisteLivraisons?.data}
          resourceItem={ClonedLivraisonCardItem}
          emptyMessage="Pas de encore de livraisons."
        />
      )}
      <LivrablesModal
        opened={showLivrableModal}
        onClosed={() => setShowLivrableModal(false)}
      />
    </FadeTransition>
  );
};

export default ListeLivraisons;
