import Modal from "components/Modal"
import React, { useState } from "react"
import InputForm from "components/shared/InputForm"
import { useForm } from "react-hook-form"
import ButtonComp from "components/ButtonComp"
import { rootStyles } from "assets/css/globalCss"
import { ApiController } from "redux/apiController"
import { toast } from "react-hot-toast"
import { useCurrentUser } from "hooks/useCurrentUser"

const AjoutAnneeAcademiqueModal = ({ openAddTrimestreModal, onClosed }) => {
    const { currentUser } = useCurrentUser()
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [isAdding, setIsAdding] = useState(false)
    const onSubmit = ({ annee_academique }) => {
        setIsAdding(true)
        ApiController.examens.createAnneeAcademique({ annee_academique, created_by: currentUser.id }).then(() => {
            toast.success("Nouvelle année académique créée")
            onClosed()
        }).catch((error) => {
            console.log({ error });
            toast.error(error.message)
        }).finally(() => {
            setIsAdding(false)
        })
    }
    return (
        <Modal
            title={"Ajoutez l'année académique"}
            opened={openAddTrimestreModal}
            onClosed={onClosed}
        >
            <form className='py-2' onSubmit={handleSubmit(onSubmit)}>
                <InputForm
                    min={2000}
                    max={2999}
                    label="Année académique"
                    type="number"
                    htmlFor='trimestre'
                    register={{ ...register("annee_academique", { required: "Année académique obligatoire" }) }}
                    error={errors.annee_academique}
                />
                <div className='mt-0'>
                    <ButtonComp
                        loading={isAdding}
                        title="Ajouter"
                        bgColor={rootStyles.color.secondary}
                        color={rootStyles.color.primary}
                        width={"100%"}
                    />
                </div>
            </form>
        </Modal>
    )
}

export default AjoutAnneeAcademiqueModal