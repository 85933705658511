import { rootStyles } from "assets/css/globalCss";
import { Badge } from "pages/bakelistes/Details/DetailsBakelistePage";
import React from "react";
import { BsWhatsapp } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { BiTime } from "react-icons/bi";
import { FcOvertime } from "react-icons/fc";
import styled from "styled-components";
import { API_IMAGE_URL } from "redux/apiController/axiosInstance";
import logoBakeli from "../../assets/img/bakeli-best-talent.jpg";

const EventContainer = styled.div`
  background-color: ${rootStyles.color.primary};
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${rootStyles.radius.card};
  position: relative;
  box-shadow: ${rootStyles.shadow.card};
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
`;
const ImageContainer = styled.div`
  height: 150px;
  cursor: pointer;
`;
const CardBody = styled.div`
  padding: ${rootStyles.padding.btnPadding};
`;
const CardTitle = styled.h2`
  font-size: ${rootStyles.fonts.h2FontSize};
`;
const CardText = styled.p`
  font-size: ${rootStyles.fonts.fontSize};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const CardFooter = styled.div`
  background-color: ${rootStyles.color.red};
`;
const Span = styled.span`
  font-weight: ${rootStyles.fonts.h2FontWeight};
  color: ${rootStyles.color.primary};
`;
const TimeSpan = styled.span`
  font-size: 12px;
  display: block;
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 2px;
`;
const Position = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const EvenementCardItem = ({ evenement, navigateToDetails }) => {
    const {
        rencontre_name,
        rencontre_description,
        created_at,
        rencontre_start_date,
        rencontre_place,
        rencontre_start_time,
        lien_groupe_whatsapp,
        participant,
        rencontre_file,
    } = evenement;
    const replaceImage = (error) => {
        // replacement of broken Image
        error.target.src = logoBakeli;
    };
    return (
        <EventContainer onClick={() => navigateToDetails(evenement)}>
            <Position>
                <Badge>{rencontre_place}</Badge>
            </Position>
            <ImageContainer>
                <img
                    style={{ height: "100%", objectFit: "cover", width: "100%" }}
                    src={API_IMAGE_URL + rencontre_file}
                    alt={rencontre_name}
                    onError={replaceImage}
                />
            </ImageContainer>
            <CardBody>
                <CardTitle>{rencontre_name}</CardTitle>
                <CardText>{rencontre_description}</CardText>
                <TimeSpan>
                    <FcOvertime size={20} />
          Date:{" "}
                    {new Date(rencontre_start_date).toLocaleString("fr-Fr", {
                        dateStyle: "short",
                    })}{" "}
          à {rencontre_start_time}
                </TimeSpan>
                <TimeSpan>
                    <BiTime size={20} />
          Créé le:{" "}
                    {new Date(created_at).toLocaleString("fr-Fr", {
                        dateStyle: "short",
                    })}
                </TimeSpan>
                <div className="mt-1">
                    <Badge>
                        <a
                            onClick={(e) => e.stopPropagation()}
                            style={{ textDecoration: "none", color: "#000" }}
                            href={lien_groupe_whatsapp}
                            rel="noreferrer"
                            target="_blank"
                            className="fw-bold"
                        >
                            <BsWhatsapp color={rootStyles.color.secondary} size={20} />{" "}
              Rejoindre le groupe
                        </a>
                    </Badge>
                </div>
            </CardBody>
            <CardFooter className="d-flex justify-content-between align-items-center p-2">
                <Span>Inscrits</Span>
                <Span className="d-flex align-items-center gap-2">
                    {participant?.length}
                    <FaUsers size={25} color={rootStyles.color.primary} />
                </Span>
            </CardFooter>
        </EventContainer>
    );
};

export default EvenementCardItem;
