/* eslint-disable valid-jsdoc */
import { rootStyles } from "assets/css/globalCss";
import CardRegionItem from "components/prospects/CardRegionItem";
import { Title2 } from "components/shared/TableListeView";
import React, { cloneElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  STATISTIQUES_PROSPECTS_BAKELISTES_VISITEURS_ENDPOINT,
} from "routes/api/endpoints";
import { PROSPECTS_PAR_REGION_PATH } from "routes/navigation/navigationPaths";
import { statistiqueProspectsSwrFetcher } from "../api";
import useSWR from "swr";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  SubTitle,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useDispatch } from "react-redux";
import {
  setCurrentRegion,
  setProspectsListSlice,
} from "redux/features/prospectSlice";
import { Container } from "pages/bakelistes/BakelisteHomePageDashboard";
import ResourceDataMap from "components/ResourceDataMap";
import FadeTransition from "components/shared/FadeTransition";
import { useGetProspectsByRegionHook } from "../../../hooks/useGetProspectsByRegion";
import ButtonComp from "components/ButtonComp";
import FlexBetween from "components/shared/FlexBetween";
import { FiRefreshCw } from "react-icons/fi";
import { bakelisteOptions, prospectsOptions, visiteursOptions } from "utils/prospectUtils";
import FilterYearComp from "components/prospects/FilterYear";
// import Flex from "components/shared/Flex";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  SubTitle,
  ChartDataLabels
);

const ProspectsParRegionDashboardPage = () => {
  const [filterYear, setFilterYear] = useState(new Date().getFullYear())
  const { prospects, isProspectsLoading, refetchProspects, isFetching } = useGetProspectsByRegionHook({ year: filterYear })
  const {
    data = {
      dataBakelistes: [],
      dataProspects: [],
      dataVisiteurs: [],
      dataMonths: [],
    },
  } = useSWR(
    STATISTIQUES_PROSPECTS_BAKELISTES_VISITEURS_ENDPOINT,
    statistiqueProspectsSwrFetcher
    );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  /**
   ***********************************************************
    Naviguer vers la liste des prospects
   ***********************************************************
   */
  const goToProspectsParRegion = (currentRegion, prospects) => {
    dispatch(setCurrentRegion(currentRegion));
    dispatch(setProspectsListSlice(prospects));
    return navigate(PROSPECTS_PAR_REGION_PATH);
  };
  /**
   ***********************************************************
    Cloner CardRegionItem
   ***********************************************************
   */
  const CardRegionItemClone = ({ location }) =>
    cloneElement(<CardRegionItem location={location} />, {
      goToProspectsParRegion,
    });

  /**
   ***********************************************************
    labels et les chatData
   ***********************************************************
   */
  const labels = data.dataMonths.map((month) => month);

  const chartDataBakelistes = {
    labels,
    datasets: [
      {
        label: "Bakelistes",
        data: data.dataBakelistes.map((numberBakeliste) => numberBakeliste),
        borderColor: rootStyles.color.secondary,
        backgroundColor: rootStyles.color.secondary,
      },
    ],
  };

  const chartDataProspects = {
    labels,
    datasets: [
      {
        label: "Prospects",
        data: data.dataProspects.map((nombreProspects) => nombreProspects),
        borderColor: rootStyles.color.red,
        backgroundColor: rootStyles.color.red,
      },
    ],
  };
  const chartDataVisiteurs = {
    labels,
    datasets: [
      {
        label: "Visiteurs",
        data: data.dataVisiteurs.map((nombreVisiteurs) => nombreVisiteurs),
        borderColor: rootStyles.color.tercary,
        backgroundColor: rootStyles.color.tercary,
      },
    ],
  };

  return (
    <FadeTransition in={true}>
      <FlexBetween>
        <Title2>Prospects par région</Title2>
        <FlexBetween gap={10}>
          <FilterYearComp
            setFilterYear={(year) => {
              setFilterYear(year)
            }}
          />
        <ButtonComp
          className="my-custom-tour-class"
          bgColor={rootStyles.color.red}
          icon={{
            name: FiRefreshCw
          }}
          color={rootStyles.color.primary}
          title="Synchroniser"
          onClick={() => refetchProspects()}
        />
        </FlexBetween>
      </FlexBetween>
      <Container>
        <ResourceDataMap
          resourceData={[
            {
              region: "Total",
              number: prospects.allProspects,
              prospectsList: prospects.listAllProspects,
              isLoading: isProspectsLoading || isFetching,
            },
            {
              region: "Dakar",
              number: prospects.prospectDakar,
              prospectsList: prospects.listProspectDakar,
              isLoading: isProspectsLoading || isFetching,
            },
            {
              region: "Thiès",
              number: prospects.prospectThies,
              prospectsList: prospects.listProspectThies,
              isLoading: isProspectsLoading || isFetching,
            },
            {
              region: "Mbour",
              number: prospects.prospectMbour,
              prospectsList: prospects.listProspectMbour,
              isLoading: isProspectsLoading || isFetching,
            },
          ]}
          resourceName="location"
          resourceItem={CardRegionItemClone}
        />
      </Container>
      {/* <Flex gap={15}>
        <CardRegionItem />
        <CardRegionItem />
        <CardRegionItem />
      </Flex> */}
      <div
        className="mt-5 row mx-2 prospects-stats"
        style={{
          // borderTop: `1px solid rgba(20, 50,60, .4)`
          minHeight: "500px",
          backgroundColor: "white",
          borderRadius: rootStyles.radius.card,
          boxShadow: rootStyles.shadow.card,
        }}
      >
        <Title2 className="mt-3">
          Statistiques : Bakelistes - Prospects - Visiteurs
        </Title2>
        <div className="col-md-12 col-lg-6">
          <Bar options={bakelisteOptions} data={chartDataBakelistes} />
        </div>
        <div className="col-md-12 col-lg-6 mt-3 mt-md-0">
          <Bar options={prospectsOptions} data={chartDataProspects} />
        </div>
        <div className="col-md-12 col-lg-6">
          <Bar options={visiteursOptions} data={chartDataVisiteurs} />
        </div>
      </div>
    </FadeTransition>
  );
};

export default ProspectsParRegionDashboardPage;
