/* eslint-disable operator-linebreak */
import React from "react";
import Reply from "./Reply";

const ReplyContainer = ({
  commentData,
  commentPostedTime,
  firstReply,
  showAllReplies,
  handleShowReplies,
}) => {
  return (
    <>
      <div className="reply-container">
        {!showAllReplies
          ? firstReply?.map((reply) => (
              <Reply
                key={reply.id}
                commentData={reply}
                commentPostedTime={commentPostedTime}
              />
            ))
          : commentData?.map((reply) => (
              <Reply
                key={reply.id}
                commentData={reply}
                commentPostedTime={commentPostedTime}
              />
            ))}
      </div>
      <div className="d-flex justify-content-end">
        {!showAllReplies && commentData?.length >= 1 && (
          <p
            onClick={(e) => {
              handleShowReplies();
            }}
            style={{ cursor: "pointer" }}
            className={`${
              commentData?.length - firstReply?.length === 0 && "d-none"
            }`}
          >
            {`Afficher ${
              commentData?.length - firstReply?.length === 1
                ? commentData?.length - firstReply?.length
                : `${commentData?.length - firstReply?.length}`
            } autre${
              commentData?.length - firstReply?.length > 1 ? "s" : ""
            } réponse${
              commentData?.length - firstReply?.length > 1 ? "s" : ""
            }`}
          </p>
        )}
      </div>
    </>
  );
};

export default ReplyContainer;
