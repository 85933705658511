import { rootStyles } from "assets/css/globalCss"
import React from "react"
import styled from "styled-components"
const Title = styled.p`
    font-size: ${rootStyles.fonts.fontSize};
    font-weight: ${rootStyles.fonts.btnWeight};
    margin:0
`
const Paragraphe = styled.p`
    font-size: ${rootStyles.fonts.fontSize};
    margin-bottom: 10px
`
const ProfilDetail = ({ title, desc }) => {
    return (
        <div className="mb-4">
            <Title>{title}</Title>
            <Paragraphe>{desc()}</Paragraphe>
        </div>
    )
}

export default ProfilDetail