import { axiosInstance } from "redux/apiController/axiosInstance"

export const bakelisteEnPresentielSwrFetcher = (url) => axiosInstance.get(url).then((res) => {
    return res.data
})
export const bakelisteEnLigneSwrFetcher = (url) => axiosInstance.get(url).then((res) => {
    return res.data
})
export const bakelisteAbandonsSwrFetcher = (url) => axiosInstance.get(url).then((res) => {
    return res.data
})
export const bakelisteEnPauseSwrFetcher = (url) => axiosInstance.get(url).then((res) => {
    return res.data
})
export const bakelisteBloquesSwrFetcher = (url) => axiosInstance.get(url).then((res) => {
    return res.data
})
export const bakelisteArchivesSwrFetcher = (url) => axiosInstance.get(url).then((res) => {
    return res.data
})
export const bakelisteEnStageSwrFetcher = (url) => axiosInstance.get(url).then((res) => {
    return res.data
})
export const stagiaireArchivesSwrFetcher = (url) => axiosInstance.get(url).then((res) => {
    return res.data
})
export const bakelisteTerminesSwrFetcher = (url) => axiosInstance.get(url).then((res) => {
    return res.data
})