import { REGIONS_ENDPOINT } from "routes/api/endpoints";

const { siAdminAPi } = require("redux/rtkquery");

const regionsApi = siAdminAPi.injectEndpoints({
    endpoints: (build) => ({
        getRegions: build.query({
            query: () => REGIONS_ENDPOINT,
            providesTags: ["regions"],
        }),
    }),
    overrideExisting: false,
});

export const { useGetRegionsQuery } = regionsApi