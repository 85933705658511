import { rootStyles } from "assets/css/globalCss";
import ButtonComp from "components/ButtonComp";
import Modal from "components/Modal";
import InputForm from "components/shared/InputForm";
import SelectForm from "components/shared/SelectForm";
import { useCurrentUser } from "hooks/useCurrentUser";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { ApiController } from "redux/apiController";
import { useAddNewEmployeMutation } from "../api/mutations";

const AddNewEmployeModal = ({ onClosed, openAddNewEmploye }) => {
  useEffect(() => {
    ApiController.domaines.getDomaines();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { allDomainesList } = useSelector((state) => state.domaines);
  const { currentUser } = useCurrentUser();

  const [addNewEmploye, { isLoading }] = useAddNewEmployeMutation();

  const onSubmit = (data) => {
    data["created_by"] = currentUser.id;
    addNewEmploye(data)
      .unwrap()
      .then((res) => {
        toast.success("Employé ajouté");
        onClosed();
      })
      .catch((err) => {
        console.log({ err });
        toast.error("Une erreur est survenue");
      });
  };

  return (
    <div>
      <Modal
        title={"Nouveau Employé"}
        opened={openAddNewEmploye}
        onClosed={onClosed}
      >
        <form className="py-2" onSubmit={handleSubmit(onSubmit)}>
          <div className="d-md-flex gap-2">
            <InputForm
              min={3}
              max={50}
              label="Prénom"
              type="text"
              htmlFor="first_name"
              register={{
                ...register("first_name", {
                  required: "Prenom obligatoire",
                }),
              }}
              error={errors.first_name?.message}
            />
            <InputForm
              min={3}
              max={50}
              label="Nom"
              type="text"
              htmlFor="last_name"
              register={{
                ...register("last_name", {
                  required: "Nom obligatoire",
                }),
              }}
              error={errors.last_name?.message}
            />
          </div>
          <div className="d-md-flex gap-2">
            <InputForm
              min={3}
              max={50}
              label="Téléphone"
              type="tel"
              htmlFor="phone"
              register={{
                ...register("phone", {
                  required: "Nom obligatoire",
                }),
              }}
              error={errors.phone?.message}
            />
            <InputForm
              min={3}
              max={50}
              label="Email"
              type="mail"
              htmlFor="email"
              register={{
                ...register("email", {
                  required: "Nom obligatoire",
                }),
              }}
              error={errors.email?.message}
            />
          </div>
          <InputForm
            min={3}
            max={50}
            label="Adresse"
            type="text"
            htmlFor="address"
            register={{
              ...register("address", {
                required: "Nom obligatoire",
              }),
            }}
            error={errors.address?.message}
          />
          <SelectForm
            label="Domaine"
            register={{
              ...register("domaine_id", {
                required: "Domaine obligatoire",
              }),
            }}
            error={errors.domaine_id?.message}
          >
            <option value="">Sélectionner</option>
            {allDomainesList &&
              allDomainesList.map((domaine) => (
                <option key={domaine.id} value={domaine.id}>
                  {domaine.name}
                </option>
              ))}
          </SelectForm>
          <div className="d-md-flex gap-2">
            <InputForm
              label="Début contrat"
              type="date"
              htmlFor="debut_contrat"
              register={{
                ...register("debut_contrat", {
                  required: "Debut contrat obligatoire",
                }),
              }}
              error={errors.debut_contrat?.message}
            />
            <InputForm
              label="Fin contrat"
              type="date"
              htmlFor="fin_contrat"
              register={{
                ...register("fin_contrat", {
                  required: "Fin contrat obligatoire",
                }),
              }}
              error={errors.fin_contrat?.message}
            />
          </div>
          <div className="d-md-flex gap-2">
            <SelectForm
              label="Civilité"
              register={{
                ...register("civility", {
                  required: "Civilité obligatoire",
                }),
              }}
              error={errors.civility?.message}
            >
              <option value="">Sélectionner</option>
              <option value="madame">Madame</option>
              <option value="monsieur">Monsieur</option>
            </SelectForm>
            <SelectForm
              label="Entité"
              register={{
                ...register("entite", {
                  required: "Entité obligatoire",
                }),
              }}
              error={errors.entite?.message}
            >
              <option value="">Sélectionner</option>
              <option value="RED Team">RED Team</option>
              <option value="Bakeli">Bakeli</option>
              <option value="Volkeno">Volkeno</option>
            </SelectForm>
          </div>
          <div className="mt-0">
            <ButtonComp
              loading={isLoading}
              title="Ajouter"
              bgColor={rootStyles.color.secondary}
              color={rootStyles.color.primary}
              width={"100%"}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default AddNewEmployeModal;
