import { useGetCoachsAssistantsQuery } from "actions/coach-assistants/queries"
// import { axiosSwrFetcher } from "redux/apiController/axiosInstance"
// import { GET_COACHS_ASSISTANTS_ENDPOINT } from "routes/api/endpoints"
// import useSWR from "swr"

const useCoachAssistants = () => {
    // const { data: coachsAssistantsList, isLoading } = useSWR(GET_COACHS_ASSISTANTS_ENDPOINT, axiosSwrFetcher)
    const { data: coachsAssistantsList = { data: [] }, isLoading
    } = useGetCoachsAssistantsQuery()
    console.log({ coachsAssistantsList });
    return { data: coachsAssistantsList?.data, isLoading }
}

export default useCoachAssistants