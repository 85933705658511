import React from "react"
import SuiviPointageBakelistes from "./Bakelistes/SuiviPointageBakelistes"

const SuiviPointagePage = () => {
    return (
        <div>
            <SuiviPointageBakelistes />
        </div>
    )
}

export default SuiviPointagePage