/* eslint-disable operator-linebreak */
import { rootStyles } from "assets/css/globalCss";
import ButtonComp from "components/ButtonComp";
import ProgressBar from "components/ProgressBar";
import Div from "components/shared/Div";
import FadeTransition from "components/shared/FadeTransition";
// import { Title2 } from "components/shared/TableListeView";
import { useGetBakelisteGlobalSyllabusProgression } from "pages/suivi-bakelistes/api/hooks/useGetBakelisteGlobalSyllabusProgression";
import React, { useRef } from "react";
import { AiOutlinePrinter } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import { HiDocumentReport, HiOutlineBookOpen } from "react-icons/hi";
import { useSelector } from "react-redux";
import { Link, Outlet, useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { completeDateFormatter } from "utils/timeFormatters";
import PortfolioPagePrint from "./portfolio/PortfolioPagePrint";

const LabelText = ({ label, text }) => {
  return <Div className="d-flex align-items-center gap-2 mt-3">
    <span style={{ fontSize: 14 }} className="fw-semibold">
      {label}:
    </span>
    <span style={{ fontSize: 12 }}>{text ?? "..."}</span>
  </Div>
}

const DetailsSuiviBakeliste = () => {
  const { suiviCurrentBakeliste } = useSelector((state) => state.bakelistes);
  const { currentbakelisteGlobalProgression } =
    useGetBakelisteGlobalSyllabusProgression(suiviCurrentBakeliste?.id);

  // assigning location variable
  const location = useLocation();
  // destructuring pathname from location
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  // Portfolio ref
  const porfolioRef = useRef();

  return (
    <FadeTransition in={true}>
      <PortfolioPagePrint ref={porfolioRef} />
      {/* <Title2>
        Suivi de{" "}
        <span style={{ color: rootStyles.color.tercary }}>
          {suiviCurrentBakeliste.first_name} {suiviCurrentBakeliste.last_name}
        </span>
      </Title2> */}
      <Div className="container-fluid">
        <Div className="row">
          <Div className="col-3 bg-white">
            <Div className="sticky-top">
              {/* img */}
              <Div className="d-flex justify-content-center justify-content-center mt-2">
                {suiviCurrentBakeliste.avatar_file !== null ? (
                  <img
                    src={
                      "https://via-api.bakeli.tech" +
                      suiviCurrentBakeliste.avatar_file
                    }
                    alt="bakeliste_profil"
                    className="img-fluid img-thumbnail rounded-circle"
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <img
                      src="https://e7.pngegg.com/pngimages/84/165/png-clipart-united-states-avatar-organization-information-user-avatar-service-computer-wallpaper-thumbnail.png"
                    alt="bakeliste_profil"
                    className="img-fluid img-thumbnail rounded-circle"
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "cover",
                    }}
                  />
                )}
              </Div>
              <Div className="">
                <p className="text-xl-start text-lg-start text-center mt-md-2 fw-bold fs-5">
                  {`${suiviCurrentBakeliste?.first_name} ${suiviCurrentBakeliste?.last_name}`}
                </p>
                <Div className="text-xl-start text-lg-start text-center">
                  <span
                    className="badge rounded-pill"
                    style={{ backgroundColor: rootStyles.color.secondary }}
                  >
                    {suiviCurrentBakeliste?.domaine?.name}
                  </span>
                </Div>
                <Div className={"mt-4"}>
                  <p style={{ fontSize: 14 }} className="fw-semibold">
                    Progression global du syllabus
                  </p>
                  <ProgressBar
                    max={100}
                    value={
                      currentbakelisteGlobalProgression?.globalProgression ?? 0
                    }
                    color={rootStyles.color.tercary}
                    height={"15px"}
                    width="100%"
                  />
                </Div>
                <Div className={"my-3"}>
                  <ReactToPrint
                    pageStyle="p-1"
                    content={() => porfolioRef.current}
                    trigger={() => (
                      <ButtonComp
                        title="Imprimer Portfolio"
                        bgColor={rootStyles.color.secondary}
                        color={rootStyles.color.primary}
                        icon={{
                          name: AiOutlinePrinter,
                          size: 20,
                        }}
                      />
                    )}
                    documentTitle={`Portfolio ${suiviCurrentBakeliste.first_name} ${suiviCurrentBakeliste.last_name}`}
                  />
                </Div>
                <LabelText label="Email" text={suiviCurrentBakeliste?.email} />
                <LabelText label="Téléphone" text={suiviCurrentBakeliste?.phone} />
                <LabelText label="Adresse" text={suiviCurrentBakeliste?.address} />
                <LabelText label="Début formation" text={completeDateFormatter(
                  suiviCurrentBakeliste?.debut_formation
                )} />
                <LabelText label="Fin formation" text={completeDateFormatter(
                  suiviCurrentBakeliste?.fin_formation
                )} />
                <LabelText label={"Type de formation"} text={suiviCurrentBakeliste?.type_formation} />
                <LabelText label={"Situation matrimoniale"} text={suiviCurrentBakeliste?.marital_status} />
                <LabelText label={"Coach"} text={suiviCurrentBakeliste?.coach_fullName} />
                <LabelText label={"Coach assistant"} text={suiviCurrentBakeliste?.coach_assistant_fullName} />

              </Div>
            </Div>
          </Div>

          <Div className="col-9">
            <ul
              className="nav nav-pills mb-3 overflow-auto flex-nowrap"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <Link
                  className={`nav-link d-flex align-items-center ${splitLocation[3] === undefined ||
                    splitLocation[3] === "details"
                    ? "active"
                    : ""
                    }`}
                  to={""}
                >
                  <HiOutlineBookOpen size={25} className="me-2" />
                  Syllabus
                </Link>
              </li>
              <li className="nav-item" role="presentation">
                <Link
                  className={`nav-link d-flex align-items-center ${splitLocation[3] === "bulletins" ? "active" : ""
                    }`}
                  to={"bulletins"}
                >
                  <HiDocumentReport size={25} className="me-2" />
                  Bulletins
                </Link>
              </li>
              <li className="nav-item" role="presentation">
                <Link
                  className={`nav-link d-flex align-items-center ${splitLocation[3] === "pointages" ? "active" : ""
                    }`}
                  to={"pointages"}
                >
                  <BiTimeFive size={25} className="me-2" />
                  Pointages
                </Link>
              </li>
              <li className="nav-item" role="presentation">
                <Link
                  className={`nav-link d-flex align-items-center ${splitLocation[3] === "livraisons" ? "active" : ""
                    }`}
                  to={"livraisons"}
                >
                  <BiTimeFive size={25} className="me-2" />
                  Livraisons
                </Link>
              </li>
            </ul>
            <hr />
            <Div className="tab-content" id="pills-tabContent" mh={25}>
              <Outlet />
            </Div>
          </Div>
        </Div>
      </Div>
    </FadeTransition>
  );
};

export default DetailsSuiviBakeliste;
