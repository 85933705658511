import { rootStyles } from "assets/css/globalCss"
import { BAKELISTES_ACTIFS_PATH, BAKELISTES_ARCHIVES_PATH, BAKELISTES_INACTIFS_PATH, BAKELISTES_TERMINES_PATH } from "routes/navigation/navigationPaths"

/**
 * @enum
 */
export const DUREE_FORMATION = {
    THREE_MONTH: "3",
    SIX_MONTH: "6",
    YEAR: "12",
    TWO_YEAR: "24"
}
/**
 * @enum
 */
export const FORMATION_BAKELI_DUREE = [
    {
        id: DUREE_FORMATION.THREE_MONTH,
        label: "3 mois"
    },
    {
        id: DUREE_FORMATION.SIX_MONTH,
        label: "6 mois"
    },
    {
        id: DUREE_FORMATION.YEAR,
        label: "12 mois"
    },
    {
        id: DUREE_FORMATION.TWO_YEAR,
        label: "24 mois"
    },
]
/**
 * @readonly
 * @enum
 */
export const VILLE_FORMATION = {
    dakar: "Dakar",
    thies: "Thies",
    mbour: "Mbour",
    labe: "Labe"
}
/**
 * @enum
 */
export const CAMPUS_VILLES = [
    {
        id: VILLE_FORMATION.dakar,
        label: VILLE_FORMATION.dakar
    },
    {
        id: VILLE_FORMATION.thies,
        label: VILLE_FORMATION.thies
    },
    {
        id: VILLE_FORMATION.mbour,
        label: VILLE_FORMATION.mbour
    },
    {
        id: VILLE_FORMATION.labe,
        label: VILLE_FORMATION.labe
    },
]
/**
 * @enum
 * @readonly
 */
export const FORMATION_STATES = ["Terminé", "archivé", "actif", "en pause"]
/**
 * @enum
 * @readonly
 */
export const FORMATION_STATES_MAP = [
    {
        id: FORMATION_STATES[0],
        color: rootStyles.color.red,
        label: "Terminé",
        to: BAKELISTES_TERMINES_PATH
    },
    {
        id: FORMATION_STATES[1],
        color: rootStyles.color.tercary,
        label: "Archivé",
        to: BAKELISTES_ARCHIVES_PATH
    },
    {
        id: FORMATION_STATES[2],
        color: rootStyles.color.secondary,
        label: "Actif",
        to: BAKELISTES_ACTIFS_PATH
    },
    {
        id: FORMATION_STATES[3],
        color: rootStyles.color.blue,
        label: "En pause",
        to: BAKELISTES_INACTIFS_PATH
    },
]
/**
 * @enum
 * @readonly
 */
export const FORMATION_STATE_COLORS = {
    "Terminé": rootStyles.color.red,
    "archivé": rootStyles.color.tercary,
    "actif": rootStyles.color.secondary,
    "en pause": rootStyles.color.blue
}