import { useBakelisteActif } from "actions/bakelistes"
import { useMettreEnLigneMutation } from "actions/bakelistes/mutatons"
import ConfirmAction from "components/shared/ConfirmAction"
import React, { memo } from "react"
import toast from "react-hot-toast"

const MettreEnLigneFeature = ({ openConfirmModal, setOpenConfirmModal, selectedBakeliste }) => {
    const [placerBakelisteEnLigne, { isLoading }] = useMettreEnLigneMutation()
    const { refetchBakelisteActif } = useBakelisteActif()
    const placerLeBakelisteEnLigneFunc = () => {
        placerBakelisteEnLigne(selectedBakeliste.id).unwrap()
            .then(() => {
                refetchBakelisteActif()
                toast.success(`${selectedBakeliste.first_name} est mis en ligne`)
                setOpenConfirmModal(false)
            })
            .catch((err) => {
                toast.error("Une erreur est survenue")
            })
    }
    return (
        <div>
            <ConfirmAction
                modalIsOpen={openConfirmModal}
                modalText="Mettre en ligne"
                text={`Êtes-vous sûr de vouloir mettre ${selectedBakeliste?.first_name} ${selectedBakeliste?.last_name} en ligne ?`}
                cancel={() => setOpenConfirmModal(false)}
                validate={placerLeBakelisteEnLigneFunc}
                closeModal={() => setOpenConfirmModal(false)}
                loading={isLoading}
            />
        </div>
    )
}

export default memo(MettreEnLigneFeature)