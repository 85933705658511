export function regrouperParDomaine(utilisateurs) {
  const groupes = {};

  utilisateurs.forEach((utilisateur) => {
    const domaine = utilisateur.domaine;
    if (domaine in groupes) {
      groupes[domaine].push(utilisateur);
    } else {
      groupes[domaine] = [utilisateur];
    }
  });

  return groupes;
}