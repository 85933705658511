import React from "react"
import { rootStyles } from "assets/css/globalCss"
import { NavLink } from "react-router-dom"
import styled from "styled-components"

export const LinkItem = styled(NavLink)`
  position: relative;
  display: flex;
  padding-left: 0px;
  padding-right: 0;
  padding: 2px;
  border-radius: ${rootStyles.radius.card};
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0 5px 5px;
  overflow: hidden;
  text-decoration: none;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
  color: ${({ color }) => color};

  &:focus {
    color: ${rootStyles.color.tercary};
    background-color: ${rootStyles.color.tercary};
    // border-right: 5px solid ${rootStyles.color.tercary};
  }

  &:hover {
    color: ${({ colorOnHover }) => colorOnHover};
    background-color: ${({ bgOnHover }) => bgOnHover};
    border-right: 2px solid ${rootStyles.color.tercary};
  }

  &.active {
    color: ${({ colorOnActive }) => colorOnActive};
    background-color: rgba(20, 30, 40, 0.5);
    border-right: 4px solid ${rootStyles.color.tercary};
    font-weight: ${rootStyles.fonts.btnWeight};
  }
  @media only screen and (max-width: 500px) {
    font-size: 16px;
  }
`;

export const SubLinkItem = styled(NavLink)`
  position: relative;
  display: flex;
  padding-left: 0px;
  padding-right: 0;
  border-radius: ${rootStyles.radius.card};
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin: 8px 5px 0px;
  overflow: hidden;
  text-decoration: none;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
  color: ${({ color }) => color};

  &:focus {
    color: ${rootStyles.color.tercary};
    background-color: ${rootStyles.color.tercary};
    // border-right: 5px solid ${rootStyles.color.tercary};
  }

  &:hover {
    color: ${({ colorOnHover }) => colorOnHover};
    background-color: ${({ bgOnHover }) => bgOnHover};
    border-right: 1px solid ${rootStyles.color.tercary};
  }

  &.active {
    color: ${({ colorOnActive }) => colorOnActive};
    background-color: rgba(20, 30, 40, 0.5);
    border-right: 2px solid ${rootStyles.color.tercary};
    font-weight: ${rootStyles.fonts.btnWeight};
  }
  @media only screen and (max-width: 500px) {
    font-size: 16px;
  }
`;

const SidebarItem = ({ linkItem, openedMenu }) => {
    const { path, label } = linkItem;
    return (
        <LinkItem
            color={rootStyles.color.primary}
            colorOnHover={rootStyles.color.primary}
            colorOnActive={rootStyles.color.tercary}
            bgOnHover={rootStyles.color.primary05}
            // bgOnHover={"rgba(255, 2500, 240, 1)"}
            to={path}
        >
            <span
                style={{
                    height: 35,
                    width: 40,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <linkItem.icon size={16} className="icon" />
            </span>
            {openedMenu && (
                <span
                    translate="no"
                    style={{
                        // position: "absolute",
                        display: "inline-block",
                        // paddingRight: 20,
                        textDecoration: "none",
                        left: 45,
                        fontSize: "14px",
                        width: "100%",
                    }}
                >
                    {label}
                </span>
            )}
        </LinkItem>
    );
};

export default SidebarItem

export const SubSidebarItem = ({ linkItem, openedMenu }) => {
    const { path, label } = linkItem;
    return (
        <SubLinkItem
            color={rootStyles.color.primary}
            colorOnHover={rootStyles.color.secondary}
            colorOnActive={rootStyles.color.tercary}
            bgOnHover={rootStyles.color.primary}
            to={path}
        >
            <span
                style={{
                    height: 35,
                    width: 40,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <linkItem.icon size={10} className="icon" />
            </span>
            {openedMenu && (
                <span
                    translate="no"
                    style={{
                        // position: "absolute",
                        display: "inline-block",
                        // paddingRight: 20,
                        textDecoration: "none",
                        left: 45,
                        fontSize: "14px",
                        width: "100%",
                    }}
                >
                    {label}
                </span>
            )}
        </SubLinkItem>
    );
};