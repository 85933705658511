import { rootStyles } from "assets/css/globalCss"
import React from "react"
import { FcFolder } from "react-icons/fc"
// import { useNavigate } from 'react-router-dom'
// import { EXAMENS_TRIMESTRE_PATH } from 'routes/navigation/navigationPaths'

const AnneeCardItem = ({ annee, goToAnneeAcademiqueTrimestre }) => {
    // console.log({ annee });
    return (
        <div
            onClick={() => goToAnneeAcademiqueTrimestre(annee[0], annee[1])}
            style={{
                backgroundColor: "white",
                padding: "5px 10px",
                // color: rootStyles.color.secondary,
                borderRadius: rootStyles.radius.card,
                boxShadow: rootStyles.shadow.card,
                marginTop: 5,
                marginBottom: 5,
                cursor: "pointer"
            }}
            className='d-flex gap-2 align-items-center justify-content-between'
        >
            <FcFolder size={50} />
            <span className='fw-bold'>
                {annee[0]}
            </span>
            {/* 2023 */}
        </div>
    )
}

export default AnneeCardItem