import React, { useContext, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import ResourceDataMap from "components/ResourceDataMap";
import { links } from "routes/navigation/links";
import { LinkItem } from "components/SidebarItem";
import { AiOutlineClose } from "react-icons/ai";
import { DrawerContext } from "context/useDrawerContext";
import { rootStyles } from "assets/css/globalCss";

const DrawerContent = styled.div`
  width: 200px;
  background-color: #069a77;
  height: 100%;
`;

const Drawer = () => {
    const drawerRef = useRef(null);

    const { closeMenu, isMenuOpen } = useContext(DrawerContext);

    if (!isMenuOpen) {
        return null;
    }

    return (
        <motion.div
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.1 }}
            onClick={() => closeMenu()}
            style={{
                position: "fixed",
                width: "100%",
                height: "100vh",
                zIndex: "999",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
        >
            <DrawerContent
                // style={{ width: `${modalWidth}px` }}
                ref={drawerRef}
            >
                <div className="d-flex justify-content-end mb-2 p-2">
                    <button className="btn border px-2 py-1">
                        <AiOutlineClose size={20} color="#fff" />
                    </button>
                </div>
                <ResourceDataMap
                    resourceData={links}
                    resourceItem={({ linkItem }) => (
                        <LinkItem
                            to={linkItem.path}
                            color={rootStyles.color.primaryWithRgba}
                            colorOnHover={rootStyles.color.secondary}
                            colorOnActive={rootStyles.color.secondary}
                            bgOnHover={rootStyles.color.primary}
                        >
                            <span
                                style={{
                                    height: 35,
                                    width: 40,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    //   color: "#fff",
                                }}
                            >
                                <linkItem.icon size={18} className="icon" />
                            </span>

                            <span
                                translate="no"
                                style={{
                                    // position: "absolute",
                                    display: "inline-block",
                                    // paddingRight: 20,
                                    textDecoration: "none",
                                    left: 45,
                                    // fontSize: "12px",
                                    width: "100%",
                                    //   color: "#fff",
                                }}
                            >
                                {linkItem.label}
                            </span>
                        </LinkItem>
                    )}
                    resourceName="linkItem"
                />
            </DrawerContent>
        </motion.div>
    );
};

export default Drawer;
