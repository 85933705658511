import { ADD_SPACE_ENDPOINT, EDIT_SPACE_ENDPOINT } from "routes/api/endpoints";

const { siAdminAPi } = require("redux/rtkquery");

const spacesApi = siAdminAPi.injectEndpoints({
    endpoints: (builder) => ({
        addSpace: builder.mutation({
            query: (data) => {
                return {
                    url: ADD_SPACE_ENDPOINT,
                    method: "POST",
                    body: data
                }
            },
            invalidatesTags: ["spaces"],
        }),
        updateSpace: builder.mutation({
            query: ({ data, id }) => {
                return {
                    url: EDIT_SPACE_ENDPOINT + "/" + id,
                    method: "POST",
                    body: data
                }
            },
            invalidatesTags: ["spaces"],
        })
    }),
});

export const { useAddSpaceMutation, useUpdateSpaceMutation } = spacesApi