import { rootStyles } from "assets/css/globalCss";
import FlexBetween from "components/shared/FlexBetween";
import React from "react";
import { BsCheckCircle } from "react-icons/bs";
import styled from "styled-components";

const CardPane = styled.div`
  min-height: 70px;
  background-color: white;
  border-radius: ${rootStyles.radius.card};
  padding: ${rootStyles.padding.btnPadding};
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: all 100ms ease;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  &:active {
    transform: scale(0.98, 0.98);
  }
`;
const YearSpan = styled.span`
  font-weight: ${rootStyles.fonts.btnWeight};
`;
const NumberSpan = styled.span`
  font-weight: ${rootStyles.fonts.btnWeight};
  color: ${rootStyles.color.red};
`;

const ValidationByYearCardItem = ({ validation, navigateToValidations }) => {
  // console.log({ validation });
  return (
    <CardPane onClick={() => navigateToValidations(validation)}>
      <BsCheckCircle size={22} color={rootStyles.color.secondary} />
      <FlexBetween>
        <YearSpan>{validation[0]}</YearSpan>
        <NumberSpan>{validation[1].length}</NumberSpan>
      </FlexBetween>
    </CardPane>
  );
};

export default ValidationByYearCardItem;
