import React from "react"
import InfoItem from "./InfoItem"

const ApercuInfosSupplementaire = ({ bakelisteInfos }) => {
  return (
    <div>
        <h4>Informations supplémentaire</h4>
        <div className='container-fluid'>
        <InfoItem
          label1="Début formation"
          label2="Fin formation"
          data1={bakelisteInfos.debut_formation}
          data2={bakelisteInfos.fin_formation}
        />
        <div className='row apercu--alone border-bottom py-2'>
            <div className='col-6'>
                <span>Objectifs : {bakelisteInfos.objectifs}</span>
            </div>
            <div className='col-6'>
                <span>Commentaire : {bakelisteInfos.commentaire}</span>
            </div>
        </div>
        </div>
    </div>
  )
}

export default ApercuInfosSupplementaire