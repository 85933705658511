import dayjs from "dayjs";
import "dayjs/locale/fr";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

export const dateFormatter = (date) => {
  dayjs.locale("fr");
  return dayjs(date).format("DD/MM/YYYY");
};

export const monthYearFormatter = (date) => {
  dayjs.locale("fr");
  return dayjs(date).format("MMMM YYYY");
};

export const yearMonthDayFormatter = (date) => {
  dayjs.locale("fr");
  return dayjs(date).format("YYYY-MM-DD");
};

export const today = () => {
  dayjs.locale("fr");
  return dayjs().format("YYYY-MM-DD");
};

export const longDateFormatter = (date) => {
  dayjs.locale("fr");
  return dayjs(date).format("dddd DD MMMM YYYY à hh:mm");
};

export const completeDateFormatter = (date) => {
  dayjs.locale("fr");
  return dayjs(date).format("DD MMMM YYYY");
};

export const durationFormatter = (start_date, end_date) => {
  return dayjs(start_date).to(end_date, true);
};
