import { MOIS_ANNEE } from "constants/joursFormations";
import React, { createContext, useContext, useEffect, useState } from "react"
import { useLocation, useSearchParams } from "react-router-dom";
import { SUIVI_POINTAGE_PATH } from "routes/navigation/navigationPaths";

export const CalendrierPresenceContext = createContext()

const CalendrierPresenceProvider = ({ children }) => {
    const [currentMonth, setCurrentMonth] = useState(MOIS_ANNEE[new Date().getMonth()])
    const [searchParams, setSearchParams] = useSearchParams()
    const { pathname, search } = useLocation()
    const [currentVille, setCurrentVille] = useState(searchParams.get("zone"))

    useEffect(() => {
        if (pathname === SUIVI_POINTAGE_PATH && !search) {
            // ! Valeur initial et par defaut du query params
            setCurrentVille("")
            setSearchParams({ zone: "" })
        }
        if (currentVille) {
            setSearchParams({ zone: currentVille })
        }
        if (searchParams.get("zone")) {
            setCurrentVille(searchParams.get("zone"))
        }
    }, [searchParams.get("zone")])


    const changeZoneQueryParams = (zone) => {
        setSearchParams({ zone });
        setCurrentVille(zone)
    }

    const setCurrentPointageMonth = (month) => {
        setCurrentMonth(month)
    }

    return (
        <CalendrierPresenceContext.Provider value={{
            currentMonth,
            setCurrentPointageMonth,
            currentVille,
            // setCurrentPointageVille,
            changeZoneQueryParams
        }} >
            {children}
        </CalendrierPresenceContext.Provider>
    )
}

export const useCalendrierPresenceContext = () => {
    const ctx = useContext(CalendrierPresenceContext)

    if (!ctx) throw new Error("CalendrierPresenceContext is not available")
    return ctx
}
export default CalendrierPresenceProvider
