import { rootStyles } from "assets/css/globalCss"
import Flex from "components/shared/Flex"
import Loader from "components/shared/Loader"
import React from "react"
import { FaUsers } from "react-icons/fa"
import { Link } from "react-router-dom"
import styled from "styled-components"

const CardContainer = styled.div`
    background-color: ${rootStyles.color.secondaryFonce};
    color:white;
    border-radius: ${rootStyles.radius.card};
    min-height: 250px;
    padding: ${rootStyles.padding.btnPadding};
    display: flex;
    flex-direction: column;
    opacity: ${({ isnotclickable }) => isnotclickable ? .45 : 1};
`

const CardImg = styled.div`
    flex:4;
    background-color: rgba(255,200,150,.2);
    height: 150px;
    border-radius: ${rootStyles.radius.card};
`

const Title = styled.h6`
    font-size: ${rootStyles.fonts.fontSize};

    @media only screen and (max-width: 500px) {
    font-size: 12px;
  }
`

const CardBody = styled.div`
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height:100%;
    padding-top: ${rootStyles.padding.btnPadding};
`
const SpanPane = styled.span`
font-weight: 600;
   @media only screen and (max-width: 500px) {
    font-size: 12px;
    font-weight: 500
  }
`

const TypeBakelisteCardItem = ({ kpi }) => {
    const { title, number, to, isNotClickable, description } = kpi

    return (
        <Link style={{ textDecoration: "none", cursor: isNotClickable ? "not-allowed" : "pointer" }} to={isNotClickable ? null : to}>
            <CardContainer isnotclickable={isNotClickable}>
                <CardImg>
                    <Title ></Title>
                </CardImg>
                <CardBody>
                    <Title>{title}</Title>
                    <div style={{ borderTop: "1px dashed white" }} className='d-flex justify-content-between align-items-center pt-3'>
                        <Flex gap={10} align={"flex-end"}>
                            <FaUsers size={20} /> {description && <span style={{ fontSize: 12 }}>{description}</span>}
                        </Flex>
                        <SpanPane>{isNotClickable ? <Loader /> : number}</SpanPane>
                    </div>
                </CardBody>
            </CardContainer>
        </Link>
    )
}

export default TypeBakelisteCardItem