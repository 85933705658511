export const visiteursOptions = {
    indexAxis: "x",
    elements: {
        bar: {
            borderWidth: 10,
        },
    },
    // responsive: true,
    plugins: {
        legend: {
            position: "bottom",
        },
        title: {
            display: true,
            text: "Visiteurs",
        },
        datalabels: {
            display: true,
            color: "white",
            align: "start",
            padding: {
                right: 0,
                top: -15,
            },
            labels: {
                padding: { top: 0 },
                title: {
                    font: {
                        weight: "normal",
                    },
                },
                value: {
                    color: "white",
                },
            },
            formatter: function (value) {
                return value;
            },
        },
    },
};
export const prospectsOptions = {
    indexAxis: "x",
    elements: {
        bar: {
            borderWidth: 10,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            position: "bottom",
        },
        title: {
            display: true,
            text: "Prospects",
        },
        datalabels: {
            display: true,
            color: "white",
            align: "start",
            padding: {
                right: 0,
                top: -15,
            },
            labels: {
                padding: { top: 0 },
                title: {
                    font: {
                        weight: "normal",
                    },
                },
                value: {
                    color: "white",
                },
            },
            formatter: function (value) {
                return value;
            },
        },
    },
};
export const bakelisteOptions = {
    indexAxis: "x",
    elements: {
        bar: {
            borderWidth: 10,
        },
    },
    // responsive: true,
    plugins: {
        legend: {
            position: "bottom",
        },
        title: {
            display: true,
            text: "Bakelistes",
        },
        datalabels: {
            display: true,
            color: "white",
            align: "start",
            padding: {
                right: 0,
                top: -15,
            },
            labels: {
                padding: { top: 0 },
                title: {
                    font: {
                        weight: "normal",
                    },
                },
                value: {
                    color: "white",
                },
            },
            formatter: function (value) {
                return value;
            },
        },
    },
};
