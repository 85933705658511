import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "components/Input";
import {
  filterProspectByDate,
  setFilterByAllProspects,
  setFilterByCalledProspects,
  setFilterByNotCalledProspects,
} from "redux/features/prospectSlice";
import Checkbox from "components/shared/Checkbox";

import PaginationItems from "components/shared/PaginationItems";
import ListeProspects from "./ListeProspects";
// import FlexBetween from "components/shared/FlexBetween";
import ButtonComp from "components/ButtonComp";
import { BiSearch } from "react-icons/bi";
import FadeTransition from "components/shared/FadeTransition";
import { Bar } from "react-chartjs-2";
import { groupByCanalInfosData } from "utils/groupByCanalInfos";
import { useProspectAnaliticsInfo } from "hooks/useProspectsAnaliticsInfo";
const ListeProspectsPage = () => {
  const dispatch = useDispatch();
  const {
    prospectsList,
    filterByCalledProspects,
    filterByNotCalledProspects,
    filterByAllProspects,
  } = useSelector((state) => state.prospects);

  const prospectsGroupByCanal = groupByCanalInfosData(prospectsList)
  const { options, data } = useProspectAnaliticsInfo(prospectsGroupByCanal)

  const [date_debut, setDateDebut] = useState({ value: "", errorMessage: "" });
  const [date_fin, setDateFin] = useState({ value: "", errorMessage: "" });

  return (
    <FadeTransition in={true}>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div
          style={{ flex: 2, minWidth: "300px" }}
          className="d-flex align-items-center gap-2"
        >
          {/* <Title2>Liste des prospects</Title2>{" "} */}
          <div className="d-flex align-items-center gap-3 flex-wrap mt-3">
            <Checkbox
              label="Tous"
              checked={filterByAllProspects}
              onChange={(checked) => dispatch(setFilterByAllProspects(checked))}
            />
            <Checkbox
              label="Appelés"
              checked={filterByCalledProspects}
              onChange={(checked) =>
                dispatch(setFilterByCalledProspects(checked))
              }
            />
            <Checkbox
              label="Non appelés"
              checked={filterByNotCalledProspects}
              onChange={(checked) =>
                dispatch(setFilterByNotCalledProspects(checked))
              }
            />
          </div>
        </div>
        <div style={{ flex: 2, minWidth: "300px" }} className="mt-3 mt-sm-0">
          {/* <Input
            value={inputSearch}
            placeholder="Filtrer ici par type formation"
            onChange={(value) => {
              setInputSearch(value);
              dispatch(filterProspectsList(value));
            }}
          /> */}
          <div className="d-flex align-items-center flex-sm-no-wrap gap-2 px-2">
            <Input
              // label="Date début"
              type="date"
              value={date_debut.value}
              // value={Date(date_debut.value).toLocaleString("fr-Fr", {
              //   dateStyle: "full",
              // })}
              onChange={(value) => {
                setDateDebut({ value, errorMessage: "" });
              }}
              errorMessage={date_debut.errorMessage}
            />
            <Input
              // label="Date fin"
              type="date"
              value={date_fin.value}
              onChange={(value) => {
                setDateFin({ value, errorMessage: "" });
              }}
            />
            <ButtonComp
              onClick={() => {
                if (new Date(date_debut) >= new Date(date_fin)) {
                  return setDateDebut((date_debut) => ({
                    ...date_debut,
                    errorMessage: "Date de début supérieur à la date de fin",
                  }));
                }
                dispatch(filterProspectByDate({ date_debut, date_fin }));
              }}
              icon={{
                name: BiSearch,
              }}
            />
          </div>
        </div>
      </div>
      <div className="mt-4" style={{ maxWidth: "800px" }}>
        <Bar options={options} data={data} />
      </div>
      <div className="mt-3">
        {/* {date_debut.value && date_fin.value && (
          <Title2>
            Prospects du{" "}
            {Date(date_debut.value).toLocaleString("fr-Fr", {
              dateStyle: "short",
            })}{" "}
            au{" "}
            {Date(date_fin.value).toLocaleString("fr-Fr", {
              dateStyle: "short",
            })}
          </Title2>
        )} */}
        <PaginationItems ressourceData={prospectsList} itemsPerPage={15}>
          <ListeProspects />
        </PaginationItems>
      </div>
    </FadeTransition>
  );
};

export default ListeProspectsPage;
