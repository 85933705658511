import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    currentExamen: {},
    examensList: [],
    allExamensList: [],
    trimestreByAnneeList: [],
    anneeAcademiqueList: [],
    currentAnneeAcademique: null,
    currentTrimestre: null,
    currentEpreuveType: "",
    currentEpreuveModule: "",
    currentEpreuveDomaine: "",
    epreuveTheorique: [],
    epreuveTheoriqueList: [],
    epreuvePratique: [],
    epreuvePratiqueList: [],
    currentTrimestreBulletins: [],
    currentBulletin: null
}

/**
* !Some Examenslice reducers are used in ExamenApi.js located in
redux/apiContoller/
*/

const examenSlice = createSlice({
    name: "examens",
    initialState,
    reducers: {
        setCurrentExamenSlice: (state, { payload }) => {
            state.currentExamen = payload
        },
        setTrimestreByAnnee: (state, { payload }) => {
            state.trimestreByAnneeList = payload
        },
        setExamensListSlice: (state, { payload }) => {
            state.examensList = payload
            state.allExamensList = payload
        },
        setAnneeAcademiqueListSlice: (state, { payload }) => {
            return {
                ...state,
                anneeAcademiqueList: payload
            }
        },
        setCurrentAnneeAcademique: (state, { payload }) => {
            return {
                ...state,
                currentAnneeAcademique: payload
            }
        },
        setCurrentTrimestre: (state, { payload }) => {
            return {
                ...state,
                currentTrimestre: payload
            }
        },
        setCurrentEpreuveType: (state, { payload }) => {
            return {
                ...state,
                currentEpreuveType: payload
            }
        },
        setCurrentEpreuveModule: (state, { payload }) => {
            return {
                ...state,
                currentEpreuveModule: payload
            }
        },
        setCurrentEpreuveDomaine: (state, { payload }) => {
            return {
                ...state,
                currentEpreuveDomaine: payload
            }
        },
        setEpreuveTheorique: (state, { payload }) => {
            return {
                ...state,
                epreuveTheorique: payload
            }
        },
        setEpreuvePratique: (state, { payload }) => {
            return {
                ...state,
                epreuvePratique: payload
            }
        },
        setEpreuveTheoriqueList: (state, { payload }) => {
            return {
                ...state,
                epreuveTheoriqueList: payload
            }
        },
        setEpreuvePratiqueList: (state, { payload }) => {
            return {
                ...state,
                epreuvePratiqueList: payload
            }
        },
        setCurrentTrimestreBulletins: (state, { payload }) => {
            // console.log({ payload });
            return {
                ...state,
                currentTrimestreBulletins: payload
            }
        },
        setCurrentBulletin: (state, { payload }) => {
            // console.log({ payload });
            return {
                ...state,
                currentBulletin: payload
            }
        },
        addNewAnneeAcademiqueListSlice: (state, { payload }) => {
            return {
                ...state,
                anneeAcademiqueList: { ...state.anneeAcademiqueList, ...payload }
            }
        },
        addNewExamenSlice: (state, { payload }) => {
            state.allExamensList = [...state.allExamensList, payload].reverse()
            state.examensList = [...state.allExamensList, payload].reverse()
        },
        updateExamenSlice: (state, { payload }) => {
            let examens = state.allExamensList.filter((examen) => examen.id !==
                payload.id)
            examens = [...examens, payload].reverse()
            state.allExamensList = examens
            state.examensList = examens
        },
        archiveExamenSlice: (state, { payload }) => {
            const examens = state.allExamensList.filter((examen) => examen.id !==
                payload.id)
            state.examensList = examens
            state.allExamensList = examens
        },
        filterFournisseuList: (state, { payload }) => {
            /**
            * @param payload {string}
            */
            state.examensList = state.allExamensList.filter((item) => {
                const data = ["******** PUT RESOURCE PROPERTIES HERE *********"].join(" ").toLowerCase()
                return data.includes(payload.toLowerCase())
            })
        }
    }
}
)


export const {
    setTrimestreByAnnee,
    setAnneeAcademiqueListSlice,
    addNewAnneeAcademiqueListSlice,
    setCurrentAnneeAcademique,
    setExamensListSlice,
    setCurrentTrimestre,
    setCurrentEpreuveType,
    setEpreuveTheorique,
    setEpreuvePratique,
    setCurrentEpreuveModule,
    setCurrentTrimestreBulletins,
    setCurrentEpreuveDomaine,
    setEpreuvePratiqueList,
    setEpreuveTheoriqueList,
    setCurrentBulletin
} = examenSlice.actions

export default examenSlice.reducer