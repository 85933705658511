import TableListeView from "components/shared/TableListeView"
import React, { useMemo, useState } from "react"
import { tooltip_options } from "utils/tooltip_options"
import { Tooltip as ReactTooltip } from "react-tooltip";
import { AiOutlineEdit } from "react-icons/ai"
import { rootStyles } from "assets/css/globalCss";
import { useDispatch } from "react-redux";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { DETAILS_BAKELISTE_PATH, EDIT_BAKELISTE_PATH } from "routes/navigation/navigationPaths";
import { setCurrentBakelisteSlice } from "redux/features/bakelisteSlice";
// import ButtonComp from "components/ButtonComp";
// import PaiementModal from "pages/paiements/ui/PaiementModal";
import { textFilter } from "react-bootstrap-table2-filter";
// import { useBakelistePresentiel } from "./api/hooks/useBakelistePresentiel";
import Loader from "components/shared/Loader";
import FadeTransition from "components/shared/FadeTransition";
import ButtonComp from "components/ButtonComp";
// import { useMettreEnLigneMutation } from "actions/bakelistes/mutatons";
// import toast from "react-hot-toast";
// import ConfirmAction from "components/shared/ConfirmAction";
import MettreEnLigneFeature from "features/mettreEnLigne/MettreEnLigneFeature";
import { HiUsers } from "react-icons/hi";
import Flex from "components/shared/Flex";

const ListeBakelistesPresentielPage = ({ bakelistePresentiel, isPresentielLoading }) => {
// const { bakelistePresentiel, isPresentielLoading } = useBakelistePresentiel()
    // const [placerBakelisteEnLigne, { isLoading }] = useMettreEnLigneMutation()

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // const [openModalPaiement, setOpenModalPaiement] = useState(false);
    const [selectedBakeliste, setSelectedBakeliste] = useState(null);
    const [openConfirmModal, setOpenConfirmModal] = useState(false)

    const navigateToBakelisteDetails = (bakeliste) => {
        dispatch(setCurrentBakelisteSlice(bakeliste))
        navigate(DETAILS_BAKELISTE_PATH)
        return
    }
    const goToEditPage = (bakeliste) => {
        dispatch(setCurrentBakelisteSlice(bakeliste))
        navigate(EDIT_BAKELISTE_PATH)
        return
    }

    // ! A ne pas supprimer
    // const actionsPaiementFormatter = (cell, row) => {
    //     return (
    //         <div className="d-flex justify-content-center">
    //             <ButtonComp
    //                 title="Payez ici"
    //                 onClick={() => {
    //                     setOpenModalPaiement(true);
    //                     setSelectedBakeliste(row);
    //                 }}
    //             />
    //         </div>
    //     );
    // };

    const actionsFormatter = (cell, row) => {
        return <div className='d-flex gap-4 justify-content-center'>
            <FaEye
                onClick={() => navigateToBakelisteDetails(row)}
                data-tip data-tooltip-id='details-btn'
                className='cursor-pointer'
                size={20}
                color={rootStyles.color.secondary}
            />
            <AiOutlineEdit
                onClick={() => goToEditPage(row)}
                data-tip data-tooltip-id='edit-btn'
                className='cursor-pointer'
                size={20}
                color={rootStyles.color.tercary}
            />

            <ReactTooltip style={tooltip_options} id='details-btn'>
                Détails
            </ReactTooltip>
            <ReactTooltip style={tooltip_options} id='edit-btn'>
                Modifier
            </ReactTooltip>
        </div>
    }
    const columns = useMemo(() => [{
        dataField: "first_name",
        text: "Nom complet",
        filter: textFilter({
            placeholder: "Recherche pas nom complet",
        }),
        formatter: (cell, row) =>
            <p style={{ cursor: "pointer" }} onClick={() => navigateToBakelisteDetails(row)}
            >
                {row.first_name} {row.last_name}
            </p>
    },
    {
        dataField: "email",
        text: "Email",
        filter: textFilter({
            placeholder: "Recherche par mail",
        })
        },
        {
            dataField: "phone",
            text: "Téléphone",
            filter: textFilter({
                placeholder: "Recherche par telephone",
            })
        },
        {
        dataField: "domaine.name",
        text: "Domaines",
    },
    {
        dataField: "Actions",
        text: "Actions",
        formatter: actionsFormatter
        },
        // ! A ne pas supprimer
        // {
        //     dataField: "paiements",
        //     text: "Paiements",
        //     formatter: actionsPaiementFormatter,
        // },
        {
            dataField: "placer-en-ligne",
            text: "Formation",
            formatter: (cell, row) => <Flex justify={"center"}>
                <ButtonComp
                    onClick={() => {
                        console.log({ row });
                        setOpenConfirmModal(true)
                        setSelectedBakeliste(row)
                    }}
                    title={"Placer en ligne"}
                />
            </Flex>
        }
    ], [])

    return (
        <FadeTransition in={true}>
            {/* A ne pas supprimer */}
            {/* {openModalPaiement && (
                <PaiementModal
                    opened={openModalPaiement}
                    onClosed={() => setOpenModalPaiement(false)}
                    selectedBakeliste={selectedBakeliste}
                />
            )} */}
            {
                openConfirmModal && <MettreEnLigneFeature
                    openConfirmModal={openConfirmModal}
                    setOpenConfirmModal={setOpenConfirmModal}
                    selectedBakeliste={selectedBakeliste}
                />
            }
            {
                isPresentielLoading ? <Loader /> : <TableListeView
                    data={bakelistePresentiel}
                    columns={columns}
                    title={`Bakelistes en présentiel (${bakelistePresentiel?.length ?? "..."})`}
                    navigateTo={() => navigate(BAKELISTES_PATH + "/" + BAKELISTES_EN_LIGNE_PATH)}
                    navigateToTitle="Voir Bakelistes en ligne"
                    icon={{ name: HiUsers }}
                />
            }

        </FadeTransition>
    )
}

export default ListeBakelistesPresentielPage