import { axiosInstance } from "redux/apiController/axiosInstance"
import {
    setDakarBakelistesCurrentMonthPaiement,
    setDakarBakelistesPaiement,
    setMbourBakelistesCurrentMonthPaiement,
    setMbourBakelistesPaiement,
    setThiesBakelistesCurrentMonthPaiement, setThiesBakelistesPaiement
} from "redux/features/paiementSlice"
import { store } from "redux/store"

const dispatch = store.dispatch

export const bakelisteEnPresentielSwrFetcher = (url) => axiosInstance.get(url).then((res) => {
    return res.data.data
})
export const bakelisteEnLigneSwrFetcher = (url) => axiosInstance.get(url).then((res) => {
    return res.data.data
})
export const listePaiementSwrFetcher = (url) => axiosInstance.get(url).then((res) => {
    return res.data.data
})
export const detailsPaiementBakelisteSwrFetcher = (url) => axiosInstance.get(url).then((res) => {
    return res.data.data
})
export const listeMoisDePaieSwrFetcher = (url) => axiosInstance.get(url).then((res) => {
    return res.data
})
export const listeAnneesDePaieSwrFetcher = (url) => axiosInstance.get(url).then((res) => {
    return res.data
})
export const getCurrentMonthPaiementSwrFetcher = (url) => axiosInstance.get(url).then((res) => {
    // console.log(Object.entries(res.data.paie));

    dispatch(setDakarBakelistesCurrentMonthPaiement([...res.data.paie["Grand Yoff 1"], ...res.data.paie["Grand Yoff 2"]]))
    dispatch(setThiesBakelistesCurrentMonthPaiement(res.data.paie["Thiés"]))
    dispatch(setMbourBakelistesCurrentMonthPaiement(res.data.paie["Mbour"]))

    return res.data
})
export const filterPaiementByMonthAndYeaySwrFetcher = (url) => axiosInstance.get(url).then((res) => {
    console.log("filter paiements: ", res);
    console.log("current month Grand Yoff 1: ", res.data.paiement["Grand Yoff 1"])
    if (!res.data.paiement) {
        dispatch(setDakarBakelistesPaiement([]))
        dispatch(setThiesBakelistesPaiement([]))
        dispatch(setMbourBakelistesPaiement([]))
    }
    console.log("current month Grand Yoff 2: ", res.data.paiement["Grand Yoff 2"])
    console.log("current month Thies: ", res.data.paiement["Thiés"])
    console.log("current month Mbour: ", res.data.paiement["Mbour"])

    dispatch(setDakarBakelistesPaiement([...res.data.paie["Grand Yoff 1"], ...res.data.paie["Grand Yoff 2"]]))
    dispatch(setThiesBakelistesPaiement([]))
    dispatch(setMbourBakelistesPaiement([]))
    // dispatch(setDakarBakelistesPaiement(res.data['']))
    // dispatch(setThiesBakelistesPaiement(res.data['']))
    // dispatch(setMbourBakelistesPaiement(res.data['']))
    return res.data
})