import { useGetCurrentBakelisteSyllabusDetailsQuery } from "../queries";

export const useGetBakelisteSyllabusDetails = (syllabusId, bakelisteId) => {
  const {
    data: currentBakelistesSyllabusDetails,
    isLoading: loadingCurrentBakelisteSyllabusDetails,
  } = useGetCurrentBakelisteSyllabusDetailsQuery({
    syllabus_id: syllabusId,
    bakeliste_id: bakelisteId,
  });

  return {
    currentBakelistesSyllabusDetails,
    loadingCurrentBakelisteSyllabusDetails,
  };
};
