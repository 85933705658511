import { Title2 } from "components/shared/TableListeView";
import React from "react";
import ListeProspectsPage from "./ListeProspectsPage";
import { useSelector } from "react-redux";
import { rootStyles } from "assets/css/globalCss";
import { Badge } from "pages/bakelistes/Details/DetailsBakelistePage";
import FadeTransition from "components/shared/FadeTransition";
import ExportExcel from "components/Export/ExportExcel";
import Div from "components/shared/Div";

const ProspectsParRegionPage = () => {
  const { currentRegion, prospectsList } = useSelector(
    (state) => state.prospects
  );
  return (
    <FadeTransition in={true}>
      <div
        style={{ flex: 2, minWidth: "300px", position: "sticky", top: "0", zIndex: "100", backgroundColor: rootStyles.color.primaryWithRgba }}
        className="d-flex justify-content-between align-items-center gap-2 py-3"
      >
        <Div className={"d-flex gap-2"}>
          <Title2>Liste des prospects de {currentRegion}</Title2>
          <Badge bgColor={rootStyles.color.red} color={rootStyles.color.primary}>
            {prospectsList.length}
          </Badge>
        </Div>
        <ExportExcel fileName={"Prospects"} data={prospectsList} />
      </div>
      <ListeProspectsPage />
    </FadeTransition>
  );
};

export default ProspectsParRegionPage;
