const { useGetBakelisteLivraionsQuery } = require("../queries");

export const useGetBakelisteLivraisons = (bakelisteId) => {
  const {
    data: currentBakelisteLivraisons,
    isLoading: loadingCurrentBakelisteLivraisons,
  } = useGetBakelisteLivraionsQuery({
    bakeliste_id: bakelisteId,
  });

  return { currentBakelisteLivraisons, loadingCurrentBakelisteLivraisons };
};
