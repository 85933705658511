import Input from "components/Input"
import React from "react"

const FiltrerEntreDeuxDate = ({ date_debut, setDateDebut, date_fin, setDateFin, setSearchParams }) => {
  return (
    <div className="d-flex align-items-center flex-sm-no-wrap gap-4 px-0">
      <div className="d-flex gap-2 align-items-center">
        <span>Du</span>
        <Input
          // label="Date début"
          type="date"
          value={date_debut.value}
          max={date_fin.value}
          // value={Date(date_debut.value).toLocaleString("fr-Fr", {
          //   dateStyle: "full",
          // })}
          onChange={(value) => {
            // !ajouter le params sur l'url de la page
            setSearchParams((searchParams) => {
              searchParams.set("date_debut", value)
              return searchParams;
            })
            // !mise a jour du state
            setDateDebut({ value, errorMessage: "" });
          }}
          errorMessage={date_debut.errorMessage}
        />
      </div>
      <div className="d-flex gap-2 align-items-center">
        <span>Au</span>
      <Input
        label="Date fin"
        type="date"
        min={date_debut.value}
        value={date_fin.value}
        onChange={(value) => {
          // !ajouter le params sur l'url de la page
          setSearchParams((searchParams) => {
            searchParams.set("date_fin", value)
            return searchParams;
          })
          // !mise a jour du state
          setDateFin({ value, errorMessage: "" });
        }}
      />
      </div>
    </div>
  )
}

export default FiltrerEntreDeuxDate