/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from "react";
import Comment from "./Comment";
import "./Styles/App.scss";

const Comments = (props) => {
  const [showAllComments, setShowAllComments] = useState(false);
  const [firstThreeComments, setFirstThreeComments] = useState();

  useEffect(() => {
    setFirstThreeComments(props.comments?.slice(0, 3));
  }, [props.comments]);

  const handleShowComments = () => {
    setShowAllComments(true);
  };

  return (
    <>
      <div style={{ position: "relative", height: "70vh", overflow: "auto" }}>
        {!showAllComments
          ? firstThreeComments?.map((comment) => (
              <Comment
                key={comment.id}
                commentData={comment}
                livraison_id={props.livraison_id}
              />
            ))
          : props.comments?.map((comment) => (
              <Comment
                key={comment.id}
                commentData={comment}
                livraison_id={props.livraison_id}
              />
            ))}
        <div style={{ position: "absolute", right: "0", bottom: "0" }}>
          {!showAllComments && props.comments?.length >= 4 && (
            <p
              onClick={(e) => {
                handleShowComments();
              }}
              style={{ cursor: "pointer" }}
              className={`${props.comments?.length === 0 && "d-none"}`}
            >
              {`Voir ${
                props.comments?.length - firstThreeComments?.length === 1
                  ? props.comments?.length - firstThreeComments?.length
                  : `les ${props.comments?.length - firstThreeComments?.length}`
              } autre${
                props.comments?.length - firstThreeComments?.length > 1
                  ? "s"
                  : ""
              } commentaire${
                props.comments?.length - firstThreeComments?.length > 1
                  ? "s"
                  : ""
              }`}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default Comments;
