
import { rootStyles } from "assets/css/globalCss"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
// import PersonalInfo from "components/bakelistes/PersonalInfo"
// import { useGetBakelisteById } from "../api/hooks/useGetBakelisteById"
import Loader from "components/shared/Loader"
import FadeTransition from "components/shared/FadeTransition"
import { useNavigate } from "react-router-dom"
import { setCurrentBakelisteSlice } from "redux/features/bakelisteSlice"
import { EDIT_BAKELISTE_PATH } from "routes/navigation/navigationPaths"
import ErrorBakeliste from "components/error/ErrorBakeliste"
import MettreEnLigneFeature from "features/mettreEnLigne/MettreEnLigneFeature"
import MettreBakelisteEnPresentielFeature from "features/mettreEnPresentiel/MettreBakelisteEnPresentielFeature"
import ButtonComp from "components/ButtonComp"
import BloquerAvecMotif from "components/bakelistes/Details/BloquerAvecMotif"
import DebloquerAvecMotif from "components/bakelistes/Details/DebloquerAvecMotif"
import PauseBakelisteAvecMotif from "components/bakelistes/Details/PauseBakelisteAvecMotif"
import ActionsRapides from "components/bakelistes/Details/ActionsRapides"
import InformationsPersonnel from "components/bakelistes/Details/InformationsPersonnel"
import SuiviFormation from "components/bakelistes/Details/SuiviFormation"
import { BiEditAlt } from "react-icons/bi"
import { Badge } from "./DetailsBakelistePage"
// import { setCurrentCoachSuivi } from "redux/features/coachSlice";

// dayjs.locale('fr')

const Container = styled.div`
    background-color: white;
    padding: ${rootStyles.padding.btnPadding};
    border-radius: ${rootStyles.radius.card};
    min-height: 500px;
`

const Title = styled.h3`
    font-size: ${rootStyles.fonts.h2FontSize};
    color: ${rootStyles.color.black};
    font-weight: ${rootStyles.fonts.h2FontWeight}
`

const BakelisteAdminInfo = ({ currentBakeliste, formation_days1, isLoading, isError }) => {
  // const { currentBakeliste } = useSelector((state) => state.bakelistes)
  const [openMotifModal, setOpenMotifModal] = useState(false)
  const [openPauseModal, setOpenPauseModal] = useState(false)
  const [openConfirmModalToEnLigne, setOpenConfirmModalToEnLigne] = useState(false)
  const [openConfirmModalToPresentiel, setOpenConfirmModalToPresentiel] = useState(false)
  const [openMotifDeblocageModal, setOpenMotifDeblocageModal] = useState(false)
  // const { data = { data: [], formation_days1: [] }, isLoading, isError } = useGetBakelisteById(currentBakeliste.id)
  const isBakelisteOnline = currentBakeliste?.is_online
  const isBakelistePresentiel = currentBakeliste?.is_presentiel

  // console.log("Bakeliste details page", data.data);
  // const navigateToDetails = () => {
  //   dispatch(setCurrentBakelisteSuivi(currentBakeliste));
  //   return navigate(DETAILS_SUIVI_BAKELISTES_PATH);
  // };

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const goToEditPage = () => {
    dispatch(setCurrentBakelisteSlice(currentBakeliste))
    navigate(EDIT_BAKELISTE_PATH)
    return
  }
  return <FadeTransition in={true}>
    {openConfirmModalToEnLigne && <MettreEnLigneFeature
      openConfirmModal={openConfirmModalToEnLigne}
      setOpenConfirmModal={setOpenConfirmModalToEnLigne}
      selectedBakeliste={currentBakeliste} />}
    {openConfirmModalToPresentiel && <MettreBakelisteEnPresentielFeature
      openConfirmModal={openConfirmModalToPresentiel}
      setOpenConfirmModal={setOpenConfirmModalToPresentiel}
      selectedBakeliste={currentBakeliste} />}
    <BloquerAvecMotif
      currentBakeliste={currentBakeliste}
      openMotifModal={openMotifModal}
      setOpenMotifModal={setOpenMotifModal} />
    <DebloquerAvecMotif
      currentBakeliste={currentBakeliste}
      openMotifDeblocageModal={openMotifDeblocageModal}
      setOpenMotifDeblocageModal={setOpenMotifDeblocageModal} />
    <PauseBakelisteAvecMotif
      currentBakeliste={currentBakeliste}
      openPauseModal={openPauseModal}
      setOpenPauseModal={setOpenPauseModal} />
    {isError ? <ErrorBakeliste /> : <>
      <Container>
        {/* {
        isLoading ? <Loader /> :

    } */}
        {isLoading ? <Loader /> : <>
          {/* <div className="d-flex justify-content-between align-items-start">
            <PersonalInfo
              etat={currentBakeliste.etat}
              isBakelisteOnline={isBakelisteOnline}
              isBakelistePresentiel={isBakelistePresentiel}
              fullName={`${currentBakeliste.first_name} ${currentBakeliste.last_name}`}
              email={currentBakeliste.email}
              address={currentBakeliste.address}
              phone={currentBakeliste.phone}
              ville={currentBakeliste.ville} />
            <div className="d-flex gap-2">
              <ButtonComp
                title={"Voir portfolio"}
                borderColor={rootStyles.color.tercary}
                onClick={navigateToDetails} />
              <ButtonComp
                icon={{
                  name: BiEditAlt,
                  size: 25
                }}
                borderColor={rootStyles.color.redSecondary}
                onClick={goToEditPage} />
            </div>
          </div> */}
          <div className="my-3 mt-4 d-flex gap-2">
            {!isBakelisteOnline && <ButtonComp
              borderColor={rootStyles.color.red}
              color={rootStyles.color.red}
              onClick={() => {
                setOpenConfirmModalToEnLigne(true)
              }}
              title={"Placer en ligne"} />}
            {!isBakelistePresentiel && <ButtonComp
              borderColor={rootStyles.color.secondaryFonce}
              color={rootStyles.color.secondaryFonce}
              onClick={() => {
                setOpenConfirmModalToPresentiel(true)
              }}
              title={"Placer en présentiel"} />}
          </div>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <Title className='mt-4 mb-1'>Actions rapides</Title>
            <Badge bgColor={rootStyles.color.tercary} color={rootStyles.color.primary} onClick={goToEditPage}>
              <BiEditAlt /> Modifier le profil
            </Badge>
            {/* <ButtonComp
              icon={{
                name: BiEditAlt,
                size: 25
              }}
              borderColor={rootStyles.color.redSecondary}
              onClick={goToEditPage} /> */}
          </div>
          <ActionsRapides
            bakeliste={currentBakeliste}
            setOpenMotifDeblocageModal={setOpenMotifDeblocageModal}
            setOpenMotifModal={setOpenMotifModal}
            setOpenPauseModal={setOpenPauseModal} />
          <div className='container p-0 m-0 mt-5'>
            <Title>Informations personnelles</Title>
            <InformationsPersonnel
              bakeliste={currentBakeliste}
              goToEditPage={goToEditPage} />
          </div>
          <div className='container p-0 m-0 mt-5'>
            <Title>Formation suivie</Title>
            <SuiviFormation
              formation_days1={formation_days1}
              bakeliste={currentBakeliste}
              currentBakeliste={currentBakeliste} />
          </div>
        </>}
      </Container>

      {/* <div className='mt-3'>
                <Title2>Informations supplémentaires</Title2>
            </div> */}
    </>}

  </FadeTransition>
}

export default BakelisteAdminInfo;