import React from "react";
import BakelisteAdminInfo from "./BakelisteAdminInfo";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { rootStyles } from "assets/css/globalCss";
import { useGetBakelisteById } from "../api/hooks/useGetBakelisteById";
import BakelisteInfo from "./BakelisteInfo";
// import { NavLink, Outlet } from "react-router-dom"
// import { HiDocumentReport, HiOutlineBookOpen } from "react-icons/hi"
// import { BiTimeFive } from "react-icons/bi"
import Div from "components/shared/Div";
import TabsProvider from "components/Tabs/TabsProvider";
import ListeSyllabusPage from "pages/suivi-bakelistes/ui/details-bakeliste/syllabus/ListeSyllabusPage";
import ListePointages from "pages/suivi-bakelistes/ui/details-bakeliste/pointages/ListePointages";
import ListeLivraisons from "pages/suivi-bakelistes/ui/details-bakeliste/livraisons/ListeLivraisons";
import AttestationPage from "pages/suivi-bakelistes/ui/details-bakeliste/attestation/AttestationPage";
import { completeDateFormatter } from "utils/timeFormatters";

const BtnBadge = styled.button`
  padding: ${({ padding }) => (padding ? `${padding}px` : "5px")};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${rootStyles.fonts.btnWeight};
  border: none;
  font-size: 12px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px;
  background-color: ${({ bgColor }) => bgColor};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  color: ${({ color }) => color};
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  &:hover {
    opacity: ${({ disabled }) => (disabled ? "0.4" : "0.8")};
    transform: ${({ disabled }) => (disabled ? "scale(1)" : "scale(1.01)")};
  }
  &:active {
    box-shadow: 0 3px 0 rgba(0, 0, 0, 0.5);
    margin-bottom: 1px;
  }

  @media only screen and (max-width: 500px) {
    font-size: 12px;
    font-weight: 500;
    padding: ${({ padding }) => (padding ? `${padding}px` : "7px")};
  }
`;

export const Badge = ({
  children,
  bgColor,
  color,
  disabled,
  onClick,
  padding,
}) => {
  return (
    <BtnBadge
      padding={padding}
      onClick={onClick}
      disabled={disabled}
      bgColor={bgColor}
      color={color}
    >
      {children}
    </BtnBadge>
  );
};

const DetailsBakelistePage = () => {
  const { currentBakeliste } = useSelector((state) => state.bakelistes);
  const {
    data = { data: [], formation_days1: [] },
    isLoading,
    isError,
  } = useGetBakelisteById(currentBakeliste.id);

  // assigning location variable
  // const location = useLocation();
  // destructuring pathname from location
  // const { pathname } = location;
  // const splitLocation = pathname.split("/");

  // const links = [
  //     { to: "", label: "Syllabus" },
  //     { to: "bulletins", label: "Bulletins" },
  //     { to: "pointages", label: "Pointages" },
  //     { to: "livraisons", label: "Livraisons" }
  // ]

  const userFullname = `${data?.data[0]?.first_name} ${data?.data[0]?.last_name}`;
  const birthDate = completeDateFormatter(data?.data[0]?.date_naissance);
  const birthPlace = data?.data[0]?.lieu_naissance;
  const program = data?.data[0]?.domaine?.name;
  const certificationType = data?.data[0]?.type_formation;

  return (
    <div className="container-fluid">
      <div className="row">
        <Div className="col-3 bg-white rounded">
          <BakelisteInfo
            isLoading={isLoading}
            currentBakeliste={data.data[0]}
          />
        </Div>
        <Div className="col-9">
          <TabsProvider
            content={[
              {
                title: "Informations personnelles",
                // number: bakelisteActifs.countBakelisteActifEnPresentiel,
                content: () => (
                  <BakelisteAdminInfo
                    currentBakeliste={data.data[0]}
                    formation_days1={data.formation_days1}
                    isLoading={isLoading}
                    isError={isError}
                  />
                ),
              },
              {
                title: "Syllabus",
                // number: bakelisteActifs.countBakelisteActifEnLigne,
                content: () =>
                  !isLoading && (
                    <ListeSyllabusPage bakelisteId={data?.data[0]?.id} />
                  ),
              },
              // {
              //     title: "Bulletins",
              //     content: () => !isLoading && <div>Je suis bulletins</div>
              // },
              {
                title: "Pointages",
                // number: bakelisteActifs.countBakelisteActifEnLigne,
                content: () =>
                  !isLoading && (
                    <ListePointages pointages={data?.data[0]?.newpointages} />
                  ),
              },
              {
                title: "Livraisons",
                // number: bakelisteActifs.countBakelisteActifEnLigne,
                content: () =>
                  !isLoading && (
                    <ListeLivraisons bakelisteId={data?.data[0]?.id} />
                  ),
              },
              {
                title: "Attestation",
                content: () =>
                  !isLoading && (
                    <AttestationPage
                      studentName={userFullname}
                      birthDate={birthDate}
                      birthPlace={birthPlace}
                      program={program}
                      certificationType={certificationType}
                    />
                  ),
              },
            ]}
          />
        </Div>
        {/* <BakelisteAdminInfo currentBakeliste={data.data[0]} formation_days1={data.formation_days1} isLoading={isLoading} isError={isError} /> */}
      </div>
    </div>
  );
};

export default DetailsBakelistePage;
