import { BsFillCalendarEventFill } from "react-icons/bs";
import {
  FaHandshake,
  FaUserGraduate,
  FaUserPlus,
  // FaHome,
  FaUserTie,
} from "react-icons/fa";
import { GiTeacher } from "react-icons/gi";
import { BiTime } from "react-icons/bi";
import { HiHome } from "react-icons/hi";
import { IoIosSchool } from "react-icons/io";
import { MdHistory, MdQueryStats, MdWork } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";

import {
  ACCEUIL_PATH,
  BAKELISTES_EN_STAGE_PATH,
  BAKELISTES_LIVRAISONS_PATH,
  BAKELISTES_PATH,
  COACHS_ASSISTANTS_PATH,
  COACHS_PATH,
  EMPLOYES_PATH,
  EVENEMENTS_PATH,
  EXAMENS_PATH,
  SYLLABUS_PATH,
  HISTORY_PATH,
  PARAMETRES_PATH,
  PARTENAIRES_PATH,
  PROSPECTS_PATH,
  SUIVI_BAKELISTES_PATH,
  SUIVI_COACHING_PATH,
  SUIVI_POINTAGE_PATH,
} from "./navigationPaths";

export const links = [
  { path: ACCEUIL_PATH, icon: HiHome, label: "Accueil" },

  // {
  //   path: SUIVI_BAKELISTES_PATH,
  //   icon: MdQueryStats,
  //   label: "Suivi Bakelistes",
  // },
  {
    label: "Suivi Formation", details: [
      { path: BAKELISTES_PATH, icon: FaUserTie, label: "Bakelistes" },
      { path: SUIVI_POINTAGE_PATH, icon: BiTime, label: "Pointages", search: "?zone=all" },
      { path: BAKELISTES_LIVRAISONS_PATH, icon: BiTime, label: "Livraisons" },
      { path: SUIVI_BAKELISTES_PATH, icon: MdQueryStats, label: "Suivi" },
      { path: EXAMENS_PATH, icon: IoIosSchool, label: "Examens" },
      { path: SYLLABUS_PATH, icon: IoIosSchool, label: "Syllabus" },

    ]
  },

  // { path: COACHS_ASSISTANTS_PATH, icon: GiTeacher, label: "Coachs Assistants" },

  // { path: SUIVI_COACHING_PATH, icon: MdQueryStats, label: "Suivi Coaching" },
  {
    label: "Coaching", details: [
      { path: SUIVI_COACHING_PATH, icon: MdQueryStats, label: "Suivi Coaching" },
      { path: COACHS_PATH, icon: GiTeacher, label: "Coachs" },
      { path: COACHS_ASSISTANTS_PATH, icon: GiTeacher, label: "Assistants" },
    ]
  },
  { path: BAKELISTES_EN_STAGE_PATH, icon: FaUserGraduate, label: "Stagiaires" },
  // { path: COACHS_ASSISTANTS_PATH, icon: GiTeacher, label: "Coachs Assistants" },
  // { path: STAGIAIRES_ARCHIVES_PATH, icon: IoIosSchool, label: "Stagiaires Archivés" },
  { path: EVENEMENTS_PATH, icon: BsFillCalendarEventFill, label: "Evénements" },
  { path: PROSPECTS_PATH, icon: FaUserPlus, label: "Prospects" },


  { path: PARTENAIRES_PATH, icon: FaHandshake, label: "Partenaires" },

  { path: EMPLOYES_PATH, icon: MdWork, label: "Employés" },

  //   { path: PAIEMENTS_PATH, icon: FaMoneyBillAlt, label: "Paiements" },

  { path: HISTORY_PATH, icon: MdHistory, label: "Historiques" },

  { path: PARAMETRES_PATH, icon: IoMdSettings, label: "Paramètres" },
];
