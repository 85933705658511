import { rootStyles } from "assets/css/globalCss"
import React from "react"
import styled from "styled-components"
import { Label } from "./InputForm"
const TextAreaForm = styled.textarea`
    padding: ${rootStyles.padding.inputPadding};
    width:100%;
    border: ${({ error }) => !error ? `1px solid ${rootStyles.color.secondary} ` : `3px solid ${rootStyles.color.red} `};
    background-color: ${rootStyles.color.primaryWithRgba};
    font-size:${rootStyles.fonts.fontSize};
    transition: all ease 0.1s;
    &:focus{
        border: ${({ error }) => !error ? `3px solid ${rootStyles.color.secondary} ` : `3px solid ${rootStyles.color.red} `} ;
    }
`
const TextArea = ({ label, error, htmlFor, register, placeholder }) => {
    return (
        <>
            <Label
                error={error}
                htmlFor={htmlFor}
            >{label}
                <TextAreaForm
                    placeholder={placeholder}
                    name={label}
                    id={htmlFor}
                    {...register}
                    error={error}
                ></TextAreaForm>
            </Label>
        </>
    )
}

export default TextArea