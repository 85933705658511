import TableListeView from "components/shared/TableListeView"
import React, { useEffect, useMemo, useState } from "react"
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { ApiController } from "redux/apiController"
import { ADD_COACH_PATH, COACHS_ARCHIVES_PATH, UPDATE_COACH_PATH } from "routes/navigation/navigationPaths"
import { Tooltip as ReactTooltip } from "react-tooltip";
import { rootStyles } from "assets/css/globalCss"
import { tooltip_options } from "utils/tooltip_options"
import ConfirmAction from "components/shared/ConfirmAction"
import { setCurrentCoachSlice } from "redux/features/coachSlice"
import { toast } from "react-hot-toast"
import Loader from "components/shared/Loader"
import FadeTransition from "components/shared/FadeTransition"
import useCoachs from "hooks/useCoachs"

const ListeCoachPage = () => {
    const { data: coachsList, isLoading } = useCoachs()
    // console.log({ data });
    // const { coachsList } = useSelector(state => state.coachs)
    const navigate = useNavigate()
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [selectedCoach, setSelectedCoach] = useState(null)
    const dispatch = useDispatch()
    const [isDeletingCoach, setIsDeletingCoach] = useState(false)

    const openDeleteModal = (row) => {
        setSelectedCoach(row)
        setConfirmDelete(true)
    }
    const navigateToEdit = (row) => {
        dispatch(setCurrentCoachSlice(row))
        navigate(UPDATE_COACH_PATH)
    }
    const deleteCoachFunc = () => {
        setIsDeletingCoach(true)
        ApiController.coachs.deleteCoach(selectedCoach.id).then(() => {
            toast.success("Coach supprimé")
            setConfirmDelete(false)
        }).catch(() => {
            toast.error("Une erreur est survenue")
        })
            .finally(() => {
                setIsDeletingCoach(false)
            })
    }

    const actionsFormatter = (cell, row) => {
        return <div className='d-flex gap-4 justify-content-center'>
            <AiOutlineEdit
                onClick={() => navigateToEdit(row)}
                data-tip data-tooltip-id='edit-btn'
                className='cursor-pointer'
                size={20}
            />
            <AiOutlineDelete
                onClick={() => openDeleteModal(row)}
                data-tip data-tooltip-id='delete-btn'
                className='cursor-pointer'
                size={20}
                color={rootStyles.color.red}
            />

            <ReactTooltip style={tooltip_options} id='edit-btn'>
                Modifier
            </ReactTooltip>
            <ReactTooltip style={tooltip_options} id='delete-btn'>
                Supprimer
            </ReactTooltip>
        </div>
    }

    const columns = useMemo(() => [{
        dataField: "coachFullname",
        text: "Nom complet",
    },
    {
        dataField: "domaine",
        text: "Domaine",
    },
    {
        dataField: "email",
        text: "Email",
    },
    {
        dataField: "phone",
        text: "Numéro",
    },
    // {
    //     dataField: 'price',
    //     text: 'Actions',
    // },
        {
            dataField: "actions",
            text: "Actions",
            formatter: actionsFormatter
        }
    ], [])

    useEffect(() => {
        // ApiController.coachs.getCoachs().finally(() => {
        //     setIsFetchingCoach(false)
        // })
        ApiController.domaines.getDomaines()
    }, [])

    return (
        <FadeTransition in={true} >
            <ConfirmAction
                loading={isDeletingCoach}
                modalIsOpen={confirmDelete}
                modalText="Supprimez coach"
                text={`Voulez-vous vraiment supprimer ${selectedCoach?.first_name} ?`}
                cancel={() => setConfirmDelete(false)}
                validate={deleteCoachFunc}
                closeModal={() => setConfirmDelete(false)}
            />
            {
                isLoading ? <Loader /> : <TableListeView
                    data={coachsList}
                    columns={columns}
                    title="Listes des Coachs"
                    navigateTo={() => navigate(COACHS_ARCHIVES_PATH)}
                    navigateToTitle="Coachs archivés"
                    nagigateToAddNewEntity={() => navigate(ADD_COACH_PATH)}
                />
            }

        </FadeTransition>
    )
}

export default ListeCoachPage