import { useDispatch } from "react-redux";
import { setDakarBakelistesPaiement, setMbourBakelistesPaiement, setThiesBakelistesPaiement } from "redux/features/paiementSlice";
import { useFilterPaiementsByMoisAndAnneeMutation } from "../mutations";
import { useEffect } from "react";

export const useFilterPaiement = (mois, annee) => {
    const [trigerFilter, result] = useFilterPaiementsByMoisAndAnneeMutation({ mois, annee })

    const dispatch = useDispatch()

    useEffect(() => {
        if (result.data) {
            dispatch(setDakarBakelistesPaiement([...result?.data?.paiement["Grand Yoff 1"], ...result?.data.paiement["Grand Yoff 2"]]))
            dispatch(setThiesBakelistesPaiement(result?.data?.paiement["Thiés"]))
            dispatch(setMbourBakelistesPaiement(result?.data?.paiement["Mbour"]))
        } else {
            dispatch(setDakarBakelistesPaiement([]))
            dispatch(setThiesBakelistesPaiement([]))
            dispatch(setMbourBakelistesPaiement([]))
        }
    }, [dispatch, result?.data?.paiement, result.isLoading])


    return { trigerFilter, isFiltering: result.isLoading }
}