/** ------------------------------------------------------------
 *! ----------------- SIKPIS ENDPOINTS ------------------------------
 */
// export const GET_COMMENTS_NUMBER_AND_VALIDATIONS_NUMBER = "all-comments"
export const GET_ADMIN_KPI_BY_YEAR = "get-kpi-by-year/";
export const GET_ALL_LIVRAISONS = "get_all_livraisons";
export const GET_ALL_ACTIFS_BAKELISTES = "statistiques-livraisons";
// export const GET_OTHERS_DATA = "count-users-by-status"
export const GET_KPI_CURRENT_YEAR = "get-kpi-admin-by-current-year";
export const GET_COACHS_AND_ASSISTANT_COACHS_COMMENTS_AND_VALIDATIONS =
  "coachs-all-comment-stats";
export const FILTER_COACHS_AND_ASSISTANT_COACHS_COMMENTS_AND_VALIDATIONS =
  "coachs-comment-stats";
// coachs-all-comment-stats
/** ------------------------------------------------------------
 *! ----------------- COACHS ENDPOINTS ------------------------------
 */
export const GET_COACHS_ENDPOINT = "liste-des-coachs";
export const GET_COACHS_ASSISTANTS_ENDPOINT = "liste-des-coachs-assistants";
export const GET_COACHS_ARCHIVES_ENDPOINT = "liste-des-coachs-archives";
export const GET_COACHS_ASSISTANTS_ARCHIVES_ENDPOINT =
  "liste-des-coachs-assistants-archives";
export const ACTIVATE_COACH_ENDPOINT = "desarchive-user/";
export const ADD_NEW_COACH_ENDPOINT = "add-coach";
export const ADD_NEW_COACH_ASSISTANT_ENDPOINT = "add-coach-assistant";
export const UPDATE_COACH_ENDPOINT = "update-coach-infos/";
export const DELETE_COACH_ENDPOINT = "archive-user/";
/** ------------------------------------------------------------
 *! ----------------- SUIVI COACHING ENDPOINTS ------------------------------
 */
export const SUIVI_COACHING_ENDPOINT = "coach-assistants-list";
export const SUIVI_COACHING_DETAILS_ENDPOINT = "kpi-bakeliste-by-coach/";
/** ------------------------------------------------------------
 *! ----------------- SUIVI BAKELISTES ENDPOINTS ---------------------
 */
export const GET_BAKELISTE_EN_COURS_DE_FORMATION =
  "liste-des-bakelistes-en-formation";
export const GET_CURRENT_BAKELISTE_FORMATION_DETAILS_ENDPOINT =
  "bakeliste-formation-infos";
export const GET_CURRENT_BAKELISTE_SYLLABUS_DETAILS_ENDPOINT =
  "suivi-bakeliste-details-syllabus";
export const GET_GLOBAL_BAKELISTE_SYLLABUS_PROGRESSION_ENDPOINT =
  "global-bakeliste-progression";
export const GET_ALL_BAKELISTES = "liste-tous-les-bakelistes";

export const GET_BAKELISTE_BULLETINS_ENDPOINT = "suivi-bakeliste-bulletins";
export const GET_BAKELISTE_POINTAGES_ENDPOINT = "suivi-bakeliste-pointage";
export const GET_BAKELISTE_LIVRAISONS_ENDPOINT = "suivi-bakeliste-livraisons";
export const GET_BAKELISTE_VALIDATED_TASKS_ENDPOINT =
  "get_bakeliste_validated_tasks";

/** ------------------------------------------------------------
 *! ----------------- BAKELISTES ENDPOINTS ---------------------
 */
// get-bakeliste-actif
export const RESEARCH_BAKELISTE_ENDPOINT = "searchBakeliste";
export const GET_BAKELISTES_BY_ID_ENPOINT = "get-bakelistste-data/";
export const GET_BAKELISTES_ACTIF_ENPOINT = "get-bakeliste-actif";
export const GET_BAKELISTES_INACTIF_ENPOINT = "get-bakeliste-inactif";
export const GET_BAKELISTES_EN_PRESENTIEL =
  "bakelistes-training-presentiel-list";
export const GET_BAKELISTES_EN_LIGNE = "bakelistes-training-online-list";
export const GET_BAKELISTES_ABANDONS = "bakelistes-training-abandon-list";
export const GET_BAKELISTES_EN_PAUSE = "bakelistes-training-pause-list";
export const GET_BAKELISTES_BLOQUES = "blocked-user-list";
export const GET_BAKELISTES_ARCHIVES = "liste-des-bakelistes-archives";
export const GET_BAKELISTES_EN_STAGE = "liste-stagiaires";
export const GET_STAGIAIRES_ARCHIVES = "liste-stagiaires-archives";
export const GET_BAKELISTES_TERMINES = "bakelistes-with-completed-training";
// export const ADD_NEW_BAKELISTE_ENDPOINT = "add-bakeliste-new";
export const ADD_NEW_BAKELISTE_ENDPOINT = "add-bakeliste-with-days";
// export const UPDATE_BAKELISTE_ENDPOINT = "update-bakeliste-infos/";
export const UPDATE_BAKELISTE_ENDPOINT = "edit-bakeliste-infos/";
export const PAUSE_BAKELISTE_ENDPOINT = "pause-training-bakeliste";
export const ACTIVATE_PAUSED_BAKELISTE_ENDPOINT = "new-activation-compte";
// export const BLOQUE_BAKELISTE_COMPTE_ENDPOINT = "block-user-account/";
export const BLOQUE_BAKELISTE_COMPTE_ENDPOINT = "blocked-bakeliste";
// export const DEBLOQUE_BAKELISTE_COMPTE_ENDPOINT = "unblock-user-account/";unblocked-bakeliste
export const DEBLOQUE_BAKELISTE_COMPTE_ENDPOINT = "unblocked-bakeliste";
export const ARCHIVER_BAKELISTE = "archive-user/";
export const DESARCHIVER_BAKELISTE = "desarchive-user/";
export const TERMINER_BAKELISTE_FORMATION = "terminer_formation";
export const PLACER_EN_STAGE = "bakeliste-to-stagiaire";
export const DE_STAGE_EN_BAKELISTE = "stagiaire-to-bakeliste/";
export const DE_TERMINE_A_BAKELISTE = "re-integrate-bakeliste-training";
export const RECRUTABLE_ENDPOINT = "change-bakeliste-to-recrutable";
export const ADD_NEW_STAGIAIRE_ENDPOINT = "add_stagiaire";
export const UPDATE_STAGIAIRE_ENDPOINT = "update_stagiaire/";
export const CHANGE_BAKELISTE_TO_ONLINE = "change-bakeliste-to-online/"
export const CHANGE_BAKELISTE_TO_PRESENCIEL = "change-bakeliste-to-presentiel/"
export const GET_BAKELISTES_LIVRAISONS = "users-without-deliveries/"
/** ------------------------------------------------------------
 *! ----------------- PARTENAIRES ENDPOINTS ------------------------------
 */
export const ADD_PARTENAIRE = "partenaires/";
export const EDIT_PARTENAIRE = "partenaires/";
export const DELETE_PARTENAIRE = "partenaires/";
export const GET_PARTENAIRES = "partenaires/";
// export const GET_PARTENAIRE_PROGRAMMES = 'get-programme-by-partenaire/'
export const GET_PARTENAIRE_PROGRAMMES = "partenaires/";
export const ADD_PROGRAMME = "programme-partenaire/";
export const EDIT_PROGRAMME = "programme-partenaire/";
export const GET_ALL_PROGRAMMES = "programme-partenaire/";


/** ------------------------------------------------------------
 *! ----------------- SYLLABUS ENDPOINTS ------------------------------
 */
 export const GET_SYLLABUS = "syllabus";


/** ------------------------------------------------------------
 *! ----------------- PROSPECTS ENDPOINTS ------------------------------
 */
export const GET_PROSPECTS = "prospects";
export const APPELER_PROSPECT_ENDPOINT = "contacted-prospect/";
export const STATISTIQUES_PROSPECTS_BAKELISTES_VISITEURS_ENDPOINT =
  "bakelistes-counter/";
// export const STATISTIQUES_PROSPECTS_PAR_REGION_ENDPOINT = "prospects-counter/";
export const STATISTIQUES_PROSPECTS_PAR_REGION_ENDPOINT = "kpi-prospects/";
/** ------------------------------------------------------------
 *! ----------------- PAIEMENTS ENDPOINTS ------------------------------
 */
export const ADD_PAIEMENT_ENDPOINT = "paiements";
export const GET_PAIEMENTS_ENDPOINT = "paiements";
export const GET_MONTHS_PAIEMENTS_ENDPOINT = "month";
export const GET_CURRENT_MONTH_PAIEMENTS_ENDPOINT = "current-month-paiement";
export const GET_ANNEES_PAIEMENTS_ENDPOINT = "annees";
export const GET_BAKELISTES_PAIEMENTS_ENDPOINT = "paiments-by-bakeliste/"; // + bakleiste_id
export const FILTER_PAIEMENT_BY_MONTH_ANNEE_ENDPOINT = "paiement/"; // + month + year
/** ------------------------------------------------------------
 *! ----------------- EVENEMENTS ENDPOINTS ------------------------------
 */
export const EVENEMENTS_RECENTS_ENDPOINT = "get-recent-rencontres";
export const EVENEMENTS_A_VENIR_ENDPOINT = "get-coming-rencontres";
export const EVENEMENTS_DETAILS_ENDPOINT = "rencontres/"; // {id}add-rencontre
export const ADD_EVENEMENT_ENDPOINT = "add-rencontre";
/** ------------------------------------------------------------
 *! ----------------- DOMAINE ENDPOINTS ------------------------------
 */
export const GET_DOMAINES_ENDPOINTS = "domaines";
/** ------------------------------------------------------------
 *! ----------------- EXAMENS ENDPOINTS ------------------------------
 */
export const GET_EXAMENS_BY_TRIMESTRE_ENDPOINT = "getExamenByTrimestre/";
export const GET_EXAMENS_THEORIQUE_BY_TRIMESTRE_ENDPOINT =
  "getExamenTheoriqueByTrimestre/";
export const GET_EXAMENS_PRATIQUES_BY_TRIMESTRE_ENDPOINT =
  "getExamenPratiqueByTrimestre/";
export const GET_BULLETINS_BY_TRIMESTRE_ENDPOINT = "getBulletinByTrimestre/";
export const GET_ALL_ANNEE_ACADEMIQUE = "getAllAnneesAcademiques";
export const ADD_NEW_ANNEE_ACADEMIQUE = "generateTrimestresByAnnee";
export const GET_TRIMESTRE_BY_ANNEE = "dossier_examens";
// getExamenTheoriqueByTrimestre/

/** ------------------------------------------------------------
 *! ----------------- HISTORIQUES ENDPOINTS ------------------------------
 */
export const GET_USERS_HISTORY_ENDPOINT = "historiques-new";
/** ------------------------------------------------------------
 *! ----------------- JOURS FORMATIONS ENDPOINTS ------------------------------
 */
export const GET_JOURS_FORMATION = "jours";
/** ------------------------------------------------------------
 *! ----------------- JOURS FORMATIONS ENDPOINTS ------------------------------
 */
export const TYPE_PRESENCE_ENDPOINT = "typePresences";
/** ------------------------------------------------------------
 *! ----------------- SPACES ENDPOINTS ------------------------------
 */
export const REGIONS_ENDPOINT = "regions";
/** ------------------------------------------------------------
 *! ----------------- SPACES ENDPOINTS ------------------------------
 */
export const ADD_SPACE_ENDPOINT = "add-space";
export const EDIT_SPACE_ENDPOINT = "edit-space";
export const SPACES_ENDPOINT = "spaces";

/** ------------------------------------------------------------
 *! ----------------- EMPLOYES ENDPOINTS ------------------------------
 */

export const GET_EMPLOYES_LIST_ENDPOINT = "get-list-employe";
export const ADD_NEW_EMPLOYE_ENDPOINT = "add-employe";
/** ------------------------------------------------------------
 *! ----------------- CALENDRIER PRESENCE ENDPOINTS ------------------------------
 */
export const CALENDRIER_PRESENCE_ENDPOINT = "calendrier_presence_all_space";
export const PRESENCES_ABSENCES_HEBDO_ENDPOINT = "liste-pointage-par-semaine";
