import Modal from "components/Modal"
import PauseBakelisteWithMotif from "features/pauseBakelisteWithMotif/PauseBakelisteWithMotif"
import { PauseBakelisteWithMotifProvider } from "features/pauseBakelisteWithMotif/Provider"
import React from "react"

const PauseBakelisteAvecMotif = ({ currentBakeliste, openPauseModal, setOpenPauseModal }) => {
    return (
        <PauseBakelisteWithMotifProvider>
            <Modal
                opened={openPauseModal}
                onClosed={() => setOpenPauseModal(false)}
                title={"Veuillez ajouter le motif pour lequel vous mettez ce bakeliste en pause"}
                modalWidth={600}
            >
                <PauseBakelisteWithMotif
                    onClosed={() => setOpenPauseModal(false)}
                    currentBakeliste={currentBakeliste}
                />
            </Modal>
        </PauseBakelisteWithMotifProvider>
    )
}

export default PauseBakelisteAvecMotif