import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist-indexeddb-storage";

import bakelistes from "./features/bakelisteSlice";
import coachs from "./features/coachSlice";
import domaines from "./features/domaineSlice";
import evenements from "./features/evenementSlice";
import examens from "./features/examenSlice";
import paiements from "./features/paiementSlice";
import partenaires from "./features/partenaireSlice";
import prospects from "./features/prospectSlice";
import siKpis from "./features/siKpiSlice";
import user from "./features/userSlice";

import { siAdminAPi } from "./rtkquery";
// import thunk from 'redux-thunk';/

const persistConfig = {
  key: "root",
  storage: storage("si-admin"),
  // blacklist: ['bakelistes', 'coachs']
};

const siAdminReducer = combineReducers({
  [siAdminAPi.reducerPath]: siAdminAPi.reducer,
  siKpis,
  user,
  coachs,
  bakelistes,
  prospects,
  evenements,
  domaines,
  examens,
  paiements,
  partenaires,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return siAdminReducer(undefined, action);
  }
  return siAdminReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  // middleware: [thunk],
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware({
  //     immutableCheck: false,
  //     serializableCheck: false,
  // }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(siAdminAPi.middleware),
});

export const persistor = persistStore(store);
