import { rootStyles } from "assets/css/globalCss";
import TableListeView from "components/shared/TableListeView";
import React, { useMemo } from "react"
import { toast } from "react-hot-toast";
import { BsArrowReturnLeft } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { filterBakelistesList, setCurrentBakelisteSlice } from "redux/features/bakelisteSlice";
// import { filterBakelistesList } from "redux/features/bakelisteSlice";
import { tooltip_options } from "utils/tooltip_options";
// import { useBakelisteEnPause } from "./api/hooks/useBakelisteEnPause";
import Loader from "components/shared/Loader";
import { useCurrentUser } from "hooks/useCurrentUser"
import { textFilter } from "react-bootstrap-table2-filter";
import { useActiverBakelisteMutation } from "./api/mutations";
import FadeTransition from "components/shared/FadeTransition";
import { DETAILS_BAKELISTE_PATH } from "routes/navigation/navigationPaths";

const ListeBakelisteEnPausePage = ({ bakelisteEnPause, isEnPauseLoading }) => {
// const { bakelisteEnPause, isEnPauseLoading } = useBakelisteEnPause()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { currentUser } = useCurrentUser()

    const [activatePauseBakelisteFormation] = useActiverBakelisteMutation()

    // const reIntegrerBakeliste = (bakeliste) => {
    //     let bakeliste_id = bakeliste.id
    //     toast.promise(ApiController.bakelistes.activatePauseBakelisteFormation(bakeliste_id), {
    //         loading: "Réintégration",
    //         success: bakeliste.first_name + ' est réintegré',
    //         error: `La réintégration de ${bakeliste.first_name} a echouée`
    //     })
    // }
    const navigateToBakelisteDetails = (bakeliste) => {
        dispatch(setCurrentBakelisteSlice(bakeliste))
        navigate(DETAILS_BAKELISTE_PATH)
    }
    const reIntegrerBakeliste = (bakeliste) => {
        const bakeliste_id = bakeliste.id
        const paused_by = currentUser.id
        toast.promise(activatePauseBakelisteFormation({ bakeliste_id, paused_by }), {
            loading: "Réintégration",
            success: bakeliste.first_name + " est réintegré",
            error: `La réintégration de ${bakeliste.first_name} a echouée`
        })
    }

    const actionsFormatter = (cell, row) => {
        return <div className='d-flex gap-4 justify-content-center'>
            <BsArrowReturnLeft
                onClick={() => reIntegrerBakeliste(row)}
                data-tip data-tooltip-id='details-btn'
                className='cursor-pointer'
                size={20}
                color={rootStyles.color.secondary}
            />

            <ReactTooltip style={tooltip_options} id='details-btn'>
                Réintegré
            </ReactTooltip>
        </div>
    }
    const columns = useMemo(() => [{
        dataField: "first_name",
        text: "Nom complet",
        filter: textFilter({
            placeholder: "Recherche pas nom complet",
        }),
        formatter: (cell, row) => <p style={{ cursor: "pointer" }} onClick={() => navigateToBakelisteDetails(row)}
        >
            {row.first_name} {row.last_name}
        </p>
    },
    {
        dataField: "email",
        filter: textFilter({
            placeholder: "Recherche pas mail",
        }),
        text: "Email",
        },
        {
            dataField: "phone",
            filter: textFilter({
                placeholder: "Recherche pas téléphone",
            }),
            text: "Téléphone",
        },
        {
        dataField: "domaine.name",
        text: "Domaines",
    },
    {
        dataField: "Actions",
        text: "Actions",
        formatter: actionsFormatter
    }
    ], [])
    return (
        <FadeTransition in={true}>
            {
                isEnPauseLoading ? <Loader /> : <TableListeView
                    onChangeInputSearch={(value) => dispatch(filterBakelistesList({ value, type: "enPause" }))}
                    data={bakelisteEnPause}
                    columns={columns}
                    title="Bakelistes en pause"
                    navigateToTitle="Liste des bakelistes archivés"
                />
            }
        </FadeTransition>
    )
}

export default ListeBakelisteEnPausePage