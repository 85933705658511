import { rootStyles } from "assets/css/globalCss";
import ButtonComp from "components/ButtonComp";
import ProgressBar from "components/ProgressBar";
import Div from "components/shared/Div";
import { useGetBakelisteGlobalSyllabusProgression } from "pages/suivi-bakelistes/api/hooks/useGetBakelisteGlobalSyllabusProgression";
import React from "react"
import { AiOutlinePrinter } from "react-icons/ai";
import ReactToPrint from "react-to-print";
import { completeDateFormatter } from "utils/timeFormatters";

const LabelText = ({ label, text }) => {
  return <Div className="d-flex align-items-center gap-2 mt-3">
    <span style={{ fontSize: 14 }} className="fw-semibold">
      {label}:
    </span>
    <span style={{ fontSize: 12 }}>{text ?? "..."}</span>
  </Div>
}


const BakelisteInfo = ({ currentBakeliste }) => {
  const { currentbakelisteGlobalProgression } =
    useGetBakelisteGlobalSyllabusProgression(currentBakeliste?.id);
  console.log({ currentBakeliste });

  return (
    <Div className="sticky-top">
      {/* img */}
      <Div className="d-flex justify-content-center justify-content-center mt-2">
        {currentBakeliste?.avatar_file !== null ? (
          <img
            src={
              "https://via-api.bakeli.tech" +
              currentBakeliste?.avatar_file
            }
            alt="bakeliste_profil"
            className="img-fluid img-thumbnail rounded-circle"
            style={{
              width: "150px",
              height: "150px",
              objectFit: "cover",
            }}
          />
        ) : (
          <img
            src="https://e7.pngegg.com/pngimages/84/165/png-clipart-united-states-avatar-organization-information-user-avatar-service-computer-wallpaper-thumbnail.png"
            alt="bakeliste_profil"
            className="img-fluid img-thumbnail rounded-circle"
            style={{
              width: "150px",
              height: "150px",
              objectFit: "cover",
            }}
          />
        )}
      </Div>
      <Div className="">
        <p className="text-center mt-md-2 fw-bold fs-5">
          {`${currentBakeliste?.first_name} ${currentBakeliste?.last_name}`}
        </p>
        <Div className="text-center mt-3">
          <span
            className="badge p-2 px-4 rounded-pill"
            style={{ backgroundColor: rootStyles.color.secondary }}
          >
            {currentBakeliste?.domaine?.name}
          </span>
        </Div>
        <Div className={"mt-4"}>
          <p style={{ fontSize: 14 }} className="fw-semibold">
            Progression global du syllabus
          </p>
          <ProgressBar
            max={100}
            value={
              currentbakelisteGlobalProgression?.globalProgression ?? 0
            }
            color={rootStyles.color.tercary}
            height={"10px"}
            width="100%"
          />
        </Div>
        <Div className={"my-3"}>
          <ReactToPrint
            pageStyle="p-1"
            content={() => porfolioRef.current}
            trigger={() => (
              <ButtonComp
                width={"100%"}
                title="Imprimer Portfolio"
                bgColor={rootStyles.color.secondary}
                color={rootStyles.color.primary}
                icon={{
                  name: AiOutlinePrinter,
                  size: 20,
                }}
              />
            )}
            documentTitle={`Portfolio ${currentBakeliste?.first_name} ${currentBakeliste?.last_name}`}
          />
        </Div>
        <LabelText label="Email" text={currentBakeliste?.email} />
        <LabelText label="Téléphone" text={currentBakeliste?.phone} />
        <LabelText label="Adresse" text={currentBakeliste?.address} />
        <LabelText label="Début formation" text={completeDateFormatter(
          currentBakeliste?.debut_formation
        )} />
        <LabelText label="Fin formation" text={completeDateFormatter(
          currentBakeliste?.fin_formation
        )} />
        <LabelText label={"Type de formation"} text={currentBakeliste?.type_formation} />
        <LabelText label={"Situation matrimoniale"} text={currentBakeliste?.marital_status} />
        <LabelText label={"Coach"} text={currentBakeliste?.coach_fullName} />
        <LabelText label={"Coach assistant"} text={currentBakeliste?.coach_assistant_fullName} />

      </Div>
    </Div>
  )
}

export default BakelisteInfo