import { siAdminAPi } from "redux/rtkquery";
import {
  GET_ALL_BAKELISTES,
  GET_BAKELISTES_ABANDONS,
  GET_BAKELISTES_ARCHIVES,
  GET_BAKELISTES_BLOQUES,
  GET_BAKELISTES_BY_ID_ENPOINT,
  GET_BAKELISTES_EN_LIGNE,
  GET_BAKELISTES_EN_PAUSE,
  GET_BAKELISTES_EN_PRESENTIEL,
  GET_BAKELISTES_EN_STAGE,
  GET_BAKELISTES_TERMINES,
  GET_STAGIAIRES_ARCHIVES,
} from "routes/api/endpoints";

const bakelistesApi = siAdminAPi.injectEndpoints({
  endpoints: (build) => ({
    getAllBakelistes: build.query({
      query: () => GET_ALL_BAKELISTES,
      providesTags: ["Bakelistes"],
    }),
    getBakelistesById: build.query({
      query: (bakeliste_id) => GET_BAKELISTES_BY_ID_ENPOINT + bakeliste_id,
      providesTags: ["Bakelistes"],
    }),
    getBakelistesEnPresentiel: build.query({
      query: () => GET_BAKELISTES_EN_PRESENTIEL,
      providesTags: ["Bakelistes"],
    }),
    getBakelistesEnLigne: build.query({
      query: () => GET_BAKELISTES_EN_LIGNE,
      providesTags: ["Bakelistes"],
    }),
    getBakelistesEnPause: build.query({
      query: () => GET_BAKELISTES_EN_PAUSE,
      providesTags: ["Bakelistes"],
    }),
    getBakelistesBloques: build.query({
      query: () => GET_BAKELISTES_BLOQUES,
      providesTags: ["Bakelistes"],
    }),
    getBakelistesArchives: build.query({
      query: () => GET_BAKELISTES_ARCHIVES,
      providesTags: ["Bakelistes"],
    }),
    getBakelistesEnStage: build.query({
      query: () => GET_BAKELISTES_EN_STAGE,
      providesTags: ["Bakelistes"],
    }),
    getStagiairesArchives: build.query({
      query: () => GET_STAGIAIRES_ARCHIVES,
      providesTags: ["Bakelistes"],
    }),
    getBakelistesTermines: build.query({
      query: () => GET_BAKELISTES_TERMINES,
      providesTags: ["Bakelistes"],
    }),
    getBakelistesAbandonnes: build.query({
      query: () => GET_BAKELISTES_ABANDONS,
      providesTags: ["Bakelistes"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllBakelistesQuery,
  useGetBakelistesByIdQuery,
  useGetBakelistesAbandonnesQuery,
  useGetBakelistesArchivesQuery,
  useGetBakelistesBloquesQuery,
  useGetBakelistesEnLigneQuery,
  useGetBakelistesEnPauseQuery,
  useGetBakelistesEnPresentielQuery,
  useGetBakelistesEnStageQuery,
  useGetBakelistesTerminesQuery,
  useGetStagiairesArchivesQuery,
} = bakelistesApi;
