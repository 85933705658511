import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";

const debloquerSchema = Yup.object().shape({
    motif_deblocage: Yup.string().required("Veuillez fournir le motif de déblocage")
})

export const DebloquerBakelisteWithMotifProvider = ({ children }) => {
    const methods = useForm({
        resolver: yupResolver(debloquerSchema),
        defaultValues: {
            motif_deblocage: ""
        }
    })
    return <FormProvider {...methods}>{children}</FormProvider>
}