import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
// import getCurrentUser from "../hooks/getCurrentUser";
import NavbarComponent from "./navbar/NavbarComponent";
import SidebarComponent from "./sidebar/SidebarComponent";
import SpliTemplateScreen from "./SplitTemplateScreen";
import { LOGIN_PATH } from "../routes/navigation/navigationPaths";
import { rootStyles } from "assets/css/globalCss";
import { useCurrentUser } from "hooks/useCurrentUser";
import MotionDiv from "components/shared/MotionDiv";
import BreadCrumps from "components/shared/BreadCrumps";
import styled from "styled-components";
import { Suspense } from "react";
// import styled from "styled-components";

// const Header = styled.header`
//   border: 1px solid ${rootStyles.color.secondary};
//   padding: ${rootStyles.padding.btnPadding};
//   margin-bottom: 10px
// `

const OutletContainer = styled.div`
  padding-bottom: 50px;
`


export const PublicAuth = () => {
    const { currentUser } = useCurrentUser()

    return !currentUser ? <Outlet /> : <Outlet />
}

function RequireAuth() {
    const { currentUser } = useCurrentUser()

    const location = useLocation();

    // currentUser = true

    return currentUser ? (
        <Suspense fallback={<p className="text-center mt-5">loading...</p>}>
            <SpliTemplateScreen>
                <NavbarComponent />
                <SidebarComponent bgColor={rootStyles.color.secondary} />
                <MotionDiv>
                    <BreadCrumps />
                    <OutletContainer>
                        <Outlet />
                    </OutletContainer>
                </MotionDiv>
            </SpliTemplateScreen>
        </Suspense>
    ) : (
        <Navigate to={LOGIN_PATH} state={{ from: location }} replace />
    );
}

export default RequireAuth;
