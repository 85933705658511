const { siAdminAPi } = require("redux/rtkquery");
const { ADD_PARTENAIRE, EDIT_PARTENAIRE, DELETE_PARTENAIRE, ADD_PROGRAMME, EDIT_PROGRAMME } = require("routes/api/endpoints");

const partenaireApi = siAdminAPi.injectEndpoints({
    endpoints: (build) => ({
        addPartenaires: build.mutation({
            query: (data) => ({
                url: ADD_PARTENAIRE,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Partenaires"],
        }),
        editPartenaires: build.mutation({
            query: ({ data, partenaire_id }) => ({
                url: EDIT_PARTENAIRE + partenaire_id,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["Partenaires"],
        }),
        deletePartenaires: build.mutation({
            query: (partenaire_id) => ({
                url: DELETE_PARTENAIRE + partenaire_id,
                method: "DELETE",
            }),
            invalidatesTags: ["Partenaires"],
        }),
        addProgrammes: build.mutation({
            query: (data) => ({
                url: ADD_PROGRAMME,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Partenaires"],
        }),
        editProgrammes: build.mutation({
            query: ({ newData, programme_id }) => ({
                url: EDIT_PROGRAMME + programme_id,
                method: "PUT",
                body: newData,
            }),
            invalidatesTags: ["Partenaires"],
        }),
    }),
    overrideExisting: false,
})

export const { useAddPartenairesMutation, useEditPartenairesMutation, useDeletePartenairesMutation, useAddProgrammesMutation, useEditProgrammesMutation } = partenaireApi