import { rootStyles } from "assets/css/globalCss";
import ButtonComp from "components/ButtonComp";
import Modal from "components/Modal";
import InputForm from "components/shared/InputForm";
import { useAddPartenairesMutation } from "pages/partenaires/api/mutations";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

const AddPartenaireModal = ({ openAddPartenaire, onClosed }) => {
  // const [isAdding, setIsAdding] = useState(false);
  const [addPartenaire, { isLoading }] = useAddPartenairesMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    // setIsAdding(true);
    addPartenaire(data)
      .then(() => {
        toast.success("Partenaire créé");
        onClosed();
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        // setIsAdding(false);
      });
  };
  return (
    <div>
      <Modal
        title={"Nouveau partenaire"}
        opened={openAddPartenaire}
        onClosed={onClosed}
      >
        <form className="py-2" onSubmit={handleSubmit(onSubmit)}>
          <InputForm
            min={2000}
            max={2999}
            label="Nom Partenaire"
            type="text"
            htmlFor="name"
            register={{
              ...register("name", {
                required: "Nom partenaire obligatoire",
              }),
            }}
            error={errors.name?.message}
          />
          <div className="mt-0">
            <ButtonComp
              loading={isLoading}
              title="Ajouter"
              bgColor={rootStyles.color.secondary}
              color={rootStyles.color.primary}
              width={"100%"}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default AddPartenaireModal;
