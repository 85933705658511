import { useUpdateMutation } from "actions/typePresence/mutatons"
import { rootStyles } from "assets/css/globalCss"
import ButtonComp from "components/ButtonComp"
import InputForm from "components/shared/InputForm"
import React from "react"
import { useFormContext } from "react-hook-form"
import toast from "react-hot-toast"

const EditTypePresence = ({ closeEditTypePresenceModal, selectedTypePresence }) => {
  const { register, handleSubmit, reset, formState: { errors, isSubmitting } } = useFormContext()
  const [mettreAjourTypePresence, { isLoading }] = useUpdateMutation()

  // console.log({ selectedTypePresence });

  const onSubmit = (data) => {
    // console.log({ data, reset });
    toast.promise(mettreAjourTypePresence({ data, id: selectedTypePresence.id })
      .unwrap().then(() => {
        reset()
        closeEditTypePresenceModal()
      }), {
      loading: "Mise à jour en cours...",
      success: "Mise à jour réussie",
      error: "La mise à jour a echouée"
    })
  }

  return (
    <div className="mt-3">
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputForm
          label={"Libellé"}
          register={{ ...register("libelle") }}
          error={errors.libelle?.message}
        />
        <InputForm
          label={"Heure début"}
          type={"time"}
          register={{ ...register("heure_debut") }}
          error={errors.heure_debut?.message}
        />
        <InputForm
          label={"Heure fin"}
          type={"time"}
          register={{ ...register("heure_fin") }}
          error={errors.heure_fin?.message}
        />
        <ButtonComp
          title={"Envoyez"}
          disabled={isLoading || isSubmitting}
          loading={isLoading || isSubmitting}
          bgColor={rootStyles.color.secondary}
          color={rootStyles.color.primary}
        />
      </form>
    </div>
  )
}

export default EditTypePresence