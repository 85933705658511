import { useGetSpacesQuery } from "actions/spaces/queries";
import { useState } from "react";

export const useSpaces = () => {
  const { data: spaces, isLoading, isError } = useGetSpacesQuery()
  const [isAddSpaceModal, setIsAddSpaceModal] = useState(false);
  const [isEditSpaceModal, setIsEditSpaceModal] = useState(false);
  const [initialValues, setInitialValues] = useState(null)

  const addAddSpaceModal = () => {
    setIsAddSpaceModal(true);
  }
  const closeAddSpaceModal = () => {
    setIsAddSpaceModal(false);
  }

  const openEditSpaceModal = (space) => {
    setInitialValues(space)
    setIsEditSpaceModal(true);
  }
  const closeEditSpaceModal = () => {
    setIsEditSpaceModal(false);
    setInitialValues(null)
  }

  return {
    spaces,
    isLoading,
    isError,
    isAddSpaceModal,
    addAddSpaceModal,
    closeAddSpaceModal,
    openEditSpaceModal,
    closeEditSpaceModal,
    isEditSpaceModal,
    initialValues
  }
}