import { rootStyles } from "assets/css/globalCss"
import React from "react"
import styled from "styled-components"

const BadgePane = styled.span`
    border-radius: 100px;
    border: ${({ bordercolor }) => `1px solid ${bordercolor}`};
    padding: 5px 10px;
    font-size: ${rootStyles.fonts.spanErrFontSize};
    font-weight: 500;
    color: ${({ color }) => color}
`

const Badge = ({ children, borderColor = rootStyles.color.tercary, color = "#000", style }) => {
    return (
        <BadgePane color={color} bordercolor={borderColor} style={{ ...style }}>
            {children}
        </BadgePane>
    )
}

export default Badge