import { GET_STAGIAIRES_ARCHIVES } from "routes/api/endpoints";
import { stagiaireArchivesSwrFetcher } from "..";
import useSWR from "swr";

export const useStagiaireArchives = () => {
    const { data: stagiairesArchives = { count: 0 }, isLoading: isStagiairesArchivesLoading } = useSWR(
        GET_STAGIAIRES_ARCHIVES,
        stagiaireArchivesSwrFetcher
    );

    return { stagiairesArchives, isStagiairesArchivesLoading }
}