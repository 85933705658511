import React from "react"
import ProfilDetail from "../ProfilDetail"
import styled from "styled-components"

const SpanLinkPane = styled.span`
  color: red;
  text-decoration: underline;
  cursor: pointer;
`

const InformationsPersonnel = ({ bakeliste, goToEditPage }) => {
    return (
        <div className='row'>
            <div className='col-sm-3'>
                <ProfilDetail
                    title="Date de naissance"
                    desc={() => new Date(bakeliste.date_naissance).toLocaleString("fr-Fr", {
                        dateStyle: "long"
                    }) ?? <SpanLinkPane onClick={goToEditPage}>Mettre à jour</SpanLinkPane>}
                />
            </div>
            <div className='col-sm-3'>
                <ProfilDetail
                    title="Lieu de naissance"
                    desc={() => bakeliste.lieu_naissance ?? <SpanLinkPane onClick={goToEditPage}>Mettre à jour</SpanLinkPane>}
                />
            </div>
            <div className='col-sm-3'>
                <ProfilDetail
                    title="Civilité"
                    desc={() => bakeliste.civility ?? "......"}
                />
            </div>
            <div className='col-sm-3'>
                <ProfilDetail
                    title="Status"
                    desc={() => bakeliste.status ?? "......"}
                />
            </div>
            <div className='col-sm-3'>
                <ProfilDetail
                    title="Domaine"
                    desc={() => bakeliste.domaine?.name ?? "......"}
                />
            </div>
            <div className='col-sm-3'>
                <ProfilDetail
                    title="Ecole d'origine"
                    desc={() => bakeliste.ecole ?? "......"}
                />
            </div>
            <div className='col-sm-3'>
                <ProfilDetail
                    title="Programme partenaire"
                    desc={() => bakeliste?.programme_partenariat?.name ?? "......"}
                />
            </div>
        </div>
    )
}

export default InformationsPersonnel