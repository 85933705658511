// import { rootStyles } from "assets/css/globalCss";
import PropTypes from "prop-types";
import React from "react";
import Styled from "styled-components";

const Container = Styled.div`
    display: flex;
    align-items: center;
    padding: 0px;

    progress {
        margin-right: 8px;
        border-radius: 50px
        background-color: ${(props) => props.color} !important;
  }

  progress[value] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: ${(props) => props.width};
    border-radius: 30px;
    height: ${(props) => props.height};
    background: #ffffff;
}

progress[value]::-webkit-progress-bar {
    border-radius: 20px;
    background: #ffffff;
    border: 1px solid #CDC7C2
}

progress[value]::-webkit-progress-value {
    border-radius: 20px;
    background: ${(props) => props.color};
  }

progress[value]::-moz-progress-bar{
    border-radius: 20px;
    background: ${(props) => props.color};
}
`;

const ProgressBar = ({ value, max, color, width, height }) => {
  return (
    <Container color={color} width={width} height={height}>
      <progress value={value} max={max} />
      <span>{max === 0 ? "0" : Math.floor((value / max) * 100)}%</span>
    </Container>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number,
  color: PropTypes.string,
  width: PropTypes.string,
};

ProgressBar.defaultProps = {
  max: 100,
  color: "lightBlue",
  width: "250px",
  height: "10px",
};

export default ProgressBar;
