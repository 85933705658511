import React from "react";

const Checkbox = ({ label, checked, onChange: onChangeCheck, disabled }) => {
    return (
        <div style={{ opacity: disabled ? ".4" : 1 }} className="d-flex gap-1 align-items-center">
            <label className="switch">
                <input
                    disabled={disabled}
                    type="checkbox"
                    checked={checked}
                    onChange={(e) => {
                        onChangeCheck(e.target.checked)
                    }
                    }
                />
                <span className="slider round"></span>
            </label>
            <p style={{ fontSize: 12 }} className="m-0 fw-bold">{label}</p>
        </div>
    );
}

export default Checkbox;
