import { GET_COACHS_ENDPOINT } from "routes/api/endpoints";

const { siAdminAPi } = require("redux/rtkquery");

const coachsApi = siAdminAPi.injectEndpoints({
    endpoints: (build) => ({
        getCoachs: build.query({
            query: () => GET_COACHS_ENDPOINT,
            providesTags: ["coachs"],
        }),
    }),
    overrideExisting: false,
});

export const { useGetCoachsQuery } = coachsApi