import { rootStyles } from "assets/css/globalCss"
import ButtonComp from "components/ButtonComp"
import InputForm from "components/shared/InputForm"
import { useCurrentUser } from "hooks/useCurrentUser"
import { usePauseBakelisteMutation } from "pages/bakelistes/api/mutations"
import React from "react"
import { useFormContext } from "react-hook-form"
import toast from "react-hot-toast"

const PauseBakelisteWithMotif = ({ onClosed, currentBakeliste }) => {
    const { register, handleSubmit, reset, formState: { errors, isSubmitting } } = useFormContext()
    const [pauseBakelisteCompte, { isLoading }] = usePauseBakelisteMutation()
    const { currentUser } = useCurrentUser()
    const onSubmit = (data) => {
        const bakeliste_id = currentBakeliste.id
        toast.promise(pauseBakelisteCompte({ bakeliste_id, paused_by: currentUser.id, motif_pause: data.motif_pause })
            .unwrap().then(() => {
                onClosed()
                reset()
            }), {
            loading: "Pause en cours...",
            success: currentBakeliste.first_name + " est mise en pause",
            error: `L'arrêt de la formation de ${currentBakeliste.first_name} a echouée`
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ margin: "20px 0" }}>
            <InputForm
                label={"Motif pause"}
                register={{ ...register("motif_pause") }}
                error={errors.motif_pause?.message}
            />
            <ButtonComp
                title={"Envoyez"}
                disabled={isLoading || isSubmitting}
                loading={isLoading || isSubmitting}
                bgColor={rootStyles.color.secondary}
                color={rootStyles.color.primary}
            />
        </form>
    )
}

export default PauseBakelisteWithMotif