import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";

const reintegrerSchema = Yup.object().shape({
    fin_formation: Yup.date().required("Veuillez fournir la nouvelle date de fin de formation")
})

export const ReintegrerWithDateFinProvider = ({ children }) => {
    const methods = useForm({
        resolver: yupResolver(reintegrerSchema),
        defaultValues: {
            fin_formation: ""
        }
    })
    return <FormProvider {...methods}>{children}</FormProvider>
}