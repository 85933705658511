import { rootStyles } from "assets/css/globalCss"
import TableListeView from "components/shared/TableListeView"
import React, { useMemo } from "react"
import { MdOutlineUnarchive } from "react-icons/md"
import { Tooltip as ReactTooltip } from "react-tooltip";
import { tooltip_options } from "utils/tooltip_options"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast"
import { filterBakelistesList, setCurrentBakelisteSlice } from "redux/features/bakelisteSlice";
import { useBakelisteArchives } from "./api/hooks/useBakelisteArchives";
import Loader from "components/shared/Loader";
import { textFilter } from "react-bootstrap-table2-filter";
import { useDesarchiverBakelisteMutation } from "./api/mutations";
import FadeTransition from "components/shared/FadeTransition";
import { DETAILS_BAKELISTE_PATH } from "routes/navigation/navigationPaths";

const ListeBakelistesArchivesPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { bakelisteArchives, count, isArchivesLoading } = useBakelisteArchives()

    const [desarchiverBakelisteMutation] = useDesarchiverBakelisteMutation()

    const desarchiverBakelistefunc = (bakeliste) => {
        const bakeliste_id = bakeliste.id
        toast.promise(desarchiverBakelisteMutation(bakeliste_id), {
            loading: "Désarchivage en cours",
            success: bakeliste.first_name + " désarchivé(e)",
            error: `L'archivage ${bakeliste.first_name} a echouée`
        })
    }
    const navigateToBakelisteDetails = (bakeliste) => {
        dispatch(setCurrentBakelisteSlice(bakeliste))
        navigate(DETAILS_BAKELISTE_PATH)
    }
    const actionsFormatter = (cell, row) => {
        return <div className='d-flex gap-4 justify-content-center'>
            <MdOutlineUnarchive
                onClick={() => desarchiverBakelistefunc(row)}
                data-tip data-tooltip-id='desarchive-btn'
                className='cursor-pointer'
                size={20}
                color={rootStyles.color.secondary}
            />

            <ReactTooltip style={tooltip_options} id='desarchive-btn'>
                Désarchiver
            </ReactTooltip>
        </div>
    }
    const columns = useMemo(() => [{
        dataField: "first_name",
        text: "Nom complet",
        filter: textFilter({
            placeholder: "Recherche pas nom complet",
        }),
        formatter: (cell, row) => <p style={{ cursor: "pointer" }} onClick={() => navigateToBakelisteDetails(row)}
        >
            {row.first_name} {row.last_name}
        </p>
    },
    {
        dataField: "email",
        filter: textFilter({
            placeholder: "Recherche pas mail",
        }),
        text: "Email",
    },
        {
            dataField: "phone",
            filter: textFilter({
                placeholder: "Recherche pas téléphone",
            }),
            text: "Téléphone",
        },
        {
        dataField: "domaine.name",
        text: "Domaines",
    },
    {
        dataField: "Actions",
        text: "Actions",
        formatter: actionsFormatter
    }
    ], [])

    return (
        <FadeTransition in={true}>
            {/* <ConfirmAction
                loading={isDearchiving}
                modalIsOpen={confirmDesarchive}
                modalText="Désarchiver coach"
                text={`Voulez-vous vraiment désarchiver ${selectedBakeliste?.first_name} ?`}
                cancel={() => setConfirmDesarchive(false)}
                validate={desarchiverBakeliste}
                closeModal={() => setConfirmDesarchive(false)}
            /> */}
            {
                isArchivesLoading ? <Loader /> : <TableListeView
                    onChangeInputSearch={(value) => dispatch(filterBakelistesList({ value, type: "archives" }))}
                    data={bakelisteArchives.bakelistesArchived}
                    columns={columns}
                    title={`Bakelistes archivés (${count})`}
                // navigateTo={() => navigate(BAKELISTES_PATH)}
                // navigateToTitle="Liste des bakelistes"
                />
            }

        </FadeTransition>
    )
}

export default ListeBakelistesArchivesPage