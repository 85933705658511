// import React from "react";

// const SyllabusPage = () => {
//   return (
//     <>
//       <p>i'm here</p>
//     </>
//   );
// };

// export default SyllabusPage;

import React, { cloneElement } from "react";
import { Title2 } from "components/shared/TableListeView";
import { useSyllabus } from "../api/hooks/useSyllabus";
import FlexBetween from "components/shared/FlexBetween";
import Loader from "components/shared/Loader";
import { useNavigate } from "react-router-dom";
import { Container } from "pages/bakelistes/BakelisteHomePageDashboard";
import ResourceDataMap from "components/ResourceDataMap";
import FadeTransition from "components/shared/FadeTransition";
import SyllabusCard from "components/syllabus/SyllabusCard";

const SyllabusPage = () => {
  // const [openPartenaireModal, setOpenPartenaireModal] = useState(false);
  // const [openEditPartenaireModal, setOpenEditPartenaireModal] = useState(false);
  const { syllabus, isError, isLoading } = useSyllabus();
  // const [selectedPartenaire, setSelectedPartenaire] = useState(null);
  // const [deletePartenaire] = useDeletePartenairesMutation();

  const navigate = useNavigate();
  // const dispatch = useDispatch();

  const navigateTo = (syllabus) => {
    // dispatch(setCurrentPartenaire(partenaire));

    return navigate(`${syllabus.name}`);
  };
  // const editPartenaireFunc = (partenaire) => {
  //   setOpenEditPartenaireModal(true);
  //   setSelectedPartenaire(partenaire);
  // };
  // const ClonedPartenaireCardItem = ({ syllabus }) =>
  //   React.cloneElement(<PartenaireCardItem partenaire={partenaire} />, {
  //     navigateTo,
  //     // editPartenaireFunc,
  //   });

    const ClonedSyllabusCardItem = ({ syllabus }) =>
      cloneElement(<SyllabusCard syllabus={syllabus} />, {
        navigateTo,
      });
    return (
      console.log("syllabus", syllabus),
    <FadeTransition in={true}>
      {isError ? (
        <p>Une erreur est survenue lors du chargement des syllabus</p>
      ) : (
        <>
          {/* {openPartenaireModal && (
            <AddPartenaireModal
              openAddPartenaire={openPartenaireModal}
              onClosed={() => setOpenPartenaireModal(false)}
            />
          )}
          {openEditPartenaireModal && (
            <EditPartenaireModal
              selectedPartenaire={selectedPartenaire}
              openEditPartenaire={openEditPartenaireModal}
              onClosed={() => setOpenEditPartenaireModal(false)}
            />
          )} */}
          <FlexBetween>
            <Title2>Liste des syllabus</Title2>
            {/* <ButtonComp
              icon={{
                name: IoMdAddCircle,
              }}
              title="Nouveau"
              onClick={() => setOpenPartenaireModal(true)}
              bgColor={rootStyles.color.secondary}
              color={rootStyles.color.primary}
            /> */}
          </FlexBetween>
          {isLoading ? (
            <Loader />
          ) : syllabus.data.length ? (
            <Container>
              <ResourceDataMap
                resourceData={syllabus.data}
                resourceName="syllabus"
                resourceItem={ClonedSyllabusCardItem}
              />
            </Container>
          ) : (
            <p>Pas encore de partenaires</p>
          )}
        </>
      )}
    </FadeTransition>
  );
};

export default SyllabusPage;
