import React from "react"

import { useTypePresence } from "actions/typePresence"
import ErrorBakeliste from "components/error/ErrorBakeliste"
import Flex from "components/shared/Flex"
import Loader from "components/shared/Loader"
import TypePresenceCardItem from "../components/TypesPresence/TypePresenceCardItem";
import useTypesPresence from "./useTypesPresence"
import EditTypePresenceModal from "./Edit/EditTypePresenceModal"

const TypesPresences = () => {
  const { typePresence: typesPresence, isError, isLoading } = useTypePresence()
  const { openEditTypePresenceModal, isEditTypePresenceOpened, closeEditTypePresenceModal, selectedTypePresence } = useTypesPresence()

  if (isLoading) {
    return <Loader />
  }
  if (isError) {
    return <ErrorBakeliste />
  }

  return (
    <div>
      {
        !typesPresence.length ? <p> Pas encore de types de présences</p> :
          <Flex gap={20}>
            {
              typesPresence.map((typePresence) => {
                return <TypePresenceCardItem onClick={openEditTypePresenceModal} key={typePresence.id} typePresence={typePresence} />
              })
            }
          </Flex>
      }
      {
        isEditTypePresenceOpened && <EditTypePresenceModal
          selectedTypePresence={selectedTypePresence}
          isEditTypePresenceOpened={isEditTypePresenceOpened}
          closeEditTypePresenceModal={closeEditTypePresenceModal}
        />
      }
    </div>
  )
}

export default TypesPresences