import {
    addNewProspectSlice, archiveProspectSlice, setProspectsListSlice,
    updateProspectSlice
} from "../features/prospectSlice.js"
import { store } from "../store"
import { APPELER_PROSPECT_ENDPOINT, GET_PROSPECTS } from "routes/api/endpoints.js"
import { axiosInstance } from "./axiosInstance.js"

const dispatch = store.dispatch
const prospectsController = {
    getProspectById(ProspectId) {
        return new Promise((resolve, reject) => {
            axiosInstance.get("******** PUT URL HERE ***********")
                .then((response) => {
                    if (response.status === 200) {
                        /**
                        * !setProspectList is from reducer.Prospect.js, just for Prospect
                        * !Don't forget to check your response content data type
                        */
                        const data = response.data
                        resolve(data)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    // !------------------------------
    // confirmation d'avoir contacter le prospect: contacted-prospect/{id}
    getProspects() {
        return new Promise((resolve, reject) => {
            axiosInstance.get(GET_PROSPECTS)
                .then((response) => {
                    if (response.status >= 200) {
                        /**
                        * !setProspectList is from reducer.Prospect.js, just for Prospect
                        * !Don't forget to check your response content data type
                        */
                        const data = response.data.data
                        dispatch(setProspectsListSlice(data))
                        resolve(data)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    confirmationDavoirAppelerLeProspect(prospect_id) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(APPELER_PROSPECT_ENDPOINT + prospect_id)
                .then((response) => {
                    if (response.status >= 200) {
                        const prospect = response.data.data
                        this.getProspects()
                        // dispatch(changeProspectStatus(prospect))
                        resolve(prospect)
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
    addNewProspect(data) {
        return new Promise((resolve, reject) => {
            axiosInstance.post("******** PUT URL HERE ***********", data)
                .then((response) => {
                    if (response.status === 201) {
                        /**
                        * !addNewProspect is from reducer.Prospect.js, just for Prospect
                        * !Don't forget to check your response content data type
                        */
                        const Prospect = response.data
                        dispatch(addNewProspectSlice(Prospect))
                        resolve(Prospect)
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
    updateProspect(data, ProspectId) {
        return new Promise((resolve, reject) => {
            axiosInstance.put("******** PUT URL HERE ***********", data)
                .then((response) => {
                    if (response.status === 200) {
                        /**
                        * !updateProspect is from reducer.Prospect.js, just for Prospect
                        * !Don't forget to check your response content data type
                        */
                        const Prospect = response.data
                        dispatch(updateProspectSlice(Prospect))
                        resolve(Prospect)
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
    deleteProspect(prospectId) {
        return new Promise((resolve, reject) => {
            axiosInstance.delete(`${"******** PUT URL HERE ***********" + prospectId}/`)
                .then((response) => {
                    if (response.status === 204) {
                        resolve(response)
                        dispatch(archiveProspectSlice(response))
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
}
export default prospectsController