import { rootStyles } from "assets/css/globalCss";
import ButtonComp from "components/ButtonComp";
import ResourceDataMap from "components/ResourceDataMap";
import SelectOnChange from "components/shared/SelectOnChange";
import { useAnnees } from "pages/paiements/api/hooks/useAnneesDePaiement";
import { useMoisPaiement } from "pages/paiements/api/hooks/useMoisPaiement";
import React from "react";
import { useDispatch } from "react-redux";
import {
  setCurrentAnneeFilter,
  setCurrentMoisFilter,
} from "redux/features/paiementSlice";

const PaiementFormFilter = ({ trigger, onFilterChange }) => {
  const { moisDePaie, isMoisDePaieLoading } = useMoisPaiement();
  const { anneesDePaie, isAnneesLoading } = useAnnees();

  const dispatch = useDispatch();

  const fullFieldMonthAndYear = (e) => {
    e.preventDefault();
    // console.log({ data });

    // setSelectedMonth(data.mois);
    // setSelectedYear(data.annee);

    trigger();
  };

  return (
    <form onSubmit={fullFieldMonthAndYear}>
      <div className="d-sm-flex gap-2 align-items-center">
        <SelectOnChange
          //   label="Mois"
          onChange={(value) => {
            dispatch(setCurrentMoisFilter(value));
            onFilterChange({ mois: { value, error: "" } });
          }}
          // error={errors.mois?.message}
        >
          <option value="">Filtre par mois</option>
          {!isMoisDePaieLoading ? (
            <ResourceDataMap
              resourceData={moisDePaie}
              resourceName="mois"
              resourceItem={({ mois: { id, name } }) => (
                <option value={name}>{name}</option>
              )}
            />
          ) : (
            "Chargement"
          )}
        </SelectOnChange>

        <SelectOnChange
          onChange={(value) => {
            dispatch(setCurrentAnneeFilter(value));
            onFilterChange({ annee: { value, error: "" } });
          }}
          //   label="Année"
          // error={errors.annee?.message}
        >
          <option value="">Filtre par année</option>
          {!isAnneesLoading ? (
            <ResourceDataMap
              resourceData={anneesDePaie}
              resourceName="mois"
              resourceItem={({ mois: { id, annee } }) => (
                <option value={annee}>{annee}</option>
              )}
            />
          ) : (
            "Chargement"
          )}
        </SelectOnChange>

        <ButtonComp
          title="Filtrez"
          type="submit"
          // loading={isPaying}
          bgColor={rootStyles.color.secondary}
          color={rootStyles.color.primary}
          width={"100%"}
        />
      </div>
    </form>
  );
};

export default PaiementFormFilter;
