import { siAdminAPi } from "redux/rtkquery";
import { GET_EMPLOYES_LIST_ENDPOINT } from "routes/api/endpoints";

const employesApi = siAdminAPi.injectEndpoints({
  endpoints: (builder) => ({
    getEmployes: builder.query({
      query: () => GET_EMPLOYES_LIST_ENDPOINT,
      providesTags: ["Employees"],
    }),
  }),
});

export const { useGetEmployesQuery } = employesApi;
