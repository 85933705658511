import React from "react"
import { CiWarning } from "react-icons/ci"

const TextOnEmpty = ({ textOnEmpty }) => {
    return (
        <div className="d-flex align-items-center gap-2 mt-2">
            <CiWarning color="red" /> <span className="fs-6 text-danger">{textOnEmpty}</span>
        </div>
    )
}

export default TextOnEmpty