import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";

const schema = Yup.object().shape({
  name_space: Yup.string().required("Nom du space obligatoire"),
  space_manager_id: Yup.string().required("Manager obligatoire"),
  region_id: Yup.string().required("Région obligatoire")
})

export const SpaceProvider = ({ children, initialValues }) => {
  const methods = useForm({
    resolver: yupResolver(schema),
    values: {
      ...getInitialValues(initialValues)
    },
    defaultValues: {
      ...getInitialValues(initialValues)
    }
  })

  return <FormProvider {...methods}>{children}</FormProvider>
}

const getInitialValues = (initialValues) => {
  return {
    name_space: initialValues?.name_space,
    space_id: initialValues?.id,
    space_manager_id: initialValues?.space_manager.id,
    region_id: initialValues?.region.id
  }
}