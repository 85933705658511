import React, { useMemo } from "react";
import useSWR from "swr";
import {
  bakelisteEnLigneSwrFetcher,
  bakelisteEnPresentielSwrFetcher,
} from "../api";
import {
  GET_BAKELISTES_EN_LIGNE,
  GET_BAKELISTES_EN_PRESENTIEL,
} from "routes/api/endpoints";
import { Title2 } from "components/shared/TableListeView";
import Loader from "components/shared/Loader";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import { Badge } from "pages/bakelistes/Details/DetailsBakelistePage";
import { BsEye } from "react-icons/bs";
// import { DETAILS_PAIEMENTS_BAKELISTES_PATH } from "routes/navigation/navigationPaths";
import { useDispatch } from "react-redux";
import { setCurrentBakelistePaiement } from "redux/features/paiementSlice";

const ListePaiementPage = () => {
  const { data: bakelisteEnPresentiel = [], isLoading: isPresentielLoading } =
    useSWR(GET_BAKELISTES_EN_PRESENTIEL, bakelisteEnPresentielSwrFetcher);
  const { data: bakelisteEnLigne = [], isLoading: isEnLigneLoading } = useSWR(
    GET_BAKELISTES_EN_LIGNE,
    bakelisteEnLigneSwrFetcher
  );

  const dispatch = useDispatch();

  const actionsFormatter = (cell, row) => {
    return (
      <div className="d-flex justify-content-center">
        <Badge>
          <Link
            onClick={() => {
              dispatch(setCurrentBakelistePaiement(row));
            }}
            style={{ color: "gray" }}
            to={`${row.id}`}
          >
            <BsEye size={25} />
          </Link>
        </Badge>
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        dataField: "name",
        text: "Nom complet",
        formatter: (cell, row) => (
          <p>
            {row.first_name} {row.last_name}
          </p>
        ),
        filter: textFilter({
          placeholder: "Filtre par Nom",
          className: "custom-filter-search",
        }),
      },
      {
        dataField: "email",
        text: "Email",
        // filter: textFilter({
        //     placeholder: 'Domaine',
        // })
      },
      {
        dataField: "domaine.name",
        text: "Domaines",
        filter: textFilter({
          placeholder: "Filtre par Domaine",
          className: "custom-filter-search",
        }),
        // filter: textFilter({
        //     placeholder: 'Email',
        // })
      },
      {
        dataField: "Actions",
        text: "Actions",
        formatter: actionsFormatter,
      },
    ],
    []
  );
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <Title2>Liste de paiements des bakelistes</Title2>
      </div>
      {isEnLigneLoading && isPresentielLoading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <BootstrapTable
          keyField="id"
          data={[...bakelisteEnLigne, ...bakelisteEnPresentiel]}
          striped
          columns={columns}
          filter={filterFactory()}
          noDataIndication={() => "Pas encore de données"}
          pagination={paginationFactory()}
          search
        />
      )}
    </>
  );
};

export default ListePaiementPage;
