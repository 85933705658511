import { rootStyles } from "assets/css/globalCss";
import styled from "styled-components";
import { Link } from "react-router-dom";

export const Content = styled.div`
    background-color: ${rootStyles.color.primary};
    width: 100%;
    overflow: hidden;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 150px);
    position: relative;
`
export const MainContent = styled.main`
    min-height: 500px;
    height: 100%;
    overflow: auto;
    `
export const Header = styled.header`
    position: absolute;
    top: 0;
    width: 96.5%;
    background-color: ${rootStyles.color.primaryWithRgba}
`
export const TableOverflow = styled.div`
    margin-top: 80px;
    max-width: 100%;
    overflow: auto;
    padding-bottom: 100px;
`
export const Flex = styled.div`
    display: flex;
    gap: 30px;
`
export const Week = styled.div`
    margin-bottom: 50px;
`
export const TableContainer = styled.div`
    // border: 1px solid red;
    // max-width: 400px !important;
    // margin: auto !important;
    // margin-bottom: 20px !important;
    padding: 20px !important;
`
export const Table = styled.table`
    max-width: 600px !important;
    // min-width: 100% !important;
    margin: auto !important;
`
export const TheadTh = styled.th`
    border: 1px solid black;
    padding: 5px !important;
    color: #333;
`
export const Th = styled.th`
    border: 1px solid #444;
    padding: 3px !important;
    color: #333;
`
export const Td = styled.td`
    border: 1px solid;
    padding: 0 !important;
    color: #333;
`
export const Footer = styled.footer`
    background-color: #f9fbfd;
    padding-right: 50px;
    display: flex;
    // gap: 10px;
    height: 50px;
    position: absolute;
    bottom: 0;
    width: 100%;
    overflow: auto;
    scrollbar-color: #6BAF8D #232E33;
    ::-webkit-scrollbar {
        width: 3px !important;
    }
    ::-webkit-scrollbar-track {
        background: #6BAF8D;
        border-radius: 1px;
    }
    ::-webkit-scrollbar-thumb {
        background: #232E33;
        border-radius: 1px;
    }
`
export const PeriodLabel = styled.p`
    background-color: ${({ active }) => active ? rootStyles.color.secondary : "#eee"};
    color: ${({ active }) => active ? "#eee" : rootStyles.color.secondary};
    // background-color: #eee;
    border-color: #e1e9f7;
    font-size: 13px;
    text-transform: uppercase;
    box-shadow: none;
    padding: 10px 20px;
    // line-height: 20px;
    vertical-align: middle;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 2px;
    cursor: pointer;

    &:hover{
        background-color: ${rootStyles.color.secondary};
        color: white
    }
`

export const ExternalLink = styled(Link)`
    color: #000000;
    display: flex;
    align-items: center;
    gap: 2px;

    &:hover{
        color: ${rootStyles.color.secondary};
    }
`