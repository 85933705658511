import { useState } from "react"

const useTypesPresence = () => {
  const [isEditTypePresenceOpened, setIsEditTypePresenceOpened] = useState(false);
  const [selectedTypePresence, setSelectedTypePresence] = useState(null);

  const openEditTypePresenceModal = (typePresence) => {
    setIsEditTypePresenceOpened(true);
    setSelectedTypePresence(typePresence);
  }
  const closeEditTypePresenceModal = () => {
    setIsEditTypePresenceOpened(false);
    setSelectedTypePresence(null);
  }

  return { isEditTypePresenceOpened, openEditTypePresenceModal, closeEditTypePresenceModal, selectedTypePresence }
}

export default useTypesPresence