import { rootStyles } from "assets/css/globalCss";
import { Title2 } from "components/shared/TableListeView";
import { Badge } from "pages/bakelistes/Details/DetailsBakelistePage";
import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaFilePdf } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import BulletinEnTete from "./BulletinEnTete";
import BulletinResultat from "./BulletinResultat";
import { setCurrentBulletin } from "redux/features/examenSlice";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import ButtonComp from "components/ButtonComp";
import FadeTransition from "components/shared/FadeTransition";

const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <div className="mt-3 mb-2">
      <ButtonComp
        bgColor={rootStyles.color.secondary}
        color={rootStyles.color.primary}
        title="Export CSV"
        className=""
        onClick={handleClick}
      />
    </div>
  );
};

const ListeBulletins = () => {
  const {
    currentTrimestreBulletins,
    currentTrimestre,
    currentAnneeAcademique,
    currentEpreuveDomaine,
  } = useSelector((state) => state.examens);
  const [showBulletinContenu, setShowBulletinContenu] = useState(false);
  const dispatch = useDispatch();

  // console.log({ currentTrimestreBulletins });

  const fullNameFormatter = (cell, row) => {
    return `${row.user.first_name} ${row.user.last_name}`;
  };

  const moyenneGlobalFormatter = (cell, row) => (
    <p className="d-flex justify-content-center m-0">
      {!row.moyenne_globale ? (
        "vide"
      ) : row.moyenne_globale < 10 ? (
        <Badge bgColor={rootStyles.color.red} color={rootStyles.color.primary}>
          {row.moyenne_globale}
        </Badge>
      ) : (
        <Badge
          bgColor={rootStyles.color.secondary}
          color={rootStyles.color.primary}
        >
          {row.moyenne_globale}
        </Badge>
      )}
    </p>
  );

  const columns = [
    {
      dataField: "pdf",
      text: "Epreuves",
      formatter: (cell, row) => (
        <p style={{ cursor: "pointer" }} className="text-center m-0">
          <FaFilePdf
            onClick={() => {
              // dispatch(setCurrentEpreuveModule(row.module.module_name))
              // dispatch(setCurrentEpreuveType(row.type))
              // return row.type === 'Théorique' ? displayEpreuveTheorique(row.Details) : displayEpreuvePratique(row.Details)
              dispatch(setCurrentBulletin(row));
              setShowBulletinContenu(true);
            }}
            size={25}
          />
        </p>
      ),
      csvFormatter: (cell, row) => "",
    },
    {
      dataField: "user",
      text: "Nom complet",
      formatter: fullNameFormatter,
      csvFormatter: fullNameFormatter,
      // filter: textFilter({
      //   placeholder: "Filtre par Nom",
      //   className: "custom-filter-search",
      // }),
      // sort: true
    },
    {
      dataField: "moyenne_globale",
      text: "Moyenne globale",
      formatter: moyenneGlobalFormatter,
      // filter: textFilter({
      //   placeholder: "Filtre par Moyenne",
      //   className: "custom-filter-search",
      // }),
      sort: true,
      // formatter: actionsFormatter
    },
    {
      dataField: "comment_of_coach",
      text: "Coach commentaire",
      // sort: true,
      formatter: (cell, row) =>
        row.comment_of_coach !== "NULL" ? row.comment_of_coach : "...",
      // filter: textFilter({
      //   placeholder: "Filtre par domaine",
      //   className: "custom-filter-search",
      // }),
    },
    // {
    //   dataField: "module",
    //   text: "Modules",
    //   formatter: (cell, row) => row.module.module_name,
    //   filter: textFilter({
    //     placeholder: "Filtre par module",
    //     className: "custom-filter-search",
    //   }),
    // },
    // {
    //     dataField: 'price',
    //     text: 'Actions',
    // },
  ];

  return (
    <FadeTransition in={true}>
      {showBulletinContenu && (
        <BulletinEnTete
          opened={showBulletinContenu}
          onClosed={() => setShowBulletinContenu(false)}
        >
          <BulletinResultat />
        </BulletinEnTete>
      )}
      <div className="d-flex gap-1 align-items-center">
        <Title2>
          Bulletins {currentTrimestre.trimestre} en {currentEpreuveDomaine}{" "}
        </Title2>
        <Badge
          bgColor={rootStyles.color.tercary}
          color={rootStyles.color.primary}
        >
          {currentAnneeAcademique}
        </Badge>
      </div>
      {/* <BootstrapTable
        keyField="id"
        data={currentTrimestreBulletins}
        striped
        columns={columns}
        filter={filterFactory()}
        noDataIndication={() => "Pas encore de données"}
        pagination={paginationFactory()}
        search
      /> */}
      <ToolkitProvider
        keyField="id"
        data={currentTrimestreBulletins}
        columns={columns}
        exportCSV
      >
        {(props) => {
          return (
            <div>
              <MyExportCSV {...props.csvProps} />

              <BootstrapTable
                pagination={paginationFactory()}
                filter={filterFactory()}
                noDataIndication={() => "Pas encore de données"}
                {...props.baseProps}
                // keyField="id"
                // columns={columns}
                // data={[]}
              />
            </div>
          );
        }}
      </ToolkitProvider>
    </FadeTransition>
  );
};

export default ListeBulletins;
