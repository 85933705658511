import { rootStyles } from "assets/css/globalCss";
import ButtonComp from "components/ButtonComp";
import Modal from "components/Modal";
import InputForm from "components/shared/InputForm";
import { useEditProgrammesMutation } from "pages/partenaires/api/mutations";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

const EditProgrammeModal = ({
  onClosed,
  openEditProgramme,
  selectedProgramme,
}) => {
  const [editProgramme, { isLoading }] = useEditProgrammesMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: selectedProgramme.name,
    },
    values: {
      name: selectedProgramme.name,
    },
  });
  const onSubmit = (data) => {
    const newData = { name: data.name };
    editProgramme({ newData, programme_id: selectedProgramme.id })
      .then(() => {
        toast.success("Programme modifié");
        onClosed();
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        // setIsAdding(false);
      });
  };
  return (
    <div>
      <Modal
        title={`Modifier programme ${selectedProgramme.name}`}
        opened={openEditProgramme}
        onClosed={onClosed}
      >
        <form className="py-2" onSubmit={handleSubmit(onSubmit)}>
          <InputForm
            min={3}
            max={50}
            label="Nom Programme"
            type="text"
            htmlFor="name"
            register={{
              ...register("name", {
                required: "Nom programme obligatoire",
              }),
            }}
            error={errors.name?.message}
          />
          <div className="mt-0">
            <ButtonComp
              loading={isLoading}
              title="Modifiez"
              bgColor={rootStyles.color.secondary}
              color={rootStyles.color.primary}
              width={"100%"}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default EditProgrammeModal;
