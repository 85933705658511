import { rootStyles } from "assets/css/globalCss"
import TableListeView from "components/shared/TableListeView"
import React, { useState, useMemo } from "react"
import { textFilter } from "react-bootstrap-table2-filter"
import { toast } from "react-hot-toast"
// import { textFilter } from 'react-bootstrap-table2-filter'
import { AiOutlineCheckCircle } from "react-icons/ai"
import { FiArchive } from "react-icons/fi"
import { RiArrowGoBackLine } from "react-icons/ri"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { ApiController } from "redux/apiController"
import { filterBakelistesList } from "redux/features/bakelisteSlice"
import { tooltip_options } from "utils/tooltip_options"
import { useBakelistesTermines } from "./api/hooks/useBakelistesTermines"
import Loader from "components/shared/Loader"
import FadeTransition from "components/shared/FadeTransition"
import { setCurrentBakelisteSuivi } from "redux/features/bakelisteSlice";
import { DETAILS_SUIVI_BAKELISTES_PATH, BAKELISTES_PATH } from "routes/navigation/navigationPaths";
import ReintegrerWithDateFin from "features/ReintegrerWithDateFin/ReintegrerWithDateFin";
import Modal from "components/Modal";
import { ReintegrerWithDateFinProvider } from "features/ReintegrerWithDateFin/Provider";

const ListeBakelistesTerminesPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const { bakelisteTermines, isTerminesLoading } = useBakelistesTermines()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null)
    const handleOpenModal = (row) => {
        // console.log("i am ok", row);
        setSelectedRow(row);
        setIsModalOpen(true);
    }
    const archiverBakeliste = (currentBakeliste) => {
        const bakeliste_id = currentBakeliste.id
        toast.promise(ApiController.bakelistes.archiverBakeliste(bakeliste_id), {
            loading: "Archivage en cours",
            success: currentBakeliste.first_name + " archivée",
            error: `L'archivage ${currentBakeliste.first_name} a echouée`
        })
    }
    const navigateToDetails = (row) => {
        dispatch(setCurrentBakelisteSuivi(row));
        return navigate(BAKELISTES_PATH + "/" +DETAILS_SUIVI_BAKELISTES_PATH);
    };
    const placerEnStageFunc = (currentBakeliste) => {
        toast.promise(ApiController.bakelistes.placerEnStage({ bakeliste_id: currentBakeliste.id, recruteur_raison: "susi", recruteur_domaine: undefined }), {
            loading: "En cours",
            success: currentBakeliste.first_name + " est maintenant en stage",
            error: `La recrutement de ${currentBakeliste.first_name} a echoué`
        })
    }
    // const reIntegrerStagiaireEnBakeliste = (currentBakeliste) => {
    //     const bakeliste_id = currentBakeliste.id
    //     toast.promise(ApiController.bakelistes.deStageEnBakeliste(bakeliste_id), {
    //         loading: "Réintégration",
    //         success: currentBakeliste.first_name + " est maintenant bakeliste",
    //         error: `La réintégration de ${currentBakeliste.first_name} a echouée`
    //     })
    // }
    // const reIntegrerEnBakeliste = (currentBakeliste) => {
    //     const bakeliste_id = currentBakeliste.id
    //     toast.promise(ApiController.bakelistes.deTerminerABakeliste(bakeliste_id), {
    //         loading: "Réintégration",
    //         success: currentBakeliste.first_name + " est maintenant bakeliste",
    //         error: `La réintégration de ${currentBakeliste.first_name} a echouée`
    //     })
    // }
    const actionsFormatter = (cell, row) => {
        return <div className='d-flex gap-4 justify-content-center'>
            {/* <FiPauseCircle
                onClick={null}
                data-tip data-tooltip-id='pause-btn'
                className='cursor-pointer'
                size={20}
                color={rootStyles.color.secondary}
            /> */}
            <RiArrowGoBackLine
                // onClick={() => reIntegrerEnBakeliste(row)}
                onClick={() => handleOpenModal(row)}
                data-tip data-tooltip-id='reintegrer-btn'
                className='cursor-pointer'
                size={20}
                color={rootStyles.color.tercary}
            />
            <FiArchive
                onClick={() => archiverBakeliste(row)}
                data-tip data-tooltip-id='archiver-btn'
                className='cursor-pointer'
                size={20}
                color={rootStyles.color.red}
            />
            <AiOutlineCheckCircle
                onClick={() => placerEnStageFunc(row)}
                data-tip data-tooltip-id='placer-btn'
                className='cursor-pointer'
                size={20}
                color={rootStyles.color.secondary}
            />

            {/* <ReactTooltip style={tooltip_options} id='pause-btn'>
                Pause
            </ReactTooltip> */}
            <ReactTooltip style={tooltip_options} id='reintegrer-btn'>
                Réintegrez
            </ReactTooltip>
            <ReactTooltip style={tooltip_options} id='archiver-btn'>
                Archivez
            </ReactTooltip>
            <ReactTooltip style={tooltip_options} id='placer-btn'>
                Placez en stage
            </ReactTooltip>
        </div>
    }
    const columns = useMemo(() => [{
        dataField: "first_name",
        text: "Nom complet",
        filter: textFilter({
            placeholder: "Recherche pas nom complet",
        }),
        formatter: (cell, row) => (
            <p
              style={{ cursor: "pointer" }}
              onClick={() => navigateToDetails(row)}
            >
              {row?.first_name} {row?.last_name}
            </p>
          ),
        // formatter: (cell, row) => <p>{row.first_name} {row.last_name}</p>
    },
    {
        dataField: "email",
        filter: textFilter({
            placeholder: "Recherche pas mail",
        }),
        text: "Email",
        },
        {
            dataField: "phone",
            filter: textFilter({
                placeholder: "Recherche pas téléphone",
            }),
            text: "Téléphone",
        },
        {
        dataField: "domaine.name",
        text: "Domaines",
    },
    {
        dataField: "Actions",
        text: "Actions",
        formatter: actionsFormatter
    }
    ], [])
    return (
        <FadeTransition in={true} >
            {isModalOpen && (
                <ReintegrerWithDateFinProvider>
                    <Modal
                        opened={isModalOpen}
                        onClosed={() => setIsModalOpen(false)}
                        title={"Veuillez modifier la nouvelle date de fin de formation du bakeliste"}
                        modalWidth={600}
                    >
                    <ReintegrerWithDateFin
                        onClosed={() => setIsModalOpen(false)}
                        currentBakeliste={selectedRow}
                    />
                    </Modal>
                </ReintegrerWithDateFinProvider>
            )
            }
            {
                isTerminesLoading ? <Loader /> : <TableListeView
                    onChangeInputSearch={(value) => dispatch(filterBakelistesList({ value, type: "termines" }))}
                    data={bakelisteTermines.bakelistesTermine}
                    columns={columns}
                    title={`Bakelistes terminés (${bakelisteTermines?.countBakelistesTermine})`}
            />
            }
        </FadeTransition>
    )
}

export default ListeBakelistesTerminesPage