import "normalize.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import "@sjmc11/tourguidejs/src/scss/tour.scss" // Style<s

// Sentry.init({
//   dsn: "https://f481cf8588836f385245102d18242e5f@o4505788966174720.ingest.sentry.io/4505788967813120",
//   integrations: [
//     new Sentry.BrowserTracing({
//       tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
//     }),
//     new Sentry.Replay(),
//   ],

//   tracesSampleRate: 1.0,
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //   <React.StrictMode>
  <App />
  //   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorkerRegistration.register();
reportWebVitals();
