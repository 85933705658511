import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { privateRoutes, publicRoutes } from "./navigation/navigationRoutes";
import RequireAuth, { PublicAuth } from "layouts/RequireAuth";
import { NOTFOUND } from "./navigation/navigationPaths";

const recursiveRoutes = (routes) => {
    return <>
        {
            routes.map((route, index) => {
                if (route.children) {
                    return <Route path={route.path} element={route.element} key={index}>
                        {recursiveRoutes(route.children)}
                    </Route>
                }
                return <Route path={route.path} element={route.element} key={index} />
            })
        }
    </>
}

function Roots() {
    return (
        <BrowserRouter>
            {/* <RouterProvider router={publicRoutes} />
            <RouterProvider router={privateRoutes} /> */}
            <Routes>
                <Route element={<PublicAuth />}>
                    {
                        recursiveRoutes(publicRoutes)
                    }
                </Route>
                <Route element={<RequireAuth />}>
                    {
                        recursiveRoutes(privateRoutes)
                    }
                </Route>
                <Route path={NOTFOUND} element={<div>Page 404</div>} />
            </Routes>
        </BrowserRouter>
    );
}

export default Roots;
