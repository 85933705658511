import Modal from "components/Modal";
import { useAddNewStagiaireMutation } from "pages/bakelistes/api/mutations";
import React from "react";
import { toast } from "react-hot-toast";
import { useCurrentUser } from "hooks/useCurrentUser"

import StagiaireForm from "./StagiaireForm";

const AddNewStagiaireModal = ({
  onClosed,
  openAddNewStagiaire
}) => {
  const { currentUser } = useCurrentUser();

  const [addNewStagiaire, { isLoading }] = useAddNewStagiaireMutation();
  const onSubmit = (data) => {
    data["created_by"] = currentUser.id;
    addNewStagiaire(data)
      .unwrap()
      .then((res) => {
        toast.success("Stagiaire ajouté");
        onClosed();
      })
      .catch((err) => {
        console.log({ err });
        toast.error("Une erreur est survenue");
      });
  };
  return (
    <div>
      <Modal
        title={"Nouveau Stagiaire"}
        opened={openAddNewStagiaire}
        onClosed={onClosed}
      >
        <StagiaireForm onSubmit={onSubmit} isSubmiting={isLoading} />
      </Modal>
    </div>
  );
};

export default AddNewStagiaireModal;
