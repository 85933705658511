import React from "react"
import EditTypePresence from "./EditTypePresence"
import Modal from "components/Modal"
import { TypesPresenceProvider } from "./TypePresenceProvider"

const EditTypePresenceModal = ({ isEditTypePresenceOpened, closeEditTypePresenceModal, selectedTypePresence }) => {
  return (
    <div>
      {
        isEditTypePresenceOpened && <Modal
          opened={isEditTypePresenceOpened}
          onClosed={closeEditTypePresenceModal}
          title={"Modifier"}
        >
          <TypesPresenceProvider initialValues={selectedTypePresence}>
            <EditTypePresence closeEditTypePresenceModal={closeEditTypePresenceModal} selectedTypePresence={selectedTypePresence} />
          </TypesPresenceProvider>
        </Modal>
      }
    </div>
  )
}

export default EditTypePresenceModal