/* eslint-disable operator-linebreak */
import React, { useRef } from "react";
import styled from "styled-components";
import senegal from "../../../../../assets/img/senegal.png";
import logoBakeli from "../../../../../assets/img/logoBqk.png";
import ReactToPrint from "react-to-print";
import { rootStyles } from "assets/css/globalCss";

const CertificateContainer = styled.div`
  border: 4px solid #ff9800;
  padding: 1rem;
  margin: 1rem;
  height: 90vh;
  background: white;
  cursor: pointer;
`;
const Separator = styled.div`
  width: 300px;
  height: 3px;
  background-color: #069a77;
  margin-bottom: 1rem;
`;

const SeparatorEnd = styled.div`
  width: 300px;
  height: 3px;
  background-color: #069a77;
  margin-bottom: 1rem;
  position: absolute;
  right: 10px;
`;

const Content = styled.div`
  padding: 1rem;
  border: 2px solid black;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .diploma_title {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 1rem 0; */
    p {
      font-size: 1.2rem;
      padding: 8px 1.5rem;
      background: ${(props) =>
        props?.type === "courte"
          ? rootStyles.color.secondary
          : rootStyles.color.tercary};
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      border-radius: 7px;
      border: none;
      -webkit-box-shadow: 0px 7px 22px -12px rgba(0, 0, 0, 1);
      -moz-box-shadow: 0px 7px 22px -12px rgba(0, 0, 0, 1);
      box-shadow: 0px 7px 22px -12px rgba(0, 0, 0, 1);
    }
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  :nth-child(2) {
    margin-top: 2rem;
  }

  h1 {
    font-size: 24px;
    font-weight: bold;
  }
  .left {
    display: flex;
    gap: 8px;
    p {
      font-weight: 500;
      /* font-size: 14px; */
    }
  }
  .sub_left {
    margin-top: 20px;
    p {
      font-weight: 500;
      /* font-size: 12px; */
    }
  }

  .right {
    display: flex;
    align-items: center;
    p {
      font-weight: bold;
      font-size: 12px;
    }
    gap: 8px;
  }
  img {
    width: 60px;
  }

  .number-section {
    font-size: 14px;
    align-self: self-end;
    font-weight: 500;
    span {
      color: red;
      font-weight: bold;
    }
  }
`;

const Body = styled.div`
  .diploma_content {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 2rem;
    span {
      font-weight: bold;
    }
    text-align: center;
    .diploma_student_name {
      /* span {
        text-decoration: underline;
        text-decoration-style: dotted;
      } */
    }
    .diploma_student_infos {
      text-align: center;
      span {
        font-weight: bold;
      }
    }
  }
`;

const Footer = styled.footer`
  /* border-top: 1px solid #ccc; */
  padding: 1rem;
  text-align: right;
  font-weight: 600;
  font-style: italic;
  font-size: 14px;
`;

const AttestationPage = ({
  studentName,
  birthDate,
  birthPlace,
  program,
  certificationType,
}) => {
  const attestationRef = useRef();
  return (
    <>
      <div className="my-2 px-2 fw-semibold text-center">
        Cliquer directement sur l'atestation pour l'imprimer
      </div>
      <ReactToPrint
        // pageStyle="p-1"
        content={() => attestationRef.current}
        trigger={() => (
          <div className="bg-white p-2 position-relative" ref={attestationRef}>
            <Separator />
            <CertificateContainer>
              <Content type={certificationType}>
                <div>
                  <Header>
                    <div className="left">
                      <img
                        // src="https://upload.wikimedia.org/wikipedia/commons/f/fd/Flag_of_Senegal.svg"
                        src={senegal}
                        alt="senegal flag"
                        loading="lazy"
                      />
                      <div>
                        <p>RÉPUBLIQUE DU SÉNÉGAL</p>
                        <p>Un Peuple - Un But - Une Foi</p>
                      </div>
                    </div>
                    <div className="right">
                      <div>
                        <img
                          src={logoBakeli}
                          alt="senegal flag"
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <p>Bakeli School of Technology</p>
                        <p>HLM Grand YOFF, VILLA N241 (Dakar-Sn)</p>
                        <p>TEL + 221 78 546 15 51</p>
                      </div>
                    </div>
                  </Header>

                  <Header>
                    <div className="sub_left">
                      <p>
                        Direction de la Formation Proféssionnelle et Technique
                      </p>
                      <p>
                        Ministère de la Formation Proféssionnelle, de
                        l'apprentissage et de l'artisanat
                      </p>
                    </div>
                    <div className="number-section">
                      <p>
                        <span>N: 00000 122</span> MFPAA/SG/DFPT/Div-priv
                      </p>
                    </div>
                  </Header>
                </div>

                <div className="diploma_title">
                  {certificationType === "diplomante" && (
                    <p>Brevet de technicien supperieur (bts)</p>
                  )}

                  {certificationType === "courte" && (
                    <p>Certificat professionel</p>
                  )}
                </div>

                <Body>
                  <div className="diploma_content">
                    <p>
                      Le Directeur Général de Bakeli School of Technology,
                      soussigné M. Abdoul Khadre DIALLO atteste
                    </p>
                    <p className="diploma_student_name">
                      que : <span>{studentName}</span>
                    </p>
                    <p className="diploma_student_infos">
                      née le <span>{birthDate}</span> à{" "}
                      <span>{birthPlace}</span> a suivi le programme de
                      formation requis en <br />
                      <span>
                        {program === "Programmation"
                          ? "Développement logigiel"
                          : program}
                      </span>
                      .
                    </p>
                  </div>
                </Body>

                <Footer>
                  <p>Le Directeur Général</p>
                </Footer>
              </Content>
            </CertificateContainer>
            <SeparatorEnd />
          </div>
        )}
        documentTitle={`Attestation ${studentName}`}
      />
    </>
  );
};

export default AttestationPage;
