import ResourceDataMap from "components/ResourceDataMap"
import Skeleton from "components/skeleton/Skeleton"
// import { Container } from 'pages/bakelistes/BakelisteHomePageDashboard'
import React from "react"
import styled from "styled-components"

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(250px,1fr));
    gap: 15px;
    overflow-y: auto;
    padding: 20px 5px;
`
const GridListe = ({
    isLoading,
    resourceItem: ResourceItem,
    resourceName,
    resourceData = [],
    emptyMessage
}) => {
    return (
        <>
            {
                isLoading ?
                    <Container>
                        {
                            [1, 2, 3, 4, 5, 6].map((item) => <Skeleton key={item} />)
                        }
                    </Container> :
                    resourceData.length ?
                        <Container>
                            <ResourceDataMap
                                resourceData={resourceData}
                                resourceItem={ResourceItem}
                                resourceName={resourceName}
                            />
                        </Container> :
                        <p>{emptyMessage}</p>
            }
        </>
    )
}


export default GridListe