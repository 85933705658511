import { CHANGE_BAKELISTE_TO_ONLINE, CHANGE_BAKELISTE_TO_PRESENCIEL } from "routes/api/endpoints";

const { siAdminAPi } = require("redux/rtkquery");

const bakelistesMutationsApi = siAdminAPi.injectEndpoints({
    endpoints: (builder) => ({
        mettreEnLigne: builder.mutation({
            query: (bakelisteId) => {
                if (!bakelisteId) throw new Error("bakelisteId is required")
                return {
                    url: CHANGE_BAKELISTE_TO_ONLINE + bakelisteId,
                    method: "POST",
                }
            },
            invalidatesTags: ["Bakelistes"],
        }),
        mettreEnPresentiel: builder.mutation({
            query: (bakelisteId) => {
                if (!bakelisteId) throw new Error("bakelisteId is required")
                return {
                    url: CHANGE_BAKELISTE_TO_PRESENCIEL + bakelisteId,
                    method: "POST",
                }
            },
            invalidatesTags: ["Bakelistes"],
        }),
    }),
});

export const { useMettreEnLigneMutation, useMettreEnPresentielMutation } = bakelistesMutationsApi