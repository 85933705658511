/* eslint-disable operator-linebreak */
import { rootStyles } from "assets/css/globalCss";
import ProgressBar from "components/ProgressBar";
import React from "react";
import { FcOvertime } from "react-icons/fc";
import { FiCheckCircle } from "react-icons/fi";
import styled from "styled-components";
// import { createMarkup } from "utils/createMarkup";
import { dateFormatter } from "utils/timeFormatters";
import { truncateString } from "utils/truncateString";
// import { truncateString } from "utils/truncateString";

// STYLES
const SyllabusContainer = styled.div`
  background-color: ${rootStyles.color.primary};
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${rootStyles.radius.card};
  position: relative;
  box-shadow: ${rootStyles.shadow.card};
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  height: 150px;
`;

const CardBody = styled.div`
  padding: ${rootStyles.padding.btnPadding};
`;
const CardTitle = styled.h2`
  font-size: ${rootStyles.fonts.h2FontSize};
`;
const TimeSpan = styled.span`
  font-size: 12px;
  display: block;
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 2px;
`;

// const DescriptionSection = styled.div`
//   font-size: ${rootStyles.fonts.fontSize};
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// `;

const ProgressSpan = styled.span`
  font-size: 1rem;
  font-weight: 500;
  padding: 1px 1rem;
  text-align: end;
  color: gray;
  display: flex;
  align-items: center;
  justify-content: end;
`;

const SyllabusCardItem = ({ syllabus, navigateToDetails }) => {
  const {
    syllabus_name,
    syllabus_image,
    created_at,
    taches_validees_count,
    details_count,
    // syllabus_description,
  } = syllabus;
  // console.log({ taches_validees_count, details_count })
  return (
    <SyllabusContainer onClick={() => navigateToDetails(syllabus)}>
      <ImageContainer>
        <img
          style={{ height: "100%", objectFit: "cover", width: "100%" }}
          src={
            syllabus_image
              ? `${process.env.REACT_APP_API_IMAGE_URL}syllabusImages/${syllabus_image}`
              : "https://www.ekreative.com/wp-content/uploads/2021/03/pankaj-patel-_SgRNwAVNKw-unsplash-1024x683.jpg"
          }
          alt={syllabus_name}
        />
      </ImageContainer>
      <CardBody>
        <CardTitle>{truncateString(syllabus_name, 22)}</CardTitle>

        {/* <DescriptionSection
          dangerouslySetInnerHTML={createMarkup(
            truncateString(syllabus_description, 100)
          )}
        /> */}
        {created_at && (
          <TimeSpan>
            <FcOvertime size={20} />
            Date de création: {dateFormatter(created_at)}
          </TimeSpan>
        )}
      </CardBody>
      {created_at && (
        <>
          <ProgressBar
            color={rootStyles.color.secondary}
            width={"100%"}
            value={taches_validees_count}
            max={details_count}
          />
          <ProgressSpan>
            <FiCheckCircle className="mx-2" />
            {`${taches_validees_count}/${details_count}`}
          </ProgressSpan>
        </>
      )}
    </SyllabusContainer>
  );
};

export default SyllabusCardItem;
