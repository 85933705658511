import Flex from "components/shared/Flex"
import SelectOnChange from "components/shared/SelectOnChange"
import React from "react"
import { CAMPUS_VILLES, VILLE_FORMATION } from "../../../constants/index";
import { useCalendrierPresenceContext } from "context/usePointageCalendarContext";

const FilterPeriod = () => {
    // const { anneesDePaie, isAnneesLoading } = useAnnees()
    const { changeZoneQueryParams, currentVille } = useCalendrierPresenceContext()
    return (
        <Flex gap={10}>
            <div>
                <SelectOnChange
                    defaultValue={currentVille}
                    onChange={(value) => {
                        changeZoneQueryParams(value)
                        // return setCurrentPointageVille(value)
                    }}
                >
                    {
                        CAMPUS_VILLES
                            .filter((ville) => ville.id !== VILLE_FORMATION.labe)
                            .map((option) => {
                            return <option key={option.id}>
                                {option.label}
                            </option>
                            })
                            .concat(<option key={1} value={""}>Toutes les régions</option>).reverse()
                    }
                    {/* <option>Dakar</option>
                    <option>Thies</option>
                    <option>Mbour</option> */}
                </SelectOnChange>
            </div>
            {/* <div>
                <SelectOnChange>
                    {
                        isAnneesLoading ? null : anneesDePaie.map((annee) => (
                            <option key={annee.id} value={annee.id}>
                                {annee.annee}
                            </option>
                        ))
                    }
                </SelectOnChange>
            </div> */}
        </Flex>
    )
}

export default FilterPeriod