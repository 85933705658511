import { setProspectsListSlice } from "redux/features/prospectSlice";
import { store } from "redux/store";

const { axiosInstance } = require("redux/apiController/axiosInstance");

const dispatch = store.dispatch

export const prospectsSwrFetcher = (url) => axiosInstance.get(url).then((res) => {
    console.log({ prospects: data });
    dispatch(setProspectsListSlice(data))
    return res.data.data
})
export const statistiqueProspectsSwrFetcher = (url) => axiosInstance.get(url).then((res) => {
    return res.data
})