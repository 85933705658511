import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    totalBakelistes: 0,
    totalAdmins: 0,
    totalCoachs: 0,
    totalCoachsAssistant: 0,
    totalVisiteur: 0,
    totalUtilisateur: 0,
    comments: [],
    commentsNumber: 0,
    validations: [],
    validationsNumber: 0,
    livraisons: [],
    livraisonsNumber: 0,
    bakelistesActifs: [],
    bakelistesActifsNumber: 0,
    coachAndAssistantCoachsStatsRelatedToCommentsAndValidations: []
}

/**
* !Some SiKpislice reducers are used in SiKpiApi.js located in
redux/apiContoller/
*/

const siKpiSlice = createSlice({
    name: "siKpis",
    initialState,
    reducers: {
        setTotalBakeliste: (state, { payload }) => {
            return {
                ...state,
                totalBakelistes: payload
            }
        },
        setTotalAdmin: (state, { payload }) => {
            return {
                ...state,
                totalAdmins: payload
            }
        },
        setTotalCoach: (state, { payload }) => {
            return {
                ...state,
                totalCoachs: payload
            }
        },
        setTotalCoachAssistant: (state, { payload }) => {
            return {
                ...state,
                totalCoachsAssistant: payload
            }
        },
        setTotalVisiteur: (state, { payload }) => {
            return {
                ...state,
                totalVisiteur: payload
            }
        },
        setTotalUtilisateur: (state, { payload }) => {
            return {
                ...state,
                totalUtilisateur: payload
            }
        },
        setCommentsSlice: (state, { payload }) => {
            return {
                ...state,
                comments: payload
            }
        },
        setCommentsNumberSlice: (state, { payload }) => {
            return {
                ...state,
                commentsNumber: payload
            }
        },
        setValidationsSlice: (state, { payload }) => {
            return {
                ...state,
                validations: payload
            }
        },
        setValidationsNumberSlice: (state, { payload }) => {
            return {
                ...state,
                validationsNumber: payload
            }
        },
        setLivraisonsSlice: (state, { payload }) => {
            return {
                ...state,
                livraisons: payload
            }
        },
        setLivraisonsNumberSlice: (state, { payload }) => {
            console.log({ payload });
            return {
                ...state,
                livraisonsNumber: payload
            }
        },
        setBakelistesActifsSlice: (state, { payload }) => {
            return {
                ...state,
                bakelistesActifs: payload
            }
        },
        setBakelistesActifsNumberSlice: (state, { payload }) => {
            return {
                ...state,
                bakelistesActifsNumber: payload
            }
        },
        setCoachAndAssistantCoachsStatsRelatedToCommentsAndValidations: (state, { payload }) => {
            return {
                ...state,
                coachAndAssistantCoachsStatsRelatedToCommentsAndValidations: payload
            }
        }
    }
}
)


export const {
    setTotalBakeliste,
    setTotalAdmin,
    setTotalCoach,
    setTotalCoachAssistant,
    setTotalVisiteur,
    setTotalUtilisateur,
    setCommentsSlice,
    setCommentsNumberSlice,
    setValidationsSlice,
    setValidationsNumberSlice,
    setLivraisonsSlice,
    setLivraisonsNumberSlice,
    setBakelistesActifsSlice,
    setBakelistesActifsNumberSlice,
    setCoachAndAssistantCoachsStatsRelatedToCommentsAndValidations
} = siKpiSlice.actions

export default siKpiSlice.reducer