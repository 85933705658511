import { rootStyles } from "assets/css/globalCss"
import React from "react"

const AvatarBakeliste = ({ avatar_file }) => {
  return (
    <>
      <img
        width={70}
        height={70}
        style={{
          display: "inline-block",
          borderRadius: "100%",
          objectFit: "cover",
          border: `5px solid ${rootStyles.color.tercary}`
        }}
        src={process.env.REACT_APP_API_BAKELISTE_PROFIL + avatar_file}
        onError={({ target }) => target.src = "https://t4.ftcdn.net/jpg/01/24/65/69/360_F_124656969_x3y8YVzvrqFZyv3YLWNo6PJaC88SYxqM.jpg"}
        loading="lazy"
      />
    </>
  )
}

export default AvatarBakeliste