import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Badge } from "pages/bakelistes/Details/DetailsBakelistePage";
import { Link } from "react-router-dom";
import { setCurrentBakelistePaiement } from "redux/features/paiementSlice";
import { BsEye } from "react-icons/bs";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import Loader from "components/shared/Loader";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const PaiementPresentielListe = ({
  paiementBakelisteEnPresentiel = [],
  isPresentielLoading,
}) => {
  const dispatch = useDispatch();
  const actionsFormatter = (cell, row) => {
    return (
      <div className="d-flex justify-content-center">
        <Badge>
          <Link
            onClick={() => {
              dispatch(setCurrentBakelistePaiement(row));
            }}
            style={{ color: "gray" }}
            to={`${row.id}`}
          >
            <BsEye size={25} />
          </Link>
        </Badge>
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        dataField: "first_name",
        text: "Nom complet",
        formatter: (cell, row) => (
          <p>
            {row.user.first_name} {row.user.last_name}
          </p>
        ),
        filter: textFilter({
          placeholder: "Filtre par Nom",
          className: "custom-filter-search",
        }),
      },
      {
        dataField: "user.email",
        text: "Email",
        // filter: textFilter({
        //     placeholder: 'Domaine',
        // })
      },
      {
        dataField: "paye_le",
        text: "Paye le",
        filter: textFilter({
          placeholder: "Filtre par data",
          className: "custom-filter-search",
        }),
        formatter: (cell, row) => {
          return new Date(row.paye_le).toLocaleString("fr-Fr", {
            dateStyle: "full",
          });
        },
      },
      {
        dataField: "montant",
        text: "Montant",
        // filter: textFilter({
        //     placeholder: 'Email',
        // })
        formatter: (cell, row) => {
          return <p className="text-center">{row.montant}</p>;
        },
      },
      {
        dataField: "Actions",
        text: "Details",
        formatter: actionsFormatter,
      },
    ],
    []
  );

  return (
    <>
      {isPresentielLoading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <BootstrapTable
          keyField="id"
          data={paiementBakelisteEnPresentiel}
          striped
          columns={columns}
          filter={filterFactory()}
          noDataIndication={() => "Pas encore de données"}
          pagination={paginationFactory()}
          search
        />
      )}
    </>
  );
};

export default PaiementPresentielListe;
