import { useAddSpaceMutation, useUpdateSpaceMutation } from "actions/spaces/mutatons"
import { useFormContext } from "react-hook-form"
import toast from "react-hot-toast"

export const useSpace = ({ closeSpaceModal }) => {
  const { reset } = useFormContext()
  const [ajouterSpace, { isLoading: isAddingSpace }] = useAddSpaceMutation()
  const [editSpace, { isLoading: isEditingSpace }] = useUpdateSpaceMutation()

  const handleAddSpace = (data) => {
    toast.promise(ajouterSpace(data)
      .unwrap().then(() => {
        reset()
        closeSpaceModal()
      }), {
      loading: "Ajout en cours...",
      success: "Ajout réussi",
      error: "L'ajout a echouée"
    })
  }

  const handleEditSpace = (data) => {
    const id = data.space_id
    delete data.space_id
    toast.promise(editSpace({ data, id })
      .unwrap().then(() => {
        reset()
        closeSpaceModal()
      }), {
      loading: "Mise à jour en cours...",
      success: "Mise à jour réussie",
      error: "La mise à jour a echouée"
    })
  }

  return { handleAddSpace, handleEditSpace, isAddingSpace, isEditingSpace }
}