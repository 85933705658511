import { store } from "../store"
import {
    FILTER_COACHS_AND_ASSISTANT_COACHS_COMMENTS_AND_VALIDATIONS,
    GET_ALL_ACTIFS_BAKELISTES, GET_ALL_LIVRAISONS, GET_COACHS_AND_ASSISTANT_COACHS_COMMENTS_AND_VALIDATIONS, GET_KPI_CURRENT_YEAR
} from "routes/api/endpoints.js"
import {
    setBakelistesActifsNumberSlice, setBakelistesActifsSlice,
    setCoachAndAssistantCoachsStatsRelatedToCommentsAndValidations,
    setCommentsNumberSlice, setLivraisonsNumberSlice, setLivraisonsSlice,
    setTotalAdmin, setTotalBakeliste, setTotalCoach, setTotalCoachAssistant, setTotalUtilisateur, setTotalVisiteur, setValidationsNumberSlice
} from "redux/features/siKpiSlice.js"
import { axiosInstance } from "./axiosInstance"

const dispatch = store.dispatch
const siKpisController = {
    getSiKpiById(SiKpiId) {
        return new Promise((resolve, reject) => {
            axiosInstance.get("******** PUT URL HERE ***********")
                .then((response) => {
                    if (response.status === 200) {
                        /**
                        * !setSiKpiList is from reducer.SiKpi.js, just for SiKpi
                        * !Don't forget to check your response content data type
                        */
                        const data = response.data
                        resolve(data)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    // getNombreCommentsAndNombreValidations() {
    //     return new Promise((resolve, reject) => {
    //         axiosInstance.get(GET_COMMENTS_NUMBER_AND_VALIDATIONS_NUMBER)
    //             .then((response) => {
    //                 if (response.status >= 200) {
    //                     const nbres_commentaires = response.data.nbres_commentaires
    //                     const nbres_validations = response.data.nbres_validations
    //                     dispatch(setCommentsNumberSlice(nbres_commentaires))
    //                     dispatch(setValidationsNumberSlice(nbres_validations))
    //                     resolve(response)
    //                 } else {
    //                     reject(response)
    //                 }
    //             })
    //             .catch((res) => reject(res))
    //     })
    // },
    getAllLivraisons() {
        return new Promise((resolve, reject) => {
            axiosInstance.get(GET_ALL_LIVRAISONS)
                .then((response) => {
                    if (response.status >= 200) {
                        const total_count = response.data.total_count
                        const livraisons = response.data.livraisons
                        // console.log({ total_count, livraisons });
                        dispatch(setLivraisonsSlice(livraisons))
                        dispatch(setLivraisonsNumberSlice(total_count))
                        resolve(response)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    getAllActifsBakelistes() {
        return new Promise((resolve, reject) => {
            axiosInstance.get(GET_ALL_ACTIFS_BAKELISTES)
                .then((response) => {
                    if (response.status >= 200) {
                        const data = response.data
                        const bakelistesActifs = response.bakelistesActifs
                        dispatch(setBakelistesActifsSlice(data))
                        dispatch(setBakelistesActifsNumberSlice(bakelistesActifs))
                        resolve(response)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    // getOthersData() {
    //     return new Promise((resolve, reject) => {
    //         axiosInstance.get(GET_OTHERS_DATA)
    //             .then((response) => {
    //                 if (response.status >= 200) {
    //                     const nbreBakeliste = response.data.nbreBakeliste
    //                     const nbreAdmin = response.data.nbreAdmin
    //                     const nbreCoach = response.data.nbreCoach
    //                     const nbreCoachAss = response.data.nbreCoachAss
    //                     const nbreVisiteur = response.data.nbreVisiteur
    //                     const allUser = response.data.allUser
    //                     dispatch(setTotalBakeliste(nbreBakeliste))
    //                     dispatch(setTotalAdmin(nbreAdmin))
    //                     dispatch(setTotalCoach(nbreCoach))
    //                     dispatch(setTotalCoachAssistant(nbreCoachAss))
    //                     dispatch(setTotalVisiteur(nbreVisiteur))
    //                     dispatch(setTotalUtilisateur(allUser))
    //                     resolve(response)
    //                 } else {
    //                     reject(response)
    //                 }
    //             })
    //             .catch((res) => reject(res))
    //     })
    // },
    getKpiAdminByCurrentYear() {
        return new Promise((resolve, reject) => {
            axiosInstance.get(GET_KPI_CURRENT_YEAR)
                .then((response) => {
                    if (response.status >= 200) {
                        const nbreBakeliste = response.data.nbreBakeliste
                        const nbreAdmin = response.data.nbreAdmin
                        const nbreCoach = response.data.nbreCoach
                        const nbreCoachAss = response.data.nbreCoachAss
                        const nbreVisiteur = response.data.nbreVisiteur
                        const allUser = response.data.allUser
                        const nbres_commentaires = response.data.nbres_commentaires
                        const nbres_validations = response.data.nbres_validations
                        dispatch(setTotalBakeliste(nbreBakeliste))
                        dispatch(setTotalAdmin(nbreAdmin))
                        dispatch(setTotalCoach(nbreCoach))
                        dispatch(setTotalCoachAssistant(nbreCoachAss))
                        dispatch(setTotalVisiteur(nbreVisiteur))
                        dispatch(setTotalUtilisateur(allUser))
                        dispatch(setCommentsNumberSlice(nbres_commentaires))
                        dispatch(setValidationsNumberSlice(nbres_validations))
                        resolve(response)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    getCoachsAndAssistansCoachsCommentsAndValidations() {
        return new Promise((resolve, reject) => {
            axiosInstance.get(GET_COACHS_AND_ASSISTANT_COACHS_COMMENTS_AND_VALIDATIONS)
                .then((response) => {
                    if (response.status >= 200) {
                        // console.log({ data: response.data.data });
                        const data = response.data.data
                        dispatch(setCoachAndAssistantCoachsStatsRelatedToCommentsAndValidations(data))
                        resolve(response)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    filterCoachsAndAssistansCoachsCommentsAndValidations(data) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(FILTER_COACHS_AND_ASSISTANT_COACHS_COMMENTS_AND_VALIDATIONS, data)
                .then((response) => {
                    if (response.status >= 200) {
                        // console.log({ data: response.data.data });
                        const data = response.data.data
                        dispatch(setCoachAndAssistantCoachsStatsRelatedToCommentsAndValidations(data))
                        resolve(response)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
}
export default siKpisController