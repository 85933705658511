import { rootStyles } from "assets/css/globalCss"
import { Title2 } from "components/shared/TableListeView"
import { Badge } from "pages/bakelistes/Details/DetailsBakelistePage"
import React, { useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next"
import { useDispatch, useSelector } from "react-redux"
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { FaFilePdf } from "react-icons/fa"
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import EpreuveModal from "components/examens/EpreuveModal"
import EpreuveTheorique from "components/examens/EpreuveTheorique"
import EpreuvePratique from "components/examens/EpreuvePratique"
import {
  setCurrentEpreuveModule,
  setCurrentEpreuveType,
  setEpreuvePratique,
  setEpreuveTheorique,
} from "redux/features/examenSlice";
import Loader from "components/shared/Loader"
import FlexBetween from "components/shared/FlexBetween"
import ButtonComp from "components/ButtonComp"
import { useNavigate } from "react-router-dom"
import { LISTE_BULLETINS_PATH } from "routes/navigation/navigationPaths"
import useSWR from "swr";
import { GET_EXAMENS_BY_TRIMESTRE_ENDPOINT } from "routes/api/endpoints";
import { axiosSwrFetcherForEpreuves } from "../api";
import FadeTransition from "components/shared/FadeTransition";

const ListeExamensPage = () => {
  const { currentAnneeAcademique, currentTrimestre } = useSelector(
    (state) => state.examens
  );
  const { data: examensList, isLoading } = useSWR(
    GET_EXAMENS_BY_TRIMESTRE_ENDPOINT + currentTrimestre.id,
    axiosSwrFetcherForEpreuves
  );

  const [showEpreuveTheorique, setShowEpreuveTheorique] = useState(false);
  const [showEpreuvePratique, setShowEpreuvePratique] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const titleFormatter = (cell, row) => {
    return `Epreuve ${row.module.module_name}`;
  };

  const displayEpreuveTheorique = (epreuve) => {
    setShowEpreuveTheorique(true);
    console.log({ epreuve });
    dispatch(setEpreuveTheorique(epreuve));
  };
  const displayEpreuvePratique = (epreuve) => {
    setShowEpreuvePratique(true);
    dispatch(setEpreuvePratique(epreuve));
  };

  const columns = useMemo(
    () => [
      {
        dataField: "pdf",
        text: "Epreuves",
        formatter: (cell, row) => (
          <p style={{ cursor: "pointer" }} className="text-center m-0">
            <FaFilePdf
              onClick={() => {
                dispatch(setCurrentEpreuveModule(row.module.module_name));
                dispatch(setCurrentEpreuveType(row.type));
                return row.type === "Théorique" ?
                  displayEpreuveTheorique(row.Details) :
                  displayEpreuvePratique(row.Details);
              }}
              size={25}
            />
          </p>
        ),
      },
      {
        dataField: "title",
        text: "Titre",
        formatter: titleFormatter,
        filter: textFilter({
          placeholder: "Filtre par titre",
          className: "custom-filter-search",
        }),
        // sort: true
      },
      {
        dataField: "type",
        text: "Type",
        filter: textFilter({
          placeholder: "Filtre par type",
          className: "custom-filter-search",
        }),
        // sort: true
        // formatter: actionsFormatter
      },
      {
        dataField: "domaine",
        text: "Domaines",
        // sort: true,
        formatter: (cell, row) => row.domaine.name,
        filter: textFilter({
          placeholder: "Filtre par domaine",
          className: "custom-filter-search",
        }),
      },
      {
        dataField: "module",
        text: "Modules",
        formatter: (cell, row) => row.module.module_name,
        filter: textFilter({
          placeholder: "Filtre par module",
          className: "custom-filter-search",
        }),
        // sort: true
      },
      // {
      //     dataField: 'price',
      //     text: 'Actions',
      // },
    ],
    []
  );
  return (
    <FadeTransition in={true}>
      {showEpreuveTheorique && (
        <EpreuveModal
          opened={showEpreuveTheorique}
          onClosed={() => setShowEpreuveTheorique(false)}
        >
          <EpreuveTheorique />
        </EpreuveModal>
      )}
      {showEpreuvePratique && (
        <EpreuveModal
          opened={showEpreuvePratique}
          onClosed={() => setShowEpreuvePratique(false)}
        >
          <EpreuvePratique />
        </EpreuveModal>
      )}

      <FlexBetween>
        <div className="d-flex gap-1 align-items-center">
          <Title2>Epreuves {currentTrimestre.trimestre} </Title2>
          <Badge
            bgColor={rootStyles.color.tercary}
            color={rootStyles.color.primary}
          >
            {currentAnneeAcademique}
          </Badge>
        </div>
        <ButtonComp
          onClick={() => {
            // dispatch(setCurrentTrimestreBulletins())
            navigate(LISTE_BULLETINS_PATH);
          }}
          bgColor={rootStyles.color.tercary}
          color={rootStyles.color.primary}
          title="Voir les bulletins"
        />
      </FlexBetween>
      {isLoading ? (
        <Loader />
      ) : (
        <BootstrapTable
          keyField="id"
          data={examensList}
          striped
          columns={columns}
          filter={filterFactory()}
          noDataIndication={() => "Pas encore de données"}
          pagination={paginationFactory()}
          search
        />
      )}
    </FadeTransition>
  );
};

export default ListeExamensPage