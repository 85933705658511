import { rootStyles } from "assets/css/globalCss";
import ResourceDataMap from "components/ResourceDataMap";
import Loader from "components/shared/Loader";
import SelectOnChange from "components/shared/SelectOnChange";
import TableListeView, { Title2 } from "components/shared/TableListeView";
import { Container } from "pages/bakelistes/BakelisteHomePageDashboard";
import React, { useEffect, useMemo, useState } from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import { toast } from "react-hot-toast";
import { BsEye } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
// import { ApiController } from "redux/apiController";
import styled from "styled-components";
import { useGetSuiviCoaching } from "../../api/hooks/useGetSuiviClient";

import ButtonComp from "components/ButtonComp";
import Div from "components/shared/Div";
import FadeTransition from "components/shared/FadeTransition";
import InputForm from "components/shared/InputForm";
import Title3 from "components/shared/Title3";
import TitleLine from "components/shared/TitleLine";
import { useGetCoachsAssistantCommentsAndValidations } from "pages/suivi-coaching/api/hooks/useGetCoachsAssistantCommentsAndValidations";
import { Bar } from "react-chartjs-2";
import { useForm } from "react-hook-form";
import { BiSearch } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { setCurrentCoachSuivi } from "redux/features/coachSlice";
import { setCoachAndAssistantCoachsStatsRelatedToCommentsAndValidations } from "redux/features/siKpiSlice";
import { DETAILS_SUIVI_COACHING_PATH } from "routes/navigation/navigationPaths";
import { today } from "utils/timeFormatters";

const options = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 10,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: true,
      text: "Vue d'ensemble des commentaires et validations des coachs",
    },
    datalabels: {
      display: true,
      color: "white",
      align: "start",
      padding: {
        right: 2,
        top: 10,
      },
      labels: {
        padding: { top: 20 },
        title: {
          font: {
            weight: "normal",
          },
        },
        value: {
          color: "white",
        },
      },
      formatter: function (value) {
        return value;
      },
    },
  },
};

const Section = styled.section`
  background-color: ${rootStyles.color.primary};
  padding: ${rootStyles.padding.btnPadding};
  margin-top: 20px;
  border-radius: ${rootStyles.radius.card};
  box-shadow: ${rootStyles.shadow.card};
`;

export const ClickPane = styled.div`
  cursor: pointer;
  // border: 1px solid gray;
  padding: 5px 10px;
  border-radius: 10px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;

  &:hover {
    opacity: 0.7;
    background-color: rgba(190, 200, 230, 0.8);
  }
`;

const SuiviCoachingpage = () => {
  // state
  const [commentTime, setCommentTime] = useState("day");

  const { register, handleSubmit } = useForm();

  const { suiviCoaching, isSuiviLoading } = useGetSuiviCoaching();
  const { coachAndAssistantCoachsStatsRelatedToCommentsAndValidations } =
    useSelector((state) => state.siKpis);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateToDetails = (suiviCoach) => {
    dispatch(setCurrentCoachSuivi(suiviCoach));
    return navigate(DETAILS_SUIVI_COACHING_PATH);
  };

  const columns = useMemo(
    () => [
      {
        dataField: "first_name",
        text: "Nom complet",
        filter: textFilter({
          placeholder: "Nom complet",
        }),
        formatter: (cell, row) => (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => navigateToDetails(row)}
          >
            {row.first_name} {row.last_name}
          </p>
        ),
      },
      {
        dataField: "domaine",
        text: "Domaine",
        filter: textFilter({
          placeholder: "Domaine",
        }),
      },
      {
        dataField: "email",
        text: "Email",
        filter: textFilter({
          placeholder: "Email",
        }),
      },
      {
        dataField: "phone",
        text: "Téléphone",
        filter: textFilter({
          placeholder: "Email",
        }),
      },
      {
        dataField: "details",
        text: "Détails",
        formatter: (cell, row) => (
          <div className="d-flex justify-content-center">
            {/* <button
              onClick={() => navigateToDetails(row)}
              className=""
            > */}
            <ClickPane
              onClick={() => navigateToDetails(row)}
              className="focus--click"
            >
              <BsEye size={20} color="#069a77" />
            </ClickPane>
            {/* </button> */}
          </div>
        ),
      },
    ],
    []
  );

  const labels =
    coachAndAssistantCoachsStatsRelatedToCommentsAndValidations.map(
      (coach) => coach.first_name + " " + coach.last_name
    );
  const data = {
    labels,
    datasets: [
      {
        label: "Commentaires",
        data: coachAndAssistantCoachsStatsRelatedToCommentsAndValidations.map(
          (coach) => coach.comments
        ),
        borderColor: rootStyles.color.secondary,
        backgroundColor: rootStyles.color.secondary,
      },
      {
        label: "Validations",
        data: coachAndAssistantCoachsStatsRelatedToCommentsAndValidations.map(
          (coach) => {
            return coach.validations;
          }
        ),
        borderColor: rootStyles.color.red,
        backgroundColor: rootStyles.color.red,
      },
    ],
  };

  //   Coachs comments data with RTK
  const { coachsAssistantCommentsAndValidations } =
    useGetCoachsAssistantCommentsAndValidations();

  const filterStats = (data) => {
    const toastId = toast.loading("En cours...");
    coachsAssistantCommentsAndValidations(data)
      .unwrap()
      .then((res) => {
        const data = res.data;
        dispatch(
          setCoachAndAssistantCoachsStatsRelatedToCommentsAndValidations(data)
        );
        toast.success("Requête réussie", {
          id: toastId,
        });
      })
      .catch((err) => {
        toast.error(err?.data?.message.toString(), {
          id: toastId,
        });
      });
  };

  useEffect(() => {
    filterStats({ commentTime });
  }, [commentTime]);

  return (
    <FadeTransition in={true}>
      <Title2>Suivi Coaching</Title2>
      <Section>
        <Title3>COMMENTAIRES ET VALIDATIONS DES TÂCHES</Title3>
        <div className="d-flex align-items-center justify-content-between">
          <div style={{ width: "150px" }}>
            <SelectOnChange
              onChange={(value) => {
                setCommentTime(value);
              }}
            >
              <ResourceDataMap
                resourceItem={({ filter }) => (
                  <option value={filter.value}>{filter.label}</option>
                )}
                resourceName="filter"
                resourceData={[
                  { label: "Aujourd'hui", value: "day" },
                  { label: "Semaine", value: "week" },
                  { label: "Mois", value: "month" },
                ]}
              />
            </SelectOnChange>
          </div>

          <form
            className="d-flex align-items-center flex-sm-no-wrap gap-2 px-2"
            onSubmit={handleSubmit(filterStats)}
          >
            <div>
              <InputForm
                type="date"
                label="Date début"
                register={{
                  ...register("start_date"),
                }}
                max={today()}
              />
            </div>
            <div>
              <InputForm
                type="date"
                label="Date fin"
                register={{
                  ...register("end_date"),
                }}
                max={today()}
              />
            </div>
            <div className="mt-2">
              <ButtonComp
                bgColor={rootStyles.color.secondary}
                color={rootStyles.color.primary}
                icon={{
                  name: BiSearch,
                }}
              />
            </div>
          </form>
        </div>
        <Container>
          <Bar options={options} data={data} />
        </Container>
      </Section>

      <Div mt={50}>
        <TitleLine>Liste des coachs ({suiviCoaching?.data?.length})</TitleLine>
      </Div>
      {isSuiviLoading ? (
        <Loader />
      ) : (
        <TableListeView data={suiviCoaching.data} columns={columns} title="" />
      )}
    </FadeTransition>
  );
};

export default SuiviCoachingpage;
