import { rootStyles } from "assets/css/globalCss";
import FlexBetween from "components/shared/FlexBetween";
import React from "react";
import { FiBookmark } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
import styled from "styled-components";

const CardItemPane = styled.div`
  background-color: #ffffff;
  padding: 10px;
  border-radius: ${rootStyles.radius.card};
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 5px;
  cursor: pointer;
  transition: all 200ms ease;
  border-left: 5px solid ${rootStyles.color.tercary};

  &:hover {
    background-color: rgba(50, 60, 70, 0.8);
  }

  @media only screen and (max-width: 500px) {
    height: 125px;
  }
`;
const SpanNamePane = styled.span`
  font-weight: ${rootStyles.fonts.btnWeight};
  color: ${rootStyles.color.secondary};

  @media only screen and (max-width: 500px) {
    font-size: 12px;
  }
`;
const SpanNumberPane = styled.span`
  font-weight: ${rootStyles.fonts.btnWeight};
  color: ${rootStyles.color.tercary};
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 5px;

  @media only screen and (max-width: 500px) {
    font-size: 12px;
  }
`;

const ProgrammeCardItem = ({
  programme,
  editProgrammeFunc,
  navigateToApprenantsPage,
}) => {
  const { name, participants } = programme;
  return (
    <CardItemPane onClick={() => navigateToApprenantsPage(participants)}>
      <div className="d-flex justify-content-end gap-2">
        <button
          onClick={(e) => {
            e.stopPropagation();
            editProgrammeFunc(programme);
          }}
          className="btn btn-outline-secondary border"
        >
          <MdEdit size={20} color={rootStyles.color.tercary} />
        </button>
      </div>
      <FiBookmark size={25} color={rootStyles.color.secondary} />
      <FlexBetween>
        <SpanNamePane>{name}</SpanNamePane>
        <SpanNumberPane>
          {participants.length}
          <span style={{ fontSize: "10px" }}>apprenants</span>
        </SpanNumberPane>
      </FlexBetween>
    </CardItemPane>
  );
};

export default ProgrammeCardItem;
