import {
    addNewBakelisteSlice, archiveBakelisteSlice, setBakelistesAbandonesSlice,
    setBakelistesArchivesSlice, setBakelistesBloquesSlice,
    setBakelistesEnLigneSlice, setBakelistesEnPauseSlice,
    setBakelistesEnPresentielSlice, setBakelistesEnStageSlice,
    setBakelistesTerminesSlice,
    setCurrentBakelisteSlice,
    // updateBakelisteSlice
} from "../features/bakelisteSlice.js"
import { store } from "../store"
import {
    ACTIVATE_PAUSED_BAKELISTE_ENDPOINT, ADD_NEW_BAKELISTE_ENDPOINT,
    ARCHIVER_BAKELISTE, BLOQUE_BAKELISTE_COMPTE_ENDPOINT,
    DEBLOQUE_BAKELISTE_COMPTE_ENDPOINT,
    DESARCHIVER_BAKELISTE, DE_STAGE_EN_BAKELISTE,
    GET_BAKELISTES_ABANDONS, GET_BAKELISTES_ARCHIVES,
    GET_BAKELISTES_BLOQUES, GET_BAKELISTES_BY_ID_ENPOINT,
    GET_BAKELISTES_EN_LIGNE, GET_BAKELISTES_EN_PAUSE,
    GET_BAKELISTES_EN_PRESENTIEL, GET_BAKELISTES_EN_STAGE,
    GET_BAKELISTES_TERMINES, PAUSE_BAKELISTE_ENDPOINT,
    PLACER_EN_STAGE, RECRUTABLE_ENDPOINT, TERMINER_BAKELISTE_FORMATION, UPDATE_BAKELISTE_ENDPOINT
} from "routes/api/endpoints.js"
import { axiosInstance } from "./axiosInstance.js"

const dispatch = store.dispatch
const bakelistesController = {
    getBakelisteById(bakeliste_id) {
        return new Promise((resolve, reject) => {
            axiosInstance.get(GET_BAKELISTES_BY_ID_ENPOINT + bakeliste_id)
                .then((response) => {
                    if (response.status >= 200) {
                        /**
                        * !setBakelisteList is from reducer.Bakeliste.js, just for Bakeliste
                        * !Don't forget to check your response content data type
                        */
                        const data = response.data.data[0]
                        console.log({ bakeliste: data });
                        dispatch(setCurrentBakelisteSlice(data))
                        resolve(data)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    getBakelistes() {
        Promise.all(
            [this.getBakelistesEnPresentiel(),
            this.getBakelistesEnLigne(),
            this.getBakelistesAbandons(),
            this.getBakelistesEnPause(),
            this.getBakelistesBloques(),
            this.getBakelistesArchives(),
            this.getBakelistesEnStage(),
            this.getBakelistesTermines(),
            ])
    },
    getBakelistesEnPresentiel() {
        return new Promise((resolve, reject) => {
            axiosInstance.get(GET_BAKELISTES_EN_PRESENTIEL)
                .then((response) => {
                    if (response.status >= 200) {
                        const data = response.data
                        dispatch(setBakelistesEnPresentielSlice(data))
                        resolve(data)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    getBakelistesEnLigne() {
        return new Promise((resolve, reject) => {
            axiosInstance.get(GET_BAKELISTES_EN_LIGNE)
                .then((response) => {
                    if (response.status >= 200) {
                        const data = response.data
                        dispatch(setBakelistesEnLigneSlice(data))
                        resolve(data)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    getBakelistesAbandons() {
        return new Promise((resolve, reject) => {
            axiosInstance.get(GET_BAKELISTES_ABANDONS)
                .then((response) => {
                    if (response.status >= 200) {
                        const data = response.data
                        dispatch(setBakelistesAbandonesSlice(data))
                        resolve(data)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    getBakelistesEnPause() {
        return new Promise((resolve, reject) => {
            axiosInstance.get(GET_BAKELISTES_EN_PAUSE)
                .then((response) => {
                    if (response.status >= 200) {
                        const data = response.data
                        dispatch(setBakelistesEnPauseSlice(data))
                        resolve(data)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    getBakelistesBloques() {
        return new Promise((resolve, reject) => {
            axiosInstance.get(GET_BAKELISTES_BLOQUES)
                .then((response) => {
                    if (response.status >= 200) {
                        const data = response.data
                        dispatch(setBakelistesBloquesSlice(data))
                        resolve(data)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    getBakelistesArchives() {
        return new Promise((resolve, reject) => {
            axiosInstance.get(GET_BAKELISTES_ARCHIVES)
                .then((response) => {
                    if (response.status >= 200) {
                        const data = response.data
                        dispatch(setBakelistesArchivesSlice(data))
                        resolve(data)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    getBakelistesEnStage() {
        return new Promise((resolve, reject) => {
            axiosInstance.get(GET_BAKELISTES_EN_STAGE)
                .then((response) => {
                    if (response.status >= 200) {
                        const data = response.data
                        dispatch(setBakelistesEnStageSlice(data))
                        resolve(data)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    getBakelistesTermines() {
        return new Promise((resolve, reject) => {
            axiosInstance.get(GET_BAKELISTES_TERMINES)
                .then((response) => {
                    if (response.status >= 200) {
                        const data = response.data
                        dispatch(setBakelistesTerminesSlice(data))
                        resolve(data)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
    addNewBakeliste(data) {
        // console.log("bakelisteInfos",data)
        return new Promise((resolve, reject) => {
            axiosInstance.post(ADD_NEW_BAKELISTE_ENDPOINT, data)
                .then((response) => {
                    if (response.status >= 200) {
                        const bakeliste = response.data.data
                        dispatch(addNewBakelisteSlice(bakeliste))
                        resolve(bakeliste)
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
    updateBakeliste(data, bakeliste) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(UPDATE_BAKELISTE_ENDPOINT + bakeliste.id, data)
                .then((response) => {
                    if (response.status >= 200) {
                        /**
                        * !updateBakeliste is from reducer.Bakeliste.js, just for Bakeliste
                        * !Don't forget to check your response content data type
                        */
                        // this.getBakelistesEnPresentiel()
                        // this.getBakelistesEnLigne()
                        // dispatch(updateBakelisteSlice(bakeliste))
                        resolve(bakeliste)
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
    activatePauseBakelisteFormation(bakeliste_id) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(ACTIVATE_PAUSED_BAKELISTE_ENDPOINT, { bakeliste_id })
                .then(async (response) => {
                    if (response.status >= 200) {
                        // await Promise.all([
                        // this.getBakelisteById(bakeliste_id)
                        // this.getBakelistesEnPresentiel()
                        // this.getBakelistesEnLigne()
                        // this.getBakelistesEnPause()
                        // ])
                        // dispatch(updateBakelisteSlice(bakeliste))
                        resolve(response)
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
    pauseBakelisteFormation(bakeliste_id) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(PAUSE_BAKELISTE_ENDPOINT, { bakeliste_id })
                .then(async (response) => {
                    if (response.status >= 200) {
                        // await Promise.all([
                        this.getBakelisteById(bakeliste_id)
                        this.getBakelistesEnPresentiel()
                        this.getBakelistesEnLigne()
                        // ])
                        // dispatch(updateBakelisteSlice(bakeliste))
                        resolve(response)
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
    bloqueBakelisteCompte(bakeliste_id) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(BLOQUE_BAKELISTE_COMPTE_ENDPOINT + bakeliste_id)
                .then((response) => {
                    if (response.status >= 200) {
                        this.getBakelisteById(bakeliste_id)
                        this.getBakelistesEnPresentiel()
                        this.getBakelistesEnLigne()
                        this.getBakelistesBloques()
                        // dispatch(updateBakelisteSlice(bakeliste))
                        resolve(response)
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
    debloqueBakelisteCompte(bakeliste_id) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(DEBLOQUE_BAKELISTE_COMPTE_ENDPOINT + bakeliste_id)
                .then(async (response) => {
                    if (response.status >= 200) {
                        // await Promise.all([
                        this.getBakelisteById(bakeliste_id)
                        this.getBakelistesEnPresentiel()
                        this.getBakelistesEnLigne()
                        this.getBakelistesBloques()
                        // ])
                        // dispatch(updateBakelisteSlice(bakeliste))
                        resolve(response)
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
    archiverBakeliste(bakeliste_id) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(ARCHIVER_BAKELISTE + bakeliste_id)
                .then(async (response) => {
                    console.log({ response });
                    if (response.status >= 200) {
                        // await Promise.all([
                        this.getBakelisteById(bakeliste_id)
                        // this.getBakelistesEnPresentiel()
                        //     this.getBakelistesArchives()
                        // ])
                        // dispatch(updateBakelisteSlice(bakeliste))
                        resolve(response)
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
    desarchiverBakeliste(bakeliste_id) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(DESARCHIVER_BAKELISTE + bakeliste_id)
                .then(async (response) => {
                    if (response.status >= 200) {
                        // await Promise.all([
                        this.getBakelisteById(bakeliste_id)
                        // this.getBakelistesEnPresentiel()
                        //     this.getBakelistesArchives()
                        // ])
                        // dispatch(updateBakelisteSlice(bakeliste))
                        resolve(response)
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
    terminerBakelisteFormation(bakeliste_id) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(TERMINER_BAKELISTE_FORMATION, { bakeliste_id })
                .then(async (response) => {
                    if (response.status >= 200) {
                        // await Promise.all([
                        this.getBakelistesEnStage()
                        this.getBakelistesTermines()
                        this.getBakelisteById(bakeliste_id)
                        // ])
                        // dispatch(updateBakelisteSlice(bakeliste))
                        resolve(response)
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
    placerEnStage(data) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(PLACER_EN_STAGE, data)
                .then(async (response) => {
                    if (response.status >= 200) {
                        // await Promise.all([
                        this.getBakelisteById(data.bakeliste_id)
                        this.getBakelistesEnPresentiel()
                        this.getBakelistesEnStage()
                        // ])
                        // dispatch(updateBakelisteSlice(bakeliste))
                        resolve(response)
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
    xdeStageEnBakeliste(bakeliste_id) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(DE_STAGE_EN_BAKELISTE, { bakeliste_id })
                .then(async (response) => {
                    if (response.status >= 200) {
                        // await Promise.all([
                        this.getBakelisteById(bakeliste_id)
                        this.getBakelistesTermines()
                        this.getBakelistesEnStage()
                        // ])
                        // dispatch(updateBakelisteSlice(bakeliste))
                        resolve(response)
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
    recrutable(data) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(RECRUTABLE_ENDPOINT, data)
                .then(async (response) => {
                    if (response.status >= 200) {
                        // await Promise.all([
                        this.getBakelisteById(data.bakeliste_id)
                        this.getBakelistesTermines()
                        this.getBakelistesEnStage()
                        // ])
                        // dispatch(updateBakelisteSlice(bakeliste))
                        resolve(response)
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
    // activer bakeliste en pause: activate-paused-bakeliste-training, payload: bakeliste_id
    // mettre le bakeliste en pause: pause-bakeliste-training, payload: bakeliste_id
    // !--------------------------------------
    // bloquer compte: block-user-account/665
    // debloquer compte bakeliste: unblock-user-account/665
    // !-----------------------------------------
    // archiver bakeliste: archive-user/695
    // desarchives bakeliste:  desarchive-user/2862
    // !------------------------------------------
    // terminer formation: terminer_formation, payload {bakeliste_id}
    // !-----------------------------------------
    // placer en stage: bakeliste-to-stagiaire, payload: {bakeliste_id: 1104, recruteur_raison: susi,recruteur_domaine: undefined}
    // !-----------------------------------------
    // stagiaire-to-bakeliste: stagiaire-to-bakeliste, payload:{bakeliste_id}
    // !---------------------------------------------
    // bakeliste recrutable: change-bakeliste-to-recrutable, payload:{bakeliste_nickname, bakeliste_id, label}
    deleteBakeliste(bakelisteId) {
        return new Promise((resolve, reject) => {
            axiosInstance.delete(`${"******** PUT URL HERE ***********" + bakelisteId}/`)
                .then((response) => {
                    if (response.status === 204) {
                        resolve(response)
                        dispatch(archiveBakelisteSlice(response))
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => reject(error))
        })
    },
}
export default bakelistesController