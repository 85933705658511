/* eslint-disable operator-linebreak */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentCoach: {},
  coachsList: [],
  allCoachsList: [],
  coachsArchivesList: [],
  allCoachsArchivesList: [],
  coachsAssistantsList: [],
  allCoachsAssistantsList: [],
  suiviCurrentCoach: {},
  currentBakelisteSuiviCoach: [],
  allCoachCommentaires: [],
  coachCommentaires: [],
  coachYearComments: [],
  allCoachTaskValidations: [],
  coachTaskValidations: [],
  coachCurrentYearTaskValidations: [],
  month: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  currentYear: "",
};

const coachSlice = createSlice({
  name: "coachs",
  initialState,
  reducers: {
    setCurrentCoachSlice: (state, { payload }) => {
      state.currentCoach = payload;
    },
    setCoachsListSlice: (state, { payload }) => {
      state.coachsList = payload;
      state.allCoachsList = payload;
    },
    setCoachsAssistantsListSlice: (state, { payload }) => {
      state.coachsAssistantsList = payload;
      state.allCoachsAssistantsList = payload;
    },
    setCoachsArchivesListSlice: (state, { payload }) => {
      state.coachsArchivesList = payload;
      state.allCoachsArchivesList = payload;
    },
    addNewCoachSlice: (state, { payload }) => {
      state.allCoachsList = [...state.allCoachsList, payload];
      state.coachsList = [...state.allCoachsList, payload];
    },
    addNewCoachAssistantSlice: (state, { payload }) => {
      state.allCoachsAssistantsList = [...state.allCoachsAssistantsList, payload];
      state.coachsAssistantsList = [...state.allCoachsAssistantsList, payload];
    },
    updateCoachSlice: (state, { payload }) => {
      let coachs = state.allCoachsList.filter(
        (coach) => coach.id !== payload.id
      );
      coachs = [...coachs, payload].reverse();
      state.allCoachsList = coachs;
      state.coachsList = coachs;
    },
    archiveCoachSlice: (state, { payload }) => {
      const coachs = state.allCoachsList.filter(
        (coach) => coach.id !== payload.id
      );
      state.coachsArchivesList = [...state.allCoachsArchivesList, payload];
      state.allCoachsArchivesList = [...state.allCoachsArchivesList, payload];
      state.coachsList = coachs.reverse();
      state.allCoachsList = coachs.reverse();
    },
    activateCoachArchiveSlice: (state, { payload }) => {
      const coachs = state.allCoachsArchivesList.filter(
        (coach) => coach.id !== payload.id
      );
      state.coachsArchivesList = coachs.reverse();
      state.allCoachsArchivesList = coachs.reverse();
    },
    setCurrentCoachSuivi: (state, { payload }) => {
      return {
        ...state,
        suiviCurrentCoach: payload,
      };
    },
    setCoachCommentaires: (state, { payload }) => {
      const commentaireGroupeByYear = payload.reduce(
        (group, currentCommentaire) => {
          const { created_at } = currentCommentaire;

          const group_by_created_at = new Date(created_at).getFullYear();

          group[group_by_created_at] = group[group_by_created_at]
            ? group[group_by_created_at]
            : [];

          group[group_by_created_at].push(currentCommentaire);

          return group;
        },
        {}
      );
      return {
        ...state,
        coachCommentaires: commentaireGroupeByYear,
        currentYear: payload[0],
      };
    },
    setCoachYearComments: (state, { payload }) => {
      const livraisonsGroupeByMonth = payload[1].reduce(
        (group, currentLivraison) => {
          const { created_at } = currentLivraison;

          // ! Recuperer le mois en string
          const group_by_created_at =
            state.month[new Date(created_at).getMonth()];
          // const month_index = new Date(created_at).getMonth()

          group[group_by_created_at] = group[group_by_created_at]
            ? group[group_by_created_at]
            : [];

          group[group_by_created_at].push(currentLivraison);

          return group;
        },
        {}
      );
      return {
        ...state,
        coachYearComments: livraisonsGroupeByMonth,
        currentYear: payload[0],
      };
    },
    setCoachTaskValidations: (state, { payload }) => {
      const livraisonsGroupeByYear = payload.reduce(
        (group, currentLivraison) => {
          const { created_at } = currentLivraison;

          const group_by_created_at = new Date(created_at).getFullYear();

          group[group_by_created_at] = group[group_by_created_at]
            ? group[group_by_created_at]
            : [];

          group[group_by_created_at].push(currentLivraison);

          return group;
        },
        {}
      );
      return {
        ...state,
        coachTaskValidations: livraisonsGroupeByYear,
      };
    },
    setCoachCurrentYearTaskValidations: (state, { payload }) => {
      // const validationsGroupeByMonth = payload[1].reduce(
      //     (group, currentLivraison) => {
      //         const { created_at } = currentLivraison;

      //         // ! Recuperer le mois en string
      //         const group_by_created_at = state.month[new Date(created_at).getMonth()];
      //         // const month_index = new Date(created_at).getMonth()

      //         group[group_by_created_at] = group[group_by_created_at] ? group[group_by_created_at] : [];

      //         group[group_by_created_at].push(currentLivraison);

      //         return group;
      //     },
      //     {}
      // );

      return {
        ...state,
        coachCurrentYearTaskValidations: payload[1],
        currentYear: payload[0],
      };
    },
    setCurrentBakelistesSuiviCoach: (state, { payload }) => {
      return {
        ...state,
        currentBakelisteSuiviCoach: payload,
      };
    },
    filterCoachsList: (state, { payload }) => {
      /**
       * @param payload {string}
       */
      state.coachsList = state.allCoachsList.filter((item) => {
        const data = ["******** PUT RESOURCE PROPERTIES HERE *********"]
          .join(" ")
          .toLowerCase();
        return data.includes(payload.toLowerCase());
      });
    },
  },
});

export const {
  setCoachsListSlice,
  setCoachsAssistantsListSlice,
  addNewCoachSlice,
  addNewCoachAssistantSlice,
  setCurrentCoachSlice,
  updateCoachSlice,
  archiveCoachSlice,
  filterCoachsList,
  setCoachsArchivesListSlice,
  activateCoachArchiveSlice,
  setCurrentCoachSuivi,
  setCurrentBakelistesSuiviCoach,
  setCoachCommentaires,
  setCoachYearComments,
  setCoachCurrentYearTaskValidations,
  setCoachTaskValidations,
} = coachSlice.actions;

export default coachSlice.reducer;
