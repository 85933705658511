import React from "react"
import ListeBakelistesEnStage from "./ListeBakelistesEnStage"
import FadeTransition from "components/shared/FadeTransition"

const ListeBakelisteEnStagePage = () => {
    return (
        <FadeTransition in={true}>
            <ListeBakelistesEnStage />
        </FadeTransition>
    )
}

export default ListeBakelisteEnStagePage