import { GET_BAKELISTES_LIVRAISONS } from "routes/api/endpoints";


const { siAdminAPi } = require("redux/rtkquery");

const livraisonsApi = siAdminAPi.injectEndpoints({
    endpoints: (build) => ({
        getLivraison: build.query({
            query: (params) => {
                return {
                    url: GET_BAKELISTES_LIVRAISONS,
                    params
                }
            },
            providesTags: ["Livraisons"],
            keepUnusedDataFor: 60000
        }),
    }),
    overrideExisting: false,
});

export const { useGetLivraisonQuery } = livraisonsApi