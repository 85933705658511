import React from "react";
import "./dropdown.css";
import { RiArrowDropDownLine } from "react-icons/ri";
import { MdArrowDropUp } from "react-icons/md";
// import { RxAvatar } from "react-icons/rx";
import { useCurrentUser } from "hooks/useCurrentUser";
import { rootStyles } from "../../assets/css/globalCss";

const DropDown = ({ showDropdown, setShowDropdown, children }) => {
    const { currentUser } = useCurrentUser();
    return (
        <div className="dropdown-wrapper">
            <button
                onClick={setShowDropdown}
                className="trigger-button"
            >
                {!showDropdown ? (
                    <div>
                        <div className="mt-3">
                            <span
                                style={{ color: rootStyles.color.secondary }}
                                className="fw-bold"
                            >
                                {`${currentUser?.first_name.charAt(
                                    0
                                )}${currentUser?.last_name.charAt(0)}`}</span>
                            {/* <RxAvatar size={25} color="#069a77" /> */}
                            <RiArrowDropDownLine
                                style={{ marginTop: "-10px" }}
                                size={20}
                                color="#069a77"
                            />
                        </div>
                    </div>
        ) : (
          <MdArrowDropUp size={30} color="#069a77" />
        )}
            </button>
            <ul className={showDropdown ? "active drop-down" : "drop-down"}>
                {children}
            </ul>
        </div>
    );
};

export default DropDown;
