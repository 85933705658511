import React from "react";
import { useSelector } from "react-redux";
import { Title2 } from "components/shared/TableListeView";

const DetailsPaiementsPage = () => {
  const { currentBakelistePaiement } = useSelector((state) => state.paiements);
  // console.log({ data });
  return (
    <div>
      <Title2>
        Liste des paiements de {currentBakelistePaiement.first_name}{" "}
        {currentBakelistePaiement.last_name}
      </Title2>
    </div>
  );
};

export default DetailsPaiementsPage;
