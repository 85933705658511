import { EVENEMENTS_RECENTS_ENDPOINT } from "routes/api/endpoints";
import { evenementsRecentesSwrFetcher } from "..";
import useSWR from "swr";

export const useEvenementsRecents = () => {
    const { data: evenementsRecent = [], isLoading: isEvenementsRecentLoading } = useSWR(
        EVENEMENTS_RECENTS_ENDPOINT,
        evenementsRecentesSwrFetcher,
        {
            refreshInterval: 86400
        }
    );

    return { evenementsRecent, isEvenementsRecentLoading }
}