const { siAdminAPi } = require("redux/rtkquery");
import {
  GET_BAKELISTE_BULLETINS_ENDPOINT,
  GET_BAKELISTE_EN_COURS_DE_FORMATION,
  GET_BAKELISTE_LIVRAISONS_ENDPOINT,
  GET_BAKELISTE_POINTAGES_ENDPOINT,
  GET_BAKELISTE_VALIDATED_TASKS_ENDPOINT,
  GET_CURRENT_BAKELISTE_FORMATION_DETAILS_ENDPOINT,
  GET_CURRENT_BAKELISTE_SYLLABUS_DETAILS_ENDPOINT,
  GET_GLOBAL_BAKELISTE_SYLLABUS_PROGRESSION_ENDPOINT,
} from "routes/api/endpoints";

const suiviBakeliste = siAdminAPi.injectEndpoints({
  endpoints: (build) => ({
    getBakelistesEnCoursDeFormation: build.query({
      query: (bakeliste_id) => GET_BAKELISTE_EN_COURS_DE_FORMATION,
      providesTags: ["Suivi_Bakelistes"],
    }),

    getCurrentBakelisteFormationDetails: build.query({
      query: (args) => {
        const { bakeliste_id } = args;
        return {
          url: GET_CURRENT_BAKELISTE_FORMATION_DETAILS_ENDPOINT,
          method: "GET",
          params: { bakeliste_id },
        };
      },
    }),

    getCurrentBakelisteSyllabusDetails: build.query({
      query: (args) => {
        const { syllabus_id, bakeliste_id } = args;
        return {
          url: GET_CURRENT_BAKELISTE_SYLLABUS_DETAILS_ENDPOINT,
          method: "GET",
          params: { syllabus_id, bakeliste_id },
        };
      },
    }),

    getBakelisteGlobalSyllabusProgression: build.query({
      query: (args) => {
        const { bakeliste_id } = args;
        return {
          url: GET_GLOBAL_BAKELISTE_SYLLABUS_PROGRESSION_ENDPOINT,
          method: "GET",
          params: { bakeliste_id },
        };
      },
    }),

    getBakelisteBulletins: build.query({
      query: (args) => {
        const { bakeliste_id } = args;
        return {
          url: GET_BAKELISTE_BULLETINS_ENDPOINT,
          method: "GET",
          params: { bakeliste_id },
        };
      },
    }),

    getBakelistePointages: build.query({
      query: (args) => {
        const { bakeliste_id, start_date, end_date } = args;
        return {
          url: GET_BAKELISTE_POINTAGES_ENDPOINT,
          method: "GET",
          params: { bakeliste_id, start_date, end_date },
        };
      },
    }),

    getBakelisteLivraions: build.query({
      query: (args) => {
        const { bakeliste_id } = args;
        return {
          url: GET_BAKELISTE_LIVRAISONS_ENDPOINT,
          method: "GET",
          params: { bakeliste_id },
        };
      },
    }),

    getBakelisteValidatedTasks: build.query({
      query: (bakeliste_id) =>
        `${GET_BAKELISTE_VALIDATED_TASKS_ENDPOINT}/${bakeliste_id}`,
      providesTags: ["Suivi_Bakelistes"],
    }),
  }),
});

export const {
  useGetBakelistesEnCoursDeFormationQuery,
  useGetCurrentBakelisteFormationDetailsQuery,
  useGetCurrentBakelisteSyllabusDetailsQuery,
  useGetBakelisteGlobalSyllabusProgressionQuery,
  useGetBakelisteBulletinsQuery,
  useGetBakelistePointagesQuery,
  useGetBakelisteLivraionsQuery,
  useGetBakelisteValidatedTasksQuery,
} = suiviBakeliste;
