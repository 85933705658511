import React, { createContext, useContext, useState } from "react"
import { Content, NumberTop, Tab, Tabs } from "./Tabs";
// import Tab from "./Tab";

const TabsContext = createContext()

const TabsProvider = ({ content }) => {
    const [active, setActive] = useState(0);
    const handleClick = (e) => {
        const index = parseInt(e.target.id, 0);
        if (index !== active) {
            setActive(index);
        }
    };
    return (
        <TabsContext.Provider>
            <Tabs>
                {
                    content.map((tab, index) => {
                        return <Tab key={index} onClick={handleClick} active={active === index} id={index}>
                            {tab.title}
                            {!!tab.number && <NumberTop onClick={handleClick} active={active === index} id={index}>{tab.number}</NumberTop>}
                        </Tab>
                    })
                }
            </Tabs>
            <>
                {
                    content.map((tab, index) => {
                        return <Content key={index} active={active === index}>
                            {
                                tab.content()
                            }
                        </Content>
                    })
                }
            </>
        </TabsContext.Provider>
    )
}

export default TabsProvider

export const useTabsContext = () => {
    const ctx = useContext(TabsContext)

    if (!ctx) throw new Error("TabsContext is not available")

    return ctx
}