import { rootStyles } from "assets/css/globalCss"
import TableListeView from "components/shared/TableListeView"
import React, { useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { tooltip_options } from "utils/tooltip_options"
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FiPauseCircle, FiArchive } from "react-icons/fi"
import { RiArrowGoBackLine } from "react-icons/ri"
import { AiOutlineCheckCircle, AiOutlineUserAdd } from "react-icons/ai";
import { toast } from "react-hot-toast"
import { filterBakelistesList } from "redux/features/bakelisteSlice"
import { textFilter } from "react-bootstrap-table2-filter"
import { useBakelisteEnStage } from "./api/hooks/useBakelisteEnStage"
import Loader from "components/shared/Loader"
import { useNavigate } from "react-router-dom"
import { STAGIAIRES_ARCHIVES_PATH } from "routes/navigation/navigationPaths"
import { useArchiverBakelisteMutation, useDeStageEnBakelisteMutation, usePauseBakelisteMutation, useTerminerFormationBakelisteMutation } from "./api/mutations"
import ButtonComp from "components/ButtonComp"
import AddNewStagiaireModal from "pages/stagiaires/components/AddNewStagiaireModal"
import FadeTransition from "components/shared/FadeTransition"
import { useCurrentUser } from "hooks/useCurrentUser"
import { CiEdit } from "react-icons/ci"
import EditStagiaireModal from "pages/stagiaires/components/EditStagiaireModal"


const ListeBakelistesEnStage = () => {
    const [openAddStagiaire, setOpenAddStagiare] = useState(false)
    const dispatch = useDispatch()
    const { bakelisteEnStage, isEnStageLoading } = useBakelisteEnStage()
    const navigate = useNavigate()
    const { currentUser } = useCurrentUser()
    const [selectedStagiaire, setSelectedStagiaire] = useState({})
    const [openEditStagire, setOpenEditStagiaire] = useState()
    const [pauseBakelisteFormation] = usePauseBakelisteMutation()
    const [terminerBakelisteFormation] = useTerminerFormationBakelisteMutation()
    const [archiverBakelisteMutation] = useArchiverBakelisteMutation()
    const [deStagiaireEnBakeliste] = useDeStageEnBakelisteMutation()

    console.log({ selectedStagiaire, openEditStagire });
    // const navigate = useNavigate()
    const editStagiaire = (currentStagiare) => {
        // const bakeliste_id = currentStagiare.id
        console.log("edit");
        setOpenEditStagiaire(true)
        setSelectedStagiaire(currentStagiare)
    }
    const pauseBakeliste = (currentBakeliste) => {
        const bakeliste_id = currentBakeliste.id
        toast.promise(pauseBakelisteFormation(bakeliste_id), {
            loading: "Mise en pause",
            success: currentBakeliste.first_name + " mis(e) en pause",
            error: `La mise en pause de ${currentBakeliste.first_name} a echouée`
        })
    }

    const terminerBakelisteFormationFunc = (bakeliste) => {
        const bakeliste_id = bakeliste.id
        toast.promise(terminerBakelisteFormation(bakeliste_id), {
            loading: "Términer en cours",
            success: bakeliste.first_name + " Terminé(e)",
            error: `La términaison de la formation de ${bakeliste.first_name} a echouée`
        })
    }

    const reIntegrerStagiaireEnBakeliste = (currentBakeliste) => {
        const bakeliste_id = currentBakeliste.id
        // deStagiaireEnBakeliste({ bakeliste_id }).unwrap().then((res) => {
        //     console.log({ res });
        // }).catch((err) => {
        //     console.log({ err });
        // })
        toast.promise(deStagiaireEnBakeliste({ bakeliste_id, change_status_by: currentUser.id }), {
            loading: "Réintégration",
            success: currentBakeliste.first_name + " est réintegré en tant que bakeliste",
            error: `La réintégration de ${currentBakeliste.first_name} a echouée`
        })
    }

    const archiverBakeliste = (currentBakeliste) => {
        const bakeliste_id = currentBakeliste.id
        toast.promise(archiverBakelisteMutation(bakeliste_id), {
            loading: "Archivage en cours",
            success: currentBakeliste.first_name + " archivée",
            error: `L'archivage ${currentBakeliste.first_name} a echouée`
        })
    }

    const navigateTo = () => {
        return navigate(STAGIAIRES_ARCHIVES_PATH)
    }

    const actionsFormatter = (cell, row) => {
        return <div className='d-flex gap-4 justify-content-center'>
            <CiEdit
                onClick={() => editStagiaire(row)}
                data-tip data-tooltip-id='edit-btn'
                className='cursor-pointer'
                size={25}
                color={rootStyles.color.secondary}
            />
            <FiPauseCircle
                onClick={() => pauseBakeliste(row)}
                data-tip data-tooltip-id='pause-btn'
                className='cursor-pointer'
                size={20}
                color={rootStyles.color.secondary}
            />
            <RiArrowGoBackLine
                onClick={() => reIntegrerStagiaireEnBakeliste(row)}
                data-tip data-tooltip-id='reintegrer-btn'
                className='cursor-pointer'
                size={20}
                color={rootStyles.color.tercary}
            />
            <AiOutlineCheckCircle
                onClick={() => terminerBakelisteFormationFunc(row)}
                data-tip data-tooltip-id='terminer-btn'
                className='cursor-pointer'
                size={20}
                color={rootStyles.color.red}
            />
            <FiArchive
                onClick={() => archiverBakeliste(row)}
                data-tip data-tooltip-id='archiver-btn'
                className='cursor-pointer'
                size={20}
                color={rootStyles.color.red}
            />

            <ReactTooltip style={tooltip_options} id='edit-btn'>
                Modifier
            </ReactTooltip>
            <ReactTooltip style={tooltip_options} id='pause-btn'>
                Pause
            </ReactTooltip>
            <ReactTooltip style={tooltip_options} id='reintegrer-btn'>
                Réintegrez en Bakeliste
            </ReactTooltip>
            <ReactTooltip style={tooltip_options} id='terminer-btn'>
                Términez
            </ReactTooltip>
            <ReactTooltip style={tooltip_options} id='archiver-btn'>
                Archivez
            </ReactTooltip>
        </div>
    }
    const columns = useMemo(() => [{
        dataField: "first_name",
        text: "Nom complet",
        filter: textFilter({
            placeholder: "Recherche pas nom complet",
        }),
        formatter: (cell, row) => <p>{row.first_name} {row.last_name}</p>
    },
        {
            dataField: "email",
            filter: textFilter({
                placeholder: "Recherche pas mail",
            }),
            text: "Email",
        },
        {
            dataField: "debut_formation",
            text: "Debut stage",
            formatter: (cell, row) => {
                return <p className='text-center'>
                    {
                        new Date(row.debut_formation).toLocaleString("fr-Fr", {
                            dateStyle: "short",
                        })
                    }
                </p>
            },
        },
        {
            dataField: "fin_formation",
            text: "Fin stage",
            formatter: (cell, row) => {
                return <p className='text-center'>
                    {
                        new Date(row.fin_formation).toLocaleString("fr-Fr", {
                            dateStyle: "short",
                        })
                    }
                </p>
            },
    },
    {
        dataField: "domaine.name",
        text: "Domaines",
    },
    {
        dataField: "Actions",
        text: "Actions",
        formatter: actionsFormatter
    }
    ], [])
    return (
        <FadeTransition in={true} >
            {
                (openAddStagiaire && !openEditStagire) && <AddNewStagiaireModal
                    openAddNewStagiaire={openAddStagiaire}
                    onClosed={() => setOpenAddStagiare(false)}
                />
            }
            {
                (openEditStagire && !openAddStagiaire) && <EditStagiaireModal
                    openEditStagiaire={openEditStagire}
                    defaultValue={selectedStagiaire}
                    onClosed={() => setOpenEditStagiaire(false)}
                />
            }
            <div className='mb-3'>
                <ButtonComp
                    title="Nouveau"
                    icon={{ name: AiOutlineUserAdd }}
                    color={rootStyles.color.primary}
                    bgColor={rootStyles.color.secondary}
                    onClick={() => setOpenAddStagiare(true)}
                />
            </div>
            {
                isEnStageLoading ? <Loader /> : <TableListeView
                    navigateTo={navigateTo}
                    navigateToTitle="Stagiaires archivés"
                    onChangeInputSearch={(value) => dispatch(filterBakelistesList({ value, type: "stage" }))}
                    data={bakelisteEnStage.data}
                    columns={columns}
                    title="Bakelistes en stage"
                />
            }

        </FadeTransition>
    )
}

export default ListeBakelistesEnStage