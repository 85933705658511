/* eslint-disable max-len */
import React, { useMemo } from "react";
import InfoItem from "./InfoItem";
import { useSelector } from "react-redux";
import { useTypePresence } from "actions/typePresence";
import { useJoursFormations } from "actions/jours/queries";
import { useSpaces } from "pages/parametres/Spaces/useSpaces";

const ApercuFormation = ({ bakelisteInfos }) => {
  const { joursFormation } = useJoursFormations()
  const { typePresence } = useTypePresence()
  const { spaces } = useSpaces()

  // console.log(typePresence.filter((presence) => presence.id === Number(bakelisteInfos.type_presence_id))[0]);
  const horaire = typePresence.filter((presence) => presence.id === Number(bakelisteInfos.type_presence_id))[0]?.libelle + " " + typePresence.filter((presence) => presence.id === Number(bakelisteInfos.type_presence_id))[0]?.heure_debut + " - " + typePresence.filter((presence) => presence.id === Number(bakelisteInfos.type_presence_id))[0]?.heure_fin

  // !recuperer les jour qui sont dans jours_presence
  const jours_formation = joursFormation.map((jour) => bakelisteInfos.jours_presence.includes(jour.id) ? jour.name : null).filter((jour) => jour).join(", ")
  // console.log({ jours_formation });

  // !trouver le space de formation
  const space = useMemo(() => {
    return spaces.find((space) => space.id == bakelisteInfos.space_id).name_space
  }, [])

  const { allDomainesList: domaines } = useSelector((state) => state.domaines)

  // console.log(spaces.find((space) => space.id == bakelisteInfos.space_id));
  // console.log(space);

  return (
    <div>
      <h4>Formation à Bakeli</h4>
      <div className="container-fluid">
        <div className="border-bottom fs-6">
          En partenariat : {bakelisteInfos.partenariat ? <span className="fw-bold">OUI</span> : <span className="fw-bold">NON</span>}
        </div>
        {bakelisteInfos.type_formation === "diplomante" && (
          <>
            <div className="row apercu--alone border-bottom py-2">
              <div className="col-6">
                <span>
                  Type de formation :{" "}
                  {bakelisteInfos.type_formation ? (
                    bakelisteInfos.type_formation
                  ) : (
                    <span className="required--info">
                      Information indispensable
                    </span>
                  )}
                </span>
              </div>
            </div>
            <InfoItem
              label1="Horaire"
              label2="Jours formations"
                // <span style={{ fontWeight: 500 }}>{presence.libelle} : </span>
              // <span>{presence.heure_debut} - {presence.heure_fin}</span>
              data1={horaire}
              data2={jours_formation}
            />
          </>
        )}
        {/* ******************************** professionnel ************************* */}
        {bakelisteInfos.type_formation === "courte" &&
          Number(bakelisteInfos.is_pro) === 1 &&
          Number(bakelisteInfos.nbre_jours) === 2 && (
            <>
              <div className="row border-bottom py-2">
                <div className="col-6">
                  <span>
                    Type de formation :{" "}
                    {bakelisteInfos.type_formation ? (
                      bakelisteInfos.type_formation
                    ) : (
                      <span className="required--info">
                        Information indispensable
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <InfoItem
                label1="Status professionnel"
                label2="Type de présence"
                data1="Professionnel"
                data2="Semaine (2 jours )"
              />
              <InfoItem
                label1="Horaire"
                label2="Jours"
              data1={horaire}
              data2={jours_formation}
              />
            </>
          )}
        {bakelisteInfos.type_formation === "courte" &&
          Number(bakelisteInfos.is_pro) === 1 &&
          Number(bakelisteInfos.is_weekend) === 1 && (
            <>
              <div className="row border-bottom py-2">
                <div className="col-6">
                  <span>
                    Type de formation :{" "}
                    {bakelisteInfos.type_formation ? (
                      bakelisteInfos.type_formation
                    ) : (
                      <span className="required--info">
                        Information indispensable
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <InfoItem
                label1="Status professionnel"
                label2="Type de présence"
                data1="Professionnel"
                data2="Weekend"
              />
              <InfoItem
                label1="Horaire"
                label2="Jours"
              data1={horaire}
              data2={jours_formation}
              />
            </>
          )}
        {/* ********************************** etudiant ******************************* */}
        {bakelisteInfos.type_formation === "courte" &&
          Number(bakelisteInfos.is_etudiant) === 1 &&
          Number(bakelisteInfos.is_etudiant_a_tester) !== 1 &&
          Number(bakelisteInfos.nbre_jours) === 3 && (
            <>
              <div className="row border-bottom py-2">
                <div className="col-6">
                  <span>
                    Type de formation :{" "}
                    {bakelisteInfos.type_formation ? (
                      bakelisteInfos.type_formation
                    ) : (
                      <span className="required--info">
                        Information indispensable
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <InfoItem
                label1="Status professionnel"
                label2="Type de présence"
                data1="Etudiant"
                data2="Semaine (3 jours)"
              />
              <InfoItem
                label1="Horaire"
                label2="Jours"
              data1={horaire}
              data2={jours_formation}
              />
            </>
          )}
        {bakelisteInfos.type_formation === "courte" &&
          Number(bakelisteInfos.is_etudiant) === 1 &&
          Number(bakelisteInfos.is_etudiant_a_tester) !== 1 &&
          Number(bakelisteInfos.is_weekend) === 1 && (
            <>
              <div className="row border-bottom py-2">
                <div className="col-6">
                  <span>
                    Type de formation :{" "}
                    {bakelisteInfos.type_formation ? (
                      bakelisteInfos.type_formation
                    ) : (
                      <span className="required--info">
                        Information indispensable
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <InfoItem
                label1="Status professionnel"
                label2="Type de présence"
                data1="Etudiant"
                data2="Weekend"
              />
              <InfoItem
                label1="Horaire"
                label2="Jours"
              data1={horaire}
              data2={jours_formation}
              />
            </>
          )}
        {bakelisteInfos.type_formation === "courte" &&
          Number(bakelisteInfos.is_etudiant) === 1 &&
          Number(bakelisteInfos.is_etudiant_a_tester) !== 1 &&
          Number(bakelisteInfos.nbre_jours) === 5 && (
            <>
              <div className="row border-bottom py-2">
                <div className="col-6">
                  <span>
                    Type de formation :{" "}
                    {bakelisteInfos.type_formation ? (
                      bakelisteInfos.type_formation
                    ) : (
                      <span className="required--info">
                        Information indispensable
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <InfoItem
                label1="Status professionnel"
                label2="Type de présence"
                data1="Etudiant"
                data2="Semaine (lundi au vendredi)"
              />
              <InfoItem
                label1="Horaire"
                label2="Jours"
              data1={horaire}
              data2={jours_formation}
              />
            </>
          )}
        {/* ********************************** etudiant a tester ******************************* */}
        {bakelisteInfos.type_formation === "courte" &&
          Number(bakelisteInfos.is_etudiant) === 1 &&
          Number(bakelisteInfos.is_etudiant_a_tester) === 1 &&
          Number(bakelisteInfos.nbre_jours) === 3 && (
            <>
              <div className="row border-bottom py-2">
                <div className="col-6">
                  <span>
                    Type de formation :{" "}
                    {bakelisteInfos.type_formation ? (
                      bakelisteInfos.type_formation
                    ) : (
                      <span className="required--info">
                        Information indispensable
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <InfoItem
                label1="Status professionnel"
                label2="Type de présence"
                data1="Etudiant à tester"
                data2="Semaine (3 jours)"
              />
              <InfoItem
                label1="Horaire"
                label2="Jours"
              data1={horaire}
              data2={jours_formation}
              />
            </>
          )}
        {bakelisteInfos.type_formation === "courte" &&
          Number(bakelisteInfos.is_etudiant) === 1 &&
          Number(bakelisteInfos.is_etudiant_a_tester) === 1 &&
          Number(bakelisteInfos.is_weekend) === 1 && (
            <>
              <div className="row border-bottom py-2">
                <div className="col-6">
                  <span>
                    Type de formation :{" "}
                    {bakelisteInfos.type_formation ? (
                      bakelisteInfos.type_formation
                    ) : (
                      <span className="required--info">
                        Information indispensable
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <InfoItem
                label1="Status professionnel"
                label2="Type de présence"
                data1="Etudiant à tester"
                data2="Weekend"
              />
              <InfoItem
                label1="Horaire"
                label2="Jours"
              data1={horaire}
              data2={jours_formation}
              />
            </>
          )}
        {bakelisteInfos.type_formation === "courte" &&
          Number(bakelisteInfos.is_etudiant) === 1 &&
          Number(bakelisteInfos.is_etudiant_a_tester) === 1 &&
          Number(bakelisteInfos.nbre_jours) === 5 && (
            <>
              <div className="row border-bottom py-2">
                <div className="col-6">
                  <span>
                    Type de formation :{" "}
                    {bakelisteInfos.type_formation ? (
                      bakelisteInfos.type_formation
                    ) : (
                      <span className="required--info">
                        Information indispensable
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <InfoItem
                label1="Status professionnel"
                label2="Type de présence"
                data1="Etudiant à tester"
                data2="Semaine (lundi au vendredi)"
              />
              <InfoItem
                label1="Horaire"
                label2="Jours"
              data1={horaire}
              data2={jours_formation}
              />
            </>
          )}
        <InfoItem
          label1="Domaine choisi"
          label2="Formation payante"
          data1={
            domaines.length !== 0 ?
              domaines.find(
                (domaine) =>
                  domaine.id === Number(bakelisteInfos.domaines_id)
              )?.name :
              "No domaine"
          }
          data2={
            Number(bakelisteInfos.is_formation_payante) === 1 ? "Oui" : "Non"
          }
        />

        <InfoItem
          label1="Paiement inscription"
          label2="Stage de formation"
          data1={
            Number(bakelisteInfos.paiement_inscription) === 1 ? "Oui" : "Non"
          }
          data2={
            Number(bakelisteInfos.is_stagede_formation) === 1 ? "Oui" : "Non"
          }
        />

        <InfoItem
          label1="Space"
          data1={space}
        />
        <InfoItem
          label1="Lieu de formation"
          label2="Niveau du bakeliste"
          data1={bakelisteInfos.lieu_formation}
          data2={bakelisteInfos.bakeliste_level}
        />

        <div className="row border-bottom py-2">
          <div className="col-6">
            <span>
              Durée formation :{" "}
              {bakelisteInfos.training_duration ? (
                bakelisteInfos.training_duration + " mois"
              ) : (
                <span className="required--info">
                  Information indispensable
                </span>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApercuFormation;
