import { rootStyles } from "assets/css/globalCss"
import React from "react"
import styled from "styled-components"

const LoaderPane = styled.div`
    text-align: center;
    margin-top: 5px;
`

const LoaderChild = styled.div`
    // height: 30px;
    // width: 30px;
    // margin:auto;
    // background: rgba(0, 0, 0, 0.2);
    // border-radius: 50%;
    // border: 2px solid rgba(0, 0, 0, 0.4);
    // border-top-color: #fff;
    // border-bottom-color: #fff;
    // animation: spinner3 1000ms ease infinite;
   width: 40px;
   height: 40px;
   margin:auto;
   border-radius: 50%;
   background: radial-gradient(farthest-side,${rootStyles.color.secondary} 94%,#0000) top/9px 9px no-repeat,
          conic-gradient(#0000 30%,${rootStyles.color.secondary});
   -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 0);
   animation: spinner3 900ms infinite linear;
  
  @keyframes spinner3 {
      to {
        transform: rotate(360deg);
      }
//    100% {
//       transform: rotate(1turn);
//    }
  }
`

const Loader = () => {
    return (
        <LoaderPane>
            <LoaderChild></LoaderChild>
        </LoaderPane>
    )
}

export default Loader