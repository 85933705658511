import Button from "components/ButtonComp";
import Input from "components/Input";
import withHandleForm from "hoc/withHandleForm";
// import { bgColor } from "constants";
import React from "react";
import { FaPassport, FaPhone, FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import { LOGIN_PATH } from "routes/navigation/navigationPaths";


const RegisterPage = withHandleForm(({ form, handleChange }) => {
  const handleRegister = (e) => {
    e.preventDefault()
    console.log(form);
  };

  return (
    <div style={{ maxWidth: "500px", margin: "auto" }}>

      <form style={{ maxWidth: "500px", margin: "auto", padding: "0.5rem" }}>
        <h2 style={{ textAlign: "center" }}>
          Inscrivez vous
        </h2>
        <Input
          type={"tel"}
          label={"77 XXX XX XX"}
          icon={{ name: FaPhone }}
          style={{ marginBottom: 20 }}
          value={form.phone}
          onChange={handleChange}
          name="phone"
        />

        <Input
          type={"tel"}
          label={"Prenom et nom"}
          icon={{ name: FaUser }}
          style={{ marginBottom: 20 }}
          value={form.username}
          onChange={handleChange}
          name="username"
        />

        <Input
          type={"password"}
          label={"mot de passe"}
          icon={{ name: FaPassport }}
          style={{ marginBottom: 20 }}
          value={form.password}
          onChange={handleChange}
          name="password"
        />

        <Input
          type={"password"}
          label={"confirm mot de passe"}
          icon={{ name: FaPassport }}
          value={form.confirmPassword}
          onChange={handleChange}
          name="confirmPassword"
        />

        <Link
          to={LOGIN_PATH}
          style={{ textAlign: "right", margin: "20px 0", cursor: "pointer", display: "inline-block" }}
        >
          Connexion
        </Link>

        <Button
          title={"S'incrire"}
          onClick={(e) => handleRegister(e)}
          bgColor={"rgba(10,20,30,.8)"}
          color={"#fff"}
        />
      </form>
    </div>
  );
})

export default RegisterPage;
