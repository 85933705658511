import { useGetBakelisteActifQuery, useGetBakelisteInActifQuery, useResearchQuery } from "./queries"

export const useBakelisteResearch = (params) => {
    const { data, isLoading, isError } = useResearchQuery(params)
    return { data, isLoading, isError }
}
export const useBakelisteActif = () => {
    const {
        data: bakelisteActifs = { data: { bakelisteEnPresentiel: [], bakelisteEnLigne: [], countBakelisteActif: 0 } },
        isLoading: isActifLoading, isError: isActifError,
        refetch: refetchBakelisteActif
    } = useGetBakelisteActifQuery()

    console.log({ bakelisteActifs })

    return { bakelisteActifs: bakelisteActifs.data, actifcount: bakelisteActifs.countBakelisteActif, isActifLoading, isActifError, refetchBakelisteActif }
}
export const useBakelisteInActif = () => {
    const {
        data: bakelisteInActifs = { data: { bakelistesBloque: [], bakelistesEnPause: [], countbakelistesBloque: 0, countbakelistesEnPause: 0 }, count: 0 },
        isLoading: isInactifLoading,
        isError: isInactifError
    } = useGetBakelisteInActifQuery()

    // console.log({ bakelisteInActifs });

    return { bakelisteInActifs: bakelisteInActifs.data, InActifcount: bakelisteInActifs.count, isInactifLoading, isInactifError }
}