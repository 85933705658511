import { useGetBakelisteGlobalSyllabusProgressionQuery } from "../queries";

export const useGetBakelisteGlobalSyllabusProgression = (bakelisteId) => {
  const { data: currentbakelisteGlobalProgression } =
    useGetBakelisteGlobalSyllabusProgressionQuery({
      bakeliste_id: bakelisteId,
    });

  return { currentbakelisteGlobalProgression };
};
