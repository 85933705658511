import { rootStyles } from "assets/css/globalCss"
import React, { useState } from "react"
import styled from "styled-components"
import { Label, SpanError } from "./InputForm"
const Select = styled.select`
    padding: ${rootStyles.padding.inputPadding};
    width:100%;
    border: ${({ error }) => !error ? `1px solid ${rootStyles.color.secondary} ` : `3px solid ${rootStyles.color.red} `};
    background-color: ${rootStyles.color.primaryWithRgba};
    font-size:${rootStyles.fonts.fontSize};
    transition: all ease 0.1s;
    &:focus{
        border: ${({ error }) => !error ? `3px solid ${rootStyles.color.secondary} ` : `3px solid ${rootStyles.color.red} `} ;
    };
    &:active{
        border: ${({ error }) => !error ? `3px solid ${rootStyles.color.secondary} ` : `3px solid ${rootStyles.color.red} `} ;
    };
`

const SelectOnChange = ({ children, error, label, onChange, defaultValue }) => {
    const [value, setValue] = useState(defaultValue)
    return (
        <>
            <Label error={error}>
                {label}
                <Select
                    error={error}
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value)
                        onChange(e.target.value)
                    }}
                >
                    {children}
                </Select>
                {
                    error && <SpanError>{error}</SpanError>
                }
            </Label>
        </>
    )
}

export default SelectOnChange