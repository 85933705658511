/* eslint-disable operator-linebreak */
import { rootStyles } from "assets/css/globalCss";
import Div from "components/shared/Div";
import React from "react";

const TaskCardItem = ({ task }) => {
  const { syllabus_task, status, date_validation } = task;

  return (
    <Div className="card">
      <Div className="card-body d-flex flex-column justify-content-between">
        <Div className="d-flex mb-3">
          <Div>
            <Div>
              <h6 className="mb-0 text-muted">
                <i className="mdi mdi-circle-medium"></i>
                <span className="">
                  {date_validation ? `Validée le ${date_validation}` : ""}
                </span>
              </h6>
            </Div>
          </Div>
        </Div>

        <Div className="d-flex align-items-center justify-content-between">
          <h5 className="font-size-17">{syllabus_task}</h5>
          {/* <p className="text-muted mb-0 team-description">{description}</p> */}
          <Div>
            <span
              className="badge p-2"
              style={{
                backgroundColor:
                  status === "validee"
                    ? rootStyles.color.secondary
                    : status === "en_cours"
                    ? rootStyles.color.tercary
                    : rootStyles.color.red ?? rootStyles.color.primaryWithRgba,
              }}
            >
              {status === "en_cours" ? "en cours" : status ?? "Pas demarrée"}
            </span>
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

export default TaskCardItem;
