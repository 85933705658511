import DriveLine, { DriveHead } from "components/examens/DriveLine";
import FadeTransition from "components/shared/FadeTransition";
import { Title2 } from "components/shared/TableListeView";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BULLETINS_DOMAINES_PATH,
  TYPES_EPREUVES_PATH,
} from "routes/navigation/navigationPaths";

const DossiersTrimestre = () => {
  const { currentTrimestre } = useSelector((state) => state.examens);
  const navigate = useNavigate();
  // console.log({ currentTrimestre });
  const goToTypeEpreuves = () => {
    return navigate(TYPES_EPREUVES_PATH);
  };
  const goToBulletinsDomaines = () => {
    return navigate(BULLETINS_DOMAINES_PATH);
  };

  const currentTrimestreStr = `${currentTrimestre.trimestre} - ${new Date(
    currentTrimestre.annee_academique
  ).getFullYear()}`;

  // const currentTrimestreStr = `${currentTrimestre.trimestre.charAt(
  //   0
  // )} ${currentTrimestre.trimestre.substr(
  //   currentTrimestre.trimestre.length - 1
  // )} - ${new Date(currentTrimestre.annee_academique).getFullYear()}`;

  return (
    <FadeTransition in={true}>
      <Title2>{currentTrimestreStr}</Title2>
      <DriveHead
        label1="Nom"
        // label2="Trimestres"
        label3="Nombre"
      />
      <DriveLine
        label1="EPREUVES"
        // label2={currentTrimestreStr}
        label3={
          currentTrimestre.examen_theorique_count +
          currentTrimestre.examens_pratiques_count
        }
        onClick={goToTypeEpreuves}
      />
      <DriveLine
        label1="BULLETINS"
        // label2={currentTrimestreStr}
        label3={currentTrimestre.bulletins_count}
        onClick={goToBulletinsDomaines}
      />
      <DriveLine
        label1="STATISTIQUES"
        // label2={currentTrimestreStr}
      />
    </FadeTransition>
  );
};

export default DossiersTrimestre;
