import ResourceDataMap from "components/ResourceDataMap";
import DriveLine, { DriveHead } from "components/examens/DriveLine";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setCurrentEpreuveDomaine,
  setEpreuvePratiqueList,
} from "redux/features/examenSlice";
import { EPREUVES_PRATIQUES_LISTE_PATH } from "routes/navigation/navigationPaths";
import useSWR from "swr";
import { axiosSwrFetcherForEpreuvesPratiques } from "../../api";
import { GET_EXAMENS_PRATIQUES_BY_TRIMESTRE_ENDPOINT } from "routes/api/endpoints";
import Loader from "components/shared/Loader";
import { Title2 } from "components/shared/TableListeView";
import FadeTransition from "components/shared/FadeTransition";

const EpreuvesPratiquesDomainesPage = () => {
  const { currentTrimestre } = useSelector((state) => state.examens);
  const dispatch = useDispatch();
  const { data = {}, isLoading } = useSWR(
    GET_EXAMENS_PRATIQUES_BY_TRIMESTRE_ENDPOINT + currentTrimestre.id,
    axiosSwrFetcherForEpreuvesPratiques
  );
  const navigate = useNavigate();
  const goToEpreuvesTheoriquesListe = (epreuveDomaine, epreuves) => {
    dispatch(setCurrentEpreuveDomaine(epreuveDomaine));
    dispatch(setEpreuvePratiqueList(epreuves));
    return navigate(EPREUVES_PRATIQUES_LISTE_PATH);
  };

  const currentTrimestreStr = `${currentTrimestre.trimestre} - ${new Date(
    currentTrimestre.annee_academique
  ).getFullYear()}`;
  //   console.log({ data: Object.entries(data) });
  return (
    <FadeTransition in={true}>
      <Title2>{currentTrimestreStr}</Title2>
      <DriveHead
        label1="Domaines"
        // label2="Trimestres"
        label3="Nombre"
      />
      {isLoading ? (
        <Loader />
      ) : (
        <ResourceDataMap
          resourceName="domaine"
          resourceData={Object.entries(data)}
          resourceItem={({ domaine }) => {
            return (
              <DriveLine
                label1={domaine[0]}
                // label2={`${currentTrimestre.trimestre} - ${new Date(
                //   currentTrimestre.annee_academique
                // ).getFullYear()}`}
                label3={domaine[1].length}
                onClick={() =>
                  goToEpreuvesTheoriquesListe(domaine[0], domaine[1])
                }
              />
            );
          }}
        />
      )}
    </FadeTransition>
  );
};

export default EpreuvesPratiquesDomainesPage;
