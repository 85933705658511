import { rootStyles } from "assets/css/globalCss"
import Flex from "components/shared/Flex"
import React from "react"
import { BsClock } from "react-icons/bs"
import { MdEdit } from "react-icons/md"

const TypePresenceCardItem = ({ typePresence, onClick }) => {
  return (
    <Flex
      style={{ backgroundColor: rootStyles.color.primary, padding: 20, borderRadius: 8, flexDirection: "column" }}>
      <Flex style={{ margin: 0 }} justify={"space-between"}>
        <p style={{ fontWeight: 500 }}>{typePresence.libelle}</p>
        <MdEdit onClick={() => onClick(typePresence)} spacing={10} style={{ cursor: "pointer" }} size={20} color={rootStyles.color.tercary} />
      </Flex>
      <Flex gap={5} align={"center"}>
        <BsClock />
        <p>{typePresence.heure_debut}</p>
        <span>-</span>
        <p>{typePresence.heure_fin}</p>
      </Flex>
    </Flex>
  )
}

export default TypePresenceCardItem