import { rootStyles } from "assets/css/globalCss";
import Div from "components/shared/Div";
import FadeTransition from "components/shared/FadeTransition";
import FlexBetween from "components/shared/FlexBetween";
import { Title2 } from "components/shared/TableListeView";
import TitleLine from "components/shared/TitleLine";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const CardComment = styled.div`
  background-color: white;
  padding: ${rootStyles.padding.btnPadding};
  border-radius: ${rootStyles.padding.btnPadding};
  margin: 10px 0px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  // font-weight: ${rootStyles.fonts.btnWeight}
`;
const SpanDate = styled.span`
  background-color: ${rootStyles.color.tercary};
  color: white;
  padding: 5px;
  font-weight: ${rootStyles.fonts.btnWeight};
  font-size: 12px;
  border-radius: ${rootStyles.radius.card};
`;
const SpanTime = styled.span`
  background-color: ${rootStyles.color.red};
  color: white;
  padding: 5px;
  font-weight: ${rootStyles.fonts.btnWeight};
  font-size: 12px;
  border-radius: ${rootStyles.radius.card};
`;
const SpanPeriod = styled.span`
  display: flex;
  flex-wrap: no-wrap;
  gap: 2px;
`;

const CoachCommentsPage = () => {
  const { coachYearComments, suiviCurrentCoach, currentYear } = useSelector(
    (state) => state.coachs
  );

  // const timeComments = coachYearComments;

  return (
    <FadeTransition in={true}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            position: "sticky",
            top: "0px",
            zIndex: 2,
            backgroundColor: "rgba(241, 241, 241)",
            padding: 10,
            // borderRadius: rootStyles.radius.card,
          }}
        >
          <Title2>
            Commentaires de{" "}
            <span style={{ color: rootStyles.color.tercary }}>
              {suiviCurrentCoach.first_name} {suiviCurrentCoach.last_name}
            </span>{" "}
            de{" "}
            <span style={{ color: rootStyles.color.red }}>{currentYear}</span>
          </Title2>
        </div>
        <Div mt={20}>
          {/* <Title3>Total ({coachYearComments[1].length})</Title3> */}
          {Object.entries(coachYearComments).map((comment) => {
            return (
              <Div key={comment[0]} id={comment[0]} mb={40}>
                <div
                  style={{
                    position: "sticky",
                    top: "50px",
                    zIndex: 2,
                    backgroundColor: "#f1f1f1",
                    padding: 10,
                    borderRadius: rootStyles.radius.card,
                  }}
                >
                  <TitleLine color={rootStyles.color.redSecondary}>
                    {comment[0]} ({comment[1].length})
                  </TitleLine>
                </div>

                <div
                  style={{
                    // marginTop: "20px",
                    position: "sticky",
                    top: "200px",
                    zIndex: 1,
                    heigth: "100%",
                    overflowY: "auto",
                    padding: 10,
                  }}
                >
                  {comment[1].map((comment) => {
                    return (
                      <CardComment key={comment}>
                        <FlexBetween>
                          {comment.comment_text}

                          <SpanPeriod>
                            <SpanDate>
                              {new Date(comment.created_at).toLocaleString(
                                "fr-Fr",
                                {
                                  dateStyle: "short",
                                }
                              )}{" "}
                            </SpanDate>
                            <SpanTime>
                              {`${new Date(
                                comment.created_at
                              ).getHours()}:${new Date(
                                comment.created_at
                              ).getMinutes()}:${new Date(
                                comment.created_at
                              ).getSeconds()}`}
                            </SpanTime>
                          </SpanPeriod>
                        </FlexBetween>
                      </CardComment>
                    );
                  })}
                </div>
              </Div>
            );
          })}
        </Div>
      </div>
    </FadeTransition>
  );
};

export default CoachCommentsPage;
