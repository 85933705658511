import { siAdminAPi } from "redux/rtkquery";
import { GET_CURRENT_MONTH_PAIEMENTS_ENDPOINT } from "routes/api/endpoints";

export const paiementQueryApi = siAdminAPi.injectEndpoints({
    endpoints: (build) => ({
        getCurrentMonthPaiement: build.query({
            query: () => GET_CURRENT_MONTH_PAIEMENTS_ENDPOINT,
            providesTags: ["Paiements"]
        })
    })
})

export const { useGetCurrentMonthPaiementQuery } = paiementQueryApi