import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentBakeliste: {},
  suiviCurrentBakeliste: {},
  suiviCurrentBakelisteSyllabus: {},
  suiviCurrentBakelisteLivrables: {},
  currentTrimestreBulletin: [],
  bakelistesList: [],
  allBakelistesList: [],
  allFilteredBakelistes: [],
  bakelistesEnPresentiel: [],
  allBakelistesEnPresentiel: [],
  countBakelisteEnPresentiel: 0,
  bakelistesEnLigne: [],
  allBakelistesEnLigne: [],
  countBakelisteEnLigne: 0,
  bakelistesAbandonnes: [],
  allBakelistesAbandonnes: [],
  countBakelisteAbandonnes: 0,
  bakelistesEnPause: [],
  allBakelistesEnPause: [],
  countBakelisteEnPause: 0,
  bakelistesBloques: [],
  allBakelistesBloques: [],
  countBakelisteBloques: 0,
  bakelistesArchives: [],
  allBakelistesArchives: [],
  countBakelisteArchives: 0,
  bakelistesEnStage: [],
  allBakelistesEnStage: [],
  countBakelisteEnStage: 0,
  bakelistesTermines: [],
  allBakelistesTermines: [],
  countBakelisteTermines: 0,
  research: ""
};

/**
* !Some Bakelisteslice reducers are used in BakelisteApi.js located in
redux/apiContoller/
*/

const bakelisteSlice = createSlice({
  name: "bakelistes",
  initialState,
  reducers: {
    setCurrentBakelisteSlice: (state, { payload }) => {
      state.currentBakeliste = payload;
    },
    setCurrentBakelisteSuivi: (state, { payload }) => {
      return {
        ...state,
        suiviCurrentBakeliste: payload,
      };
    },
    setCurrentBakelisteSuiviSyllabus: (state, { payload }) => {
      return {
        ...state,
        suiviCurrentBakelisteSyllabus: payload,
      };
    },
    setCurrentBakelisteLivrables: (state, { payload }) => {
      return {
        ...state,
        suiviCurrentBakelisteLivrables: payload,
      };
    },
    setCurrentTrimestreBulletin: (state, { payload }) => {
      return {
        ...state,
        currentTrimestreBulletin: payload,
      };
    },
    setBakelistesListSlice: (state, { payload }) => {
      state.bakelistesList = payload;
      state.allBakelistesList = payload;
    },
    setBakelistesEnPresentielSlice: (state, { payload }) => {
      state.bakelistesEnPresentiel = payload.data;
      state.allBakelistesEnPresentiel = payload.data;
      state.countBakelisteEnPresentiel = payload.count;
    },
    setBakelistesEnLigneSlice: (state, { payload }) => {
      state.bakelistesEnLigne = payload.data;
      state.allBakelistesEnLigne = payload.data;
      state.countBakelisteEnLigne = payload.count;
    },
    setBakelistesAbandonesSlice: (state, { payload }) => {
      state.bakelistesAbandonnes = payload.data;
      state.allBakelistesAbandonnes = payload.data;
      state.countBakelisteAbandonnes = payload.count;
    },
    setBakelistesEnPauseSlice: (state, { payload }) => {
      state.bakelistesEnPause = payload.data;
      state.allBakelistesEnPause = payload.data;
      state.countBakelisteEnPause = payload.count;
    },
    setBakelistesBloquesSlice: (state, { payload }) => {
      state.bakelistesBloques = payload.data;
      state.allBakelistesBloques = payload.data;
      state.countBakelisteBloques = payload.count;
    },
    setBakelistesArchivesSlice: (state, { payload }) => {
      state.bakelistesArchives = payload.data;
      state.allBakelistesArchives = payload.data;
      state.countBakelisteArchives = payload.count;
    },
    setBakelistesEnStageSlice: (state, { payload }) => {
      state.bakelistesEnStage = payload.data;
      state.allBakelistesEnStage = payload.data;
      state.countBakelisteEnStage = payload.count;
    },
    setBakelistesTerminesSlice: (state, { payload }) => {
      state.bakelistesTermines = payload.data;
      state.allBakelistesTermines = payload.data;
      state.countBakelisteTermines = payload.count;
    },
    addNewBakelisteSlice: (state, { payload }) => {
      state.allBakelistesList = [...state.allBakelistesList, payload].reverse();
      state.bakelistesList = [...state.allBakelistesList, payload].reverse();
      state.allBakelistesEnPresentiel = [
        ...state.allBakelistesList,
        payload,
      ].reverse();
      state.bakelistesEnPresentiel = [
        ...state.allBakelistesList,
        payload,
      ].reverse();
    },
    updateBakelisteSlice: (state, { payload }) => {
      let bakelistes = state.allBakelistesList.filter(
        (bakeliste) => bakeliste.id !== payload.id
      );
      bakelistes = [...bakelistes, payload].reverse();
      state.allBakelistesList = bakelistes;
      state.bakelistesList = bakelistes;
    },
    archiveBakelisteSlice: (state, { payload }) => {
      const bakelistes = state.allBakelistesList.filter(
        (bakeliste) => bakeliste.id !== payload.id
      );
      state.bakelistesList = bakelistes;
      state.allBakelistesList = bakelistes;
    },
    filterBakelistesList: (state, { payload }) => {
      /**
       * @param payload {string}
       */
      if (payload.type === "presentiel") {
        if (!payload.value) {
          state.bakelistesEnPresentiel = state.allBakelistesEnPresentiel;
          return;
        }
        state.bakelistesEnPresentiel = state.allBakelistesEnPresentiel.filter(
          (item) => {
            const data = [item.first_name, item.last_name]
              .join(" ")
              .toLowerCase();
            return data.includes(payload.value.toLowerCase());
          }
        );
      }
      if (payload.type === "enLigne") {
        if (!payload.value) {
          state.bakelistesEnLigne = state.allBakelistesEnLigne;
          return;
        }
        state.bakelistesEnLigne = state.allBakelistesEnLigne.filter((item) => {
          const data = [item.first_name, item.last_name]
            .join(" ")
            .toLowerCase();
          return data.includes(payload.value.toLowerCase());
        });
      }
      if (payload.type === "enPause") {
        if (!payload.value) {
          state.bakelistesEnPause = state.allBakelistesEnPause;
          return;
        }
        state.bakelistesEnPause = state.allBakelistesEnPause.filter((item) => {
          const data = [item.first_name, item.last_name]
            .join(" ")
            .toLowerCase();
          return data.includes(payload.value.toLowerCase());
        });
      }
      if (payload.type === "bloques") {
        if (!payload.value) {
          state.bakelistesBloques = state.allBakelistesBloques;
          return;
        }
        state.bakelistesBloques = state.allBakelistesBloques.filter((item) => {
          const data = [item.first_name, item.last_name]
            .join(" ")
            .toLowerCase();
          return data.includes(payload.value.toLowerCase());
        });
      }
      if (payload.type === "archives") {
        if (!payload.value) {
          state.bakelistesArchives = state.allBakelistesArchives;
          return;
        }
        state.bakelistesArchives = state.allBakelistesArchives.filter(
          (item) => {
            const data = [item.first_name, item.last_name]
              .join(" ")
              .toLowerCase();
            return data.includes(payload.value.toLowerCase());
          }
        );
      }
      if (payload.type === "termines") {
        if (!payload.value) {
          state.bakelistesTermines = state.allBakelistesTermines;
          return;
        }
        state.bakelistesTermines = state.allBakelistesTermines.filter(
          (item) => {
            const data = [item.first_name, item.last_name]
              .join(" ")
              .toLowerCase();
            return data.includes(payload.value.toLowerCase());
          }
        );
      }
      if (payload.type === "stage") {
        if (!payload.value) {
          state.bakelistesEnStage = state.allBakelistesEnStage;
          return;
        }
        state.bakelistesEnStage = state.allBakelistesEnStage.filter((item) => {
          const data = [item.first_name, item.last_name]
            .join(" ")
            .toLowerCase();
          return data.includes(payload.value.toLowerCase());
        });
      }
    },
    filterAllBakeliste: (state, { payload }) => {
      return {
        ...state,
        research: payload,
        // allFilteredBakelistes: state.allBakelistesList.filter(
        //   (item) => {
        //     const data = [item.first_name, item.last_name]
        //       .join(" ")
        //       .toLowerCase();
        //     return data.includes(payload.value.toLowerCase());
        //   }
        // )
      }
    }
  },
});

export const {
  setBakelistesListSlice,
  addNewBakelisteSlice,
  setCurrentBakelisteSlice,
  setCurrentBakelisteSuivi,
  setCurrentBakelisteSuiviSyllabus,
  updateBakelisteSlice,
  archiveBakelisteSlice,
  filterBakelistesList,
  filterAllBakeliste,
  setBakelistesEnPresentielSlice,
  setBakelistesEnLigneSlice,
  setBakelistesAbandonesSlice,
  setBakelistesEnPauseSlice,
  setBakelistesBloquesSlice,
  setBakelistesArchivesSlice,
  setBakelistesEnStageSlice,
  setBakelistesTerminesSlice,
  setCurrentTrimestreBulletin,
  setCurrentBakelisteLivrables,
} = bakelisteSlice.actions;

export default bakelisteSlice.reducer;
