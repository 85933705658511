const { siAdminAPi } = require("redux/rtkquery");
const { GET_PARTENAIRES, GET_PARTENAIRE_PROGRAMMES, GET_ALL_PROGRAMMES } = require("routes/api/endpoints");

const partenairesApi = siAdminAPi.injectEndpoints({
    endpoints: (build) => ({
        getPartenaireById: build.query({
            query: (partenaire_id) => GET_PARTENAIRES + partenaire_id,
            providesTags: ["Partenaires"]
        }),
        getPartenaireProgrammes: build.query({
            query: (partenaire_name) => GET_PARTENAIRE_PROGRAMMES + partenaire_name,
            providesTags: ["Partenaires"]
        }),
        getPartenaires: build.query({
            query: () => GET_PARTENAIRES,
            providesTags: ["Partenaires"]
        }),
        getAllProgrammes: build.query({
            query: () => GET_ALL_PROGRAMMES,
            providesTags: ["Partenaires"]
        }),
    }),
    overrideExisting: false,
})

export const { useGetPartenaireByIdQuery, useGetPartenairesQuery, useGetPartenaireProgrammesQuery, useGetAllProgrammesQuery } = partenairesApi