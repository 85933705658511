import { rootStyles } from "assets/css/globalCss";
import Flex from "components/shared/Flex";
import React from "react";
import { createPortal } from "react-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BiDownload } from "react-icons/bi";
import { FcCloseUpMode } from "react-icons/fc";
// import { bgColorPrimary } from "constants";
// import HacText from "./HacText";
// import HacDivider from "./HacDivider";
// import { Download, X } from "tabler-icons-react";
// import HacFlex from "./HacFlex";

function ModalInstall({ opened, onClosed, onInstall }) {
    if (!opened) {
        return null;
    }

    return createPortal(
        <div
            style={{
                position: "absolute",
                top: 0,
                // left: "50%",
                // transform: "translate(-50%)",
                // maxWidth: 600,
                width: "100%",
                height: "100dvh",
                zIndex: "9",
                backgroundColor: "rgba(30, 40, 50, .8)",
                display: "flex",
                justifyContent: "center",
                alignItems: "end",
                // zIndex: 199,
            }}
        >
            <div
                className=""
                style={{
                    backgroundColor: "#fff",
                    border: "1px solid rgba(0,0,0,0.1)",
                    maxWidth: "600px",
                    margin: "auto",
                    borderRadius: 10,
                    padding: "1rem",
                    // position: "relative",
                    transition: "all .3s",
                    overflow: "hidden",
                }}
            >
                <div
                    style={{
                        // position: "absolute",
                        // right: 0,
                        // top: 0,
                        // width: 50,
                        // margin: "auto",
                        // backgroundColor: "red",
                        padding: 4,
                    }}
                    className="text-end"
                    onClick={onClosed}
                >
                    <AiOutlineCloseCircle style={{ cursor: "pointer" }} size={30} />
                    {/* <X size={20} /> */}
                </div>

                <p>
          Installation de l'application <FcCloseUpMode />
                </p>

                {/* <HacDivider my={20} /> */}

                <div
                    style={{
                        display: "grid",
                        placeItems: "center",
                        width: "100%",
                    }}
                >
                    <button
                        style={{
                            width: "100%",
                            height: 35,
                            borderRadius: 15,
                            outline: "none",
                            backgroundColor: rootStyles.color.secondary,
                            color: "#fff",
                            fontWeight: 600,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 20,
                            border: "none",
                        }}
                        onClick={onInstall}
                    >
                        <Flex gap={10}>
                            <BiDownload size={20} /> <p>Installer</p>
                        </Flex>
                    </button>
                </div>
            </div>
        </div>,
        document.getElementById("root")
    );
}

export default ModalInstall;
