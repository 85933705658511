import React from "react"
import { Label, SpanError } from "./InputForm"
import styled from "styled-components"
import { rootStyles } from "assets/css/globalCss"

const Select = styled.select`
    padding: ${rootStyles.padding.inputPadding};
    width:100%;
    border: ${({ error }) => !error ? `1px solid ${rootStyles.color.secondary} ` : `3px solid ${rootStyles.color.red} `};
    background-color: ${rootStyles.color.primaryWithRgba};
    font-size:${rootStyles.fonts.fontSize};
    transition: all ease 0.1s;
    &:focus{
        border: ${({ error }) => !error ? `3px solid ${rootStyles.color.secondary} ` : `3px solid ${rootStyles.color.red} `} ;
    };
    &:active{
        border: ${({ error }) => !error ? `3px solid ${rootStyles.color.secondary} ` : `3px solid ${rootStyles.color.red} `} ;
    };
`

const SelectForm = ({ children, register, error, label, defaultValue }) => {
    return (
        <div className='mt-2 w-100'>
            <Label
                error={error}
            >
                {label}
                <Select
                    defaultValue={defaultValue}
                    {...register}
                    error={error}
                >
                    {children}
                </Select>
                {
                    error && <SpanError>{error}</SpanError>
                }
            </Label>
        </div>
    )
}

export default SelectForm