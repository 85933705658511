import React, { useState } from "react";
import AutresInfos from "./AutresInfos";
import FormationBakeli from "./FormationBakeli";
import InformationPersonnelle from "./InformationPersonnelle";
import MultiStepProgress from "./MultiStepProgress";
import { rootStyles } from "assets/css/globalCss";

const MultiStepForm = ({
  page,
  setPage,
  bakelisteInfos,
  setBakelisteInfos,
  domaines,
  coachs,
  days,
  apercu,
  setApercu,
  coachAssistants,
}) => {
  const [x, setX] = useState(0);
  const components = [
    <InformationPersonnelle
      key={page}
      x={x}
      setX={setX}
      page={page}
      setPage={setPage}
      bakelisteInfos={bakelisteInfos}
      setBakelisteInfos={setBakelisteInfos}
    />,
    <FormationBakeli
      key={page}
      x={x}
      setX={setX}
      days={days}
      domaines={domaines}
      page={page}
      setPage={setPage}
      bakelisteInfos={bakelisteInfos}
      setBakelisteInfos={setBakelisteInfos}
    />,
    <AutresInfos
      key={page}
      coachs={coachs}
      apercu={apercu}
      coachAssistants={coachAssistants}
      setApercu={setApercu}
      x={x}
      setX={setX}
      page={page}
      setPage={setPage}
      bakelisteInfos={bakelisteInfos}
      setBakelisteInfos={setBakelisteInfos}
    />,
  ];
  return (
    <section
      style={{
        borderRadius: rootStyles.radius.card,
        backgroundColor: "white",
        padding: rootStyles.padding.btnPadding,
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        minHeight: "450px"
      }}
    >
      <MultiStepProgress page={page} />

      <form encType="multipart/form-data">{components[page]}</form>
    </section>
  );
};

export default MultiStepForm;
