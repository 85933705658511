import { rootStyles } from "assets/css/globalCss"
import React from "react"
import styled from "styled-components"

export const Label = styled.label`
    width:100%;
    font-size:${rootStyles.fonts.fontSize};
    color: ${({ error }) => !error ? "normal" : rootStyles.color.red};
    margin-bottom: 15px;
    font-weight: ${rootStyles.fonts.btnWeight};
`

const Input = styled.input`
    padding: ${rootStyles.padding.inputPadding};
    width:100%;
    border: ${({ error }) => !error ? `1px solid ${rootStyles.color.secondary} ` : `3px solid ${rootStyles.color.red} `};
    background-color: ${rootStyles.color.primaryWithRgba};
    font-size:${rootStyles.fonts.fontSize};
    transition: all ease 0.1s;
    &:focus{
        border: ${({ error }) => !error ? `3px solid ${rootStyles.color.secondary} ` : `3px solid ${rootStyles.color.red} `} ;
    }
`

export const SpanError = styled.span`
    color: ${rootStyles.color.red};
    font-size: ${rootStyles.fonts.spanErrFontSize};
    font-weight: 500
`

const InputForm = ({ label, type, htmlFor, register, error, min, max }) => {
    return (
        <>
            <Label error={error} htmlFor={htmlFor}>{label}
                <Input
                    type={type}
                    name={label}
                    id={htmlFor}
                    {...register}
                    error={error}
                    min={min}
                    max={max}
                />
                {
                    error && <SpanError>{error}</SpanError>
                }
            </Label>
        </>
    )
}

export default InputForm