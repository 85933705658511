import { Title2 } from "components/shared/TableListeView";
import React, { useState } from "react";
import styled from "styled-components";
import PaiementKpiCard from "./components/PaiementKpiCard";
import { rootStyles } from "assets/css/globalCss";
import ResourceDataMap from "components/ResourceDataMap";

// import ApexCharts from "apexcharts";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import PaiementPresentielListe from "./PaiementPresentielListe";
import PaiementEnLigneListe from "./PaiementEnLigneListe";
import { useCurrentMonthPaiement } from "../api/hooks/useCurrentMonth";
import { Badge } from "pages/bakelistes/Details/DetailsBakelistePage";
import Flex from "components/shared/Flex";
import PaiementFormFilter from "./components/PaiementFormFilter";
import { useDispatch, useSelector } from "react-redux";
import { useFilterPaiement } from "../api/hooks/useFilterPaiement";
import { setIsFilteringPaiement } from "redux/features/paiementSlice";
import Title3 from "components/shared/Title3";

// import Chart from "react-apexcharts";

const KpiContainerPane = styled.div`
  background-color: rgb(255 152 0 / 12%);
  border-radius: ${rootStyles.radius.card};
  padding: ${rootStyles.padding.btnPadding};
  display: flex;
  // height: 400px;
`;

const PaiementsPage = () => {
  const [filterFields, setFilterFields] = useState({
    mois: { value: "", error: "" },
    annee: { value: "", error: "" },
  });

  const dispatch = useDispatch();

  const {
    dakarBakelistesCurrentMonthPaiement = [],
    thiesBakelistesCurrentMonthPaiement = [],
    mbourBakelistesCurrentMonthPaiement = [],

    dakarBakelistesPaiement = [],
    thiesBakelistesPaiement = [],
    mbourBakelistesPaiement = [],

    isFilteringPaiement,
    currentMoisFilter,
    currentAnneeFilter,
  } = useSelector((state) => state.paiements);

  const { isCurrentMonthLoading } = useCurrentMonthPaiement();
  const { trigerFilter, isFiltering } = useFilterPaiement(
    filterFields.mois.value,
    filterFields.annee.value
  );

  const onFilterChange = (value) => {
    return setFilterFields((state) => ({ ...state, ...value }));
  };

  const paiementsKpis = [
    {
      text: "Payés",
      series: [20, 40, 50, 65],
      labels: ["Dakar", "Thies", "Mbour"],
    },
    {
      text: "Impayés",
      series: [20, 40, 50, 65],
      labels: ["Dakar", "Thies", "Mbour"],
    },
  ];

  const ClonedPaiementKpiCard = ({ paiementKpi }) =>
    React.cloneElement(<PaiementKpiCard paiementKpi={paiementKpi} />, {});

  return (
    <>
      <Title2>
        Paiement des bakelistes {currentMoisFilter} {currentAnneeFilter}
      </Title2>
      <div className="d-flex justify-content-end align-items-center mb-3">
        <PaiementFormFilter
          onFilterChange={onFilterChange}
          trigger={() => {
            dispatch(setIsFilteringPaiement(true));
            trigerFilter({
              mois: filterFields.mois.value,
              annee: filterFields.annee.value,
            });
          }}
        />
      </div>
      <KpiContainerPane>
        <ResourceDataMap
          resourceData={paiementsKpis}
          resourceItem={ClonedPaiementKpiCard}
          resourceName="paiementKpi"
        />
      </KpiContainerPane>

      <div className="mt-4 border-top pt-4">
        <Title3>
          Paiement des bakelistes {currentMoisFilter} {currentAnneeFilter}
        </Title3>
        <Tabs>
          <TabList>
            <Tab>
              <Flex gap={10}>
                Dakar{" "}
                <Badge
                  bgColor={rootStyles.color.tercary}
                  color={rootStyles.color.primary}
                >
                  {isFilteringPaiement ?
                    dakarBakelistesPaiement?.length :
                    dakarBakelistesCurrentMonthPaiement?.length}
                </Badge>
              </Flex>
            </Tab>
            <Tab>
              <Flex gap={10}>
                Thies{" "}
                <Badge
                  bgColor={rootStyles.color.tercary}
                  color={rootStyles.color.primary}
                >
                  {isFilteringPaiement ?
                    thiesBakelistesPaiement?.length :
                    thiesBakelistesCurrentMonthPaiement?.length}
                </Badge>{" "}
              </Flex>
            </Tab>
            <Tab>
              <Flex gap={10}>
                Mbour{" "}
                <Badge
                  bgColor={rootStyles.color.tercary}
                  color={rootStyles.color.primary}
                >
                  {isFilteringPaiement ?
                    mbourBakelistesPaiement?.length :
                    mbourBakelistesCurrentMonthPaiement?.length}
                </Badge>{" "}
              </Flex>
            </Tab>
          </TabList>

          <TabPanel>
            <PaiementPresentielListe
              paiementBakelisteEnPresentiel={
                isFilteringPaiement ?
                  dakarBakelistesPaiement :
                  dakarBakelistesCurrentMonthPaiement
              }
              isPresentielLoading={isCurrentMonthLoading || isFiltering}
            />
          </TabPanel>
          <TabPanel>
            <PaiementEnLigneListe
              paiementBakelisteEnLigne={
                isFilteringPaiement ?
                  thiesBakelistesPaiement :
                  thiesBakelistesCurrentMonthPaiement
              }
              isEnLigneLoading={isCurrentMonthLoading || isFiltering}
            />
          </TabPanel>
          <TabPanel>
            <PaiementEnLigneListe
              paiementBakelisteEnLigne={
                isFilteringPaiement ?
                  mbourBakelistesPaiement :
                  mbourBakelistesCurrentMonthPaiement
              }
              isEnLigneLoading={isCurrentMonthLoading || isFiltering}
            />
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};

export default PaiementsPage;
