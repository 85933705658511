export const groupByCanalInfosData = (data) => {
    if (!Array.isArray(data)) {
        throw new Error("data must be an array in groupByCanalInfos")
    }
    const groupedByCanal = data.reduce(
        (group, participant) => {
            const { canal_infos } = participant;
            group[canal_infos] = group[canal_infos] ?? [];
            group[canal_infos].push(participant);
            return group;
        },
        {}
    );
    return groupedByCanal
}