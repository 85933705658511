import { useBakelistesLivraisons } from "actions/suivi-livraisons";
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom";

const useSuiviLivraisons = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { data, isLoading, isError, isFetching } = useBakelistesLivraisons(
    searchParams.get("date") ?
      { date: searchParams.get("date") } :
      { start_date: searchParams.get("date_debut") ?? "", end_date: searchParams.get("date_fin") ?? "" }
  );
  const [date_debut, setDateDebut] = useState({ value: searchParams.get("date_debut"), errorMessage: "" });
  const [date_fin, setDateFin] = useState({ value: searchParams.get("date_fin"), errorMessage: "" });
  const [date, setDate] = useState({ value: searchParams.get("date"), errorMessage: "" });

  useEffect(() => {
    if (!searchParams.has("entre")) {
      if (date.value) {
        setSearchParams((searchParams) => {
          searchParams.set("date", date.value)
          return searchParams;
        })
      }
    } else {
      if (date_debut.value) {
        setSearchParams((searchParams) => {
          searchParams.set("date_debut", date_debut.value)
          return searchParams;
        })
      }
      if (date_fin.value) {
        setSearchParams((searchParams) => {
          searchParams.set("date_fin", date_fin.value)
          return searchParams;
        })
      }
    }
  }, [searchParams.has("entre")])

  return { setSearchParams, searchParams, data, isFetching, isError, isLoading, date_debut, setDateDebut, date_fin, setDateFin, date, setDate };
}

export default useSuiviLivraisons