/* eslint-disable operator-linebreak */
import { rootStyles } from "assets/css/globalCss";
import FadeTransition from "components/shared/FadeTransition";
import TableListeView, { Title2 } from "components/shared/TableListeView";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
// import { longDateFormatter } from "utils/timeFormatters";

const SpanPeriod = styled.span`
  display: flex;
  flex-wrap: no-wrap;
  justify-content: center;
  gap: 2px;
`;

// const SpanDate = styled.span`
//   background-color: ${rootStyles.color.tercary};
//   color: white;
//   padding: 5px;
//   font-weight: ${rootStyles.fonts.btnWeight};
//   font-size: 12px;
//   border-radius: ${rootStyles.radius.card};
// `;

const CoachValidationsPage = () => {
  const { coachCurrentYearTaskValidations, suiviCurrentCoach, currentYear } =
    useSelector((state) => state.coachs);

  // Columns
  const columns = useMemo(
    () => [
      {
        dataField: "task.syllabus_task",
        text: "Tache",
      },
      {
        dataField: "syllabus.syllabus_name",
        text: "Syllabus",
        formatter: (cell, row) => {
          return row?.syllabus !== null
            ? row?.syllabus?.syllabus_name
            : "Néant";
        },
      },
      {
        dataField: "bakeliste",
        text: "Bakeliste",
        formatter: (cell, row) => {
          return row?.bakeliste?.first_name + " " + row?.bakeliste?.last_name;
        },
      },
      {
        dataField: "created_at",
        text: "Date et heure de validation",
        formatter: (cell, row) => {
          return (
            <SpanPeriod>
              {/* <SpanDate>{longDateFormatter(row?.created_at)}</SpanDate> */}
            </SpanPeriod>
          );
        },
      },
    ],
    []
  );

  return (
    <FadeTransition in={true}>
      <Title2>
        Validations de{" "}
        <span style={{ color: rootStyles.color.tercary }}>
          {suiviCurrentCoach.first_name} {suiviCurrentCoach.last_name}
        </span>{" "}
        de <span style={{ color: rootStyles.color.red }}>{currentYear}</span>
      </Title2>
      <TableListeView
        data={coachCurrentYearTaskValidations}
        columns={columns}
        title=""
      />
    </FadeTransition>
  );
};

export default CoachValidationsPage;
