import { ADD_PAIEMENT_ENDPOINT } from "routes/api/endpoints"

const { axiosInstance } = require("./axiosInstance")

const paiementsController = {

    payer(paiement) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(ADD_PAIEMENT_ENDPOINT, paiement)
                .then((response) => {
                    console.log({ response });
                    if (response.status === 200) {
                        resolve(response)
                    } else {
                        reject(response)
                    }
                })
                .catch((res) => reject(res))
        })
    },
}

export default paiementsController