import { rootStyles } from "assets/css/globalCss"
import TableListeView from "components/shared/TableListeView"
import React, { useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom";
import { tooltip_options } from "utils/tooltip_options"
import { Tooltip as ReactTooltip } from "react-tooltip";
import { AiOutlineCheckCircle } from "react-icons/ai"
// import { toast } from "react-hot-toast"
import { filterBakelistesList, setCurrentBakelisteSlice } from "redux/features/bakelisteSlice";
// import { useBakelisteBloques } from "./api/hooks/useBakelistesBloques"
import Loader from "components/shared/Loader"
import { textFilter } from "react-bootstrap-table2-filter"
// import { useDebloquerBakelisteMutation } from "./api/mutations"
import FadeTransition from "components/shared/FadeTransition"
import { DETAILS_BAKELISTE_PATH } from "routes/navigation/navigationPaths";
// import { useBakelisteActif } from "actions/bakelistes";
import DebloquerAvecMotif from "components/bakelistes/Details/DebloquerAvecMotif";

const ListeBakelistesBloques = ({ bakelisteBloques, isBloquesLoading }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // const { bakelisteBloques, isBloquesLoading } = useBakelisteBloques()

    // const [debloqueBakelisteCompte] = useDebloquerBakelisteMutation()
    // const { refetchBakelisteActif } = useBakelisteActif()
    const [currentBakeliste, setCurrentBakeliste] = useState([])
    const [openMotifDeblocageModal, setOpenMotifDeblocageModal] = useState(false)

    const navigateToBakelisteDetails = (bakeliste) => {
        dispatch(setCurrentBakelisteSlice(bakeliste))
        navigate(DETAILS_BAKELISTE_PATH)
    }
    const debloquerBakeliste = (currentBakeliste) => {
        console.log({ currentBakeliste });
        setCurrentBakeliste(currentBakeliste)
        setOpenMotifDeblocageModal(true)
        // const bakeliste_id = currentBakeliste.id
        // toast.promise(debloqueBakelisteCompte(bakeliste_id).unwrap().then(() => refetchBakelisteActif()), {
        //     loading: "Déblocage en cours...",
        //     success: currentBakeliste.first_name + " débloqué(e)",
        //     error: `Le déblocage de ${currentBakeliste.first_name} a echouée`
        // })
    }

    const actionsFormatter = (cell, row) => {
        return <div className='d-flex gap-4 justify-content-center'>
            <AiOutlineCheckCircle
                onClick={() => debloquerBakeliste(row)}
                data-tip
                data-tooltip-id='details-btn'
                className='cursor-pointer'
                size={20}
                color={rootStyles.color.secondary}
            />

            <ReactTooltip style={tooltip_options} id='details-btn'>
                Débloquez
            </ReactTooltip>
        </div>
    }
    const columns = useMemo(() => [{
        dataField: "first_name",
        text: "Nom complet",
        filter: textFilter({
            placeholder: "Recherche pas nom complet",
        }),
        formatter: (cell, row) => <p style={{ cursor: "pointer" }} onClick={() => navigateToBakelisteDetails(row)}
        >
            {row.first_name} {row.last_name}
        </p>
    },
    {
        dataField: "email",
        filter: textFilter({
            placeholder: "Recherche pas mail",
        }),
        text: "Email",
        },
        {
            dataField: "phone",
            filter: textFilter({
                placeholder: "Recherche pas téléphone",
            }),
            text: "Téléphone",
        },
        {
        dataField: "domaine.name",
        text: "Domaines",
    },
    {
        dataField: "Actions",
        text: "Actions",
        formatter: actionsFormatter
    }
    ], [])

    return (
        <FadeTransition in={true}>
            <DebloquerAvecMotif
                currentBakeliste={currentBakeliste}
                openMotifDeblocageModal={openMotifDeblocageModal}
                setOpenMotifDeblocageModal={setOpenMotifDeblocageModal}
            />
            {
                isBloquesLoading ? <Loader /> : <TableListeView
                    onChangeInputSearch={(value) => dispatch(filterBakelistesList({ value, type: "bloques" }))}
                    data={bakelisteBloques}
                    columns={columns}
                    title="Bakelistes bloqués"
                    navigateToTitle="Liste des bakelistes archivés"
                />
            }
        </FadeTransition>
    )
}

export default ListeBakelistesBloques