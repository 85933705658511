import { rootStyles } from "assets/css/globalCss"
import ButtonComp from "components/ButtonComp"
import React from "react"
import { SiMicrosoftexcel } from "react-icons/si"
import { handleExportCsv } from "utils/handleExportCsv"

const ExportExcel = ({ fileName, data }) => {
    return (
        <ButtonComp
            bgColor={rootStyles.color.secondary}
            color={rootStyles.color.primary}
            icon={{ name: SiMicrosoftexcel }}
            onClick={() => handleExportCsv(fileName, data)}
            title="Exporter .xlsx"
            className={"my-3"}
        />
    )
}

export default ExportExcel