/* eslint-disable no-unused-vars */
import React from "react"
import { MdArrowDropDown } from "react-icons/md";
import { TbExternalLink } from "react-icons/tb";

import * as Styled from "./styles"
import "./test.css"
import ErrorBakeliste from "components/error/ErrorBakeliste";
import Loader from "components/shared/Loader";
import Flex from "components/shared/Flex";
import Title3 from "components/shared/Title3";
import FilterPeriod from "./Filter";
import { useCalendrierPresenceContext } from "context/usePointageCalendarContext";
import { JOUR_DE_FORMATION } from "constants/joursFormations";
import { useAllBakelistePointage } from "actions/suivi-pointage";
import { Badge } from "pages/bakelistes/Details/DetailsBakelistePage";
import { rootStyles } from "assets/css/globalCss";
import { Link } from "react-router-dom";
import { SUIVI_POINTAGE_HEBDO_PATH } from "routes/navigation/navigationPaths";
import FadeTransition from "components/shared/FadeTransition";

// Composant pour une cellule de tableau
const TableCellTh = ({ content, colSpan }) => <Styled.Th colSpan={colSpan}>{content}</Styled.Th>;


/*
 * [data] contient les jours de la semaine, avec les propertes "Matin", "Soir", "Journée", "Total"
 */
const TableRow = ({ period, weekDays }) => {
    // console.log({ weekDays: Object.values(weekDays["lundi"]["Matin"]) });
    return <tr>
        <TableCellTh content={period} />
        {
            JOUR_DE_FORMATION.map((jour, index) => {
                // on verifie si le jour de la semaine est present dans la liste de presence de la semaine
                if (weekDays.hasOwnProperty(jour.toLocaleLowerCase())) {
                    return Object.values(weekDays[jour.toLocaleLowerCase()][period]).map((value, i) => {
                        // console.log({ myarr });
                        return <TableCellTh key={index * 10 + i} content={value} />
                    })
                }
                return [1, 2, 3].map((index) => <TableCellTh key={index} content={""} />)
            })
        }
    </tr>
};

// eslint-disable-next-line valid-jsdoc
/**
 *
 * @param {object} data
 * @returns
 */
// data ={[jeudi :{Matin: {…}, Soir: {…}, Journée: {…}, Total: {…}} ]}
const TableWeek = ({ data }) => (
    <Styled.Table>
        <thead>
            <tr>
                <TableCellTh key={""} content="" />
                <TableCellTh key={"LUNDI"} content="LUNDI" colSpan="3" />
                <TableCellTh key={"MARDI"} content="MARDI" colSpan="3" />
                <TableCellTh key={"MERCREDI"} content="MERCREDI" colSpan="3" />
                <TableCellTh key={"JEUDI"} content="JEUDI" colSpan="3" />
                <TableCellTh key={"VENDREDI"} content="VENDREDI" colSpan="3" />
            </tr>
            <tr>
                <TableCellTh content="" />
                {/* Jour de la semaine */}
                {JOUR_DE_FORMATION.map((index) => {
                    // console.log({ data });
                    return <>
                        <TableCellTh key={index} content="A venir" />
                        <TableCellTh key={index} content="Présent" />
                        <TableCellTh key={index} content="Absent" />
                    </>
                })}
            </tr>
        </thead>
        <tbody>
            <TableRow period="Matin" weekDays={data} />
            <TableRow period="Soir" weekDays={data} />
            <TableRow period="Journée" weekDays={data} />
            <TableRow period="Total" weekDays={data} />
        </tbody>
    </Styled.Table>
);

const SuiviPointageParMois = () => {
    const { currentMonth, setCurrentPointageMonth, currentVille } = useCalendrierPresenceContext()
    const { pointages = [], isError, isLoading, refetch } = useAllBakelistePointage({ ville: currentVille })

    return (
        <FadeTransition>
            <Styled.Content>
                <Styled.MainContent>
                    <Styled.Header>
                        <Flex justify={"space-between"}>
                            <Flex gap={10} align={"center"}>
                                <Title3>Présence bakelistes</Title3>
                                {currentVille ? <Badge
                                    bgColor={rootStyles.color.red}
                                    color={rootStyles.color.primary}
                                >{currentVille}</Badge> : ""}
                            </Flex>
                            <FilterPeriod />
                        </Flex>
                    </Styled.Header>
                    <Styled.TableOverflow>
                        {
                            isError ? <ErrorBakeliste /> :
                                isLoading ? <Loader /> :
                                    <Styled.TableContainer>
                                        {
                                            !Object.keys(pointages.data).length ? <p>Pas encore de mois</p> : Object.entries(pointages.data[currentMonth.toLocaleLowerCase()]).map((weekDays, id) => {
                                                return <Styled.Week key={id}>
                                                    <h4 className="d-flex justify-content-center fs-6">
                                                        <Styled.ExternalLink
                                                            // eslint-disable-next-line max-len
                                                            title={`RV Hebdo, semaine du ${new Date(weekDays[1].startOfWeek).toLocaleDateString("fr-Fr", { day: "2-digit", month: "long", year: "numeric" })}`}
                                                            to={`${weekDays[1].startOfWeek}` + `?zone=${currentVille}`}>
                                                            {weekDays[0]} <TbExternalLink />
                                                        </Styled.ExternalLink>
                                                    </h4>
                                                    <TableWeek data={weekDays[1]} />
                                                </Styled.Week>
                                            })
                                        }
                                    </Styled.TableContainer>
                        }
                    </Styled.TableOverflow>
                </Styled.MainContent>
                <Styled.Footer>
                    {
                        isLoading ? null : !Object.keys(pointages.data).length ? <p>Pas encore de mois</p> :
                            Object.keys(pointages.data).map((mois, index) => {
                                return <Styled.PeriodLabel
                                    onClick={() => setCurrentPointageMonth(mois)}
                                    active={mois.toLocaleLowerCase() === currentMonth.toLocaleLowerCase()}
                                    key={index}
                                >
                                    {mois}<MdArrowDropDown size={25} />
                                </Styled.PeriodLabel>
                            })
                    }
                </Styled.Footer>
            </Styled.Content>
        </FadeTransition>
    )
}

export default SuiviPointageParMois