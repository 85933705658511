import { rootStyles } from "assets/css/globalCss";
import ButtonComp from "components/ButtonComp";
import ProgressBar from "components/ProgressBar";
import FadeTransition from "components/shared/FadeTransition";
import Flex from "components/shared/Flex";
import Loader from "components/shared/Loader";
import TableListeView from "components/shared/TableListeView";
import { useBakelistePresentiel } from "pages/bakelistes/api/hooks/useBakelistePresentiel";
import { useBakelisteEnCoursDeFormation } from "pages/suivi-bakelistes/api/hooks/useGetBakelisteEnCoursDeFormation";
import { ClickPane } from "pages/suivi-coaching/ui/home/SuiviCoachingpage";
import React, { useMemo } from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import { AiOutlineUserAdd } from "react-icons/ai";
import { BsEye } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentBakelisteSuivi } from "redux/features/bakelisteSlice";
import {
  ADD_BAKELISTE_PATH,
  BAKELISTES_PATH,
  DETAILS_SUIVI_BAKELISTES_PATH,
} from "routes/navigation/navigationPaths";

const SuiviBakelistePage = () => {
  const { isPresentielLoading } = useBakelistePresentiel();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { bakelistesEnCoursDeFormaion } = useBakelisteEnCoursDeFormation();

  const navigateToDetails = (row) => {
    dispatch(setCurrentBakelisteSuivi(row));
    return navigate(DETAILS_SUIVI_BAKELISTES_PATH);
  };

  const columns = useMemo(
    () => [
      {
        dataField: "first_name",
        text: "Nom complet",
        filter: textFilter({
          placeholder: "Recherche pas nom complet",
        }),
        formatter: (cell, row) => (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => navigateToDetails(row)}
          >
            {row?.first_name} {row?.last_name}
          </p>
        ),
      },
      {
        dataField: "email",
        text: "Email",
        formatter: (cell, row) => (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => navigateToDetails(row)}
          >
            {row?.email}
          </p>
        ),
        filter: textFilter({
          placeholder: "Recherche par mail",
        }),
      },
      {
        dataField: "phone",
        text: "Téléphone",
        formatter: (cell, row) => (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => navigateToDetails(row)}
          >
            {row?.phone}
          </p>
        ),
        filter: textFilter({
          placeholder: "Recherche par telephone",
        }),
      },
      {
        dataField: "domaine.name",
        formatter: (cell, row) => (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => navigateToDetails(row)}
          >
            {row?.domaine?.name}
          </p>
        ),
        text: "Domaines",
      },
      {
        dataField: "global_progression",
        text: "Progression globale",
        formatter: (cell, row) => {
          return (
            <ProgressBar
              value={row?.global_progression}
              max={100}
              height={"15px"}
              color={rootStyles.color.secondary}
            />
          );
        },
      },
      {
        dataField: "Actions",
        text: "Actions",
        formatter: (cell, row) => {
          return (
            <div
              onClick={() => navigateToDetails(row)}
              className="d-flex justify-content-center"
            >
              <ClickPane className="focus--click">
                <BsEye size={20} color="#069a77" />
              </ClickPane>
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <>
      <Flex justify="flex-end" align="center">
        <ButtonComp
          onClick={() => navigate(BAKELISTES_PATH + "/" + ADD_BAKELISTE_PATH)}
          icon={{
            name: AiOutlineUserAdd,
            size: 20,
          }}
          bgColor={rootStyles.color.secondary}
          color={rootStyles.color.primary}
          title="Nouveau bakeliste"
        />
      </Flex>
      <FadeTransition in={true}>
        {isPresentielLoading ? (
          <Loader />
        ) : (
          <TableListeView
            data={bakelistesEnCoursDeFormaion.data}
            columns={columns}
            title="Suivi des bakelistes"
            tableLength={bakelistesEnCoursDeFormaion.data.length}
          />
        )}
      </FadeTransition>
    </>
  );
};

export default SuiviBakelistePage;
