import React, { useEffect } from "react";
import CommentHeader from "./CommentHeader";
import "./Styles/Comment.scss";

const Reply = ({ commentData, commentPostedTime, livraison_id, parent_id }) => {
  useEffect(() => {}, [commentPostedTime]);

  const commentContent = () => {
    const text = commentData.comment_text.trim().split(",");
    const firstWord = text.shift();

    return (
      <div className="comment-content">
        <span className="replyingTo">{firstWord},</span>
        {text.join(" ")}
      </div>
    );
  };

  return (
    <div
      className={`comment-container ${commentData !== undefined ? "gap" : ""}`}
    >
      <div className="comment">
        <div className="comment--body">
          <CommentHeader commentData={commentData} />

          {commentContent()}
        </div>
      </div>
      {commentData?.replies?.map((reply) => (
        <Reply
          key={reply.id}
          commentData={reply}
          commentPostedTime={commentPostedTime}
        />
      ))}
    </div>
  );
};

export default Reply;
