import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";

const schema = Yup.object().shape({
  libelle: Yup.string().required("Libellé obligatoire"),
  heure_debut: Yup.string().required("Heure début obligatoire"),
  heure_fin: Yup.string().required("Heure fin obligatoire")
})

export const TypesPresenceProvider = ({ children, initialValues }) => {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...initialValues
    }
  })
  return <FormProvider {...methods}>{children}</FormProvider>
}