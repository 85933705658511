
import coachsController from "./coachApi";
import domainesController from "./domaineApi";
import bakelistesController from "./bakelisteApi";
import prospectsController from "./prospectApi";
import evenementsController from "./evenementApi";
import siKpisController from "./siKpiApi";
import examensController from "./examenApi";
import paiementsController from "./paiementsApi";

export const ApiController = {
    siKpis: siKpisController,
    coachs: coachsController,
    domaines: domainesController,
    bakelistes: bakelistesController,
    prospects: prospectsController,
    evenements: evenementsController,
    examens: examensController,
    paiements: paiementsController
}