import Input from "components/Input"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { filterAllBakeliste } from "redux/features/bakelisteSlice"

const Search = () => {
    const { research } = useSelector((state) => state.bakelistes)
    const dispatch = useDispatch()
    return (
        <div>
            {
                !research && <Input
                    value={research}
                    onChange={(value) => {
                        dispatch(filterAllBakeliste(value))
                    }}
                    placeholder={"Rechercher bakelistes"}
                    autoFocus
                />
            }
        </div>
    )
}

export default Search