import ResourceDataMap from "components/ResourceDataMap";
import DriveLine, { DriveHead } from "components/examens/DriveLine";
import Loader from "components/shared/Loader";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GET_BULLETINS_BY_TRIMESTRE_ENDPOINT } from "routes/api/endpoints";
import useSWR from "swr";
import { axiosSwrFetcherForBulletins } from "../api";
import {
  setCurrentEpreuveDomaine,
  setCurrentTrimestreBulletins,
} from "redux/features/examenSlice";
import { LISTE_BULLETINS_PATH } from "routes/navigation/navigationPaths";
import { Title2 } from "components/shared/TableListeView";
import FadeTransition from "components/shared/FadeTransition";

const BulletinsDomainesPage = () => {
  const { currentTrimestre } = useSelector((state) => state.examens);
  // ;
  const dispatch = useDispatch();
  const { data = {}, isLoading } = useSWR(
    GET_BULLETINS_BY_TRIMESTRE_ENDPOINT + currentTrimestre.id,
    axiosSwrFetcherForBulletins
  );

  const navigate = useNavigate();
  const goToListBulletinsPage = (epreuveDomaine, bulletins) => {
    dispatch(setCurrentEpreuveDomaine(epreuveDomaine));
    dispatch(setCurrentTrimestreBulletins(bulletins));
    return navigate(LISTE_BULLETINS_PATH);
  };

  const currentTrimestreStr = `${currentTrimestre.trimestre} - ${new Date(
    currentTrimestre.annee_academique
  ).getFullYear()}`;

  return (
    <FadeTransition in={true}>
      <Title2>{currentTrimestreStr}</Title2>
      <DriveHead
        label1="Domaines"
        // label2="Trimestres"
        label3="Nombre"
      />
      {isLoading ? (
        <Loader />
      ) : (
        <ResourceDataMap
          resourceName="domaine"
          resourceData={Object.entries(data)}
          resourceItem={({ domaine }) => {
            return (
              <DriveLine
                label1={domaine[0]}
                // label2={`${currentTrimestre.trimestre} - ${new Date(
                //   currentTrimestre.annee_academique
                // ).getFullYear()}`}
                label3={domaine[1].length}
                onClick={() => goToListBulletinsPage(domaine[0], domaine[1])}
              />
            );
          }}
        />
      )}
    </FadeTransition>
  );
};

export default BulletinsDomainesPage;
