import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";

const bloquerSchema = Yup.object().shape({
    motif_pause: Yup.string().required("Veuillez fournir le motif d'arrêt de la formation")
})

export const PauseBakelisteWithMotifProvider = ({ children }) => {
    const methods = useForm({
        resolver: yupResolver(bloquerSchema),
        defaultValues: {
            motif_pause: ""
        }
    })
    return <FormProvider {...methods}>{children}</FormProvider>
}