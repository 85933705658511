import { useBakelisteInActif } from "actions/bakelistes"
import TabsProvider from "components/Tabs/TabsProvider"
import ErrorBakeliste from "components/error/ErrorBakeliste"
import FadeTransition from "components/shared/FadeTransition"
import Loader from "components/shared/Loader"
import React from "react"
import ListeBakelistesBloques from "../ListeBakelistesBloques"
import ListeBakelisteEnPausePage from "../ListeBakelisteEnPausePage"

const BakelistesNonActifPage = () => {
    const { bakelisteInActifs, isInactifError, isInactifLoading } = useBakelisteInActif()

    console.log({ bakelisteInActifs });

    return (
        <FadeTransition in={true}>
            {
                isInactifLoading ?
                    <Loader /> :
                    isInactifError ?
                        <ErrorBakeliste /> :
                        <TabsProvider
                            content={
                                [
                                    {
                                        title: "Bloqués",
                                        number: bakelisteInActifs.countbakelistesBloque,
                                        content: () => <ListeBakelistesBloques
                                            bakelisteBloques={bakelisteInActifs.bakelistesBloque}
                                            isBloquesLoading={isInactifLoading}
                                        />
                                    },
                                    {
                                        title: "En pause",
                                        number: bakelisteInActifs.countbakelistesEnPause,
                                        content: () => <ListeBakelisteEnPausePage
                                            bakelisteEnPause={bakelisteInActifs.bakelistesEnPause}
                                            isEnPauseLoading={isInactifLoading}
                                        />
                                    },
                                ]}
                        />
            }
        </FadeTransition>
    )
}

export default BakelistesNonActifPage