import { rootStyles } from "assets/css/globalCss";
import Modal from "components/Modal";
import FlexBetween from "components/shared/FlexBetween";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import bakeliLogo from "../../../../assets/img/bakeli2.png";
import senegalLogo from "../../../../assets/img/senegal.png";

const LeftPane = styled.div`
  display: flex;
`;
const RightPane = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
const LogoPane = styled.div`
  width: 70px;
  margin-bottom: 10px;
`;
const InfoPane = styled.div`
  margin-left: 10px;
`;
const PersonalInfoPane = styled.div`
  margin-bottom: 15px;
`;
const Paragraphe = styled.p`
  font-size: ${rootStyles.fonts.fontSize};
  margin-bottom: 5px;
`;
const SpanWeight = styled.span`
  font-weight: 600;
`;
const Image = styled.img`
  width: 100%;
`;

const BulletinEnTete = ({ opened, onClosed, children }) => {
  const { currentBulletin } = useSelector((state) => state.examens);
  return (
    <Modal
      title={"Bulletin"}
      opened={opened}
      onClosed={onClosed}
      modalWidth={1000}
    >
      <FlexBetween>
        <LeftPane>
          <LogoPane>
            <Image src={bakeliLogo} alt="Bakeli School Of Technology" />
          </LogoPane>
          <InfoPane>
            <p style={{ marginBottom: 2 }}>BAKELI School of Technology</p>
            <Paragraphe>Hlm grand yoff, villa n°241</Paragraphe>
            <Paragraphe>Téléphone : +221 33 867 75 06</Paragraphe>
          </InfoPane>
        </LeftPane>
        <RightPane>
          <LogoPane>
            <Image src={senegalLogo} alt="Drapeau Senegal" />
          </LogoPane>
          <div>
            <Paragraphe>République du SENEGAL</Paragraphe>
            <Paragraphe>Ministère de la formation professionnelle</Paragraphe>
          </div>
        </RightPane>
      </FlexBetween>

      <PersonalInfoPane>
        <Paragraphe>
          <SpanWeight>Prénom</SpanWeight>: {currentBulletin.user.first_name}
        </Paragraphe>
        <Paragraphe>
          <SpanWeight>Nom</SpanWeight>: {currentBulletin.user.last_name}
        </Paragraphe>
        <Paragraphe>
          <SpanWeight>Adresse</SpanWeight>: {currentBulletin.user.address}
        </Paragraphe>
        <Paragraphe>
          <SpanWeight>Téléphone</SpanWeight>: {currentBulletin.user.phone}
        </Paragraphe>
      </PersonalInfoPane>

      {/* <HeaderPane id="header-pane">
        Epreuve {currentEpreuveType} {currentEpreuveModule}
      </HeaderPane> */}
      {children}
    </Modal>
  );
};

export default BulletinEnTete;
