import FadeTransition from "components/shared/FadeTransition"
import TableListeView from "components/shared/TableListeView"
import React, { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { BAKELISTES_ARCHIVES_PATH } from "routes/navigation/navigationPaths"

const ListeBakelistesPage = () => {
    const navigate = useNavigate()
    const columns = useMemo(() => [{
        dataField: "id",
        text: "Nom complet",
        // filter: textFilter({
        //     placeholder: 'Nom complet',
        // })
    },
    {
        dataField: "name",
        text: "Domaine",
        // filter: textFilter({
        //     placeholder: 'Domaine',
        // })
    },
    {
        dataField: "price",
        text: "Email",
        // filter: textFilter({
        //     placeholder: 'Email',
        // })
    },
    {
        dataField: "price",
        text: "Nbr Bakelistes",
        // filter: textFilter({
        //     placeholder: 'Nbr Bakelistes',
        // })
    },
    {
        dataField: "price",
        text: "Numéro",
        // filter: textFilter({
        //     placeholder: 'Numéro',
        // })
    },
    {
        dataField: "price",
        text: "Actions",
    },
    ], [])
    return (
        <FadeTransition in={true}>
            <TableListeView
                columns={columns}
                title="Listes des Bakelistes"
                navigateTo={() => navigate(BAKELISTES_ARCHIVES_PATH)}
                navigateToTitle="Bakelistes archivés"
            />
        </FadeTransition>
    )
}

export default ListeBakelistesPage