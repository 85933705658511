/* eslint-disable no-unused-vars */
// import { Title2 } from "components/shared/TableListeView"
import { useAllBakelistePointage } from "actions/suivi-pointage"
// import Title3 from "components/shared/Title3"
import SuiviPointageParMois from "components/suivi-pointage/Mois/SuiviPointageParMois"
import CalendrierPresenceProvider from "context/usePointageCalendarContext"
// import { useMoisPaiement } from "pages/paiements/api/hooks/useMoisPaiement"
import React, { useEffect } from "react"

const SuiviPointageBakelistes = () => {
    return (
        <div>
            <>
                <SuiviPointageParMois />
            </>
        </div>
    )
}

export default SuiviPointageBakelistes